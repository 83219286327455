//id : unique id
//v1 : name
//v2: emailId
//v3: phoneNumber
//v4: Linked In User Name
//v5: Comments

const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  userUpdated: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "ADD_USER_SUCCESS":
    //return action.payload;
    return {
      ...state,
      Items: [...state.Items, action.payload],
      Count: state.Count + 1,
    };
  case "GET_USER_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Items: action.payload.Items,
      isLoading: false,
    });
  case "UPDATE_USER_SUCCESS":
    //return action.payload;
    return {
      ...state,
      userUpdated: true,
      // Items: [...state.Items, action.payload],
    };
  case "USER_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  case "RESET_USER":
    return defaultState;
  default:
    return state;
  }
}
