import React, { useState, useEffect, useCallback } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'

const FinanceDetails = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const sponsorId = searchParams.get('sponsor_id')
  const programId = searchParams.get('program_id')

  const history = useHistory()

  const [values, setValues] = useState({
    Program_Id: '',
    Program_Name: '',
    Name_of_the_remitter: '',
    Transfer_Amount: '',
    Transfer_Description: '',
    Date_of_transaction: '',
    UTR_No: '',
    Bank_Name: '',
    From_Account: '',
    Beneficiary_Name: '',
    IFSC_Code: '',
    Bank_Account_no: '',
    payment_to_service_partner:''
  })

  const handleChange = (e) => {
    console.log(e.target.value)
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    fetchProgramDetail(searchParams.get('program_id'))
  }, [])

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    console.log(values)
    console.log(values.Name_of_the_remitter?.trim())
    // Validations
    if(values.Name_of_the_remitter?.trim() === ''){
      alert("Please enter remitter name")
      return 
    }else if(values.Date_of_transaction === ''){
      alert("Please enter transaction date.")
      return
    }else if(values.UTR_No === ''){
      alert("Please enter UTR number")
      return
    }else if(values.Bank_Name === ''){
      alert("Please enter bank name")
      return
    }else if(values.IFSC_Code === ''){
      alert("Please enter ifsc")
      return
    }else if(values.From_Account === ''){
      alert("Please enter account number")
      return
    }else if(values.payment_to_service_partner === ''){
      alert("Please select payment type.")
      return
    }

    try {
      const data = {
        iud_flag: 'I',
        program_id: values.Program_Id,
        sponsor_id: sponsorId,
        course_id: null,
        exp_catg_id: null,
        beneficiary_id: null,
        utr_no: values.UTR_No,
        ifsc_code: values.IFSC_Code,
        bank_name: values.Bank_Name,
        description: values.Transfer_Description,
        vendor_id: null,
        amount: values.Transfer_Amount,
        disbursed_amt_date: values.Date_of_transaction,
        payment_to_service_partner:values.payment_to_service_partner,
        name_of_remitter: values.Name_of_the_remitter,
        bank_account_no: values.From_Account
      }

      await axios.post(`${baseURL}setProgramFundsDisbursed`, data, jwtHeader)
      Swal.fire('Success', 'Finance details submitted successfully', 'success')
      history.replace('/sponsor/dashboard')
    } catch (error) {
      console.error('Error submitting finance details:', error)
      Swal.fire('Error', 'Failed to submit finance details', 'error')
    }
  }

  const fetchProgramDetail = useCallback(async (programId) => {
    const response = await axios.get(`${baseURL}getProgramById/${programId}`, {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })

    setValues({
      ...values,
      Program_Id: response.data.Content[0].program_id,
      Program_Name: response.data.Content[0].program_name,
      Transfer_Amount: response.data.Content[0].amount_to_start_program,
    })
  })
  return (
    <div className="container">
      <Card>
        <Card.Header>Details of CSR funds remitted</Card.Header>
        <Card.Body style={{ padding: '0px 11px', marginTop: '10px' }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Program Name</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Program Name"
                      name="Program_Name"
                      value={values.Program_Name}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Transfer Amount</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Transfer Amount"
                      name="Transfer_Amount"
                      value={values.Transfer_Amount}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Transfer Description</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="Transfer_Description"
                      value={values.Transfer_Description}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Name of the Remitter</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="Name_of_the_remitter"
                      value={values.Name_of_the_remitter}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Date of Transaction</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="date"
                      placeholder=""
                      name="Date_of_transaction"
                      value={values.Date_of_transaction}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                {/* Add more rows for left column */}
              </Col>

              <Col sm={6}>
                {/* Right Column */}

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">UTR No</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="UTR_No"
                      value={values.UTR_No}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Bank Name</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="Bank_Name"
                      value={values.Bank_Name}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">IFSC Code</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="IFSC_Code"
                      value={values.IFSC_Code}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Account Number</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="From_Account"
                      value={values.From_Account}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} style={{ backgroundColor: '#002347' }}>
                    <p className="labelText">Payment to service partner</p>
                  </Col>
                  <Col sm={6} className="mb-3">
                    <Form.Select
                      name="payment_to_service_partner"
                      value={values.payment_to_service_partner}
                      onChange={handleChange}
                    >
                      <option value="">-Select-</option>
                      <option value="Direct">Direct <span style={{fontSize:"10px"}}>(no beneficiary authorization required)</span></option>
                      <option value="Indirect">Indirect (credit to beneficiary wallet for authorizing payment to service partner)</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <div style={{marginTop:"20px"}}>
                <p style={{fontSize:"11px"}}><b>Note:</b>Please fill these fields carefully. You'll not be able to update, once submitted.</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                  marginTop: '10px',
                  justifyContent: 'center',
                }}
              >
                <Button
                  style={{ width: '100px', backgroundColor: '#002347', borderColor: '#002347' }}
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  style={{
                    width: '100px',
                    marginLeft: '20px',
                    backgroundColor: '#002347',
                    borderColor: '#002347',
                  }}
                  type="submit"
                  onClick={() => history.replace('/sponsor/dashboard')}
                >
                  Skip
                </Button>
              </div>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}

export default FinanceDetails
