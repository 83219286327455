import { combineReducers } from "redux";
import AuthReducer from "./reducer-Auth";
import ComboReducer from "./reducer-Combo";
import CoursesReducer from "./reducer-Courses";
import LeadsReducer from "./reducer-Leads";
import MasterReducer from "./reducer-Master";
import NotificationReducer from "./reducer-Notification";
import ProgramsReducer from "./reducer-Programs";
import UsersReducer from "./reducer-Users";
import BuildReducer from "./reducer-Build";
import ValidatorsReducer from "./reducer-Validators";
import VendorsReducer from "./reducer-Vendors";
import StudentsReducer from "./reducer-Students";

export default function getRootReducer() {
  return combineReducers({
    auth: AuthReducer,
    combo: ComboReducer,
    courses: CoursesReducer,
    leads: LeadsReducer,
    master: MasterReducer,
    notification: NotificationReducer,
    programs: ProgramsReducer,
    users: UsersReducer,
    build: BuildReducer,
    validators: ValidatorsReducer,
    vendors: VendorsReducer,
    students: StudentsReducer,
  });
}
