import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { baseURL } from '../../config/baseURLPath';

const CGPA = () => {
  const [chartData, setChartData] = useState({ categories: [], seriesData: [] });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));

    axios({
      method: 'get',
      url: baseURL + 'sponsor-cgpa-wise/' + userData.Sponsor.sponsor_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then(response => {
        const fetchedData = response?.data?.data?.CGPA;
        const categories = fetchedData.map(item => item.CGPA);
        const seriesData = fetchedData.map(item => item.CGPA_COUNT);

        setChartData({ categories, seriesData });
      })
      .catch(error => {
        console.error('Error fetching CGPA data:', error);
      });
  }, []);

  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'CGPA',
    },
    subtitle: {
      text: '(Beneficiaries Count)',
    },
    xAxis: {
      categories: chartData.categories, // Dynamic CGPA ranges
      crosshair: true,
      title: {
        text: 'CGPA Range',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Number of Students',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y} students</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: 'black',
          },
        },
      },
    },
    series: [
      {
        name: 'Students',
        data: chartData.seriesData, // Dynamic student count data
      },
    ],
  };

  return (
    <div className='chart-container'>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default CGPA;
