//id : unique id
//v1 : name
//v2: emailId
//v3: phoneNumber
//v4: Linked In User Name
//v5: Comments

const defaultState = {
  hasErrored: false,
  // isLoading: false,
  benefeciaryItems: [],
  welfareItems: [],
  gender: [],
  Incomegroup: [],
  homestate: [],
  state: [],
  city: [],
  sports: [],
  skills: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "GET_BENEFECIARY_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      benefeciaryItems: action.payload.Items,
      // isLoading: false,
    });
  case "GET_WELFARETYPE_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      welfareItems: action.payload.Items,
      // isLoading: false,
    });
  case "GET_GENDER_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      gender: action.payload.Items,
      // isLoading: false,
    });
  case "GET_INCOME_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Incomegroup: action.payload.Items,
      // isLoading: false,
    });
  case "GET_HOME_STATE_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      homestate: action.payload.Items,
      // isLoading: false,
    });
  case "GET_STATE_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      state: action.payload.Items,
      // isLoading: false,
    });
  case "GET_CITY_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      city: action.payload.Items,
      // isLoading: false,
    });
  case "GET_SPORTS_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      sports: action.payload.Items,
      // isLoading: false,
    });
  case "GET_SKILL_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      skills: action.payload.Items,
      // isLoading: false,
    });
  case "ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  default:
    return state;
  }
}
