//id : unique id
//v1 : name
//v2: emailId
//v3: phoneNumber
//v4: Linked In User Name
//v5: Comments

const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "ADD_LEAD_SUCCESS":
    //return action.payload;
    return {
      ...state,
      Items: [...state.Items, action.payload],
      Count: state.Count + 1,
    };
  case "GET_LEAD_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Count: action.payload.Items.length,
      Items: action.payload.Items,
      isLoading: false,
    });
  case "ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  case "ITEMS_IS_LOADING":
    return Object.assign({}, state, {
      Items: [],
      isLoading: action.isLoading,
    });
  case "RESET_LEAD":
    return {
      ...state,
      Count: 0,
      Items: [],
    };

  default:
    return state;
  }
}
