import React, { useState, } from "react"
import { Row, Col, Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import "../admin.css"

import CreateProgram from "./CreateProgram";

const InstituionPage = () => {
  const history = useHistory();
  
  const [activeTab, setActiveTab] = useState(1) 

  const changeActiveTab = (val) =>{
    if(val === 0){
      history.push("/service-partner/course-list")
    }
  }

  return (
    <div className="container">
      <Row>
        <Col>
          <Button onClick={()=> setActiveTab(1)} className={activeTab === 1 ? "instituionTabSection active" : "instituionTabSection"}>New Course</Button>
  
        </Col>
        <CreateProgram  changeActiveTab={(val)=> changeActiveTab(val)} />
       
      </Row>
    </div>
  )
}

export default InstituionPage
