import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import { baseURL } from "../../../config/baseURLPath"
import DataTable from "react-data-table-component"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import CustomInput from "../../utils/CustomInput"
import "../admin.css"
import swal from "sweetalert2";
const List = (props) => {
  const history = useHistory();
  const [users, setUsers] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [activeAdmin, setActiveAdmin] = useState("");

  const [activeId, setActiveId] = useState(props.id);
  const [confirmationModal, setConfirmationModal] = useState(false);

  
  const handleClose = () => {
    setConfirmationModal(false)
  }

  const jwtHeader = {
    headers: { "access-token": localStorage.getItem("csr_token") },
  }
  useEffect(() => {
    getAdminUsers()
    fetchRoles()
  }, [])

  const getAdminUsers = () => {
    axios({
      method: "get",
      url: baseURL + "auth/getSponsorUsers/"+props.id,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })
      .then((response) => {
        setUsers(response.data.Data)
      })
      .catch((error) => {})
  }
  let tableHeader = [
    {
      name: "#",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: "Name",
      selector: (row) => row?.admin_name,
    },
    {
      name: "Email",
      selector: (row) => row?.admin_email,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile_number,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="btn btn-sm primaryAdminButton" onClick={() => editUser(row.admin_id)}>
            <i className="fa fa-edit"></i>
          </button>
          {/* <button className="btn btn-sm primaryAdminButton" style={{marginLeft:"5px"}} onClick={() => changePassword(row.admin_email)}>
            <i className="fa fa-key"></i>
          </button> */}
          <button
            target="_blank"
            className="btn btn-sm"
            role="button"
            style={{backgroundColor:"#ff1a1a",color:"#ffffff", marginLeft:"5px"}}
            onClick={()=> deleteStudent(row.admin_id)}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const deleteStudent = (id) =>{
    setActiveId(id)
    setConfirmationModal(true);
  }

  const deleteRecord = () => {
    setConfirmationModal(false);
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
    }
    axios.post(
      baseURL + "auth/deleteInstitutionUser",
      {
        admin_id: activeId,
        institution_id: props.id
      },
      jwtHeader
    )
      .then((response) => {
        getAdminUsers()
        new swal({
          title: "Done!",
          text: "Student record removed successfully",
          icon: "success",
          timer: 2000,
          button: false
        }).then((result) => {
        })
      })
      .catch((error) => {
        // alert('Something went wrong. Please try again.')
      })
  }

  const editUser = (admin_id) =>{
    props.changeActiveTab(3, admin_id)
  }

  const changePassword = (admin_email) =>{
    setActiveAdmin(admin_email);
    setShowModal(true)
  }

  const successAction = () =>{
    if(password.trim() === ""){
      return
    }
    const values = {
      admin_email:activeAdmin,
      password:password.trim()
    }
    axios
      .post(baseURL + "auth/updateAdminPass", values, jwtHeader)
      .then((res) => {
        setShowModal(false);
        alert("Password updated successfully")
      })
      .catch((err) => console.log(err))
  }

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    user_email: "",
    role_id: "",
    city_id: "",
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const [roles, setRoles] = useState([])
  const fetchRoles = () => {
    axios
      .get(baseURL + "getUserRoles")
      .then((response) => {
        // console.log(response);
        setRoles(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSearch = () => {
    axios
      .post(baseURL + "auth/searchAdminUsers", values)
      .then((res) => {
        console.log(res)
        setUsers(res.data.Admin)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className="container" style={{ marginTop: "10px" }}>
      <Card>
        <Card.Header as="h4" style={{ color: "#000", backgroundColor:"#ffffff" }}>
          <div style={{ float: "right", marginBottom: "10px" }}>
            <Button className="btn primaryAdminButton" role="button" onClick={()=> props.changeActiveTab(2, 0)}>
              Add Users
            </Button>
            <Button className="btn primaryAdminButton" role="button" style={{marginLeft:"5px"}} onClick={(e)=> {e.preventDefault(); history.push("/sponsor/dashboard")}}>
              Back
            </Button>
          </div>
        </Card.Header>
        {/* <Card.Body>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Select name="role_id" value={values.role_id} onChange={handleChange}>
                  <option value="">Select Role</option>
                  {roles?.map((role) => (
                    <option value={role.role_id} key={role.role_id}>
                      {role.role_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="admin_name"
                  placeholder="Enter Admin Name"
                  value={values.admin_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  name="admin_email"
                  placeholder="Enter Email"
                  value={values.admin_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="tel"
                  name="mobile_number"
                  placeholder="Enter Mobile"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Button onClick={handleSearch} className="primaryAdminButton" >Search</Button>
            </Col>
          </Row>
        </Card.Body> */}
      </Card>
      <DataTable columns={tableHeader} data={users} pagination />
      <Modal size="sm" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title>
            <Row>
              <Col>
                <p className="modalHeading">Change Password</p>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <CustomInput
              type="text"
              placeholder="New Password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />       
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button
            className="popupButtonFalse"
            onClick={(e) => {
              e.preventDefault()
              handleClose()
            }}
          >
            CANCEL
          </Button>
          <Button
            className="popupButtonTrue"
            onClick={(e) => {
              e.preventDefault()
              successAction()
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <button
            style={{marginLeft:"5px", color:"#ffffff"}} 
            className="btn btn-sm primaryAdminButton" variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            style={{marginLeft:"5px", color:"#ffffff"}} 
            className="btn btn-sm primaryAdminButton" variant="primary" onClick={() => deleteRecord()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default List
