import React, { useState, useEffect } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Card, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import Swal from 'sweetalert2'
//import "primeflex/primeflex.css"
// import "./list.css";

const List = () => {
  const [beneficiaries, setBeneficiaries] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    beneficiary_first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    beneficiary_email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    beneficiary_dob: { value: null, matchMode: FilterMatchMode.CONTAINS },
    program_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    institute_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    beneficiaries: { value: null, matchMode: FilterMatchMode.IN },
  })
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    fetchBeneficiaries()
  }, [])

  const fetchBeneficiaries = async () => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    let sponsorData = JSON.parse(localStorage.getItem('parent_data'))

    try {
      const response = await axios.get(
        baseURL + 'getBeneficiaryBySponsorId/' + sponsorData?.sponsor_id,
        jwtHeader,
      )
      console.log(response, 'Response')
      setBeneficiaries(response.data.Data)
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    )
  }

  const beneficiaryDocument = async (id) => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    let response = await axios.get(baseURL + 'getBeneficiaryDocuments/' + id, jwtHeader)

    console.log(response, 'Response')

    if (response?.data.Content.length === 0) {
      Swal.fire({
        title: 'No Documents Available!',
        timer: 2000,
      })
    }


    if(response?.data.Content.length>0){

      const data=response?.data.Content;

      history.push(`/sponsor/beneficiary-documents/${id}`,data)
    }
  }

  const header = renderHeader()

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Card>
        <Card.Header as="h4" style={{ color: '#000', backgroundColor: '#ffffff' }}>
          <div style={{ float: 'right', marginBottom: '10px' }}>Beneficiaries</div>
          {/* Additional Back button for navigation */}
          <Button
            className="btn primaryAdminButton"
            role="button"
            style={{ marginLeft: '5px' }}
            onClick={(e) => {
              e.preventDefault()
              history.push('/sponsor/dashboard')
            }}
          >
            Back
          </Button>
        </Card.Header>
        <Card.Body>
          <DataTable
            scrollHeight="400px"
            value={beneficiaries}
            paginator
            rows={10}
            dataKey="id"
            showGridlines
            stripedRows
            filters={filters}
            loading={loading}
            filterDisplay="row"
            globalFilterFields={['beneficiary_first_name', 'beneficiary_email']}
            header={header}
            emptyMessage="No beneficiaries found."
            style={{ fontSize: '14px' }}
          >
            <Column
              field="beneficiary_first_name"
              header="Name"
              filter
              filterPlaceholder="Search by name"
              emptyMessage="No beneficiaries found!."
            />
            <Column
              field="beneficiary_email"
              header="Email"
              filter
              filterPlaceholder="Search by email"
              emptyMessage="No emails found!"
            />
            <Column header="DOB" sortable field="beneficiary_dob" />
            <Column
              header="Program Name"
              field="program_name"
              filter
              filterPlaceholder="Search by program name"
            />
            <Column
              header="Institute Name"
              filter
              filterPlaceholder="Search by Institute name"
              field="institute_name"
            />
            <Column
              header="Status"
              body={(rowData) => (
                <div>
                  {rowData?.programstatus===1? "Active":"Inactive"}
                </div>
              )}
            />
            <Column
              header="Action"
              body={(rowData) => (
                <div>
                  <Button
                    onClick={() => beneficiaryDocument(rowData.beneficiary_id)}
                    className="p-button-rounded p-button-secondary"
                    style={{
                      backgroundColor: '#002347',
                      borderColor: '#002347',
                      marginRight: '2px',
                    }}
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </Button>
                </div>
              )}
            />
          </DataTable>
        </Card.Body>
      </Card>
    </div>
  )
}

export default List
