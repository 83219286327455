import React, { useEffect, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Card, Button, Row, Col, Form, Modal } from 'react-bootstrap'
import { useHistory, useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import Swal from 'sweetalert2'

const UpdateProgress = () => {
  const [beneficiaries, setBeneficiaries] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [isUpdate, setIsUpdate] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const location = useLocation()

  const [values, setValues] = useState({
    beneficiary_prog_id:'',
    beneficiary_id: '',
    sponsor_id: '',
    program_id: '',
    course_id: '',
    progress_date: '',
    progress_details: '',
    milestone: '',
    percentage_completion: '',
  })

  useEffect(() => {
    UpdateBeneficiaryProgress()
    const fetchBeneficiaryProgress = async (id) => {
      const jwtHeader = {
        headers: { 'access-token': localStorage.getItem('csr_token') },
      }

      let VendorData = JSON.parse(localStorage.getItem('user'))

      try {
        const response = await axios.get(
          baseURL + 'getBeneficiaryProgress/' + Number(id),
          jwtHeader,
        )
        console.log(response?.data?.Content)
        if (response?.data?.Content.length > 0) {
          setValues(response.data.Content[0])
          setIsUpdate(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (id) {
      fetchBeneficiaryProgress(id)
    }
  }, [])

  const UpdateBeneficiaryProgress = () => {
    const { data } = location?.state
    console.log(data)
    setBeneficiaries(data)
    setValues((prev) => ({
      ...prev,
      ['beneficiary_id']: data.beneficiary_id,
      ['sponsor_id']: data.sponsor_id,
      ['program_id']: data.program_id,
      ['course_id']: data.course_id,
    }))
  }

  const addDetail = async () => {
    const response = await axios({
      method: 'POST',
      url: baseURL + 'addBeneficiaryProgress',
      data: values,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })

    console.log(response, 'RESPONSE')
    if (response?.status === '200') {
      Swal.fire('Success!', 'Added successfully!', 'success')
    }
  }


    const updateDetail = async () => {
    const response = await axios({
      method: 'POST',
      url: baseURL + 'setBeneficiaryProgress',
      data: values,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })

    console.log(response, 'RESPONSE')
    if (response?.status == '200') {
      Swal.fire('Success!', 'Updated successfully!', 'success')
      history.push('/service-partner/beneficiaries-list');
    }
  }


 const handleChange = (e) => {
  const { name, value } = e.target;

  setValues((prev) => ({
    ...prev,
    [name]: value,
  }));
};


  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">Beneficiary Name</p>
              </Col>

              <Col sm={9} style={{ paddingTop: '15px' }}>
                {beneficiaries &&
                  beneficiaries.beneficiary_first_name + ' ' + beneficiaries.beneficiary_last_name}
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">Beneficiary Course</p>
              </Col>
              <Col sm={9} style={{ paddingTop: '15px' }}>
                {beneficiaries && beneficiaries.course_name}
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">Beneficiary Program</p>
              </Col>
              <Col sm={9} style={{ paddingTop: '15px' }}>
                {beneficiaries && beneficiaries.program_name}
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">
                  Progress Details <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9} style={{ paddingTop: '15px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="progress_details"
                    placeholder=""
                    value={values.progress_details}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">
                  Milestone <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9} style={{ paddingTop: '15px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="milestone"
                    placeholder=""
                    value={values.milestone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">
                  Progress Date <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9} style={{ paddingTop: '15px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="date"
                    name="progress_date"
                    placeholder=""
                    value={values.progress_date}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', paddingTop: '15px' }}>
                <p className="labelText">
                  Percentage Completion <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9} style={{ paddingTop: '15px' }}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    name="percentage_completion"
                    placeholder=""
                    value={values.percentage_completion}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {isUpdate ? (
                <Col sm={12} className="d-flex justify-content-center">
                  <Button className="btn" onClick={updateDetail}>Update Progress</Button>
                </Col>
              ) : (
                <Col sm={12} className="d-flex justify-content-center">
                  <Button className="btn" onClick={addDetail}>
                    Add Progress
                  </Button>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}
export default UpdateProgress
