import React, { useEffect } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Hidden,
  HStack,
  Text,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";

import { getUsers } from "../../actions";
import { Mail, Phone } from "../../components/Icons/NzIcons";

function UserList(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  // const [Items] = useState(props.data);
  useEffect(() => {
    dispatch(getUsers("ad"));
  }, []);

  return (
    <Center>
      <Heading
        mt={{
          base: "8",
          md: "16",
        }}
        mx="4"
        textAlign="center"
      >
        Admin List
      </Heading>
      <Hidden from="md">
        <VStack
          w={"100%"}
          mt="4"
          justifyContent="space-around"
          alignItems="flex-start"
          borderBottomColor={"primary.500"}
          borderBottomWidth="12"
          borderTopColor="coolGray.200"
          borderTopWidth="1"
        >
          {users.Items.map((el, i) => {
            return (
              <Flex
                w="100%"
                px="8"
                py="4"
                borderBottomColor="coolGray.200"
                borderBottomWidth="1"
                bg={i % 2 === 0 ? "white" : ""}
              >
                <Box flexShrink="1">
                  <Text bold>{el.v9}</Text>
                  <Text fontSize="xs" color="grey">
                    {el.lid}
                  </Text>
                </Box>
                <Flex direction="row" wrap="wrap" align="center" mt="2">
                  <Phone size="xs" color="grey" />
                  <Text color="grey" ml="2">
                    {el.v3}
                  </Text>
                  <Mail size="xs" ml="10" color="grey" />
                  <Text color="grey" ml="2">
                    {el.v2}
                  </Text>
                </Flex>
              </Flex>
            );
          })}
        </VStack>
      </Hidden>
      <Hidden till="md">
        <HStack
          bg="primary.600"
          px="1"
          py="3"
          mt="6"
          w={{
            base: "90%",
            lg: "1024px",
          }}
          justifyContent="space-around"
          alignItems="flex-start"
          shadow={4}
        >
          <Box w={"28%"}>
            <Text pl="4" pr="1" bold color="white">
              User Id
            </Text>
          </Box>
          <Box w={"27%"}>
            <Text px="1" bold color="white">
              Name
            </Text>
          </Box>
          <Box w={"30%"}>
            <Text px="1" bold color="white">
              Email
            </Text>
          </Box>
          <Box w={"15%"}>
            <Text px="1" bold color="white">
              Phone Number
            </Text>
          </Box>
        </HStack>
        <VStack
          w={{
            base: "90%",
            lg: "1024px",
          }}
          justifyContent="space-around"
          alignItems="flex-start"
          shadow={8}
          mb="24"
        >
          {users.Items.map((el, i) => {
            return (
              <HStack width={"100%"} bg={i % 2 === 0 ? "white" : ""}>
                <Box
                  w={"28%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                  pl="4"
                >
                  <Text px="1" my="4">
                    {el.lid}
                  </Text>
                </Box>
                <Box
                  w={"27%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v9}
                  </Text>
                </Box>
                <Box
                  w={"30%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v2}
                  </Text>
                </Box>
                <Box
                  w={"15%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v3}
                  </Text>
                </Box>
              </HStack>
            );
          })}
        </VStack>
      </Hidden>
    </Center>
  );
}

export default UserList;
