import React from "react";
import { Center, Heading, Text, VStack } from "native-base";

function AboutUs() {
  return (
    <section id='aboutus'>
      <Center bg='primary.1000'  py={{ base: "8", lg: "12" }}>
        <VStack
          w={{
            base: "90%",
            md: "75%",
          }}
          alignItems='center'
          space='8'
          my='4'
          
        >
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='#ffff'
            fontSize={{base:'28px', md:'52px'}}
          >
            About Us
          </Heading>

          <VStack space='4' alignItems='center'>
            <Text fontSize='md'  color='#ffff' textAlign={{ base: "left", md: "justify" }}>
              We are on a transformative journey guided by measurable impact and
              unwavering transparency. We recognize that your support, whether
              as an individual volunteer, corporate partner, educational
              institution, government entity, regulator, or public member,
              deserves clear insight into the progress and outcomes achieved
              through our initiatives.
            </Text>
          </VStack>

          <VStack space='4' alignItems='start'>
            <Heading textAlign='start'  color='#ffff'>
              Vision: A World of Inclusive Prosperity
            </Heading>

            <Text fontSize='md'  color='#ffff' textAlign={{ base: "left", md: "justify" }}>
              Our vision at OptShine is not merely a statement; it&apos;s a
              conviction that fuels our every endeavour. We aspire to create a
              world where the boundaries of background and circumstance do not
              limit one&apos;s potential. We envision a society where thriving is not
              a privilege but a universal right attainable through skills,
              opportunities, and a robust support system.
            </Text>
          </VStack>

          <VStack>
            <Heading textAlign='start'  color='#ffff'>
              Purpose: Partnerships in Empowerment and Impact
            </Heading>
            <Text fontSize='md'  color='#ffff' textAlign={{ base: "left", md: "justify" }}>
              OptShine is not content with providing services or running
              initiatives in isolation. We exist to forge partnerships that
              transcend transactional relationships. We view every stakeholder
              as a valued partner, embarking on a shared mission of empowerment
              and impact. Your goals are not separate from ours; they become our
              driving force. As your dedicated partner, we transform your
              support and involvement into a tangible, life-changing impact.
              True empowerment is a collaborative effort, and in this
              partnership, we connect, create opportunities, and amplify the
              reach of your endeavors to make a significant, lasting difference.
            </Text>

            <Text  fontSize='md'  color='#ffff' textAlign={{ base: "left", md: "justify" }} >
              In the realm of OptShine, empowerment is not a solitary act but a
              symphony of collaborative efforts, where every partner, every
              initiative, and every achievement contributes to the harmonious
              melody of positive transformation. Join us on this transformative
              journey, where vision, mission, and purpose converge to create a
              legacy of inclusive prosperity.
            </Text>
          </VStack>
          <VStack>
            <Heading textAlign='start'  color='#ffff'>
              Mission: Empowering Lives, Transforming Futures
            </Heading>
            <Text fontSize='md'  color='#ffff' textAlign={{ base: "left", md: "justify" }}>
              At the heart of OptShine&apos;s purpose is a mission beyond rhetoric,
              focusing on tangible, meaningful outcomes. Our mission is to
              empower lives and transform futures through collaborative efforts
              with stakeholders. We are not just an entity; we are architects of
              positive change. Through customized skill-building initiatives,
              innovative programs, and an unwavering commitment to lasting
              impact, we unite individuals, enterprises, and communities on a
              journey toward a brighter future.
            </Text>
          </VStack>
        </VStack>
      </Center>
    </section>
  );
}

export default AboutUs;
