import React, { useEffect } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Hidden,
  HStack,
  Pressable,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";

import { getLeads } from "../../actions";
import {
  DownArrow2,
  Linkedin,
  Mail,
  Phone,
  BadgeVerified,
} from "../../components/Icons/NzIcons";

function LeadList(props) {
  const dispatch = useDispatch();
  const leads = useSelector((state) => state.leads);
  // const [Items] = useState(props.data);

  useEffect(() => {
    dispatch(getLeads({}));
  }, []);

  return (
    <Center>
      <Heading
        mt={{
          base: "8",
          md: "16",
        }}
        mx="4"
        textAlign="center"
      >
        Contact Form Responses
      </Heading>
      <Hidden from="md">
        <VStack
          w={"100%"}
          mt="4"
          justifyContent="space-around"
          alignItems="flex-start"
          borderBottomColor={"primary.500"}
          borderBottomWidth="12"
          borderTopColor="coolGray.200"
          borderTopWidth="1"
        >
          {leads.Items.map((el, i) => {
            return (
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                w="100%"
                px="8"
                borderBottomColor="coolGray.200"
                borderBottomWidth="1"
                bg={i % 2 === 0 ? "white" : ""}
              >
                <Box flexShrink="1" py="4">
                  <Text bold>{el.v1}</Text>
                  <Flex mt="1.5" direction="row" align="center">
                    <Mail color="grey" size="xs" mr="1.5" />
                    <Text fontSize="xs" color="grey">
                      {el.v2}
                    </Text>
                  </Flex>
                  <Flex mt="1.5" direction="row" align="center">
                    <Phone color="grey" size="xs" mr="1.5" />
                    <Text fontSize="xs" color="grey">
                      {el.v3}
                    </Text>
                  </Flex>
                  <Flex mt="1.5" direction="row" align="center">
                    <Linkedin color="grey" size="xs" mr="1.5" />
                    <Text fontSize="xs" color="grey">
                      {el.v4}
                    </Text>
                  </Flex>
                  <Text mt="3">{el.v5}</Text>
                </Box>
              </Flex>
            );
          })}
        </VStack>
      </Hidden>
      <Hidden till="md">
        <HStack
          bg="primary.600"
          px="1"
          py="3"
          mt="6"
          w={{
            base: "90%",
            lg: "1024px",
          }}
          justifyContent="space-around"
          alignItems="flex-start"
          shadow={4}
        >
          <Box w={"20%"}>
            <Text pl="4" pr="1" bold color="white">
              Name
            </Text>
          </Box>
          <Box w={"20%"}>
            <Text px="1" bold color="white">
              Email
            </Text>
          </Box>
          <Box w={"15%"}>
            <Text px="1" bold color="white">
              Phone Number
            </Text>
          </Box>
          <Box w={"15%"}>
            <Text px="1" bold color="white">
              LinkedIn
            </Text>
          </Box>
          <Box w={"30%"}>
            <Text pl="1" pr="4" bold color="white">
              Comments
            </Text>
          </Box>
        </HStack>
        <VStack
          w={{
            base: "90%",
            lg: "1024px",
          }}
          justifyContent="space-around"
          alignItems="flex-start"
          shadow={8}
          mb="24"
        >
          {leads.Items.map((el, i) => {
            return (
              <HStack width={"100%"} bg={i % 2 === 0 ? "white" : ""}>
                <Box
                  w={"20%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                  pl="4"
                >
                  <Text px="1" my="4">
                    {el.v1}
                  </Text>
                </Box>
                <Box
                  w={"20%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v2}
                  </Text>
                </Box>
                <Box
                  w={"15%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v3}
                  </Text>
                </Box>
                <Box
                  w={"15%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v4}
                  </Text>
                </Box>
                <Box
                  w={"30%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text pl="1" pr="4" my="4">
                    {el.v15}
                  </Text>
                </Box>
              </HStack>
            );
          })}
        </VStack>
      </Hidden>
    </Center>
  );
}

export default LeadList;
