const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  refreshPage: false,
  msg:"",
};
export default function (state = defaultState, action) {
  switch (action.type) {
  case "GET_NOTIFICATION_SUCCESS":
    return {
      ...state,
      msg:action.payload.msg,
    };
  case "NOTIFICATION_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  default:
    return state;
  }
}
