import React from "react";
import { Stack, VStack } from "native-base";

const NzBannerCard = (props) => {
  return (
    <VStack w={"100%"}>
      <Stack
        space={4}
        p={[4, 4, 4, 4]}
        bg="white"
        shadow={2}
        rounded="lg"
        mt={4}
      >
        {props.children}
      </Stack>
    </VStack>
  );
};

export default NzBannerCard;
