import React from "react";
import { Box, Center, Image, VStack, Text } from "native-base";
import Img from "./Logo_inverted.png";
import logo from "./Logo.svg";

function Footer() {
  return (
    <Center bg="primary.1000">
      <img src={logo} alt="Logo" width={182} height={42}/>
      <Text color="white" pt="2">
        Copyright 2023
      </Text>
      <Text color="white" mb="6">
        Optshine Digitech Solutions Pvt. Ltd.{" "}
      </Text>
    </Center>
  );
}
export default Footer;
