import { Center, Heading, Hidden, Text, VStack } from "native-base";
import React from "react";

function Csr() {
  return (
    <section id='csr'>
      <Center bg='#00000005' py={{ base: "8", lg: "12" }}>
        <VStack
          w={{
            base: "90%",
            md: "75%",
          }}
          alignItems='center'
          space='8'
          my='4'
        >
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='#002347' 
            fontSize={{ base: "28px", md: "52px" }} 
          >
            Corporate Social Responsibility
          </Heading>
          <VStack space='4' alignItems='center'>
            <Text
             fontSize='18px'
             textAlign={{ base: "left", md: "center" }}
             color='#002347'
            >
              In 2014, India became the first country (after Mauritius) to
              legally mandate companies to expend a percentage of their profits
              on Corporate Social Responsibility (CSR) activities. Companies
              (Amendment) Act, 2020 introduced a stringent regulatory
              architecture for carrying out CSR activities.
            </Text>

            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              CSR initiatives in India often align with the United Nations'
              Sustainable Development Goals (SDGs). These global goals provide a
              framework for addressing the world's most pressing challenges.
            </Text>

            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              More than 30% of annual CSR funding goes for Education,
              Differently Abled, Livelihood (EDL) projects. This large chunk
              into the education sector is due to the corporates' perception
              that there is huge potential for change with a skilled
              knowledgable society and belief in the education system being the
              root that needs strengthening.
            </Text>

            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              Even though the education sector receives maximum attention in
              terms of CSR spends, mere involvement from financial perspective
              to strengthen the education system is not enough.
            </Text>

            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              As per numerous surveys and studies, companies are facing
              following hurdles in effectively & efficiently deploying CSR
              budget: <br />
              <Hidden from='md'> &#x2022;</Hidden>
              <span style={{ paddingLeft: "10px" }}>
                Leakage of funds & high cost
              </span>
              <br />
              <Hidden from='md'> &#x2022;</Hidden>
              <span style={{ paddingLeft: "10px" }}>
                Lack of in-house expertise & partnerships
              </span>
              <br />
              <Hidden from='md'> &#x2022;</Hidden>
              <span style={{ paddingLeft: "10px" }}>
                Inadequate impact assessment
              </span>
              <br />
            </Text>
          </VStack>
          {/* <VStack space='4' mt='2' alignItems='center'>
            <Text
              bold
              fontSize='xl'
              textAlign={{ base: "left", md: "center" }}
              color='primary.800'
            >
              CSR Programs that deliver value to Beneficiaries
            </Text>

            <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
              There is need for an enabling ecosystem with reliable, organised
              data and knowledge about sectors and players that can accelerate
              giving decisions; ready to use programs; and platforms to
              understand and learn from giving journeys of others.
            </Text>

            <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
              OptShine helps you to improve value to the benficiaries by
              eliminating leakages and high implementation cost with use of
              technology.
            </Text>

            <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
              OptShine enables you to see all relevant data in one place and
              keeps track of the performance of the students that you sponsor
              and the impact your scholarship program is making in preparing
              students for enhancing the competencies, skills and industry
              orientation that in turn will pave way for their timely placement,
              seamless growth and greater work efficiency.
            </Text>
          </VStack> */}

          <VStack space='4' mt='2' alignItems='center'>
            <Heading
              textAlign={{ base: "left", md: "center" }}
             color='#002347' 
            fontSize={{ base: "28px" }} 
            >
              OptShine Offering for Corporate Enterprises
            </Heading>

            <Heading
              textAlign={{ base: "left", md: "center" }}
             color='#002347' 
            fontSize={{ base: "28px" }} 
            >
              OptShine CSRConnect
            </Heading>
            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              Integrated CSR Impact: Corporate Social Responsibility concerns
              impactful, worry-free societal betterment. Elevate your CSR
              initiatives with OptShine.
            </Text>

            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              Strategic Partnerships for Change
Presenter: Forge lasting change
              confidently with OptShine's purpose-driven ecosystem. Together, we
              amplify your corporate impact through strategic partnerships.
            </Text>

            <Text fontSize='18px' color='#002347' textAlign={{ base: "left", md: "center" }}>
              Employee Engagement and Investment in employee growth and
              community impact simultaneously with OptShine. Our initiatives
              enhance skills, boost morale, and foster a shared commitment to
              upliftment.
            </Text>
          </VStack>
        </VStack>
      </Center>
    </section>
  );
}
export default Csr;
