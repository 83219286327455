import React, { useState, useEffect } from "react"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import { baseURL } from "../../../config/baseURLPath"
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom"
import "../admin.css"
import swal from "sweetalert2";
const Edit = (props) => {
  const [values, setValues] = useState({
    role_id: "",
    admin_name: "",
    admin_email: "",
    password: "",
    mobile_number: "",
    institution_id: "",
    sponsor_id: "",
    gender:""
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const fetchUser = () => {
    axios
      .get(baseURL + "auth/getAdminUserById/" + props.activeId)
      .then((response) => {
        // console.log(response);
        setValues(response.data.content[0])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(()=>{
    fetchRoles()
    fetchUser()
  }, [])

  const [roles, setRoles] = useState([])
  const fetchRoles = () => {
    axios
      .get(baseURL + "getUserRoles")
      .then((response) => {
        // console.log(response);
        setRoles(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const validatePhoneNumber = (input_str) => {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(input_str);
  }

  const successAction = () => {
    if (
      values.admin_name.trim() === "" ||
      values.admin_email.trim() === "" ||
      values.mobile_number === ""
    ) {
      alert("Please fill all required fields");  
    } else if(!validateEmail(values.admin_email.trim())){
      alert("Please add valid email address.");
    } else if(!validatePhoneNumber(values.mobile_number.toString().trim())){
      alert("Please add valid mobile number.");
    } else {
      axios({
        method: "POST",
        url: baseURL + "auth/setAdminUser",
        data: values,
      })
        .then((response) => {
          new swal({
            title: "Done!",
            text: "User's details updated successfully",
            icon: "success",
            timer: 2000,
            button: false
          }).then((result) => {
            props.changeActiveTab(1)
          })
        })
        .catch(function (error) {
          if (error.response) {
            alert(error.response.data.Message)
          }
        })
    }
  }
  return (
    <div className="container">
      <Row>
        <Col md={2}></Col>
        <Col>
          <Card>
            <Card.Header as="h4" style={{ color: "#000", backgroundColor:"#ffffff" }}>
          Update User
              <div style={{ float: "right", marginBottom: "10px" }}>
                <Button className="btn primaryAdminButton" role="button" onClick={()=> props.changeActiveTab(1)}>
              List
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Select name="role_id" value={values.role_id} onChange={handleChange}>
                      <option value="">Select Role *</option>
                      {roles?.map((role) => (
                        <option value={role.role_id} key={role.role_id}>
                          {role.role_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      name="admin_name"
                      placeholder="Name *"
                      value={values.admin_name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      name="admin_email"
                      placeholder="Email *"
                      value={values.admin_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="tel"
                      name="mobile_number"
                      placeholder="Mobile * "
                      maxLength={10}
                      value={values.mobile_number}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Select name="gender" value={values.gender} onChange={handleChange}>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}></Col>
                <Col md={6}>
                  <Button onClick={successAction} className="primaryAdminButton" >Update</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  )
}

export default Edit
