/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react"
import { Card, Row, Col, Form, Button } from "react-bootstrap"
import { baseURL } from "../../../config/baseURLPath"
import axios from "axios"
import { useHistory } from "react-router-dom"
import "../admin.css"
import swal from "sweetalert2";
const CreateProgram = () => {
  const [subjectList, setSubjectList] = useState([]);
  const [subSkillList, setSubSkillList] = useState([]);
  const history = useHistory();
  useEffect(() => {
    // getCourseCategory();
    getSubjects();
  }, [])
  
  const [values, setValues] = useState({
    course_name: "",
    institution_id: JSON.parse(localStorage.getItem("user"))?.VendorData?.institution_id,
    duration: "",
    duration_unit:"Days",
    course_desc: "",
    minimum_qualification: "",
    fees: "",
    course_difficulty_level: "Beginner",
    course_rating: "",
    course_rated_by_count: "",
    course_url:"",
    course_monthly_cost:"",
    course_learning_product_type:"",
    course_enrolled_students:"",
    course_percentage_career_start:"",
    course_percentage_pay_promotion:"",
    course_instructors:"",
    course_subjects:"",
    subject_id:"",
    skill_iid:""
  })

  const isFormValid = () => {
    return (
      values.course_name.trim() !== "" &&
      values.duration !== "" &&
      values.minimum_qualification !== "" &&
      values.fees !== "" &&
      values.course_collaboration !== "" && 
      values.course_skills !== "" && 
      // values.course_rating !== '' && 
      values.course_url !=="" &&
      // values.course_rated_by_count !== '' && 
      values.duration !== "" && 
      values.fees !== "" &&
      values.course_monthly_cost!=="" &&
      values.course_learning_product_type !=="" &&
      values.course_enrolled_students !=="" &&
      values.course_percentage_career_start !=="" &&
      values.course_percentage_pay_promotion !=="" &&
      values.course_instructors !=="" &&
      values.subject_id !=="" &&
      values.sub_skill_id!==""
    )
  };

  const handleChange = (e) => { 
    const { name, value } = e.target
    

    if(name === "course_id"){
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ["course_plan_id"]:""
      }))
    }else{
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }    if(name ==="subject_id"){
      getSubjectsSkills(value)
      setValues((prev)=>({
        ...prev,
        [name]:value
      }))
    }
  }

  // const [courseCategory, setCourseCategory] = useState([])
  // const getCourseCategory = () => {
  //   axios({
  //     method: "get",
  //     url: baseURL + "getCourseCategory",
  //   })
  //     .then((response) => {
  //       setCourseCategory(response.data.Content)
  //     })
  //     .catch(() => {})
  // }

  const getSubjects=()=>{
    axios({
      method: "get",
      url: baseURL + "getSubjects",
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch(() => {})
  }


  const getSubjectsSkills=(subject_id)=>{
    axios({
      method: "get",
      url: baseURL + "getSubjectSkills/"+subject_id,
    })
      .then((response) => {
        setSubSkillList(response.data.data)
      })
      .catch(() => {})
  }

  const successAction = () => {
    if (
      values.course_name.trim() === "" ||
      values.duration === "" ||
      values.minimum_qualification === "" ||
      values.course_catg_id === "" ||
      values.fees === ""
    ) {
      alert("Please fill required fields.")  
    } 
    else {  
      values.institution_id = JSON.parse(localStorage.getItem("user"))?.VendorData?.institution_id;
      values.vendors = [{
        vendor_id: JSON.parse(localStorage.getItem("user"))?.VendorData?.institution_id
      }]
      axios({
        method: "POST",
        url: baseURL + "addCourse",
        data: values,
        headers: { "access-token": localStorage.getItem("csr_token") },
      })
        .then(() => {
          new swal({
            title: "Done!",
            text: "New Course created successfully",
            icon: "success",
            timer: 2000,
            button: false
          }).then(() => {
            history.replace("/service-partner/course-list")
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{padding:"0px 11px"}}>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347", paddingTop:"15px"}}>
                <p className="labelText">Course Name <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9} style={{paddingTop:"15px"}}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_name"
                    placeholder=""
                    value={values.course_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Description<span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_desc"
                    placeholder=""
                    value={values.course_desc}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Category <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="course_catg_id" value={values.course_catg_id} onChange={handleChange}>
                    <option value="">Select Course Category</option>  
                    {courseCategory.map((item, index)=>{
                      return(
                        <option key={index} value={item.course_catg_id}>{item.course_catg_name}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}

            <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Course Subjects <span style={{ color: "#ff0000" }}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="subject_id" value={values.subject_id} onChange={handleChange}>
                    <option value="">Select Course Subject</option>
                    {subjectList.map((subject, index) => (
                      <option key={index} value={subject.subject_id}>
                        {subject.subject_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Subject Skills* <span style={{ color: "#ff0000" }}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="sub_skill_id" value={values.sub_skill_id} onChange={handleChange}>
                    <option value="">Select Subject Skill*</option>
                    {subSkillList.map((subSkill, index) => (
                      <option key={index} value={subSkill.skill_id}>
                        {subSkill.skill_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Difficulty Level</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="course_difficulty_level" value={values.course_difficulty_level} onChange={handleChange}>
                    <option value="Beginner">Beginner</option>  
                    <option value="Intermediate">Intermediate</option>
                    <option value="Mixed">Mixed</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Collaboration</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_collaboration"
                    placeholder=""
                    value={values.course_collaboration}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Skills <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_skills"
                    placeholder=""
                    value={values.course_skills}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Rating<span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="course_rating" value={values.course_rating} onChange={handleChange}>
                    <option value="1">1</option>  
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Rated By<span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={0}
                    name="course_rated_by_count"
                    placeholder=""
                    value={values.course_rated_by_count}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Minimum qualification for the Course <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="minimum_qualification"
                    placeholder=""
                    value={values.minimum_qualification}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Duration <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={1}
                    name="duration"
                    placeholder="Enter Course Duration"
                    value={values.duration}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="mb-3">
                  <Form.Select name="duration_unit" value={values.duration_unit} onChange={handleChange}>
                    <option value="Days">Days</option>  
                    <option value="Months">Months</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Fees (INR) <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="fees"
                    placeholder=""
                    value={values.fees}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course URL <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_url"
                    placeholder=""
                    value={values.course_url}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Monthly Cost) <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_monthly_cost"
                    placeholder=""
                    value={values.course_monthly_cost}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Learning Product Type  </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_learning_product_type"
                    placeholder=""
                    value={values.course_learning_product_type}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Enrolled Students <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_enrolled_students"
                    placeholder=""
                    value={values.course_enrolled_students}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Percentage of new career starts  </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_percentage_career_start"
                    placeholder=""
                    value={values.course_percentage_career_start}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Percentage of pay increase or promotion </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_percentage_pay_promotion"
                    placeholder=""
                    value={values.course_percentage_pay_promotion}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Instructor <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_instructors"
                    placeholder=""
                    value={values.course_instructors}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

          </Card.Body>  
        </Card>
        <Row>
          <Col>
            <Button className="btn primaryAdminButton" role="button" onClick={() => history.goBack()} style={{marginTop:"15px"}}>
            Back
            </Button>
          </Col>
          <Col>
            <div style={{display:"flex", justifyContent:"flex-end", marginTop:"15px"}}>
              <Button onClick={successAction} className="primaryAdminButton" disabled={!isFormValid()} >Create Course</Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default CreateProgram

