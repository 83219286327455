import React from "react"
import { Link } from "react-router-dom"
// import { Row, Col, Container } from 'react-bootstrap'
import "./admincss.css"
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar"
import "react-pro-sidebar/dist/css/styles.css"

const SideBar = () => {
  return (
    <ProSidebar >
      <Menu iconShape="square">
        <MenuItem icon="" style={{fontSize:"16px", marginTop:"15px"}}>
          <Link className="menuLink" to="/admin/dashboard" />
            Dashboard
        </MenuItem>
        <SubMenu title="Masters" icon="" style={{fontSize:"16px", marginTop:"15px"}}>
          <MenuItem>
            <Link className="menuLink" to="/admin/tags" />
            Tags
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/subjects" />
            Subjects 
          </MenuItem>
            <MenuItem>
            <Link className="menuLink" to="/admin/skills" />
            Skills
            </MenuItem>
            
          {/* <MenuItem>
            <Link className="menuLink" to="/admin/subjects-category" />
            Subject Category
          </MenuItem> */}
          {/* <MenuItem>
            <Link className="menuLink" to="/admin/subjects-mapping" />
            Subject Mapping
          </MenuItem> */}
          <MenuItem>
            <Link className="menuLink" to="/admin/subjects-skill-mapping" />
            Subject Skill Mapping
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/sports" />
            Sports
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/income-group" />
            Income Group
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/performance-criteria" />
            Performance
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/qualifications" />
            Qualifications
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/beneficiary-add" />
            Add Beneficiaries
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/expense-category" />
            Expense Category
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/college-master" />
            Campus
          </MenuItem>
        </SubMenu>
        {/* <SubMenu title="Master" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <MenuItem icon="" style={{fontSize:'16px', marginTop:"15px"}}>
            <Link className="menuLink" to="/admin/badges-list" />
            Badges
          </MenuItem>
          <MenuItem icon="" style={{fontSize:'16px', marginTop:"15px"}}>
            <Link className="menuLink" to="/admin/justice-cause-list" />
            Justice Cause
          </MenuItem>
          <SubMenu title="Battlegrounds" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
            <MenuItem>
              <Link className="menuLink" to="/admin/localbattleground-all-list" />
              Cities
            </MenuItem>
            <MenuItem>
              <Link className="menuLink" to="/admin/localbattleground-list" />
              Local Battleground
            </MenuItem>
          </SubMenu>
        </SubMenu>
        <SubMenu title="Orgs & Donations" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/charity-list" />
            Manage Orgs
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/charity-cause-mapping" />
            Orgs Cause Mapping
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/post-charity-donation" />
           Donation Reports
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/manually-added-donation-list" />
            Manual Donation List
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/add-donation-manual" />
            Add Donation Manually
          </MenuItem>
        </SubMenu>
        <MenuItem icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <Link className="menuLink" to="/admin/event-list" />
          Events Calendar
        </MenuItem>
        <SubMenu title="Newsletters" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <MenuItem >
            <Link className="menuLink" to="/admin/add-newsletter" />
            Add Newsletter
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/newsletter-list" />
              View Newsletters
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/newsletter-members" />
              Newsletters Members
          </MenuItem>
        </SubMenu> */}
        {/* <SubMenu title="Blogs" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <MenuItem >
            <Link className="menuLink" to="/admin/blog-list" />
              Blog List
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/blog-members" />
              Members (from Dashboard)
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/blog-members" />
              Members
          </MenuItem>
        </SubMenu> */}
        {/* <SubMenu title="Email Templates" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <MenuItem >
            <Link className="menuLink" to="/admin/add-emailtemplate" />
            Add Template
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/emailtemplate-list" />
            Template List
          </MenuItem>
        </SubMenu> */}
        <MenuItem>
          <Link className="menuLink" to="/admin/admin-list" />
            Manage Users
        </MenuItem>
        {/* <MenuItem>
            <Link className="menuLink" to="/admin/vendor-list" />
            Service-provider
        </MenuItem>
        <MenuItem>
          <Link className="menuLink" to="/admin/validator-list" />
          Institution
        </MenuItem>
        <MenuItem>
            <Link className="menuLink" to="/admin/sponsors-list" />
            Sponsors
        </MenuItem> */}
        <MenuItem>
          <Link className="menuLink" to="/admin/program-list" />
            Programs
        </MenuItem>
        <MenuItem>
        Courses
          <Link 
            className="menuLink" to="/admin/courses"
          />
        </MenuItem>
       
        <MenuItem>
          <Link className="menuLink" to="/admin/leads-list" />
            Leads
        </MenuItem>

        <MenuItem>
          <Link className="menuLink" to="/admin/beneficiary" />
          Beneficiaries 
        </MenuItem>

        <MenuItem>
          <Link className="menuLink" to="/admin/beneficiary-questions" />
          Beneficiaries Questions
        </MenuItem>

        <MenuItem>
          <Link className="menuLink" to="/admin/upload-beneficiaries" />
          Bulk Upload Beneficiaries
        </MenuItem>
          <MenuItem>
          <Link className="menuLink" to="/admin/beneficiaries-mapping" />
          Mapping Beneficiaries
        </MenuItem>
        {/* <MenuItem>
            <Link className="menuLink" to="/admin/student-list" />
            Manage Students
        </MenuItem> */}
        {/* <SubMenu title="Manage User" icon="" style={{fontSize:'16px', marginTop:"15px"}}>
          <MenuItem>
            <Link className="menuLink" to="/admin/admin-list" />
            Manage Admin User
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/site-user-list" />
            Manage Site User
          </MenuItem>
        </SubMenu> */}

        <SubMenu title="Reports" icon="" style={{fontSize:"16px", marginTop:"15px"}}>
          <MenuItem>
            <Link className="menuLink" to="/admin/email-logs" />
          Email Logs
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/reports/beneficiary" />
            Beneficiaries Report
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/reports/getBeneficiaryDistribution" />
            Beneficiary Distribution
          </MenuItem>
           <MenuItem>
            <Link className="menuLink" to="/admin/reports/CSRFundsUtilisation" />
            CSR Funds Utilisation
          </MenuItem>

          
          {/* <MenuItem>
            <Link className="menuLink" to="/admin/usage-analytics" />
            Usage Report
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/upcoming-events" />
            Upcoming events
          </MenuItem>
          <MenuItem icon="">
            <Link className="menuLink" to="/admin/volunteers-report" />
            Volunteers Report
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/causes-donation-list" />
            Justice Causes <br /> Donation Report
          </MenuItem>
          <MenuItem>
            <Link className="menuLink" to="/admin/charity-donation-report" />
            Designated Donation <br />
            for Charities
          </MenuItem> */}
        </SubMenu>
      </Menu>
    </ProSidebar>
  )
}

export default SideBar
