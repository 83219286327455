import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import {
  Button,
  Box,
  CloseIcon,
  Flex,
  HStack,
  Hidden,
  Pressable,
  VStack,
  Text,
  Center,
} from "native-base";
import NzHeader from "../../components/NzComponents/NzHeader";

import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  NzLabel,
} from "../../components/NzComponents";
import {
  DownArrow2,
  BadgeNew,
  BadgeVerified,
} from "../../components/Icons/NzIcons";
import { getBuild, removeBuild, upload } from "../../actions";
const VDashBoard = (props) => {
  const auth = useSelector((state) => state.auth);
  const build = useSelector((state) => state.build);
  const dispatch = useDispatch();
  const uploadInput = useRef(null);
  const [uploading, setUploading] = useState(false);
  const uploadStudentInput = useRef(null);

  useEffect(() => {
    if (auth.c1 === "va") {
      dispatch(getBuild({ id: "studentUpload", v2: auth.id }));
    } else {
      dispatch(getBuild({ id: "courseUpload", v2: auth.id }));
    }
  }, []);

  useEffect(() => {
    if (build.refreshPage) {
      if (auth.c1 === "va") {
        dispatch(getBuild({ id: "studentUpload", v2: auth.id }));
      } else {
        dispatch(getBuild({ id: "courseUpload", v2: auth.id }));
      }
    }
    setUploading(false);
  }, [build]);

  return (
    <>
      <Nz1024 bg="#efefe8">
        {auth.c1 === "va" ? (
          <>
            <NzBannerCard>
              <NzHeading>Validator Dashboard</NzHeading>
            </NzBannerCard>
            <NzBannerCard>
              <Flex direction="row-reverse" justifyContent={"space-between"}>
                <NzButton
                  variant="outline"
                  onPress={(e) => {
                    props.history.push("/studentList");
                  }}
                >
                  Show Students list
                </NzButton>
                <a href="https://nodz.s3.ap-south-1.amazonaws.com/optz/student.csv">
                  Download sample CSV
                </a>
                <NzButton
                  type="submit"
                  onPress={(e) => {
                    uploadStudentInput.current.click();
                  }}
                >
                  Upload Students
                </NzButton>
                <input
                  type="file"
                  name="file"
                  ref={uploadStudentInput}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    dispatch(
                      upload({
                        files: event.target.files,
                        id: auth.id,
                        type: "student",
                      })
                    );
                    // this.setState({hasErrored:false})
                    // this.props.upload({id:this.state.id,ky:this.state.ky,files:event.target.files,a1:this.state.a1});
                    //setTimeout(() => this.setState({showSidebar : false},()=>this.reload()), 1500)
                  }}
                />
              </Flex>
              <Center>
                <VStack
                  w={"100%"}
                  mt="4"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  borderBottomColor={"primary.500"}
                  borderTopColor="coolGray.200"
                  borderTopWidth="1"
                >
                  {build.Items.map((el, i) => {
                    return (
                      <Flex
                        direction="row"
                        justify="space-between"
                        align="center"
                        w="100%"
                        px="8"
                        borderBottomColor="coolGray.200"
                        borderBottomWidth="1"
                        bg={i % 2 === 0 ? "white" : ""}
                      >
                        <Box flexShrink="1" py="4" pr="2">
                          <Text>{el.v1}</Text>
                          <Text fontSize="xs" color="grey">
                            <Moment fromNow>{el.createdAt}</Moment>
                          </Text>
                        </Box>
                        <Flex direction="row" align="center" space="3">
                          {el.c1 !== "d" ? (
                            <>
                              <Text fontSize="xs" color="grey">
                                Processing{" "}
                              </Text>
                              <NzButton isLoading variant="ghost"></NzButton>
                            </>
                          ) : (
                            <>
                              <Text fontSize="xs" color="grey">
                                Uploaded{" "}
                              </Text>
                              <BadgeVerified size="xs" />
                            </>
                          )}
                          {el.c1 === "d" && (
                            <NzButton
                              endIcon={<CloseIcon size="3" />}
                              mx="2"
                              variant="subtle"
                              onPress={(e) => {
                                dispatch(removeBuild({ id: el.id, ky: el.ky }));
                              }}
                            ></NzButton>
                          )}
                        </Flex>
                      </Flex>
                    );
                  })}
                </VStack>
              </Center>
            </NzBannerCard>
          </>
        ) : (
          <>
            <NzBannerCard>
              <NzHeading>Vendor Dashboard</NzHeading>
            </NzBannerCard>
            <NzBannerCard>
              <Flex direction="row-reverse" justifyContent={"space-between"}>
                <NzButton
                  mx="2"
                  variant="outline"
                  onPress={(e) => {
                    props.history.push("/courses");
                  }}
                >
                  Show Courses
                </NzButton>
                <a href="https://nodz.s3.ap-south-1.amazonaws.com/optz/courses.csv">
                  Download sample CSV
                </a>

                <NzButton
                  isLoading={uploading}
                  type="submit"
                  onPress={(e) => {
                    uploadInput.current.click();
                  }}
                >
                  Upload Courses
                </NzButton>
                <input
                  type="file"
                  name="file"
                  ref={uploadInput}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    var target = event.target || event.srcElement;
                    if (target.value.length == 0) {
                      setUploading(false);
                    } else {
                      setUploading(true);
                      dispatch(
                        upload({
                          files: event.target.files,
                          id: auth.id,
                          type: "course",
                        })
                      );
                    }
                  }}
                />
              </Flex>

              <Center>
                <VStack
                  w={"100%"}
                  mt="4"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  borderBottomColor={"primary.500"}
                  borderTopColor="coolGray.200"
                  borderTopWidth="1"
                >
                  {build.Items.map((el, i) => {
                    return (
                      <Flex
                        direction="row"
                        justify="space-between"
                        align="center"
                        w="100%"
                        px="8"
                        borderBottomColor="coolGray.200"
                        borderBottomWidth="1"
                        bg={i % 2 === 0 ? "white" : ""}
                      >
                        <Box flexShrink="1" py="4" pr="2">
                          <Text>{el.v1}</Text>
                          <Text fontSize="xs" color="grey">
                            <Moment fromNow>{el.createdAt}</Moment>
                          </Text>
                        </Box>
                        <Flex direction="row" align="center" space="3">
                          {el.c1 !== "d" ? (
                            <>
                              <Text fontSize="xs" color="grey">
                                Processing{" "}
                              </Text>
                              <NzButton isLoading variant="ghost"></NzButton>
                            </>
                          ) : (
                            <>
                              <Text fontSize="xs" color="grey">
                                Uploaded{" "}
                              </Text>
                              <BadgeVerified size="xs" />
                            </>
                          )}
                          {el.c1 === "d" && (
                            <NzButton
                              endIcon={<CloseIcon size="3" />}
                              mx="2"
                              variant="subtle"
                              onPress={(e) => {
                                dispatch(removeBuild({ id: el.id, ky: el.ky }));
                              }}
                            ></NzButton>
                          )}
                        </Flex>
                      </Flex>
                    );
                  })}
                </VStack>
              </Center>
            </NzBannerCard>
          </>
        )}
      </Nz1024>
    </>
  );
};

export default VDashBoard;
