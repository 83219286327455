import React from "react";

import { Center, Heading, Hidden, Text, VStack, Flex, Box } from "native-base";
const OurApproach = () => {
  return (
    <section id='ourapproach'>
      <Center bg='00000005' py={{ base: "8", lg: "12" }}>
        <VStack
          w={{
            base: "90%",
            md: "75%",
          }}
          alignItems='center'
          space='8'
          my='4'
        >
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Our approach
          </Heading>
          <VStack space='4' alignItems='center'>
            <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
              OptShine is transforming the CSR landscape by employing a
              business-driven strategy, collaborating with top-tier service
              providers for targeted interventions and locations, and leveraging
              technology to deliver lasting impact at scale.
            </Text>

            <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
              Partner with OptShine to amplify your CSR efforts. Collaborate
              with a diverse network to create lasting change that empowers
              individuals and communities. Forge partnerships that drive lasting
              change with confidence. OptShine connects your enterprise with a
              purpose-driven ecosystem, amplifying your corporate impact while
              ensuring your commitment to social betterment is well-supported.
            </Text>
          </VStack>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Our USPs (Value we provide)
          </Heading>
          <Flex
            flexDirection={{
              base: "column",
              lg: "row",
            }}
            alignItems='center'
            justify='center'
            w={{
              base: "90%",
              lg: "80%",
            }}
            gap={{ base: "4", lg: "0" }}
            mt='2'
            mb='5'
          >
            <Box
              bg='primary.1000'
              borderRadius='4px'
              w={{ base: "100%", lg: "30%" }}
              p='4'
              m='2'
            >
              <Flex flexDir='row' justify='space-between' w='100%'>
                <Heading fontSize='25px' color='white'>
                  Holistic Collaboration Hub:
                </Heading>
              </Flex>
              <Text fontSize='md' color='white'>
                Our platform unites all stakeholders, fostering networking,
                partnerships, and knowledge exchange
              </Text>
            </Box>
            <Box
              bg='primary.1000'
              borderRadius='4px'
              w={{ base: "100%", lg: "30%" }}
              p='4'
              m='2'
            >
              <Flex flexDir='row' justify='space-between' w='100%'>
                <Heading fontSize='25px' color='white'>
                  End-to-End Empowerment:
                </Heading>
              </Flex>
              <Text fontSize='md' color='white'>
                From skill development to impact measurement, we offer a
                comprehensive toolkit for lasting change.
              </Text>
            </Box>
            <Box
              bg='primary.1000'
              borderRadius='4px'
              w={{ base: "100%", lg: "30%" }}
              p='4'
              m='2'
            >
              <Flex flexDir='row' justify='space-between' w='100%'>
                <Heading fontSize='25px' color='white'>
                  Data-Driven Insights:
                </Heading>
              </Flex>
              <Text fontSize='md' color='white'>
                We provide transparency, accountability, and personalized
                learning paths through data-driven insights.
              </Text>
            </Box>

            <Box
              bg='primary.1000'
              borderRadius='4px'
              w={{ base: "100%", lg: "30%" }}
              p='4'
              m='2'
            >
              <Flex flexDir='row' justify='space-between' w='100%'>
                <Heading fontSize='25px' color='white'>
                  Integrated Technology Platform:
                </Heading>
              </Flex>
              <Text fontSize='md' color='white'>
                Seamlessly integrating technology, we enhance efficiency,
                effectiveness, and accessibility for all.{" "}
              </Text>
            </Box>
          </Flex>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            At OptShine, we are committed to a transformative journey guided by
            measurable impact and unwavering transparency. We recognize that
            your support, whether as an individual donor, corporate partner,
            educational institution, government entity, regulator, or public
            member, deserves clear insight into the progress and outcomes
            achieved through our initiatives.
          </Text>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
             CSR Compliance Solution: We ensure that our CSR programs are
            compliant with CSR regulations so that companies can manage their
            CSR funds effectively and demonstrate their commitment to social
            responsibility.
          </Text>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Measuring Impact: We measure our success by the lives transformed,
            skills honed, and communities uplifted. Every promotion, every CSR
            initiative, and every collaborative effort is meticulously tracked,
            quantified, and analysed. Our metrics go beyond numbers; they
            encompass stories of personal growth, community betterment, and
            societal change.
          </Text>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Transparency in Action: Transparency is at the core of everything
            we do. We ensure that our measurement processes are rigorous,
            unbiased, and externally validated.
          </Text>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Open Channels of Communication: We are dedicated to keeping our
            stakeholders informed. Our transparent communication channels
            regularly update project progress, milestones achieved, and lives
            touched. Through our platforms, you'll find real-time access to
            success stories, data insights, and your support's positive
            influence on our collective mission.
          </Text>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
           Your Role in the Impact Story: Whether you're a donor, a corporate
            partner, an educational institution, a government entity, a
            regulator, or a public member, you play an integral role in our
            impact story. Together, we co-create a narrative of empowerment,
            change, and progress. Your investment, commitment, and belief in our
            mission drive the positive change we champion.
          </Text>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
           Empowering transparency Together: Join us on this journey where
            transparency is more than a promise; it's a fundamental principle
            that guides our actions. With OptShine, you're not just investing in
            change; you're investing in a future where every step forward is
            accounted for, celebrated, and shared. We empower lives, unite
            communities, and illuminate paths to a brighter tomorrow.
          </Text>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
           Employee volunteer programs: Empower your employees to make a
            difference effortlessly with OptShine. Your team engages in
            meaningful volunteering opportunities as our platform streamlines
            the process so your employees can focus on giving back while we
            handle the logistics. Let their passion for community service shine,
            supported by OptShine.
          </Text>
        </VStack>
      </Center>
    </section>
  );
};

export default OurApproach;
