import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Center, Text, Heading, VStack } from "native-base";
import Moment from "react-moment";
import { getMaster, runNotification } from "../../actions";

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);
  const master = useSelector((state) => state.master);
  const [lastRunAt, setLastRunAt] = useState();
  const [lastRunUser, setLastRunUser] = useState("");
  const [lastRunMailSent, setLastRunMailSent] = useState("");
  const [disable, setDisable] = useState(true);

  const [msg, setMsg] = useState("");

  useEffect(() => {
    dispatch(getMaster({ ky: "welcomeMailNotification" }));
  }, []);

  useEffect(() => {
    if (master && master.Items && master.Items.length > 0) {
      setLastRunAt(master.Items[0].d1);
      setLastRunUser(master.Items[0].n1);
      setLastRunMailSent(master.Items[0].n2);
      var a = new Date();
      var b = new Date(master.Items[0].d1);
      if (parseInt((a - b) / 1000) > 1800) {
        setDisable(false);
      }
    }
  }, [master]);

  useEffect(() => {
    setMsg(notification.msg);
  }, [notification]);

  return (
    <Center>
      <VStack>
        {/*{JSON.stringify(master)}*/}
        <Heading
          mt={{
            base: "8",
            md: "16",
          }}
          mx="4"
          textAlign="center"
        >
          Notification
        </Heading>
        <VStack pt="8">
          <Center>
            <Text>
              Last Run : <Moment fromNow>{lastRunAt}</Moment>
            </Text>
            <Text>Last Run Student Count : {lastRunUser}</Text>
            <Text>Last Run Mail Sent : {lastRunMailSent}</Text>
            <Button
              isDisabled={disable}
              mt="8"
              onPress={(e) => {
                setDisable(true);
                setMsg("");
                dispatch(runNotification({}));
              }}
            >
              Send welcome Mail to new students
            </Button>
            <Text mt="8">{msg}</Text>
          </Center>
        </VStack>
      </VStack>
    </Center>
  );
};

export default Notification;
