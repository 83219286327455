import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowForwardIcon,
  Box,
  Button,
  Center,
  CheckIcon,
  Heading,
  HStack,
  Stack,
  Select,
  Text,
  VStack,
} from "native-base";

import { NzInput } from "../../components/NzComponents";
import NzlogoHeader from "../../components/NzComponents/NzlogoHeader";
import { upperCamelCase } from "../../js/util";

import { addProgram, getBenefeciary, getWelfareType } from "../../actions";
import { v4 as uuidv4 } from "uuid";

//var converter = require("number-to-words");
const { ToWords } = require("to-words");
const toWords = new ToWords();

function ProgramSummary(props) {
  const form1 = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const combo = useSelector((state) => state.combo);
  const auth = useSelector((state) => state.auth);
  const [v1, setV1] = useState();
  const [v2, setV2] = useState();
  const [v3, setV3] = useState();
  const [v14, setV14] = useState(0);
  const [v16, setV16] = useState();
  const [v17, setV17] = useState();

  // This method executes only ones on pageload
  useEffect(() => {}, []);

  useEffect(() => {
    if (!props.location.program && !props.location.new) {
      props.history.push("/dashboard");
    } else {
      if (props.location.program) {
        let program = props.location.program;
        console.log(program);
        setV1(program.v1);
        setV2(program.v2);
        setV3(program.v3);
        setV14(parseInt(program.v14));
        setV16(program.v16);
        setV17(program.v17);
      }
    }
  }, []);

  const onSubmit = (data) => {
    data.id = auth.id;
    if (!props.location.program) {
      data.ky = uuidv4();
    } else {
      data.ky = props.location.program.ky;
    }

    //console.log(data);
    dispatch(addProgram(data));
    props.history.push({
      pathname: "/criteria",
      program: data,
    });
  };

  return (
    <>
      <Center>
        <Stack
          w={"70%"}
          space={4}
          p={[4, 4, 8]}
          bg="white"
          shadow={2}
          rounded="lg"
          mt={20}
        >
          <VStack
            textAlign={"center"}
            w={{
              base: "90%",
              md: "70%",
              lg: "50%",
            }}
          >
            <Heading size={"sm"} m={5} color="#6b21a8">
              Program Summary
            </Heading>
          </VStack>
          <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
            <VStack
              mx={5}
              w={{
                base: "90%",
                md: "70%",
                lg: "50%",
              }}
            >
              <HStack justifyContent={"space-between"} py={2}>
                <Text ml={5}>Program Name</Text>
                <Text ml={5} textAlign={"right"} bold>
                  {v1}
                </Text>
              </HStack>
              <HStack justifyContent={"space-between"} py={2}>
                <Text ml={5}>Number of Eligible Students</Text>
                <Text bold mr={2} color="#6b21a8" textAlign={"center"}>
                  {v14} ({toWords.convert(v14, { currency: true })})
                </Text>
              </HStack>
              <HStack justifyContent={"space-between"} py={2}>
                <Text ml={5}>Planned Amount (Budget)</Text>
                <Controller
                  control={control}
                  name="v1"
                  render={({ field }) => (
                    <NzInput
                      {...field}
                      maxWidth={150}
                      bg={"primary.50"}
                      placeholder="Planned Amount *"
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                {errors.v1 && errors.v1.type === "required" && (
                  <Box px={2} ml={"3%"}>
                    Planned Amount is mandatory
                  </Box>
                )}
              </HStack>
              <HStack justifyContent={"space-between"} py={2}>
                <Text ml={5}>Duration (months)</Text>
                <Controller
                  control={control}
                  name="v1"
                  render={({ field }) => (
                    <NzInput
                      {...field}
                      maxWidth={150}
                      bg={"primary.50"}
                      placeholder="Duration (months) *"
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
                {errors.v1 && errors.v1.type === "required" && (
                  <Box px={2} ml={"3%"}>
                    Duration is mandatory
                  </Box>
                )}
              </HStack>

              <HStack justifyContent={"space-between"} py={2}>
                <Text ml={5}>Beneficiary Type</Text>
                <Text ml={5}>{upperCamelCase(v16)}</Text>
              </HStack>

              <HStack justifyContent={"space-between"} py={2}>
                <Text ml={5}>Welfare Type</Text>
                <Text ml={5}>{upperCamelCase(v17)}</Text>
              </HStack>
              <Button
                endIcon={<ArrowForwardIcon size="4" />}
                mt={12}
                size={"md"}
                _text={{
                  fontSize: "sm",
                  fontWeight: "bold",
                }}
                // onPress={() =>
                //   form1.current.dispatchEvent(
                //     new Event("submit", { bubbles: true, cancelable: true })
                //   )
                // }
              >
                Next (Payments)
              </Button>
            </VStack>
          </form>
        </Stack>
        {/* </Box> */}
      </Center>
    </>
  );
}
export default ProgramSummary;
