import { Center, Heading, Hidden, Text, VStack } from "native-base";
import React from "react";

const Faq = () => {
  return (
    <section id='FAQ'>
      <Center bg='#00000005' py={{ base: "8", lg: "12" }}>
        <VStack
          w={{
            base: "90%",
            md: "75%",
          }}
          alignItems='center'
          space='8'
          my='4'
        >
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            FAQ
          </Heading>
          <VStack space='4' alignItems='center'>
            <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
              Please note that these Frequently Asked Questions (FAQs) on
              Corporate Social Responsibility (CSR) are based on circular issued
              by Ministry of Corporate Affairs (MCA). Please refer to MCA
              website for more information.
            </Text>
          </VStack>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Which companies qualify for CSR under the Companies Act, 2013?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            A company satisfying any of the following criteria during the
            immediately preceding financial year is required to comply with CSR
            provisions specified under section 135(1) of the Companies Act, 2013
            read with the Companies (CSR Policy) Rules, 2014 made thereunder:
            (i) net worth of rupees five hundred crore or more, or (ii) turnover
            of rupees one thousand crore or more, or (iii) net profit of rupees
            five crore or more
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What are the responsibilities of the Board in relation to the CSR
            provisions?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            CSR is a Board-driven process. The responsibilities of the Board of
            a CSR-eligible company, inter-alia, include the following — (i)
            approve the CSR policy; (ii) disclose contents of such policy in its
            report and also place it on the company's website, if any; (iii)
            ensure that the activities included in the CSR policy are undertaken
            by the company; (iv) ensure that the company spends, in every
            financial year, at least two per cent of the average net profits of
            the company made during the three immediately preceding financial
            years; (v) satisfy itself regarding the utilisation of the disbursed
            CSR funds; and (vi) if the company fails to spend at least two per
            cent of the average net profits of the company, the Board shall, in
            its report made under clause (o) of sub-section (3) of section 134,
            specify the reasons for not spending the amount and transfer the
            unspent CSR amount as per provisions of sections 135(5) and 135(6)
            of the Act.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            How is average net profit calculated for the purpose of section 135
            of the Act? Whether ‘profit before tax’ or ‘profit after tax’ is
            used for such computation?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            The average net profit for the purpose of determining the spending
            on CSR activities is to be computed in accordance with the
            provisions of section 198 of the Act and will also be exclusive of
            the items given under rule 2(1)(h) of the Companies (CSR Policy)
            Rules, 2014. Section 198 of the Act specifies certain
            additions/deletions (adjustments) to be made while calculating the
            net profit of a company (mainly it excludes capital
            payments/receipts, income tax, set-off of past losses). Profit
            Before Tax (PBT) is used for computation of net profit under section
            135 of the Act.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What is the meaning of the term ‘administrative overheads? What is
            the maximum permissible limit for administrative overheads?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Administrative overheads are the expenses incurred by the company
            for ‘general management and administration’ of CSR functions.
            However, the expenses which are directly incurred for the designing,
            implementation, monitoring, and evaluation of a particular CSR
            project or programme, shall not be included in the administrative
            overheads. Administrative overheads generally comprise of items such
            as employee costs, utilities, office supplies, legal expenses, etc.
            However, expenses which are attributed to the project implementation
            shall be included in project cost only.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Are administrative overheads applicable only for expenses incurred
            by the company, or can they be applied to expenses incurred by the
            implementing agency as well?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            According to rule 2(1)(b) of the Companies (CSR Policy) Rules, 2014,
            administrative overheads mean the expenses incurred by the company
            in the general management and administration of CSR functions in the
            company. Therefore, expenses incurred by implementing agencies on
            the management of CSR activities shall not amount to administrative
            overheads and cannot be claimed by the company.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            If a company spends more than the requirement provided under section
            135, can that excess amount be set off against the mandatory 2% CSR
            expenditure in succeeding financial years?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Yes, the excess amount can be set off against the required 2% CSR
            expenditure up to the immediately succeeding three financial years
            subject to compliance with the conditions stipulated under rule 7(3)
            of the Companies (CSR Policy) Rules, 2014. This position is
            applicable from 22nd January, 2021 and has a prospective effect.
            Thus, no carry forward shall be allowed for the excess amount spent,
            if any, in financial years prior to FY 2020-21.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether it is mandatory for companies to carry out CSR in their
            local areas?{" "}
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            The first proviso to section 135(5) of the Act provides that the
            company shall give preference to local areas and the areas around
            where it operates. Some activities in Schedule VII such as welfare
            activities for war widows, art and culture, and other similar
            activities, transcend geographical boundaries and are applicable
            across the country. With the advent of Information & Communication
            Technology (ICT) and emergence of new age businesses like e-commerce
            companies, process-outsourcing companies, and aggregator companies,
            it is becoming increasingly difficult to determine the local area of
            various activities. The spirit of the Act is to ensure that CSR
            initiatives are aligned with the national priorities and enhance
            engagement of the corporate sector towards achieving Sustainable
            Development Goals (SDGs). Thus, the preference to local area in the
            Act is only directory and not mandatory in nature and companies need
            to balance local area preference with national priorities.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether CSR expenditure of a company can be claimed as a business
            expenditure?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            No, the amount spent by a company towards CSR cannot be claimed as
            business expenditure. Explanation 2 to section 37(1) of the Income
            Tax Act, 1961 which was inserted through the Finance Act, 2014
            provides that any expenditure incurred by an assessee on the
            activities relating to CSR referred to in section 135 of the
            Companies Act, 2013 shall not be deemed to be an expenditure
            incurred by the assessee for the purposes of the business or
            profession.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What tax benefits can be availed under CSR?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            No specific tax exemptions have been extended to CSR expenditure.
            The Finance Act, 2014 also clarifies that expenditure on CSR does
            not form part of business expenditure. Whether contribution in kind
            can be monetized to be shown as CSR expenditure? The requirement
            comes from section 135(5) that states that “The Board of every
            company shall ensure that it spends…” Therefore, CSR contribution
            cannot be in kind and monetized.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Can CSR expenditure be incurred on activities beyond Schedule VII?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            No, CSR expenditure cannot be incurred on activities beyond Schedule
            VII of the Act. The activities undertaken in pursuance of the CSR
            policy must be relatable to Schedule VII of the Companies Act, 2013.
            The items enlisted in Schedule VII of the Act are broad-based and
            are intended to cover a wide range of activities. The entries in the
            said Schedule VII must be interpreted liberally to capture the
            essence of the subjects enumerated in the said Schedule.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What are the different modes of incurring CSR expenditure?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            CSR expenditure can be incurred in multiple modes: (i) ‘Activities
            route’, which is a direct mode wherein a company undertakes the CSR
            projects or programmes as per Schedule VII of the Act, either by
            itself or by engaging implementing agencies as prescribed in
            Companies (CSR Policy) Rules, 2014. (ii) ‘Contribution to funds
            route', which allows the contributions to various funds as specified
            in Schedule VII of the Act. (iii) Contribution to incubators and R&D
            projects, as specified in item (ix)(a) and contribution to
            institutes/organisations, engaged in research and development
            activity, as specified under item (ix)(b) of Schedule VII of the
            Act.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Can CSR funds be utilised to fund Government schemes?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            The objective of CSR provisions is to involve the corporates as
            partners in the social development process. Use of corporate
            innovations and management skills in the delivery of ‘public goods’
            is at the core of CSR implementation by the companies. Therefore,
            CSR should not be interpreted as a source of financing the resource
            gaps in Government Schemes. However, the Board of the eligible
            company may undertake similar activities independently subject to
            fulfilment of Companies (CSR Policy) Rules, 2014.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether involvement of employees of a company in their CSR projects
            can be monetized and accounted for under the head of ’CSR
            expenditure’?{" "}
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            No, involvement of employees in CSR projects of a company cannot be
            monetized. Contribution and involvement of employees in CSR
            activities of the company will no doubt generate interest/pride in
            CSR work and promote transformation from Corporate Social
            Responsibility (CSR) as an obligation to Socially Responsible
            Corporate (SRC) in all aspects of their functioning. Companies,
            therefore, should be encouraged to involve their employees in CSR
            activities.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Which activities do not qualify as eligible CSR activity?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Rule 2(1)(d) of the Companies (CSR Policy) Rules, 2014 defines CSR
            and the following activities are specifically excluded from being
            considered as eligible CSR activity: (i) Activities undertaken in
            pursuance of normal course of business of the company. However,
            exemption is provided for three financial years, till FY 2022-23, to
            companies engaged in R&D activities for new vaccines, drugs, and
            medical devices in their normal course of business, related to
            COVID19. This exclusion is allowed only in case the companies are
            engaged in R&D in collaboration with organisations as mentioned in
            item (ix) of Schedule VII and disclose the same in their Board
            reports. (ii) Activities undertaken outside India, except for
            training of Indian sports personnel representing any State or Union
            Territory at national level or India at international level; (iii)
            Contribution of any amount, directly or indirectly, to any political
            party under section 182 of the Act; (iv) Activities benefitting
            employees of the company as defined in section 2(k) of the Code on
            Wages, 2019; (v) Sponsorship activities for deriving marketing
            benefits for products/services; (vi) Activities for fulfilling
            statutory obligations under any law in force in India.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether the companies can undertake any CSR activity mentioned under
            Schedule VII of the Act for the exclusive benefit of their
            employees, workers and their family members?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Rule 2(1)(d)(iv) of the Companies (CSR Policy) Rules, 2014 states
            that any activity benefitting employees of the company shall not be
            considered as eligible CSR activity. As per the rule, any activity
            designed exclusively for the benefit of employees shall be
            considered as an “activity benefitting employees” and will not
            qualify as permissible CSR expenditure. The spirit behind any CSR
            activity is to benefit the public at large and the activity should
            be nondiscriminatory to any class of beneficiaries. However, any
            activity which is not designed to benefit employees solely, but the
            public at large, and if the employees and their family members are
            incidental beneficiaries, then, such activity would not be
            considered as “activity benefitting employees” and will qualify as
            eligible CSR activity.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What are the different modes of implementation of CSR activities?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Pursuant to rule 4 of the Companies (CSR Policy) Rules, 2014 a
            company may undertake CSR activities through following three modes
            of implementation: (i) Implementation by the company itself (ii)
            Implementation through eligible implementing agencies as prescribed
            under sub-rule (1) of rule 4. (iii) Implementation in collaboration
            with one or more companies as prescribed under sub-rule (4) of rule
            4.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What is the meaning of ‘ongoing project’? Which projects can be
            considered as ongoing?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Ongoing project has been defined under rule 2(1)(i) of the Companies
            (CSR Policy) Rules, 2014 as: (i) a multi-year project, stretching
            over more than one financial year; (ii) having a timeline not
            exceeding three years excluding the year of commencement; (iii)
            includes such project that was initially not approved as a
            multi-year project but whose duration has been extended beyond one
            year by the Board based on reasonable justification. The project
            should have commenced within the financial year to be termed as
            ‘ongoing’. The intent is to include a project which has an
            identifiable commencement and completion dates. After the completion
            of any ongoing project, the Board of the company is free to design
            any other project related to operation and maintenance of such
            completed projects in a manner as may be deemed fit on a
            case-to-case basis. Note: The term ‘year’ refers to financial year
            as defined in section 2(41) of the Act.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            When will an ongoing project be regarded as ‘commenced’?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            An ongoing project will have ‘commenced’ when the company has either
            issued the work order pertaining to the project or awarded the
            contract for execution of the project.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What is the maximum permissible time period for any ongoing project?
            Can the time period of an ongoing project be extended beyond the
            permissible period?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            As per the definition of an ongoing project, the maximum permissible
            time period shall be three financial years excluding the financial
            year in which it is commenced i.e., (1+3) financial years. Under no
            circumstances shall the time period of an ongoing project be
            extended beyond its permissible limit.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What actions need to be taken if a company spends less than the
            amount required to be spent under CSR obligation in a particular
            year?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            If a company spends less than the amount required to be spent under
            their CSR obligation, the Board shall specify the reasons for not
            spending in the Board’s report and shall deal with the unspent
            amount in the following manner: Nature of unspent amount Action
            required Timelines Unspent amount pertains to ‘ongoing projects’
            Transfer such unspent amount to a separate bank account of the
            company to be called as ‘Unspent CSR Account’. Within 30 days from
            the end of the financial year. Unspent amount pertains to ‘other
            than ongoing projects’ Transfer unspent amount to any fund included
            in Schedule VII of the Act. Within 6 months from the end of the
            financial year.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether disbursal of funds by a company to the implementing agency
            for the implementation of projects will be considered as spend under
            section 135(5) and rules made there under?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Section 135(5) of the Act prescribes minimum spending obligation for
            the company. The company may fulfil its CSR spending obligation
            directly by itself or though engaging an implementing agency. The
            implementing agency acts on behalf of the company and mere disbursal
            of funds for implementation of a project does not amount to spending
            unless the implementing agency utilises the whole amount. In the
            annual action plan, the CSR Committee of the company is required to
            provide for modalities of utilisation of funds. The CSR Committee
            shall recommend to the Board on budget allocation for any CSR
            project including modalities of utilisation of funds in every
            project. Further, as per rule 4(5) of the Companies (CSR Policy)
            Rules, 2014, the Board of a company shall satisfy itself that the
            funds so disbursed have been utilised for the purposes and in the
            manner as approved by it and the Chief Financial Officer or the
            person responsible for financial management shall certify to the
            effect. Accordingly, the CSR Committee and Board should ensure that
            CSR fund should be disbursed to implementing agencies, partially or
            wholly, in such a manner so that they can be utilised by them during
            the financial year. Mere disbursal of funds for implementation of a
            project does not amount to spending unless the implementing agency
            utilises the whole amount.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What are the penal provisions for noncompliance with the provisions
            regarding transfer of unspent amount?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            The said non-compliance is a civil wrong and shall attract the
            following penalties: Company Twice the unspent amount required to be
            transferred to any fund included in Schedule VII of the Act or
            Unspent CSR Account, as the case may be, or one crore rupees,
            whichever is less. Every Officer in Default 1/10th of the unspent
            amount required to be transferred to any fund included in Schedule
            VII of the Act or Unspent CSR Account, or two lakh rupees, whichever
            is less.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Will the penal proceedings apply even after the unspent amount has
            been transferred to the Unspent CSR Account or to the funds
            mentioned in Schedule VII of the Act?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            The penalty does not relieve the company from the obligations under
            the law, and the penalty is over and above the obligated amount
            required to be transferred under section 135(5) or 135(6). The
            penalty is the consequence of not abiding by the law, and not an
            alternative for the same.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Is the penal provision in section 135(7) specific to
            non-transference of the unspent CSR amount?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Yes, section 135(7) clearly states the penalty for default in
            complying with the provisions of sub-section (5) or subsection (6)
            only. 8.4 What are the penal provisions relating to noncompliance
            with provisions other than section 135(5) and 135(6) of the Act? In
            case of non-compliance with any other provisions of the section or
            rules, the provisions of section 134(8) or general penalty under
            section 450 of the Act will be applicable. Further, in case of
            non-payment of penalty within the stipulated period, the provisions
            of section 454(8) will be applicable.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What is the objective of providing impact assessment of CSR
            activities?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            The purpose of impact assessment is to assess the social impact of a
            particular CSR project. The intent is to encourage companies to take
            considered decisions before deploying CSR amounts and assess the
            impact of their CSR spending. This not only serves as feedback for
            companies to plan and allocate resources better but shall also
            deepen the impact of CSR.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Which companies are required to undertake impact assessment?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Rule 8(3) of the Companies (CSR Policy) Rules, 2014 mandates
            following class of companies to conduct impact assessment: (i)
            companies with minimum average CSR obligation of Rs. 10 crore or
            more in the immediately preceding 3 financial years; and (ii)
            companies that have CSR projects with outlays of minimum Rs. 1 crore
            and which have been completed not less than 1 year before
            undertaking impact assessment. Impact assessment shall be carried
            out project-wise only in cases where both the above conditions are
            fulfilled. In other cases, it can be taken up by the company on a
            voluntary basis.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Who can conduct impact assessment?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Rule 8(3) of the Companies (CSR Policy) Rules, 2014 requires that
            the impact assessment be conducted by an independent agency. The
            Board has the prerogative to decide on the eligibility criteria for
            selection of the independent agency for impact assessment.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Is expenditure on impact assessment over and above the
            administrative overheads of 5%, or included in the same?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Yes, the expenditure incurred on impact assessment is over and above
            the specified administrative overheads of 5%. Expenditure up to a
            maximum of 5% of the total CSR expenditure for that financial year
            or 50 lakh rupees (whichever is lower) can be incurred separately
            for impact assessment.
          </Text>
          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            When two or more companies collaborate for implementation of a CSR
            project, should the impact assessment carried out by one company be
            shared with other companies?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Yes, in case two or more companies choose to collaborate for the
            implementation of a CSR project, then the impact assessment carried
            out by one company for the common project may be shared with the
            other companies for the purpose of disclosure to the Board and in
            the annual report on CSR. The sharing of the cost of impact
            assessment may be decided by the collaborating companies subject to
            the limit as prescribed in rule 8(3)(c) of the Companies (CSR
            Policy) Rules, 2014 for each company.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether reporting of CSR is mandatory in Board’s Report?
          </Heading>
          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Yes, as per rule 8(1) of the Companies (CSR Policy) Rules, 2014, the
            Board’s Report pertaining to any financial year, for a CSR-eligible
            company, shall include an annual report on CSR containing
            particulars specified in Annexure I or Annexure II of the said
            rules, as applicable.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            What are the disclosure requirements on the website of the company?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            As per rule 9, the Board of Directors of the company shall
            mandatorily disclose the following on their website, if any, for
            public access: (i) Composition of the CSR Committee; (ii) CSR
            Policy; and (iii) Projects approved by the Board.
          </Text>

          <Heading
            textAlign={{ base: "left", md: "center" }}
            color='primary.1000'
          >
            Whether every CSR project irrespective of outlay and percentage to
            the total CSR expenditure of the company needs to be disclosed on
            the website of the respective company in terms of rule 9 of the
            Companies (CSR Policy) Rules, 2014?
          </Heading>

          <Text fontSize='md' textAlign={{ base: "left", md: "center" }}>
            Yes, as per rule 9 of the Companies (CSR Policy) Rules, 2014, all
            CSR projects approved by the Board are required to be disclosed on
            the website of the company, if any, for public access.
          </Text>
        </VStack>
      </Center>
    </section>
  );
};

export default Faq;
