import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../actions";
import {
  Button,
  Box,
  Heading,
  Stack,
  useBreakpointValue,
  VStack,
  Center,
} from "native-base";

import {
  NzPhone,
  Nz1024,
  NzBannerCard,
  NzFlexWrap,
  NzHeading,
  NzInput,
  NzLabel,
} from "../../components/NzComponents";

function RegisterDetail(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });
  const form1 = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);

  // useEffect(() => {
  //   if (!auth.isLoggedIn) {
  //     props.history.push("/login");
  //   }
  // }, []);

  useEffect(() => {
    if (users.userUpdated === true) {
      if (auth.c1 === "sp" || auth.c1 === "ag") {
        props.history.push("/dashboard");
      } else {
        props.history.push("/vdashboard");
      }
    }
  }, [users]);

  const onSubmit = (data) => {
    data.id = auth.id;
    console.log(data);
    dispatch(updateUser(data));
  };

  return (
    <Nz1024 bg="#efefe8">
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>
            {props.location.program ? "Edit" : "New"} Sponsor Registeration
            detail
          </NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      <form
        ref={form1}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" /*, backgroundColor: "orange"*/ }}
      >
        <NzBannerCard>
          <NzLabel>Sponsor Name</NzLabel>
          <Controller
            control={control}
            name="v8"
            render={({ field }) => (
              <NzInput
                {...field}
                bg={"primary.50"}
                mb={2}
                placeholder="Sponsor Name * "
              />
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v8 && errors.v8.type === "required" && (
            <Box px={2} ml={"3%"}>
              Sponsor Name is mandatory
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Pincode</NzLabel>
          <Controller
            control={control}
            name="v11"
            render={({ field }) => (
              <NzInput
                {...field}
                bg={"primary.50"}
                mb={2}
                placeholder="Pincode *"
              />
            )}
            rules={{
              required: true,
              pattern: /^(\d{6})$/,
            }}
          />
          {errors.v11 && errors.v11.type === "required" && (
            <Box px={2} ml={"3%"}>
              Pincode is mandatory
            </Box>
          )}
          {errors.v11 && errors.v11.type === "pattern" && (
            <Box px={2} ml={"3%"}>
              Pincode is not valid
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Contact Person Name</NzLabel>
          <Controller
            control={control}
            name="v9"
            render={({ field }) => (
              <NzInput
                {...field}
                bg={"primary.50"}
                mb={2}
                placeholder="Contact Person Name *"
              />
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v9 && errors.v9.type === "required" && (
            <Box px={2} ml={"3%"}>
              Contact Person Name is mandatory
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Contact Person Designation</NzLabel>
          <Controller
            control={control}
            name="v12"
            render={({ field }) => (
              <NzInput
                {...field}
                bg={"primary.50"}
                mb={2}
                placeholder="Contact Person Designation * "
              />
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v12 && errors.v12.type === "required" && (
            <Box px={2} ml={"3%"}>
              Contact Person Name is mandatory
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Contact Person Phone Number</NzLabel>
          <Controller
            control={control}
            name="v10"
            render={({ field }) => (
              <NzPhone {...field} placeholder="Contact Person Phone Number *" />
            )}
            rules={{
              required: true,
              pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            }}
          />
          {errors.v10 && errors.v10.type === "required" && (
            <Box px={2} ml={"3%"}>
              Phone Number is mandatory
            </Box>
          )}
          {errors.v10 && errors.v10.type === "pattern" && (
            <Box px={2} ml={"3%"}>
              Phone Number is not valid
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>eKYC</NzLabel>
          <Controller
            control={control}
            name="v13"
            render={({ field }) => (
              <NzInput {...field} bg={"primary.50"} placeholder="eKYC" />
            )}
          />
        </NzBannerCard>
        <Button
          mt={12}
          size={"md"}
          _text={{
            fontSize: "sm",
            fontWeight: "bold",
          }}
          // onPress={(e) => {
          //   props.history.push("/home");
          // }}
          onPress={() =>
            form1.current.dispatchEvent(
              new Event("submit", { bubbles: true, cancelable: true })
            )
          }
        >
          Submit
        </Button>
      </form>
    </Nz1024>
  );
}
export default RegisterDetail;
