import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Box,
  Heading,
  Input,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "native-base";
import {NzInput} from "../../components/NzComponents";

function Fpassword(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });
  const form1 = useRef(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    //props.history.push("/password");
  };

  return (
    <>
      <Box bg="white" shadow={2} rounded="lg" size="lg" m={10}>
        <Stack space={4} p={[4, 4, 8]}>
          <VStack textAlign={"center"}>
            <Heading size={"sm"} m={5} color="#6b21a8">
              Create password
            </Heading>
          </VStack>
          <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
            <VStack ml={5}>
              <Controller
                control={control}
                name="userId"
                render={({ field }) => (
                  <NzInput {...field} placeholder="Enter your Old Password" />
                )}
                rules={{
                  required: true,
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                }}
              />
              {errors.userId && errors.userId.type === "required" && (
                <Box px={2}>Email is mandatory</Box>
              )}
              {errors.userId && errors.userId.type === "pattern" && (
                <Box px={2}>Email is not valid</Box>
              )}

              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <NzInput {...field} placeholder="Enter your New Password" />
                )}
                rules={{
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{6,64}$/,
                }}
              />
              {errors.password && errors.password.type === "required" && (
                <Box px={2}>Password is mandatory</Box>
              )}
              <Button
                m={2}
                size={"sm"}
                // onPress={(e) => {
                //   props.history.push("/login");
                // }}
              >
                Continue
              </Button>
            </VStack>
          </form>
        </Stack>
      </Box>
    </>
  );
}

export default Fpassword;
