import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Heading,
  Hidden,
  Center,
  VStack,
  Flex,
  Text,
  Link,
} from "native-base";
import { v4 as uuidv4 } from "uuid";
import ReCAPTCHA from "react-google-recaptcha";

import { Instagram, Facebook, Twitter, Linkedin } from "../../Icons/NzIcons";
import { NzButton, NzInput, NzTextarea } from "../../NzComponents";
import { addLeads, resetLead } from "../../../actions";

function Contact(props) {
  const form1 = useRef(null);
  const captchaRef = useRef(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();

  const leads = useSelector((state) => state.leads);
  const [leadSubmitted, setLeadSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [captcha, setCaptcha] = useState(false);
  const [captchaMessage, setCaptchaMessage] = useState(false);

  useEffect(() => {
    if (leads.Count === 1) {
      setLeadSubmitted(true);
      setCaptcha(false);
      setCaptchaMessage(false);
      captchaRef.current.reset();
      reset({
        contact_name: "",
        contact_email: "",
        contact_nbr: "",
        contact_company: "",
        message: "",
        contactus_type: "",
        // v3: "Phone",
        // v4: "LinkedIn User Name",
        // v5: "Comments",
      });
      dispatch(resetLead());
    }
  }, [leads]);

  const onSubmit = (data) => {
    setLeadSubmitted(false);
    // if (captcha) {
    data.id = uuidv4();
    dispatch(addLeads(data));
    // } else {
    //   setCaptchaMessage(true);
    // }
    // console.log(data);
    //props.history.push("/password");
    // setDisabled(!disabled);
  };
  function onChange(value) {
    setLeadSubmitted(false);
    if (value !== "") {
      //setDisabled(false);
      setCaptcha(true);
      setCaptchaMessage(false);
    }
    //console.log("Captcha value:", value);
  }

  return (
    <form ref={form1} onSubmit={handleSubmit(onSubmit)} id='contact'>
      <Center
        bg='primary.1000'
        pt={{ base: "10", lg: "12" }}
        pb={{ base: "0", lg: "4" }}
      >
        <Hidden till='lg'>
          <Heading my='4' color='white'>
            Contact Us
          </Heading>
        </Hidden>
        <VStack
          w={{
            base: "90%",
            md: "80%",
          }}
          alignItems='center'
          space='6'
          my='4'
        >
          <Flex
            direction={{
              base: "column",
              lg: "row",
            }}
            justify='space-around'
            align={{
              base: "center",
              lg: "flex-start",
            }}
            w='100%'
          >
            <VStack
              space='6'
              w={{
                base: "90%",
                md: "50%",
                lg: "40%",
              }}
              maxW={{
                base: "320px",
                md: "360px",
                lg: "500px",
              }}
            >
              <div>
                <Text bold color='white' fontSize='xl'>
                  We'd love to hear from you <br />
                </Text>
                <Text
                  color='#ffffff90'
                  textAlign='center'
                  fontSize={{ base: "xs", lg: "sm" }}
                >
                  Enroll in a program, or learn more by dropping us a message
                  below
                </Text>
              </div>
              {/* <form> */}
              {/* <div>{JSON.stringify(errors)}</div> */}
              <VStack space='4'>
                <Box>
                  <Controller
                    control={control}
                    // defaultValue="abc"
                    name='contact_name'
                    render={({ field }) => (
                      <NzInput
                        {...field}
                        placeholder='Name *'
                        variant='contact'
                      />
                    )}
                    rules={{
                      required: true,
                      pattern: /^([a-zA-Z]|\s)*$/,
                    }}
                  />
                  {errors.contact_name &&
                    errors.contact_name.type === "required" && (
                    <Text px={2} pt='1' color='white'>
                        Name is mandatory
                    </Text>
                  )}
                  {errors.contact_name &&
                    errors.contact_name.type === "pattern" && (
                    <Text px={2} pt='1' color='white'>
                        Name is not valid
                    </Text>
                  )}
                </Box>
                <Box>
                  <Controller
                    control={control}
                    // defaultValue="abc@gmail.com"
                    name='contact_email' //EmailId
                    render={({ field }) => (
                      <NzInput
                        {...field}
                        placeholder='Email ID *'
                        variant='contact'
                      />
                    )}
                    rules={{
                      required: true,
                      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    }}
                  />
                  {errors.contact_email &&
                    errors.contact_email.type === "required" && (
                    <Text px={2} pt='1' color='white'>
                        Email ID is mandatory
                    </Text>
                  )}
                  {errors.contact_email &&
                    errors.contact_email.type === "pattern" && (
                    <Text px={2} pt='1' color='white'>
                        Email ID is not valid
                    </Text>
                  )}
                </Box>
                <Box>
                  <Controller
                    control={control}
                    name='contact_nbr' // Phone
                    render={({ field }) => (
                      <NzInput
                        {...field}
                        type="number"
                        placeholder='Phone'
                        variant='contact'
                        keyboardType='numeric' // Set the keyboardType to numeric
                      />
                    )}
                    rules={{
                      pattern: /^[0-9]*$/, // Only allow numeric values
                    }}
                  />
                  {errors.contact_nbr && errors.contact_nbr.type === "pattern" && (
                    <Text px={2} pt='1' color='white'>
                      Phone Number should contain only numeric digits
                    </Text>
                  )}
                </Box>
                <Box>
                  <Controller
                    control={control}
                    name='contact_company'
                    render={({ field }) => (
                      <NzInput
                        {...field}
                        placeholder='Company Name'
                        variant='contact'
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    control={control}
                    name='message'
                    render={({ field }) => (
                      <NzTextarea
                        {...field}
                        placeholder='Message'
                        // variant="contact"
                      />
                    )}
                  />
                </Box>      
                <Text mb='2' color='#ffffff90'>
                  * marked fields are mandatory
                </Text>
                {/* <button type="submit">Submit</button> */}
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey='6LelA6AoAAAAAMmipuuZq65Ba5zJsrdWvsIgXwPr'
                  onChange={onChange}
                />
                <VStack space='3'>
                  <NzButton
                    variant='contact'
                    //isDisabled={disabled}
                    onPress={() =>
                      // console.log('hii');
                      form1.current.dispatchEvent(
                        new Event("submit", { bubbles: true, cancelable: true })
                      )
                    }
                  >
                    Submit
                  </NzButton>
                  {captchaMessage ? (
                    <Text color='white'>
                      Please confirm that you are not a robot.
                    </Text>
                  ) : null}

                  {leadSubmitted ? (
                    <Text style={{ color: "#ffffff" }}>
                      Thanks for providing your details. We will get in touch
                      soon
                    </Text>
                  ) : null}
                </VStack>
              </VStack>
            </VStack>
            <VStack space='6'>
              <Hidden till='lg'>
                <Text bold color='white' fontSize='xl'>
                  Connect with us on social media
                </Text>
              </Hidden>
              <Flex
                direction={{
                  base: "row",
                  lg: "column",
                }}
                justify='space-around'
                align='stretch'
                minW='240px'
                mt={{
                  base: "6",
                  lg: "0",
                }}
              >
                <Link
                  href='https://www.instagram.com/optshinedigitech/'
                  my={{
                    base: "0",
                    lg: "4",
                  }}
                >
                  <Instagram size='lg' />
                  <Hidden till='lg'>
                    <Text bold fontSize='md' mx='4' color='white'>
                      Instagram
                    </Text>
                  </Hidden>
                </Link>
                <Link
                  href='https://www.facebook.com/people/OptShine/61553737251479/'
                  my={{
                    base: "0",
                    lg: "4",
                  }}
                >
                  <Facebook size='lg' />
                  <Hidden till='lg'>
                    <Text bold fontSize='md' mx='4' color='white'>
                      Facebook
                    </Text>
                  </Hidden>
                </Link>
                <Link
                  href='#'
                  my={{
                    base: "0",
                    lg: "4",
                  }}
                >
                  <Twitter size='lg' />
                  <Hidden till='lg'>
                    <Text bold fontSize='md' mx='4' color='white'>
                      Twitter
                    </Text>
                  </Hidden>
                </Link>
                <Link
                  href='https://www.linkedin.com/company/100329910/admin/feed/posts/?feedType=following'
                  my={{
                    base: "0",
                    lg: "4",
                  }}
                >
                  <Linkedin color='white' size='lg' />
                  <Hidden till='lg'>
                    <Text bold fontSize='md' mx='4' color='white'>
                      Linkedin
                    </Text>
                  </Hidden>
                </Link>
              </Flex>
            </VStack>
          </Flex>
        </VStack>
      </Center>
    </form>
  );
}
export default Contact;
