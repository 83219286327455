import React from "react";
import { TextArea } from "native-base";

function NzTextarea(props) {
  return (
    <TextArea
      bg={"#ffffff1a"}
      borderWidth={"0"}
      borderRadius={"4px"}
      p={"10px"}
      color={"white"}
      _focus={{ bg: "#ffffff2c" }}
      placeholderTextColor={"#ffffff80"}
      type={props.type}
      {...props}
    >
      {props.children}
    </TextArea>
  );
}

export default NzTextarea;
