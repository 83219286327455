import React from "react";
import { Button } from "native-base";

function NzButton(props) {
  if (props.variant === "contact") {
    return (
      <Button
        bg={"#ffffff3d"}
        borderRadius={"2px"}
        border={"none"}
        marginTop={"12px"}
        padding={"7px 15px"}
        //size={"xs"}
        //_hover={{ bg: "white" , color:'purple'}}
        w={"25%"}
        minW="100px"
        _text={{ color: "#FFFFFF", fontWeight: "bold" }}
        {...props}
      >
        {props.children}
      </Button>
    );
  } else if (props.variant === "banner") {
    return (
      <Button
        bg={"primary.1000"}
        borderRadius="20px"
        border={"none"}
        p="1.5"
        mt="12"
        mb="4"
        w={"200px"}
        _text={{ color: "#FFFFFF", fontWeight: "semibold", fontSize: "18px" }}
        {...props}
      >
        {props.children}
      </Button>
    );
  } else if (props.variant === "secondary") {
    return (
      <Button
        bg={"#ffffff3d"}
        //border="2px solid #7e22ce"
        borderWidth="2px"
        borderColor="#7e22ce"
        _text={{ color: "#7e22ce", fontWeight: "bold" }}
        {...props}
      >
        {props.children}
      </Button>
    );
  } else {
    return <Button {...props}>{props.children}</Button>;
  }
}

export default NzButton;
