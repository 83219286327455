import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import { DataTable } from 'primereact/datatable'
import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'

import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import Swal from 'sweetalert2'

const BeneficiaryDocuments = () => {
  const history = useHistory()
  const { beneficiary_id } = useParams()
  const location = useLocation()
  const [beneficiaryState, setBeneficiaryState] = useState(null)

  useEffect(() => {
    if (location.state) {
      setBeneficiaryState(location.state)
      console.log(location)
    }
  }, [location.state])

  console.log(beneficiary_id, 'Beneficiary ID')
  console.log(beneficiaryState, 'Beneficiary State')

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Card>
        <Card.Header as="h4" style={{ color: '#000', backgroundColor: '#ffffff' }}>
          <div style={{ float: 'right', marginBottom: '10px' }}>Beneficiaries Documents </div>
          <Button
            className="btn primaryAdminButton"
            role="button"
            style={{ marginLeft: '5px' }}
            onClick={(e) => {
              e.preventDefault()
              history.push('/sponsor/dashboard')
            }}
          >
            Back
          </Button>
        </Card.Header>

        <Card.Body>
          <DataTable
            scrollHeight="400px"
            value={beneficiaryState}
            paginator
            rows={10}
            dataKey="id"
            showGridlines
            stripedRows
            filterDisplay="row"
            emptyMessage="No documents found."
            style={{ fontSize: '14px' }}
          >
            <Column header="Document Type" field="document_type" />

            <Column
        header="View"
        body={(rowData) => (
            <div>
                <Button
                    onClick={() => {
                        window.open(rowData.document_name, '_blank');
                        alert('Please note: The PDF file may be downloaded depending on your browser settings.');
                    }}
                    className="p-button-rounded p-button-secondary"
                    style={{
                        backgroundColor: '#002347',
                        borderColor: '#002347',
                        marginRight: '2px',
                    }}
                >
                    View PDF
                </Button>
                <small style={{ marginLeft: '5px' }}>Opens in a new tab</small>
            </div>
        )}
    />
          </DataTable>
        </Card.Body>
      </Card>
    </div>
  )
}

export default BeneficiaryDocuments
