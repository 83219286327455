import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Box,
  CheckIcon,
  Heading,
  Select,
  Stack,
  VStack,
  useBreakpointValue,
  Center,
} from "native-base";

import { getCity, getState, updateUser } from "../../actions";
import {
  NzPhone,
  Nz1024,
  NzBannerCard,
  NzFlexWrap,
  NzHeading,
  NzInput,
  NzLabel,
} from "../../components/NzComponents";
import { upperCamelCase } from "../../js/util";

function Validator(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });
  const form1 = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const combo = useSelector((state) => state.combo);
  const [v13, setV13] = useState();

  useEffect(() => {
    if (!auth.isLoggedIn) {
      props.history.push("/login");
    }
    dispatch(getCity({}));
    dispatch(getState({}));
  }, []);

  useEffect(() => {
    if (users.userUpdated === true) {
      if (auth.c1 === "sp" || auth.c1 === "ag") {
        props.history.push("/dashboard");
      } else {
        props.history.push("/vdashboard");
      }
    }
  }, [users]);

  const onSubmit = (data) => {
    data.id = auth.id;
    console.log(data);
    dispatch(updateUser(data));
  };

  return (
    <Nz1024 bg="#efefe8">
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>
            {props.location.program ? "Edit" : "New"} Validator Registeration
            detail
          </NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      <form
        ref={form1}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" /*, backgroundColor: "orange"*/ }}
      >
        <NzBannerCard>
          <NzLabel>Validator Name</NzLabel>
          <Controller
            control={control}
            name="v8"
            render={({ field }) => (
              <NzInput
                {...field}
                mb={2}
                bg={"primary.50"}
                placeholder="Validator Name *"
              />
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v8 && errors.v8.type === "required" && (
            <Box px={2} ml={"3%"}>
              Name is mandatory
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Pincode</NzLabel>
          <Controller
            control={control}
            name="v9"
            render={({ field }) => (
              <NzInput
                {...field}
                mb={2}
                bg={"primary.50"}
                placeholder="Pincode *"
              />
            )}
            rules={{
              required: true,
              pattern: /^(\d{4}|\d{6})$/,
            }}
          />
          {errors.v9 && errors.v9.type === "required" && (
            <Box px={2} ml={"3%"}>
              Pincode is mandatory
            </Box>
          )}
          {errors.v9 && errors.v9.type === "pattern" && (
            <Box px={2} ml={"3%"}>
              Pincode is not valid
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Contact Person Name</NzLabel>
          <Controller
            control={control}
            name="v10"
            render={({ field }) => (
              <NzInput
                {...field}
                mb={2}
                bg={"primary.50"}
                placeholder="Contact Person Name * "
              />
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v10 && errors.v10.type === "required" && (
            <Box px={2} ml={"3%"}>
              Name is mandatory
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>Contact Person Phone Number</NzLabel>
          <Controller
            control={control}
            name="v11"
            render={({ field }) => (
              <NzPhone
                {...field}
                placeholder="Contact Person Phone Number * "
              />
            )}
            rules={{
              required: true,
              pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            }}
          />
          {errors.v11 && errors.v11.type === "required" && (
            <Box px={2} ml={"3%"}>
              Phone Number is mandatory
            </Box>
          )}
          {errors.v11 && errors.v11.type === "pattern" && (
            <Box px={2} ml={"3%"}>
              Phone Number is not valid
            </Box>
          )}
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>City</NzLabel>
          <Controller
            control={control}
            name="v13"
            render={({ field }) => (
              <Select
                minWidth="200"
                accessibilityLabel="Choose Service"
                placeholder="City"
                _selectedItem={{
                  bg: "primary.50",
                  endIcon: <CheckIcon size={5} />,
                }}
                bg="primary.50"
                selectedValue={v13}
                onValueChange={(itemValue) => {
                  setValue("v13", itemValue);
                  setV13(itemValue);
                }}
              >
                {combo.city.map((el) => {
                  return (
                    <Select.Item label={upperCamelCase(el.v1)} value={el.ky} />
                  );
                })}
              </Select>
            )}
          />
        </NzBannerCard>
        <Button
          mt={12}
          size={"md"}
          _text={{
            fontSize: "sm",
            fontWeight: "bold",
          }}
          onPress={() =>
            form1.current.dispatchEvent(
              new Event("submit", { bubbles: true, cancelable: true })
            )
          }
        >
          Submit
        </Button>
      </form>
    </Nz1024>
  );
}
export default Validator;
