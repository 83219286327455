import { Center, Flex, Heading, Hidden, Text, VStack } from "native-base";
import React from "react";
import { NzButton } from "../../NzComponents";
// import Img from "./dashboard.png";
import Img from "./businesspeople-discussing-laptop.jpg";
import { DownArrow } from "../../Icons/NzIcons";

function Banner() {
  return (
    <>
      <Center bg='#00000005' my='auto' minH='100vh' overflowX='hidden'>
        <Flex
          flexDirection={{
            base: "column-reverse",
            lg: "row",
          }}
          flexGrow='1'
          pt={{ base: "75px", lg: "10px" }}
          pb={{ base: "20px", lg: "0px" }}
          alignItems='center'
          justify={{ base: "space-evenly", lg: "space-around" }}
          w={{ base: "100vw", lg: "90vw" }}
        >
          <Hidden from='md'>
            <Flex direction='column' pb='2' pt='4' align='center'>
              <Text className='arrow' color='grey'>
                It all begins here
              </Text>
              <DownArrow className='arrow' size='sm' />
            </Flex>
          </Hidden>
          <VStack
            w={{
              base: "90%",
              lg: "50%",
            }}
          >
            <Heading
              size='2xl'
              fontSize={{
                base: "36px",
                lg: "48px",
              }}
              color='primary.1000'
            >
              {/* <span style={{color:"#FD7C0B"}}>Opt</span>Shine */}
            </Heading>
            <Heading
              size='md'
              // fontSize={{
              //   base: "lg",
              //   lg: "xl",
              // }}
              fontSize={"28px"}
              color='primary.1000'
              mb={{ base: "4", lg: "8" }}
              mt='2'
            >
              Elevate your CSR initiatives with OptShine and gain peace of mind.
            </Heading>
            <Text fontSize='18px' color='#002347'>
              Welcome to OptShine, where our vision transcends the ordinary,
              envisioning a world where everyone, irrespective of their
              background, survives and thrives with skills, opportunities, and
              unwavering support. At OptShine, we are dedicated to empowering
              lives and transforming futures by uniting diverse
              stakeholders—companies, beneficiaries, skilling institutes, and
              educational institutions—under a shared vision of making a lasting
              impact. OptShine is a Holistic Collaboration Hub where we work
              relentlessly, knowing that our collective efforts today will
              create a sustainable impact, shaping brighter and more prosperous
              futures for generations to come. Our platform offers a seamless
              way to invest in skill development, aligning business growth with
              societal betterment, and ensuring your corporate impact is both
              impactful and worry-free.
            </Text>
            <Hidden till='md'>
              <NzButton variant='banner' href='#skilling'>
                It all begins here
              </NzButton>
            </Hidden>
          </VStack>
          <VStack
            w={{
              base: "60%",
              md: "35%",
            }}
            h='auto'
          >
            <img
              src={Img}
              style={{ filter: "drop-shadow(0 0 0.75rem #868686)" }}
              alt=''
            />
          </VStack>
        </Flex>
      </Center>
    </>
  );
}
export default Banner;
