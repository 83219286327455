//id : unique id
//v1 : name
//v2: emailId
//v3: phoneNumber
//v4: Linked In User Name
//v5: Comments

const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  userUpdated: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "GET_VALIDATOR_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Items: action.payload.Items,
      isLoading: false,
    });

    //## TO BE IMPLEMENTED ##
  case "GET_VALIDATOR_FAIL":
    //## TO BE IMPLEMENTED ##
    return Object.assign({}, state, {
      Items: [],
      isLoading: false,
    });
    //## TO BE IMPLEMENTED ##

  default:
    return state;
  }
}
