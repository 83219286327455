import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CheckIcon, Flex, Select } from "native-base";

import {
  Nz1024,
  NzBannerCard,
  NzFlexWrap,
  NzHeading,
  NzInput,
  NzLabel,
  NzSelect,
} from "../../components/NzComponents";

import { upperCamelCase } from "../../js/util";

import { addProgram, getBenefeciary, getWelfareType } from "../../actions";
import { v4 as uuidv4 } from "uuid";

function ProgramAdd(props) {
  const form1 = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const combo = useSelector((state) => state.combo);
  const auth = useSelector((state) => state.auth);
  const [v2, setV2] = useState();
  //const [v3, setV3] = useState();

  // This method executes only ones on pageload
  useEffect(() => {
    dispatch(getBenefeciary({}));
    dispatch(getWelfareType({}));
  }, []);

  useEffect(() => {
    if (!props.location.program && !props.location.new) {
      props.history.push("/dashboard");
    } else {
      if (props.location.program) {
        let program = props.location.program;
        // console.log(program)
        setValue("v1", program.v1);
        setValue("v2", program.v2);
        //setValue("v3", program.v3);
        setV2(program.v2);
        //setV3(program.v3);
        setValue("v4", program.v4);
        setValue("v16", program.v16);
        //setValue("v17", program.v17);
      }
    }
  }, []);

  const onSubmit = (data) => {
    data.id = auth.id;
    if (!props.location.program) {
      data.ky = uuidv4();
    } else {
      data.ky = props.location.program.ky;
    }

    //console.log(data);
    dispatch(addProgram(data));
    props.history.push({
      pathname: "/criteria",
      program: data,
    });
  };

  return (
    <Nz1024 bg="#efefe8">
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>
            {props.location.program ? props.location.program.v1 : "New Program"}{" "}
          </NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      <form
        ref={form1}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" /*, backgroundColor: "orange"*/ }}
      >
        <NzBannerCard>
          <NzLabel>Program Name</NzLabel>
          <Controller
            control={control}
            name="v1"
            render={({ field }) => (
              <NzInput {...field} placeholder="Program name *" />
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v1 && errors.v1.type === "required" && (
            <Box px={2} ml={"3%"}>
              Program Name is mandatory
            </Box>
          )}
        </NzBannerCard>

        <NzBannerCard>
          <NzLabel>Target Benefeciaries</NzLabel>
          <Controller
            control={control}
            name="v2"
            render={({ field }) => (
              <NzSelect
                placeholder="Target Benefeciaries"
                selectedValue={v2}
                onValueChange={(itemValue) => {
                  setValue("v2", itemValue);
                  setV2(itemValue);
                  setValue(
                    "v16",
                    combo.benefeciaryItems.filter(
                      (el) => el.ky === itemValue
                    )[0].v1
                  );
                }}
              >
                {combo.benefeciaryItems.map((el) => {
                  return (
                    <Select.Item label={upperCamelCase(el.v1)} value={el.ky} />
                  );
                })}
              </NzSelect>
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v2 && errors.v2.type === "required" && (
            <Box px={2} ml={"3%"}>
              Please select Benefeciary Type
            </Box>
          )}
        </NzBannerCard>
        {/*
        <NzBannerCard>
          <NzLabel>Program Type</NzLabel>
          <Controller
            control={control}
            name="v3"
            render={({ field }) => (
              <NzSelect
                placeholder="Program Type"
                selectedValue={v3}
                onValueChange={(itemValue) => {
                  setValue("v3", itemValue);
                  setV3(itemValue);
                  setValue(
                    "v17",
                    combo.welfareItems.filter((el) => el.ky === itemValue)[0].v1
                  );
                }}
              >
                {combo.welfareItems.map((el) => {
                  return (
                    <Select.Item label={upperCamelCase(el.v1)} value={el.ky} />
                  );
                })}
              </NzSelect>
            )}
            rules={{
              required: true,
            }}
          />
          {errors.v3 && errors.v3.type === "required" && (
            <Box px={2} ml={"3%"}>
              Please select Welfare Type
            </Box>
          )}
        </NzBannerCard>
        */}
        <NzBannerCard>
          <NzLabel>{auth.c1 === "sp" ? "Agency Name" : "Sponsor Name"}</NzLabel>
          {auth.c1 === "sp" ? (
            <>
              <Controller
                control={control}
                name="v4"
                render={({ field }) => (
                  <NzInput {...field} placeholder="Agency" />
                )}
                rules={{
                  required: true,
                }}
              />
              {errors.v4 && errors.v4.type === "required" && (
                <Box px={2} ml={"3%"}>
                  Agency Name is mandatory
                </Box>
              )}
            </>
          ) : (
            <>
              <Controller
                control={control}
                name="v5"
                render={({ field }) => (
                  <NzInput {...field} bg={"primary.50"} placeholder="Sponsor" />
                )}
                rules={{
                  required: true,
                }}
              />
              {errors.v5 && errors.v5.type === "required" && (
                <Box px={2} ml={"3%"}>
                  Sponsor Name is mandatory
                </Box>
              )}
            </>
          )}
        </NzBannerCard>
        <Button
          mt={12}
          size={"md"}
          _text={{
            fontSize: "sm",
            fontWeight: "bold",
          }}
          onPress={() =>
            form1.current.dispatchEvent(
              new Event("submit", { bubbles: true, cancelable: true })
            )
          }
        >
          NEXT
        </Button>
      </form>
    </Nz1024>
  );
}
export default ProgramAdd;
