import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import { baseURL } from "../../../config/baseURLPath"
import DataTable from "react-data-table-component"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import swal from "sweetalert2";
import "../admin.css"
const List = () => {
  const history = useHistory();
  const [institution, setInstitution] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [activeId, setActiveId] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleClose = () => {
    setShowModal(false)
    setConfirmationModal(false)
  }

  const jwtHeader = {
    headers: { "access-token": localStorage.getItem("csr_token") },
  }

  useEffect(() => {
    fetchSponsors()
  }, [])

  const fetchSponsors = () => {
    axios({
      method: "post",
      url: baseURL + "getListofSponsors",
    })
      .then((response) => {
        setInstitution(response.data.Data)
      })
      .catch((error) => {})
  }
  
  let tableHeader = [
    {
      name: "Serial No.",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: "Name",
      selector: (row) => row?.sponsor_name,
    },
    {
      name: "Category",
      selector: (row) => row?.sponsor_catg_type,
    },
    {
      name: "City",
      selector: (row) => row.city_name,
    },
    // {
    //   name: 'Action',
    //   cell: (row) => (
    //     <>
    //       <button className="btn btn-sm primaryAdminButton" style={{marginLeft:"5px"}} onClick={() => editSponsor(row.sponsor_id)}>
    //         <i className="fa fa-edit"></i>
    //       </button>
    //       <button
    //         target="_blank"
    //         className="btn btn-sm"
    //         role="button"
    //         style={{backgroundColor:'#ff1a1a',color:"#ffffff", marginLeft:"5px"}}
    //         onClick={()=> deleteSponsor(row.sponsor_id)}
    //       >
    //         <i className="fa fa-trash" aria-hidden="true"></i>
    //       </button>
    //     </>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ]

  const deleteSponsor = (id) =>{
    setActiveId(id)
    setConfirmationModal(true);
  }

  const deleteRecord = () => {
    setConfirmationModal(false);
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("lj_token") },
    }
    axios.post(
      baseURL + "deleteInstitution/"+activeId,
      {},
      jwtHeader
    )
      .then((response) => {
        fetchSponsors()
        new swal({
          title: "Done!",
          text: "Sponsor removed successfully",
          icon: "success",
          timer: 2000,
          button: false
        }).then((result) => {
        })
      })
      .catch((error) => {
        // alert('Something went wrong. Please try again.')
      })
  }

  const editSponsor = (sponsor_id) =>{
    history.push("/admin/sponsor-edit", {sponsor_id: sponsor_id})
  }

  return (
    <div className="container" style={{ marginTop: "10px" }}>
      <Card>
        <Card.Header as="h4" style={{ color: "#000", backgroundColor:"#ffffff" }}>
          Sponsors{" "}
          <div style={{ float: "right", marginBottom: "10px" }}>
            <Link className="btn primaryAdminButton" role="button" to="/validator/dashboard">
              Back
            </Link>
          </div>
        </Card.Header>
        {/* <Card.Body>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Select name="role_id" value={values.role_id} onChange={handleChange}>
                  <option value="">Select Role</option>
                  {roles?.map((role) => (
                    <option value={role.role_id} key={role.role_id}>
                      {role.role_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="admin_name"
                  placeholder="Enter Admin Name"
                  value={values.admin_name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  name="admin_email"
                  placeholder="Enter Email"
                  value={values.admin_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="tel"
                  name="mobile_number"
                  placeholder="Enter Mobile"
                  value={values.mobile_number}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Button onClick={handleSearch} className="primaryAdminButton" >Search</Button>
            </Col>
          </Row>
        </Card.Body> */}
      </Card>
      <DataTable columns={tableHeader} data={institution} pagination />
      <Modal show={confirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Sponsor</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this Sponsor?</Modal.Body>
        <Modal.Footer>
          <button
            style={{marginLeft:"5px", color:"#ffffff"}} 
            className="btn btn-sm primaryAdminButton" variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            style={{marginLeft:"5px", color:"#ffffff"}} 
            className="btn btn-sm primaryAdminButton" variant="primary" onClick={() => deleteRecord()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default List
