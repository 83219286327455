import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_map from 'highcharts/modules/map';
import mapDataIN from './in.geo.json'; // Load India map data
import axios from 'axios';
import { baseURL } from '../../config/baseURLPath';

// Initialize the map module
HC_map(Highcharts);

const IndiaMap = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {

    const userData = JSON.parse(localStorage.getItem('user'))
    // Fetch the state-wise data from the API
    // axios(`/api/sponsor-state-wise/${userData.Sponsor.sponsor_id}`)  // Replace with your API endpoint and sponsorID
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data,'State wise data')
    //     setChartData(data.data.stateWise);
    //   });

    axios({
      method:'get',
      url:baseURL+'sponsor-state-wise/'+userData.Sponsor.sponsor_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }).then((response)=>{
      console.log(response?.data?.data?.stateWise,'State wise')
      setChartData(response?.data?.data?.stateWise)
    })
  }, []);

  const chartOptions = {
    chart: {
      map: mapDataIN, // Reference to the loaded map
    },
    title: {
      text: 'State-wise Student Distribution in India',
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, '#EFEFFF'],
        [0.5, '#4444FF'],
        [1, '#000022'],
      ],
    },
    series: [
      {
        data: chartData, // Use the fetched data here
        mapData: mapDataIN,
        name: 'Number of Students',
        states: {
          hover: {
            color: '#BADA55',
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
      },
    ],
  };

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} constructorType={'mapChart'} />
    </div>
  );
}

export default IndiaMap;
