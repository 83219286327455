import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import NzHeader from "../../components/NzComponents/NzHeader";

function UserLayout(props) {
  const auth = useSelector((state) => state.auth);
  // const [Items] = useState(props.data);

  useEffect(() => {
    if (!auth.isLoggedIn) {
      // props.history.push("/login");
    }
  }, []);


  return (
    <>
      <NzHeader {...props} />
      {props.children}
    </>
  );
}

export default UserLayout;
