//id : unique id
//v1 : name
//v2: emailId
//v3: phoneNumber
//v4: Linked In User Name
//v5: Comments

const defaultState = {
  Count: -1,
  hasErrored: false,
  isLoading: false,
  Items: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "SEARCH_PROGRAM_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Count: action.payload.Count,
      Items: action.payload.Items,
      isLoading: false,
    });

  case "GET_STUDENTS_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Items: action.payload.Items,
      isLoading: false,
    });
  case "REMOVE_STUDENT_SUCCESS":
    return {
      ...state,
      refreshPage: true,
    };
  case "REMOVE_STUDENT_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  case "ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });

  case "RESET_SEARCH_PROGRAM":
    //return action.payload;
    return defaultState;

  default:
    return state;
  }
}
