import React from "react";
import {
  Center,
  Flex,
  Menu,
  HamburgerIcon,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
// import "./style.css";
import Logo from "./Logo.png";

const NzHeaderAdmin = (props) => {
  return (
    <Center shadow={3} py={2}>
      <Center
        w={{
          base: "90%",
        }}
        maxW={{
          base: "1024px",
        }}
      >
        <VStack w={"100%"}>
          <HStack justifyContent="space-between" alignItems="center">
            <HStack space="5">
              <Menu
                w="190"
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                      alignSelf="center"
                    >
                      <HamburgerIcon />
                    </Pressable>
                  );
                }}
              >
                <Menu.Item isDisabled>
                  <Text style={{ fontWeight: "bold" }}>Admin Menu</Text>
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/leadList")}>
                  Leads
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/sponsorList")}>
                  Sponsors
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/agencyList")}>
                  Agency
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/vendorList")}>
                  Vendor
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/validatorList")}>
                  Validator
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/userlist")}>
                  User List
                </Menu.Item>
                <Menu.Item onPress={() => props.history.push("/notification")}>
                  Notification
                </Menu.Item>
              </Menu>
              <img src={Logo} height="36" />
            </HStack>
            <HStack>
              <Text onClick={() => props.history.push("/logout")}>Logout</Text>
            </HStack>
          </HStack>
        </VStack>
      </Center>
    </Center>
  );
};

export default NzHeaderAdmin;
