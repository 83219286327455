/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../../../config/baseURLPath'
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap'
import CustomInput from '../../utils/CustomInput'
import '../admin.css'
import swal from 'sweetalert2'
import { read, utils } from 'xlsx'

import { format } from 'date-fns';

import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
//import "primeflex/primeflex.css"

const make_cols = (refstr) => {
  let o = [],
    C = utils.decode_range(refstr).e.c + 1
  for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i }
  return o
}

const List = (props) => {
  const imageUploader = useRef(null)
  const history = useHistory()
  const [courses, setCourses] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [password, setPassword] = useState('')
  const [activeAdmin, setActiveAdmin] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)

  const [activeId, setActiveId] = useState(props.id)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [institution, setInstitution] = useState(0)
  const [data, setData] = useState([])
  const [cols, setCols] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    beneficiary_first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    beneficiary_email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    institute_name:{value:null, matchMode: FilterMatchMode.STARTS_WITH},
    current_state: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  })

  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null)
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false)
  const [title, setTitle] = useState('')

  const [selectedDocumentType, setSelectedDocumentType] = useState('')

  const handleClose = () => {
    setConfirmationModal(false)
  }

  const handleDocumentTypeChange = (e) => {
    setSelectedDocumentType(e.target.value)
  }
  const openBeneficiaryModal = (rowData) => {
    setSelectedBeneficiary(rowData)
    setShowBeneficiaryModal(true)
  }

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }
  useEffect(() => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'))
      getRecords(userData.AdminUser.institution_id)
      setInstitution(userData.AdminUser.institution_id)
    } catch (e) {
      console.log(e)
    }
  }, [])

  const validateTemplate = (data) => {
    //Headers
    const expectedHeaders = [
      'beneficiary_first_name',
      'beneficiary_last_name',
      'beneficiary_email',
      'gender',
      'beneficiary_phone',
      'current_street_number',
      'current_city',
      'current_pin_code',
      'current_state',
      'sports',
      'permanent_street_number',
      'permanent_city',
      'permanent_pin_code',
      'permanent_state',
      'beneficiary_dob',
      'cgpa',
      'enrollment_number',
      'family_income',
      'college',
      'skill_level',
      'wallet_status',
      'qualification',
      'subject',
    ]

    //Get the headers present in the file
    const fileHeaders = data[0].map((header) => header.toLowerCase())
    console.log(fileHeaders)
    const isValidTemplate = expectedHeaders.every((header) => fileHeaders.includes(header))
    return isValidTemplate
  }


  const formatDate = (dob) => {
    if (!dob) return '';
    const date = new Date(dob);
    try{
      return format(date, 'dd-MM-yyyy');
    }catch(e){
      console.log(e)
    }
    return null
  };
  
  const getRecords = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getBeneficiaryByValidator/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCourses(response.data.Content)
        setLoading(false)
      })
      .catch((error) => {})
  }
  // let tableHeader = [
  //   {
  //     name: '#',
  //     cell: (row, index) => index + 1, //RDT provides index by default
  //   },
  //   {
  //     name: 'First Name',
  //     selector: (row) => row?.beneficiary_first_name,
  //   },
  //   {
  //     name: 'Last Name',
  //     selector: (row) => row?.beneficiary_last_name,
  //   },
  //   {
  //     name: 'Email',
  //     selector: (row) => row?.beneficiary_email,
  //   },
  //   {
  //     name: 'Qualification',
  //     selector: (row) => row?.qualification_name,
  //   },
  //   {
  //     name: 'Current State',
  //     selector: (row) => row?.current_state,
  //   },
  //   {
  //     name: 'Action',
  //     cell: (row) => (
  //       <>
  //         <button
  //           className="btn btn-sm primaryAdminButton"
  //           onClick={() => editBeneficiary(row.beneficiary_id)}
  //         >
  //           <i className="fa fa-edit"></i>
  //         </button>
  //         <button
  //           target="_blank"
  //           className="btn btn-sm"
  //           style={{ backgroundColor: '#ff1a1a', color: '#ffffff', marginLeft: '5px' }}
  //           onClick={() => deleteBeneficiary(row.beneficiary_id)}
  //         >
  //           <i className="fa fa-trash" aria-hidden="true"></i>
  //         </button>
  //       </>
  //     ),
  //     ignoreRowClick: true,
  //     allowOverflow: true,
  //     button: true,
  //   },
  // ]

  const deleteBeneficiary = (id) => {
    setActiveId(id)
    setConfirmationModal(true)
  }

  const deleteRecord = () => {
    setConfirmationModal(false)
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    axios
      .post(
        baseURL + 'deleteBeneficiary/' + activeId,
        {
          // admin_id: activeId,
          // institution_id: props.id
        },
        jwtHeader,
      )
      .then((response) => {
        getRecords(institution)
        new swal({
          title: 'Done!',
          text: 'Record removed successfully',
          icon: 'success',
          timer: 2000,
          button: false,
        }).then((result) => {})
      })
      .catch((error) => {
        // alert('Something went wrong. Please try again.')
      })
  }

  const editBeneficiary = (beneficiary_id) => {
    history.push('/validator/beneficiaries-edit', { beneficiary_id: beneficiary_id })
  }

  const addBeneficiary = () => {
    history.push('/validator/beneficiaries-add')
  }

  const successAction = () => {
    if (password.trim() === '') {
      return
    }
    const values = {
      admin_email: activeAdmin,
      password: password.trim(),
    }
    axios
      .post(baseURL + 'auth/updateAdminPass', values, jwtHeader)
      .then((res) => {
        setShowModal(false)
        alert('Password updated successfully')
      })
      .catch((err) => console.log(err))
  }

  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    user_email: '',
    role_id: '',
    city_id: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const [roles, setRoles] = useState([])
  const fetchRoles = () => {
    axios
      .get(baseURL + 'getUserRoles')
      .then((response) => {
        // console.log(response);
        setRoles(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleSearch = () => {
    axios
      .post(baseURL + 'auth/searchAdminUsers', values)
      .then((res) => {
        console.log(res)
        setCourses(res.data.Admin)
      })
      .catch((err) => console.log(err))
  }

  const handleImageUpload = (e /*:File*/) => {
    const file = e.target.files[0]
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = read(bstr, { type: rABS ? 'binary' : 'array' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      let data = utils.sheet_to_json(ws, { header: 1 })

      data = data.filter((row) =>
        row.some((cell) => cell !== undefined && cell !== null && cell !== ''),
      )

      /* Update state */
      console.log(data)
      // setData(data);
      // setCols(make_cols(ws["!ref"]))
      // uploadCourses(data)

      // Validate the template before processing
      if (validateTemplate(data)) {
        // If the template is valid, proceed with uploading
        uploadBeneficiaries(data)
      } else {
        // If the template is not valid, show an error message
        alert('Invalid template. Please use the correct template.')
      }
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)

    e.target.value = null
  }

  const handleDocumentUpload = (event) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    // const beneficiaryId = selectedBeneficiary?.beneficiary_id;
    // if (!beneficiaryId) {
    //   alert('No beneficiary selected.');
    //   return;
    // }

    // const fileName = `beneficiary_${beneficiaryId}_${file.name}`;
    // const formData = new FormData();
    // formData.append("file", file, fileName);

    // axios.post(baseURL+'uploadfile2', formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     'access-token': localStorage.getItem('csr_token')
    //   }
    // }).then(response => {
    //   // Handle the response if necessary
    //   console.log('File uploaded successfully:', response);
    // }).catch(error => {
    //   // Handle errors if any
    //   console.error('Error uploading file:', error);
    // });
  }

  const handleUploadDocument = () => {
    if (!selectedFile) {
      alert('No file selected.')
      return
    }

    if (!selectedFile || !selectedDocumentType) {
      alert('Please select both document type and file.')
      return
    }

    const beneficiaryId = selectedBeneficiary?.beneficiary_id

    if (!beneficiaryId) {
      alert('No beneficiary selected.')
      return
    }

    const fileName = `beneficiary_${beneficiaryId}_${selectedFile.name}`
    const formData = new FormData()
    formData.append('file', selectedFile, fileName)
    formData.append('document_type', selectedDocumentType)
    formData.append('beneficiary_id', beneficiaryId)
    formData.append('institution_id', institution)

    axios
      .post(baseURL + 'uploadfile2', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'access-token': localStorage.getItem('csr_token'),
        },
      })
      .then((response) => {
        console.log('File uploaded successfully:', response)
        swal.fire({ title: 'File Uploaded Successfully!', timer: 2000 })
        setShowBeneficiaryModal(false)
        setSelectedFile(null)
        setSelectedDocumentType('')
      })
      .catch((error) => {
        console.error('Error uploading file:', error)
        setShowBeneficiaryModal(false)
        setSelectedFile(null)
        setSelectedDocumentType('')
      })
  }

  const uploadBeneficiaries = (data) => {
    console.log(data)
    let records = []
    if (data.length < 1) {
      alert('No data found in excel')
      return
    } else if (data[1].length !== 28) {
      alert('Please check your sheet format')
      return
    }
    for (let i = 1; i < data.length; i++) {
      if (data[i].length === 28) {
        let ele = {
          beneficiary_first_name: data[i][0],
          beneficiary_last_name: data[i][1],
          beneficiary_email: data[i][2],
          gender: data[i][3],
          beneficiary_phone: data[i][4],
          current_street_number: data[i][5],
          current_city_id: data[i][6],
          current_pin_code: data[i][7],
          current_country_id: data[i][8],
          current_state_id: data[i][9],
          sport_id: data[i][10],
          permanent_street_number: data[i][11],
          permanent_city_id: data[i][12],
          permanent_pin_code: data[i][13],
          permanent_country_id: data[i][14],
          permanent_state_id: data[i][15],
          beneficiary_dob: data[i][16],
          pc_id: data[i][17],
          qualification_id: data[i][18],
          enrollment_number: data[i][19],
          family_income: data[i][20],
          college_id: data[i][21],
          skill_id: data[i][22],
          institution_id: institution,
          ig_id: data[i][24],
          home_state: data[i][25],
          cgpa: data[i][26],
        }
        records.push(ele)
      }
    }

    axios({
      method: 'POST',
      url: baseURL + 'addBeneficiaryBulk',
      data: records,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        new swal({
          title: 'Done!',
          text: 'New records added successfully',
          icon: 'success',
          timer: 2000,
          button: false,
        }).then((result) => {
          getRecords(institution)
        })
      })
      .catch((error) => {
        console.log(error)
        // alert('Something went wrong. Please try again.')
      })
  }

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Card>
        <Card.Header>Beneficiaries</Card.Header>
        <DataTable
          stripedRows
          showGridlines={true}
          value={courses}
          paginator
          rows={8}
          dataKey="id"
          filters={filters}
          loading={loading}
          filterDisplay="row"
          globalFilterFields={[]}
          tableStyle={{ minWidth: '50rem' }}
          scrollable
          // scrollHeight="400px"
        >
          <Column
            field="beneficiary_first_name"
            header="Name"
            filter
            filterPlaceholder="Search by name"
            emptyMessage="No beneficiaries found!."
          />
          <Column
            field="beneficiary_email"
            header="Email"
            filter
            filterPlaceholder="Search by email"
            emptyMessage="No emails found!"
          />

          <Column 
            header="DOB" 
            sortable 
            field="beneficiary_dob" 
            body={(rowData) => <span>{formatDate(rowData.beneficiary_dob)}</span>}

            />

          <Column
            header="Institute Name"
            filter
            filterPlaceholder="Search by Institute name"
            field="institute_name"
            emptyMessage="No Institute found!"
          />

          <Column
            header="Actions"
            body={(rowData) => (
              <div className="d-flex">
                <Button
                  className="p-button-rounded p-button-secondary"
                  onClick={() => editBeneficiary(rowData?.beneficiary_id)}
                  style={{ backgroundColor: '#002347', borderColor: '#002347', marginRight: '2px' }}
                >
                  <i className="fa fa-edit"></i>
                </Button>

                <Button
                  className="p-button-rounded p-button-secondary"
                  onClick={() => openBeneficiaryModal(rowData)} // Open modal with beneficiary data
                  style={{ backgroundColor: '#002347', borderColor: '#002347' }}
                >
                  <i className="fa fa-file"></i>
                </Button>
              </div>
            )}
          />
        </DataTable>
      </Card>

      <Button
        role="button"
        onClick={() => history.push('/validator/dashboard')}
        style={{ marginTop: '15px', backgroundColor: '#002347', borderColor: '#002347' }}
      >
        Back
      </Button>
      <Modal size="sm" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title>
            <Row>
              <Col>
                <p className="modalHeading">Change Password</p>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <CustomInput
              type="text"
              placeholder="New Password"
              value={password}
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: 'none' }}>
          <Button
            className="popupButtonFalse"
            onClick={(e) => {
              e.preventDefault()
              handleClose()
            }}
          >
            CANCEL
          </Button>
          <Button
            className="popupButtonTrue"
            onClick={(e) => {
              e.preventDefault()
              successAction()
            }}
          >
            SAVE
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={confirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this record?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ marginLeft: '5px', color: '#ffffff' }}
            className="btn btn-sm primaryAdminButton"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            style={{ marginLeft: '5px', color: '#ffffff' }}
            className="btn btn-sm primaryAdminButton"
            variant="primary"
            onClick={() => deleteRecord()}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBeneficiaryModal} onHide={() => setShowBeneficiaryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Document Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Beneficiary Name: {selectedBeneficiary?.beneficiary_first_name}</p>
          <Form.Group controlId="documentType">
            <Form.Label>Select Document Type:</Form.Label>
            <Form.Control
              as="select"
              value={selectedDocumentType}
              onChange={handleDocumentTypeChange}
            >
              <option value="">Select Document Type</option>
              <option value="Income Proof">Income Proof</option>
              <option value="Academic">Academic</option>
              <option value="Identification">Identification</option>
            </Form.Control>
          </Form.Group>
          <input type="file" name="file" accept='application/pdf' onChange={handleDocumentUpload} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBeneficiaryModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUploadDocument}>
            Upload Document
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default List
