import Api from "../js/api";

let url = process.env.REACT_APP_OPT_SOCIETY_URL;

export const addLeads = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.addLeads(payload)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        dispatch(addLeadsSuccess(payload));
      })
      .catch(() => {
        dispatch(itemsHasErrored(true));
      });
  };
};

export function itemsHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const addLeadsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "ADD_LEAD_SUCCESS",
    payload: payload,
  };
};

// ------------UserTable---------------------

export const addUser = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.addUser(payload)
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        payload.id = responseJson.id;
        delete payload["p1"];
        dispatch(addUserSuccess(payload));
      })
      .catch(() => {
        dispatch(userItemsHasErrored(true));
      });
  };
};

export function userItemsHasErrored(bool) {
  return {
    type: "USER_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const addUserSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "ADD_USER_SUCCESS",
    payload: payload,
  };
};

// ------------Update UserTable---------------------

export const updateUser = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.updateUser(payload)
      .then(() => {
        dispatch(updateUserSuccess(payload));
      })
      .catch(() => {
        dispatch(userItemsHasErrored(true));
      });
  };
};

export function ItemsHasErrored(bool) {
  return {
    type: "UPDATE_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const updateUserSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "UPDATE_USER_SUCCESS",
    payload: payload,
  };
};

// ------------ProgramTable---------------------

export const addProgram = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.addProgram(payload)
      .then(() => {
        dispatch(addProgramSuccess(payload));
      })
      .catch(() => {
        dispatch(ProgramItemsHasErrored(true));
      });
  };
};

export function ProgramItemsHasErrored(bool) {
  return {
    type: "PROGRAM_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const addProgramSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "ADD_PROGRAM_SUCCESS",
    payload: payload,
  };
};

// -----------------------GetProgram------------------------

export const getProgram = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getProgram(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getProgramSuccess(responseJson));
        })
        .catch(() => {
          dispatch(programHasErrored(true));
        })
    );
  };
};

export const searchProgram = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.searchProgram(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          dispatch(searchProgramSuccess(responseJson));
        })
        .catch(() => {
          dispatch(programHasErrored(true));
        })
    );
  };
};

export const removeProgram = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.removeProgram(payload)
      .then(() => {
        dispatch(refreshPage(payload));
      })
      .catch(() => {
        dispatch(programItemsHasErrored(true));
      });
  };
};

export function programItemsHasErrored(bool) {
  return {
    type: "PROGRAM_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const refreshPage = () => {
  return {
    type: "REFRESH_PAGE",
  };
};

export function programHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const getProgramSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_PROGRAM_SUCCESS",
    payload: payload,
  };
};

export const searchProgramSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "SEARCH_PROGRAM_SUCCESS",
    payload: payload,
  };
};

// ------------loginTable---------------------
export const login = (payload) => {
  return (dispatch) => {
    return Api.login(payload)
      .then((response) => {
        console.log(response,"REs")
        if (response.Status === 200) {
          localStorage.setItem("user_id", response.AdminUser.admin_id)
          localStorage.setItem("user_name", response.AdminUser.admin_name)
          localStorage.setItem("parent_data", JSON.stringify(response.Sponsor))
          localStorage.setItem("user", JSON.stringify(response.content))
          localStorage.setItem("csr_token", response.AccessToken)

          dispatch(loginSuccess());
          dispatch(reEnter(response));
        } else {
          dispatch(loginFail("Invalid Credential"));
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(loginFail("networkerror"));
      });
  };
};

export const resetPassword = () => {
  return {
    type: "OTP_SENT"
  };
  // return (dispatch) => {
  //   return Api.resetPassword(payload)
  //     .then((response) => {
  //       return {
  //         type: "OTP_SENT"
  //       };
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       dispatch(loginFail("networkerror"));
  //     });
  // };
};

export function loginFail(errorString) {
  return {
    type: "LOGIN_FAIL",
    status: errorString,
  };
}

export const loginSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "LOGIN_SUCCESS",
    payload: payload,
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutAuth());
    dispatch(resetUser());
  };
};

//-------------Log out ----------------------------
export const logoutAuth = () => {
  return {
    type: "LOGOUT",
  };
};

// ------------ for remove lead ---------------------

export const removeLead = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return Api.removeLead(payload)
      .then(() => {
        dispatch(resetLead(payload));
      })
      .catch(() => {
        dispatch(resetLead());
        dispatch(leadItemsHasErrored(true));
      });
  };
};

export function leadItemsHasErrored(bool) {
  return {
    type: "LEAD_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

// -----------------------GetLeads------------------------

export const getLeads = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getLeads(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getLeadsSuccess(responseJson));
        })
        .catch(() => {
          dispatch(leadsHasErrored(true));
        })
    );
  };
};

export function leadsHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const getLeadsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_LEAD_SUCCESS",
    payload: payload,
  };
};

// -----------------------GetUsers------------------------

export const getUsers = (type) => {
  console.log(type);
  return (dispatch) => {
    return (
      Api.getUsers(type)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getUsersSuccess(responseJson));
        })
        .catch(() => {
          dispatch(usersHasErrored(true));
        })
    );
  };
};

// -----------------------GetValidator------------------------

export const getValidator = () => {
  return (dispatch) => {
    return (
      Api.getUsers("va")
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getValidatorsSuccess(responseJson));
        })
        .catch(() => {
          dispatch(usersHasErrored(true));
        })
    );
  };
};

// -----------------------GetVendor------------------------

export const getVendor = () => {
  return (dispatch) => {
    return (
      Api.getUsers("tr")
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getVendorsSuccess(responseJson));
        })
        .catch(() => {
          dispatch(usersHasErrored(true));
        })
    );
  };
};

export function usersHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const getUsersSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_USER_SUCCESS",
    payload: payload,
  };
};

export const getValidatorsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_VALIDATOR_SUCCESS",
    payload: payload,
  };
};

export const getVendorsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_VENDOR_SUCCESS",
    payload: payload,
  };
};

// -----------------------GetBenefeciaryCombo------------------------

export const getBenefeciary = (payload) => {
  payload.type = "os_benefeciary";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getBenefeciarySuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getMaster = (payload) => {
  return (dispatch) => {
    return (
      Api.getMaster(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(MasterSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getBenefeciarySuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_BENEFECIARY_SUCCESS",
    payload: payload,
  };
};

export const MasterSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "MASTER_SUCCESS",
    payload: payload,
  };
};

// -----------------------getWelfareTypeCombo------------------------

export const getWelfareType = (payload) => {
  payload.type = "os_welfaretype";
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getWelfareTypeSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};
export const getWelfareTypeSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_WELFARETYPE_SUCCESS",
    payload: payload,
  };
};

// -----------------------Personal Criteria Gender------------------------

export const getGender = (payload) => {
  payload.type = "gender";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getGenderSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getGenderSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_GENDER_SUCCESS",
    payload: payload,
  };
};

// -----------------------Personal Criteria Income Group------------------------

export const getIncome = (payload) => {
  payload.type = "os_income";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getIncomeSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getIncomeSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_INCOME_SUCCESS",
    payload: payload,
  };
};

// -----------------------Personal Criteria Home State------------------------

export const gethomeState = (payload) => {
  payload.type = "os_homestate";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(gethomeStateSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const gethomeStateSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_HOME_STATE_SUCCESS",
    payload: payload,
  };
};

// -----------------------Location Criteria State------------------------

export const getState = (payload) => {
  payload.type = "state";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getStateSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getStateSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_STATE_SUCCESS",
    payload: payload,
  };
};

// -----------------------Location Criteria City------------------------

export const getCity = (payload) => {
  payload.type = "city";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getCitySuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getCitySuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_CITY_SUCCESS",
    payload: payload,
  };
};

export const getSports = (payload) => {
  payload.type = "sports";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getSportsSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getSportsSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_SPORTS_SUCCESS",
    payload: payload,
  };
};

// -----------------------Performance Based------------------------

export const getSkill = (payload) => {
  payload.type = "skills";
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getCombo(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getSkillSuccess(responseJson));
        })
        .catch(() => {
          dispatch(itemsHasErrored(true));
        })
    );
  };
};

export const getSkillSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_SKILL_SUCCESS",
    payload: payload,
  };
};

// -----------------------Getstudend info------------------------
export const getStudent = (type) => {
  console.log(type);
  return (dispatch) => {
    return (
      Api.getStudent(type)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(StudentSuccess(responseJson));
        })
        .catch(() => {
          dispatch(StudentHasErrored(true));
        })
    );
  };
};

export function StudentHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}
export const StudentSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_STUDENTS_SUCCESS",
    payload: payload,
  };
};

export const removeStudent = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.removeStudent(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(removeStudentSuccess(responseJson));
        })
        .catch(() => {
          dispatch(removeStudentHasErrored(true));
        })
    );
  };
};

export const removeStudentSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "REMOVE_STUDENT_SUCCESS",
    payload: payload,
  };
};

export function removeStudentHasErrored(bool) {
  return {
    type: "REMOVE_STUDENT_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const resetLead = () => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "RESET_LEAD",
  };
};

export const resetSearchProgram = () => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "RESET_SEARCH_PROGRAM",
  };
};

export const resetUser = () => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "RESET_USER",
  };
};

export const reEnter = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  localStorage.setItem("user", JSON.stringify(payload));
  return {
    type: "REENTER",
    payload: payload,
  };
};

// -----------------------getCourses------------------------

export const getCourse = (payload) => {
  return (dispatch) => {
    return (
      Api.getCourse(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(getCourseSuccess(responseJson));
        })
        .catch(() => {
          dispatch(courseHasErrored(true));
        })
    );
  };
};

export function courseHasErrored(bool) {
  return {
    type: "ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const getCourseSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_COURSE_SUCCESS",
    payload: payload,
  };
};

export const removeCourse = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.removeCourse(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(removeCourseSuccess(responseJson));
        })
        .catch(() => {
          dispatch(removeCourseHasErrored(true));
        })
    );
  };
};

export const removeCourseSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "REMOVE_COURSE_SUCCESS",
    payload: payload,
  };
};

export function removeCourseHasErrored(bool) {
  return {
    type: "REMOVE_COURSE_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const getBuild = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.getBuild(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(BuildSuccess(responseJson));
        })
        .catch(() => {
          dispatch(BuildHasErrored(true));
        })
    );
  };
};

export const BuildSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_BUILD_SUCCESS",
    payload: payload,
  };
};

export function BuildHasErrored(bool) {
  return {
    type: "BUILD_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const removeBuild = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.removeBuild(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(removeBuildSuccess());
        })
        .catch(() => {
          dispatch(removeBuildHasErrored(true));
        })
    );
  };
};

export const removeBuildSuccess = () => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "REMOVE_BUILD_SUCCESS",
  };
};

export function removeBuildHasErrored(bool) {
  return {
    type: "REMOVE_BUILD_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}

export const upload = (payload) => {
  return (dispatch) => {
    try {
      var formData = new FormData();
      formData.append(payload.id, payload.files[0]);

      var xhr = new XMLHttpRequest();
      xhr.upload.onprogress = function () {
        // console.log(Math.ceil((e.loaded / e.total) * 100));
        // dispatch(updateProgress(Math.ceil((e.loaded / e.total) * 100)));
      };
      if (payload.type === "student") {
        xhr.open("POST", url + "/uploadStudent");
      } else {
        xhr.open("POST", url + "/uploadCourse");
      }

      //xhr.setRequestHeader('x-amz-acl', 'public-read')
      xhr.timeout = 60000; // time in milliseconds
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log("upload finished");
            //dispatch(updateProgress(0));
            setTimeout(() => {
              if (payload.type === "student") {
                dispatch(getBuild({ id: "studentUpload", v2: payload.id }));
              } else {
                dispatch(getBuild({ id: "courseUpload", v2: payload.id }));
              }
              setTimeout(() => {
                if (payload.type === "student") {
                  dispatch(getBuild({ id: "studentUpload", v2: payload.id }));
                } else {
                  dispatch(getBuild({ id: "courseUpload", v2: payload.id }));
                }
              }, 7000);
            }, 1000);
          } else {
            console.log("Upload Failed", xhr.statusText);
            dispatch(updateFailed());
          }
        }
      };
      xhr.send(formData);
    } catch (e) { /* empty */ } finally { /* empty */ }
  };
};

export const updateProgress = (item) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "UPDATE_PROGRESS",
    payload: item,
  };
};

export const updateFailed = () => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "UPDATE_FAILED",
  };
};

export const runNotification = (payload) => {
  console.log(payload);
  return (dispatch) => {
    return (
      Api.runNotification(payload)
        // .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          dispatch(NotificationSuccess(responseJson));
        })
        .catch(() => {
          dispatch(NotificationHasErrored(true));
        })
    );
  };
};

export const NotificationSuccess = (payload) => {
  //console.log('Action loadListSuccess Called:'+JSON.stringify(images))
  return {
    type: "GET_NOTIFICATION_SUCCESS",
    payload: payload,
  };
};

export function NotificationHasErrored(bool) {
  return {
    type: "NOTIFICATION_ITEMS_HAS_ERRORED",
    hasErrored: bool,
  };
}
