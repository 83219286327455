/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Button } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import '../admin.css'
import ProgramSetup from './ProgramSetup'
import Swal from 'sweetalert2'
const CreateProgram = (props) => {
  const [errors, setErrors] = useState({
    plannedBudget: '',
    // Add more error states for other fields as needed
  })
  const [values, setValues] = useState({
    planned_budget: 0,
    balance_budget: 0,
    total_budget: 0,
    eligible_candidates_count: 0,
  })

  const [error, setError] = useState(false)
  const blankProgram = {
    program_type: '0',
    // course_category: "",
    subject_id: '',
    skill_id: '',
    course_id: '',
    vendor_id: '',
    additional_expense: 0,
    seeking_scholarship: 0,
    sponsored_candidate_count: 0,
    total_amount: 0,
  }

  const sorting_parameters = {
    cgpa: false,
    financial_wellness: false,
    mobile_wallet_setup: false,
    twelth_marks: false,
    family_income: false,
    gender: false,
  }

  const [totalBudgetEmarked, setTotalBudgetEmarked] = useState(0)
  const [programs, setPrograms] = useState([blankProgram])

  useEffect(() => {
    let balance = props.plannedBudget - totalBudgetEmarked
    setValues({
      ...values,
      planned_budget: props.plannedBudget,
      balance_budget: balance,
      total_budget: totalBudgetEmarked,
    })
  }, [props.plannedBudget, totalBudgetEmarked])

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  useEffect(() => {
    if (props.id !== 0) {
      fetchProgram(props.id)
    } else {
      setValues(props.program)
      if (props.program.planned_budget !== undefined && props.program.planned_budget !== '') {
        props.setPlannedBudget(props.program.planned_budget)
        setTotalBudgetEmarked(props.program.total_amount)
        const blankProgram = {
          program_type: props.program.program_type,
          // course_category: props.program.course_category,
          subject_id: props.program.subject_id,
          skill_id: props.program.skill_id,
          course_id: props.program.course_id,
          vendor_id: props.program.vendor_id,
          additional_expense: props.program.additional_expense,
          seeking_scholarship: 0,
          sponsored_candidate_count: props.program.sponsored_candidate_count,
          total_amount: props.program.total_amount,
        }
        setPrograms([blankProgram])
      }
    }
  }, [])

  const validate = () => {
    let isValid = true
    const newErrors = { ...errors }

    // Validate planned budget
    if (props.plannedBudget === 0 || props.programType === '') {
      newErrors.plannedBudget = 'Planned budget is required'
      isValid = false
    } else {
      newErrors.plannedBudget = '' // Clear error if valid
    }

    // You can add more validation checks for other fields here

    setErrors(newErrors)
    return isValid
  }

  const successAction = () => {
    if (validate) {
      console.log('validated')
    }
    console.log(props, 'PROPS ')
    if (
      props.plannedBudget === 0 ||
      props?.programType === ''
      // props?.paymentOption === '' ||
      // props?.paymentOption === undefined
    ) {
      setError(true)
      Swal.fire({
        title: 'Enter all mandatory fields!',
        timer: 2000,
      })
    } else {
      if (props.programType === '0') {
        console.log(props)
        if (Number(props.plannedBudget) <= 0 || props.subject_id === '' || props.course_id === '') {
          setError(true)
          Swal.fire({
            title: 'Enter all mandatory fields!',
            timer: 2000,
          })
        } else {
          let combined_values = { ...values, ...programs[0] }
          console.log(combined_values, 'Combined Values')
          props.setProgram(combined_values)
        }

        //   let combined_values = { ...values, ...programs[0] }
        // console.log(combined_values, 'Combined Values')
        // props.setProgram(combined_values)
      }

      if (props.programType === '1') {
        if (props?.paymentOption === '' || props?.paymentOption === undefined) {
          setError(true)
          Swal.fire({
            title: 'Enter all mandatory fields!',
            timer: 2000,
          })
        } else {
          if (props?.paymentOption === 'monthly') {
            if (props?.programDuration === '' || props?.programDuration === null) {
              setError(true)
              Swal.fire({
                title: 'Enter all mandatory fields!',
                timer: 2000,
              })
            } else {
              let combined_values = { ...values, ...programs[0] }
              console.log(combined_values, 'Combined Values')
              props.setProgram(combined_values)
            }
          } else {
            let combined_values = { ...values, ...programs[0] }
            console.log(combined_values, 'Combined Values')
            props.setProgram(combined_values)
          }
        }
      }

      // let combined_values = { ...values, ...programs[0] }
      // console.log(combined_values, 'Combined Values')
      // props.setProgram(combined_values)
    }
  }

  const fetchProgram = (id) => {
    axios
      .get(baseURL + 'getProgramById/' + id, jwtHeader)
      .then((response) => {
        setValues(response.data.Content[0])
        props.setPlannedBudget(response.data.Content[0].planned_budget)
        setTotalBudgetEmarked(response.data.Content[0].total_amount)

        const blankProgram = {
          program_type: response.data.Content[0].program_type,
          // course_category: response.data.Content[0].course_category,
          subject_id: response.data.Content[0].subject_id,
          skill_id: response.data.Content[0].skill_id,
          course_id: response.data.Content[0].course_id,
          vendor_id: response.data.Content[0].vendor_id,
          additional_expense: response.data.Content[0].additional_expense,
          seeking_scholarship: 0,
          sponsored_candidate_count: response.data.Content[0].sponsored_candidate_count,
          total_amount: response.data.Content[0].total_amount,
        }
        setPrograms([blankProgram])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const addAnotherProgram = () => {
    let programList = [...programs]
    programList.push(blankProgram)
    setPrograms(programList)
  }

  const removeProgram = (index) => {
    let programList = [...programs]
    programList.splice(index, 1)

    setPrograms(programList)
  }

  const handleProgramValue = (name, value, index) => {
    let program_list = [...programs]
    let program = { ...program_list[index] }
    program[name] = value
    program_list[index] = program

    if (name === 'total_amount') {
      let totalBudgetEmarked = 0
      program_list.map((item) => {
        totalBudgetEmarked = totalBudgetEmarked + value
      })
      setTotalBudgetEmarked(totalBudgetEmarked)
    }
    setPrograms(program_list)
  }

  const handlePlannedBudgetValue = (value) => {
    console.log(value, 'Value', props)
    props.setPlannedBudget(value)
  }

  const handleCarryForward = (value) => {
    console.log(props.isCarryForwardFunds, 'Carry Forward')
    props.setIsCarryForwardFunds(value)
  }

  const handleInputClick = (e) => {
    e.target.value = '' // Clear the input field when clicked
  }

  const removeLeadingZeros = (value) => {
    return value.replace(/^0+/, '') // Remove leading zeros
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Target Beneficiaries</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">
                  {values?.target_beneficiaries === '1' ? 'Students' : 'Ex-Serviceman'}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Eligible Beneficiaries Count</p>
              </Col>
              {console.log(values.eligible_candidates_count)}
              <Col sm={9}>
                <p className="valueText">
                  {values?.eligible_candidates_count === undefined
                    ? 0
                    : values?.eligible_candidates_count}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Planned Budget*</p>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    name="planned_budget"
                    placeholder="Enter Amount"
                    value={props?.plannedBudget === 0 ? '' : props?.plannedBudget}
                    onChange={(e) => handlePlannedBudgetValue(removeLeadingZeros(e.target.value))}
                  />
                  {errors.plannedBudget && (
                    <Form.Text className="text-danger">{errors.plannedBudget}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Check
                  type="checkbox"
                  label="Carry Forward Funds"
                  onChange={(e) => handleCarryForward(e.target.checked)} 
                  checked={props?.isCarryForwardFunds} 
                />
              </Col>
            </Row>

            {programs.map((item, index) => {
              return (
                <ProgramSetup
                  skill_id={props.skill_id}
                  subject_id={props.subject_id}
                  course_id={props.course_id}
                  setSkill_id={props.setSkill_id}
                  setCourse_id={props.setCourse_id}
                  setSubject_id={props.setSubject_id}
                  programType={props.programType}
                  setProgramType={props.setProgramType}
                  key={index}
                  plannedBudget={props.plannedBudget}
                  program={values}
                  setValues={setValues}
                  values={item}
                  addAnotherProgram={() => addAnotherProgram()}
                  removeProgram={() => removeProgram(index)}
                  programLength={programs.length}
                  handleValue={(name, value) => handleProgramValue(name, value, index)}
                  programDuration={props?.programDuration}
                  setProgramDuration={props.setProgramDuration}
                  amountPerStudent={props.amountPerStudent}
                  setAmountPerStudent={props.setAmountPerStudent}
                  paymentOption={props.paymentOption}
                  setPaymentOption={props.setPaymentOption}
                  amountToBePaidToStartProgram={props.amountToBePaidToStartProgram}
                  setAmountToBePaidToStartProgram={props.setAmountToBePaidToStartProgram}
                  selectedCGPA={props.selectedCGPA}
                  setSelectedCGPA={props.setSelectedCGPA}
                  selectedMobileWallet={props.selectedMobileWallet}
                  setSelectedMobileWallet={props.setSelectedMobileWallet}
                  selectedFW={props.selectedFW}
                  setSelectedFW={props.setSelectedFW}
                  selected12th={props.selected12th}
                  setSelected12th={props.setSelected12th}
                  selectedFI={props.selectedFI}
                  setSelectedFI={props.setSelectedFI}
                  setVendor_id={props.setVendor_id}
                  vendor_id={props.vendor_id}
                  isCarryForwardFunds={props.isCarryForwardFunds}
                  setIsCarryForwardFunds={props.setIsCarryForwardFunds}
                  
                />
              )
            })}
          </Card.Body>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
          <Button onClick={successAction} className="primaryAdminButton">
            Commit Funds
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateProgram
