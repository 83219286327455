//id : unique id
//v1 : name
//v2: emailId
//v3: phoneNumber
//v4: Linked In User Name
//v5: Comments

const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  refreshPage:false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "ADD_PROGRAM_SUCCESS":
    //return action.payload;
    return {
      ...state,
      Items: [...state.Items, action.payload],
      Count: state.Count + 1,
    };
  case "GET_PROGRAM_SUCCESS":
    //return action.payload;
    return {
      ...state,
      Items: [...action.payload.Items],
      Count: state.Count + 1,
      refreshPage:false,
    };
    break;
    //   case "GET_USER_SUCCESS":
    //     return Object.assign({}, state, {
    //       Items: action.payload.Items,
    //       isLoading: false,
    //     });
  case "PROGRAM_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  case "REFRESH_PAGE":
    return {
      ...state,
      refreshPage:true,
    };
  default:
    return state;
  }
}
