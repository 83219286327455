import { Switch, Route } from "react-router-dom"
import React, {  Suspense } from "react"
import Header from "./common/Header"
import Dashboard from "./Dashboard"
import Signup from "./SignUp";
import "./sponsorcss.css"

import Listbeneficiaries from "./pages/manage-beneficiareis/List";
import Addbeneficiaries from "./pages/manage-beneficiareis/Add";
import Editbeneficiaries from "./pages/manage-beneficiareis/Edit";
import Uploadbeneficiaries from "./pages/manage-beneficiareis/Upload";
import Existingbeneficiaries from "./pages/manage-beneficiareis/Existing";

// Report
import SponsorsList from "./pages/manage-sponsors/List";
import ProgramsList from "./pages/manage-programs/List";

function Admin() {
  
  return (
    <div className="App">
      <Switch>
        <Route
          exact 
          path="/validator/register"
          render={(props) => (
            <Suspense fallback={<p> Loading...</p>}>
              <Signup {...props} />
            </Suspense>
          )}
        />
        <ValidatorLayoutRoute exact path="/validator/dashboard" component={Dashboard} />
        <ValidatorLayoutRoute exact path="/validator/beneficiaries-list" component={Listbeneficiaries} />
        <ValidatorLayoutRoute exact path="/validator/beneficiaries-add" component={Addbeneficiaries} />
        <ValidatorLayoutRoute exact path="/validator/beneficiaries-edit" component={Editbeneficiaries} />
        <ValidatorLayoutRoute exact path="/validator/beneficiaries-upload" component={Uploadbeneficiaries} />
        <ValidatorLayoutRoute exact path="/validator/beneficiaries-existing" component={Existingbeneficiaries} />
        <ValidatorLayoutRoute exact path="/validator/sponsors-list" component={SponsorsList} />
        <ValidatorLayoutRoute exact path="/validator/programs-list" component={ProgramsList} />
      </Switch>
    </div>
  )
}

export default Admin

const ValidatorLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          style={{
            backgroundColor: "#ffffff",
            backgroundSize: "cover",
            minHeight: "100vh",
          }}
        >
          <Header />
          <div className="contentArea">
             
            <Suspense fallback={<p> Loading...</p>}>
              <div className="fixContent">
                <Component {...props} />
              </div>
            </Suspense>
          </div>
        </div>
      )}
    />
  );
};