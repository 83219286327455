import React, { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap"
import { baseURL } from "../config/baseURLPath"
import axios from "axios"
import "./admincss.css"

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([])

  useEffect(() => {
    getAdminDashBoardData()
  }, [])

  const getAdminDashBoardData = async () => {
    const url = baseURL + "getAdminDashboard"

    const result = await axios({
      method: "GET",
      url,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })

    setDashboardData(result.data.Content)
    console.log(result.data.Content, "Dashboard")
  }

  return (
    <>
      <div className="contentBox">
        <Row className="numbersRow" >
          <Col className="numberBoxDiv">
            <div className="red colored-box">
              <div className="number-box">
                <p className='numbersText'>
                  {(dashboardData && dashboardData?.beneficiaries)||0}
                </p>
                <p className='sublabelText'>Nos. of Beneficiaries</p>
              </div>
            </div>
          </Col>

          <Col className="numberBoxDiv">
            <div className="blue colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData &&
                    dashboardData?.courses) ||
                    0}
                </p>
                <p className="sublabelText">Nos. of Courses</p>
              </div>
            </div>
          </Col>

          <Col className="numberBoxDiv">
            <div className="green colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData &&
                    dashboardData?.programs) ||
                    0}
                </p>
                <p className="sublabelText">Nos. of programs</p>
              </div>
            </div>
          </Col>
          
          <Col className="numberBoxDiv">
            <div className=" colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData &&
                    dashboardData?.sponsors) ||
                    0}
                </p>
                <p className="sublabelText">Nos. of sponsors</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Dashboard
