import React from "react";

const CustomInput = (props) => {
  return (
    <input
      autoComplete="none"
      className="form-control"
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

export default CustomInput;
