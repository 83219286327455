import React from "react";
import {
  Box,
  Center,
  Hidden,
  Stack,
  StatusBar,
  Text,
  useBreakpointValue,
  VStack,
} from "native-base";
import "./style.css";
import { NzButton } from "../../components/NzComponents";
import NzlogoHeader from "../../components/NzComponents/NzlogoHeader";
import Img from "./Logo.png";

function RegisterForm(props) {
  return (
    <VStack
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
        shadow={4}
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="lg" fontWeight="bold" mt={5}>
              Please select the type you belong to:
            </Text>
          </Hidden>
          <VStack>
            <form>
              <VStack space="3">
                <VStack
                  space={{
                    base: "7",
                    md: "4",
                  }}
                >
                  <VStack space="1">
                    <NzButton
                      mt={5}
                      variant="secondary"
                      onPress={(e) => {
                        props.history.push({
                          pathname: "/sponsor/register",
                          type: "sp", //sponsor
                        });
                      }}
                    >
                      Sponsor (Corporates/NGOs)
                    </NzButton>
                  </VStack>
                  {/* <VStack space="1">
                    <NzButton
                      variant="secondary"
                      onPress={(e) => {
                        props.history.push({
                          pathname: "/registercontact",
                          type: "ag", //agency
                        });
                      }}
                    >
                      Implementation Agency ( NGO )
                    </NzButton>
                  </VStack> */}
                  <VStack space="1">
                    <NzButton
                      variant="secondary"
                      onPress={(e) => {
                        props.history.push({
                          pathname: "/service-partner/register",
                          type: "tr", //vender
                        });
                      }}
                    >
                      Service Partner (Learning platforms{""}/Educational Institutions)
                      {/* Vendor (Learning platforms/ Academies)  */}
                    </NzButton>
                  </VStack>
                  <VStack space="1">
                    <NzButton
                      variant="secondary"
                      onPress={(e) => {
                        props.history.push({
                          pathname: "/validator/register",
                          type: "va", //validator
                        });
                      }}
                    >
                      Validator (Educational Institutions)
                    </NzButton>
                  </VStack>
                </VStack>
              </VStack>
            </form>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
function Register(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const bgColor = useBreakpointValue({
    base: "primary.1000",
    sm: "primary.1000", //after 480px
    md: "transparent", //after 768px
    lg: "transparent", //after 992px
    xl: "transparent", //after 1280px
  });

  console.log(width);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.1000",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center my="auto" bg={bgColor} h="100vh">
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "768px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <VStack space="5">
                <NzlogoHeader variant="inverted" />
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Please select the type you belong to:
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.1000"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <NzlogoHeader variant="inverted" />
            </Center>
          </Hidden>
          <RegisterForm {...props} />
        </Stack>
      </Center>
    </>
  );
}
export default Register;
