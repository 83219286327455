const defaultState = {
  Items: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "MASTER_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Items: action.payload.Items,
    });
  default:
    return state;
  }
}
