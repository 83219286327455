const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  refreshPage:false,
};
  
export default function (state = defaultState, action) {
  switch (action.type) {
  case "GET_BUILD_SUCCESS":
    //return action.payload;
    return {
      ...state,
      Items: [...action.payload.Items],
      Count: state.Count + 1,
      refreshPage:false,
    };
  case "REMOVE_BUILD_SUCCESS":
    //return action.payload;
    return {
      ...state,
      refreshPage:true,
    };
  case "REMOVE_BUILD_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  case "BUILD_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  default:
    return state;
  }
}
  
