import React from "react";
import { Heading } from "native-base";

function NzLabel(props) {
  return (
    <Heading color="#5e6368" size={"sm"} {...props}>
      {props.children}
    </Heading>
  );
}

export default NzLabel;
