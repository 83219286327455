/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import '../admin.css'
import Swal from 'sweetalert2'
const CommitFunds = (props) => {
  const history = useHistory()
  const [funds, setFunds] = useState(props.program)
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }
  const [course, setCourse] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false); // State variable to track button submission

  useEffect(() => {
    if (props?.program?.course_id) {
      fetchCourse(props?.program?.course_id)
    }
    if (props.programType === '') {
      Swal.fire('Select a program type first.', '', 'error').then(() => props.setActiveTab(2))

      return
    }
  }, [])

  const fetchCourse = (id) => {
    if (!id) return
    axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        setCourse(response.data?.Content)
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const addProgram = () => {

     // Disable button on click
     if (isSubmitting) return;
     setIsSubmitting(true);

    let values_option

    
    if (props.programType === '0') {
      values_option = {
        ...funds,
        predefined_program_ind: 'N',
        program_status: '1',
        course,
        eligibleCandidates: JSON.parse(localStorage.getItem('eligibleCandidates')).slice(
          0,
          props?.program?.sponsored_candidate_count,
        ),
        program_duration: props?.programDuration,
        amount_to_start_program: props?.amountToBePaidToStartProgram,
        amount_per_student: props?.amountPerStudent,
        cgpa: props.selectedCGPA,
        wallet_setup: props.selectedMobileWallet,
        financial_wellness: props.selectedFW,
        twelveth_marks: props.selected12th,
        family_income: props.selectedFI,
        vendor_id: props.vendor_id,
        carry_forward_funds: props.isCarryForwardFunds,
        skill_id:props.skill_id
      }
    }

    if (props.programType === '1') {
      values_option = {
        ...funds,
        predefined_program_ind: 'N',
        program_status: '1',
        course,
        eligibleCandidates: JSON.parse(localStorage.getItem('eligibleCandidates')),
        program_duration: props?.programDuration,
        amount_to_start_program: props?.amountToBePaidToStartProgram,
        amount_per_student: props?.amountPerStudent,
        cgpa: props.selectedCGPA,
        wallet_setup: props.selectedMobileWallet,
        financial_wellness: props.selectedFW,
        twelveth_marks: props.selected12th,
        family_income: props.selectedFI,
        carry_forward_funds: props.isCarryForwardFunds,
      }

      values_option['total_amount'] = props?.amountToBePaidToStartProgram
    }
    if (values_option?.program_type === '0' && values_option?.eligibleCandidates.length === 0) {
      Swal.fire('No beneficiaries!.', 'No beneficiaries select!', 'error')
      return
    }

    if (props.program.program_id) {
      axios({
        method: 'POST',
        url: baseURL + 'setProgram',
        data: values_option,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then(async (response) => {
          setIsSubmitting(false);
          localStorage.removeItem('eligibleCandidates')
          alert('New program created successfully.')

          // Extract sponsor_id and program_id from the response
          const sponsor_id = response.data.Content.sponsor_id
          const program_id = response.data.Content.program_id
          const course_id = values_option?.course_id || ''

          let response2 = await axios({
            method: 'POST',
            url: baseURL + 'addProgramCommittedFunds',
            data: [
              {
                sponsor_id,
                program_id,
                course_id,
                amount: values_option?.planned_budget,
              },
            ],
            headers: { 'access-token': localStorage.getItem('csr_token') },
          })

          if (response2) {
            // Redirect to the finance_detail page with sponsor_id and program_id
            history.replace(
              `/sponsor/finance-detail?sponsor_id=${sponsor_id}&program_id=${program_id}`,
            )
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          // console.log(error)
        })
    } else {
      axios({
        method: 'POST',
        url: baseURL + 'addProgram',
        data: values_option,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then(async (response) => {
          setIsSubmitting(false);
          localStorage.removeItem('eligibleCandidates')
          Swal.fire('Success!.', 'New program created successfully.', 'success')
          // alert('')


          // Extract sponsor_id and program_id from the response
          const sponsor_id = response.data.Content.sponsor_id
          const program_id = response.data.Content.program_id
          const course_id = values_option?.course_id || ''

          let response2 = await axios({
            method: 'POST',
            url: baseURL + 'addProgramCommittedFunds',
            data: [
              {
                sponsor_id,
                program_id,
                course_id,
                amount: values_option?.planned_budget,
              },
            ],
            headers: { 'access-token': localStorage.getItem('csr_token') },
          })

          if (response2) {
            // Redirect to the finance_detail page with sponsor_id and program_id
            history.replace(
              `/sponsor/finance-detail?sponsor_id=${sponsor_id}&program_id=${program_id}`,
            )
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          // console.log(error)
        })
    }
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Name</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">{funds?.program_name}</p>
              </Col>
            </Row>
            <Row style={{ borderBottom: '1px solid #ccc' }}>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">Program Details</p>
              </Col>
              <Col sm={9}>
                <p className="valueText">{funds?.program_desc}</p>
              </Col>
            </Row>

            {props.programType === '1' && props.paymentOption === 'monthly' && (
              <Row style={{ borderBottom: '1px solid #ccc' }}>
                <Col sm={3} style={{ backgroundColor: '#002347' }}>
                  <p className="labelText">Program Duration</p>
                </Col>
                <Col sm={9}>
                  <p className="valueText">{props?.programDuration} Months</p>
                </Col>
              </Row>
            )}
            <CommitedFunds item={funds} {...props} />
          </Card.Body>
        </Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
        <Button onClick={addProgram} className="primaryAdminButton" disabled={isSubmitting}>
            {/* {isSubmitting ? 'Submitting...' : 'Submit'} */}
            {isSubmitting ? <img alt="spinner" src="/loaders/spinner_red.gif" style={{height:"20px"}} /> : "Submit" }
          </Button>
        </div>
      </div>
    </>
  )
}

export default CommitFunds

const CommitedFunds = (props) => {
  const [vendorName, setVendorName] = useState('')

  useEffect(() => {
    if (props.item?.course_id !== '') {
      fetchCourse(props.item?.course_id)
      if (props.item?.vendor_id !== '') {
        fetchVendor(props.item?.course_id)
      }
    }
  }, [])

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const [course, setCourse] = useState({})
  const fetchCourse = (id) => {
    axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        setCourse(response.data?.Content)
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const fetchVendor = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getVendorsByCourse/' + id,
      jwtHeader,
    })
      .then((response) => {
        let vendorList = response.data.Content
        // Filter Vendor
        let vendor = vendorList.filter(
          (item) => parseInt(item.vendor_id) === parseInt(props.item?.vendor_id),
        )
        if (vendor.length > 0) {
          setVendorName(vendor[0].vendor)
        }
      })
      .catch((error) => {})
  }

  return (
    <>
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          <p className="labelText">Program Type</p>
        </Col>
        <Col sm={9}>
          <p className="valueText">{props.programType === '0' ? 'Skilling' : 'Scholorship'}</p>
        </Col>
      </Row>

      {props.programType === '1' && (
        <Row>
          <Col sm={3} style={{ backgroundColor: '#002347' }}>
            <p className="labelText">Beneficiaries being sponsored</p>
          </Col>
          <Col sm={9}>
            <p className="valueText">{props.item?.eligible_candidates_count}</p>
          </Col>
        </Row>
      )}
      {props.programType === '0' && (
        <>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Beneficiaries being sponsored</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{props.item?.sponsored_candidate_count}</p>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Name</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{course?.course_name}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Fee</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">&#x20B9; {course?.fees}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Duration</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">
                {course?.duration} {course?.duration_unit}
              </p>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          <p className="labelText">Amount Committed</p>
        </Col>
        <Col sm={9}>
          <p className="valueText">&#x20B9; {props.amountToBePaidToStartProgram}</p>
        </Col>
      </Row>
    </>
  )
}
