const defaultState = {
  Count: 0,
  hasErrored: false,
  isLoading: false,
  Items: [],
  userUpdated: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "GET_COURSE_SUCCESS":
    //return action.payload;
    return Object.assign({}, state, {
      Items: action.payload.Items,
      lastKey: action.payload.LastEvaluatedKey,
      isLoading: false,
    });
  case "REMOVE_COURSE_SUCCESS":
    //return action.payload;
    return {
      ...state,
      Items: [...action.payload.Items],
      Count: state.Count + 1,
      refreshPage: false,
    };
  case "REMOVE_BUILD_ITEMS_HAS_ERRORED":
    return Object.assign({}, state, {
      hasErrored: action.hasErrored,
    });
  default:
    return state;
  }
}
