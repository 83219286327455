import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowForwardIcon,
  Box,
  Button,
  Center,
  CheckIcon,
  Flex,
  Heading,
  HStack,
  Select,
  Stack,
  Text,
  VStack,
} from "native-base";

import { getCourse } from "../../actions";
import {
  NzBannerCard,
  NzButton,
  NzInput,
  NzLabel,
  NzSelect,
} from "../../components/NzComponents";
import { upperCamelCase } from "../../js/util";
const { ToWords } = require("to-words");
const toWords = new ToWords();

const ProgramType = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const combo = useSelector((state) => state.combo);
  const courses = useSelector((state) => state.courses);
  const vendors = useSelector((state) => state.vendors);
  const [courseList, setCourseList] = useState([]);
  const [v22, setV22] = useState(0); //Benefeciary Count
  const [selectedVendor, setSelectedVendor] = useState();
  const [selectedFees, setSelectedFees] = useState();
  const [selectedDuration, setSelectedDuration] = useState();
  const [changed, setChanged] = useState(false);

  // This method executes only ones on pageload
  useEffect(() => {}, []);

  useEffect(() => {
    if (changed) {
      setCourseList(courses.Items);
      setChanged(false);
    }
  }, [courses]);

  useEffect(() => {
    setProgramType();
  }, [selectedFees]);

  useEffect(() => {
    dispatch(getCourse({ id: selectedVendor }));
    resetProgramType();
  }, [selectedVendor]);

  function setProgramType() {
    props.setProgramType({
      t: v3, //type
      v: selectedVendor, //vendor
      f: selectedFees, //fees
      d: selectedDuration, //duration
    });
  }

  function resetProgramType() {
    props.setProgramType({
      t: "", //type
      v: "", //vendor
      f: 0, //fees
      d: 0, //duration
    });
  }

  const [v3, setV3] = useState();
  return (
    <NzBannerCard key={props.key}>
      <HStack justifyContent="space-between" alignItems="center">
        <NzLabel>Program {props.counter + 1}</NzLabel>
        {props.counter > 0 && (
          <NzButton
            variant="ghost"
            onPress={(e) => {
              props.removeProgramType();
            }}
          >
            Remove
          </NzButton>
        )}
      </HStack>
      {/*<Text>Program Type:{v3} </Text>
      <Text>VENDOR:{selectedVendor} </Text>
      <Text>Course Fees:{selectedFees} </Text>*/}
      <NzBannerCard>
        <NzLabel>Program Typee</NzLabel>
        <Controller
          control={control}
          name="v3"
          render={({ field }) => (
            <NzSelect
              placeholder="Program Type"
              selectedValue={v3}
              onValueChange={(itemValue) => {
                setValue("v3", itemValue);
                setV3(itemValue);
                resetProgramType();
                // setValue(
                //   "v17",
                //   combo.welfareItems.filter((el) => el.ky === itemValue)[0].v1
                // );
              }}
            >
              {combo.welfareItems.map((el) => {
                return (
                  <Select.Item label={upperCamelCase(el.v1)} value={el.ky} />
                );
              })}
            </NzSelect>
          )}
          rules={{
            required: true,
          }}
        />
        {errors.v3 && errors.v3.type === "required" && (
          <Box px={2} ml={"3%"}>
            Please select Program Type
          </Box>
        )}
      </NzBannerCard>

      <NzBannerCard>
        <NzLabel>Select Vendor</NzLabel>
        <Controller
          control={control}
          name="lid"
          render={({ field }) => (
            <NzSelect
              placeholder="Vendor"
              onValueChange={(itemValue) => {
                setSelectedVendor(itemValue);
                setChanged(true);
              }}
            >
              {vendors.Items.map((el) => {
                return (
                  <Select.Item label={upperCamelCase(el.v8)} value={el.id} />
                );
              })}
            </NzSelect>
          )}
        />
      </NzBannerCard>

      <NzBannerCard>
        <NzLabel>Select Course</NzLabel>
        <Controller
          control={control}
          name="v1"
          render={({ field }) => (
            <NzSelect
              placeholder="Courses"
              onValueChange={(itemValue) => {
                let course = courses.Items.filter((el) => el.ky === itemValue);
                //console.log(JSON.stringify(course));
                setSelectedDuration(course[0].v6);
                setSelectedFees(course[0].n1);
              }}
            >
              {courseList.map((el) => {
                return (
                  <Select.Item label={upperCamelCase(el.v1)} value={el.ky} />
                );
              })}
            </NzSelect>
          )}
        />
      </NzBannerCard>
      <NzBannerCard>
        <NzLabel>Course Fees and Duration (Per Benefeciary) : </NzLabel>
        <NzLabel color="#6b21a8">
          Rs. {selectedFees} ({selectedDuration} hrs)
        </NzLabel>
      </NzBannerCard>
      <NzBannerCard>
        <NzLabel>Number of Beneficiaries to Select : </NzLabel>
        <Controller
          control={control}
          name="v22"
          render={({ field }) => (
            <NzInput
              {...field}
              maxLength="5"
              placeholder="Beneficiaries Count *"
              onChange={(event) => setV22(event.target.value)}
            />
          )}
          rules={{
            required: true,
          }}
        />
        {errors.v22 && errors.v22.type === "required" && (
          <Box px={2} ml={"3%"}>
            Beneficiaries Count is mandatory
          </Box>
        )}
      </NzBannerCard>
      <NzBannerCard>
        <NzLabel>Fund allocated to Program : </NzLabel>
        <NzLabel color="#6b21a8">
          Rs.{" "}
          {isNaN(selectedFees * v22)
            ? ""
            : selectedFees * v22 +
              "\n(" +
              toWords.convert(selectedFees * v22, { currency: true }) +
              ")"}
        </NzLabel>
      </NzBannerCard>
    </NzBannerCard>
  );
};

export default ProgramType;
