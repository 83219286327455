import React from "react";
import Register from "./register";

const RegisterComponent = (props) =>{

  return(
    <>
      <a 
        href="/"
        style={{
          position: "fixed",
          right: "20px",
          top: "20px",
          fontSize: "16px",
          color: "rgb(126, 34, 206)",
          zIndex:9999
        }}
      >Back to Home</a>
      <Register history={props.history} />
    </>
  )
}



export default RegisterComponent;