import React, { useState, useEffect } from "react"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import { baseURL } from "../../../config/baseURLPath"
import axios from "axios"
import { useLocation, useHistory } from "react-router-dom"
import "../admin.css"
import swal from "sweetalert2";
import Swal from "sweetalert2"
const UpdateProgram = (props) => {
  const history = useHistory();
  const location = useLocation();
  console.log(location,"Location")
  const [subjectList, setSubjectList] = useState([]);
  const [subSkillList, setSubSkillList] = useState([]);
  const [institution, setInstitution] = useState(0);
  const [courseId, setCourseId] = useState(0);
  const [activeId, setActiveId] = useState(0);
  const [confirmationModal, setConfirmationModal] = useState(false);
 
  const handleClose = () => {
    setConfirmationModal(false)
  }
  
  useEffect(() => {
    // getMilestone(location.state.course_id);
    try{
      const userData = JSON.parse(localStorage.getItem("user"))
      console.log(userData,'User Data')
       setInstitution(userData.VendorData.institution_id)
       setCourseId(location.state.course_id);  
       getCourse(location.state.course_id);
       getSubjects();
    }catch(e){
      console.log(e)
    }
    // getCourseCategory();
  }, [])
  
  const [values, setValues] = useState({
    course_name: "",
    institution_id: "",
    duration: "",
    duration_unit:"Days",
    course_desc: "",
    minimum_qualification: "",
    course_catg_id: "",
    fees: "",
    course_difficulty_level: "Beginner",
    course_collaboration:"",
    course_skills:"",
    course_rating: "",
    course_rated_by_count: "",
    course_url:"",
    course_monthly_cost:"",
    course_learning_product_type:"",
    course_enrolled_students:"",
    course_percentage_career_start:"",
    course_percentage_pay_promotion:"",
    course_instructors:"",
    course_subjects:"",
    subject_id:"",
    skill_id:""
  })

  // const blankMileStone = {
  //   course_id: "",
  //   payment_milestone_nbr: 1,
  //   amount_due: "",
  //   duration: 1,
  //   duration_unit:"Days"
  // }

  // const [courseCategory, setCourseCategory] = useState([])
  // const getCourseCategory = () => {
  //   axios({
  //     method: "get",
  //     url: baseURL + "getCourseCategory",
  //     headers: { "access-token": localStorage.getItem("csr_token") },
  //   })
  //     .then((response) => {
  //       setCourseCategory(response.data.Content)
  //     })
  //     .catch((error) => {console.log(error)})
  // }

  // const [stones, setStones] = useState([blankMileStone]);

  const handleChange = (e) => {
    const { name, value } = e.target
    if(name === "subject_id"){
      setValues((prev) => ({
        ...prev,
        [name]:value
      }))
      getSubjectsSkills(value)
    }

    if(name === "course_id"){
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ["course_plan_id"]:""
      }))
      
    }else{
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }


   const getSubjects=()=>{
    axios({
      method: "get",
      url: baseURL + "getSubjects",
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch(() => {})
  }


  const getSubjectsSkills=(subject_id)=>{
    axios({
      method: "get",
      url: baseURL + "getSubjectSkills/"+subject_id,
    })
      .then((response) => {
        setSubSkillList(response.data.data)
      })
      .catch(() => {})
  }

  // const validateMilestone = () =>{
  //   let stonesLocal = [...stones];
  //   let status = true;
  //   for(let i=0; i < stonesLocal.length; i++){
  //     if(stonesLocal[i].amount_due === "" || stonesLocal[i].duration === "" || stonesLocal[i].duration < 0){
  //       status = false;
  //       break;
  //     }
  //   }
  //   return status;
  // }

  const successAction = () => {
    if (
      values.course_name.trim() === "" ||
      values.duration === "" ||
      values.minimum_qualification === "" ||
      values.fees === "",
      values.subject_id === "",
      values.skill_id === ""
    ) {
      alert("Please fill required fields.")  
    }
    // else if(!validateMilestone()){
    //   alert("Please fill all milestone details.")  
    // }
    else {  
      values.institution_id = institution;
      axios({
        method: "POST",
        url: baseURL + "setCourse",
        data: values,
        headers: { "access-token": localStorage.getItem("csr_token") },
      })
        .then(() => {
          // addCourseMilestone()
          Swal.fire('Course Update!','Course updated Successfully!','success');
          history.push('/service-partner/dashboard')
        })
        .catch((error) => {
          console.log(error)
          // alert('Something went wrong. Please try again.')
        })
    }
  }

  // const addCourseMilestone = () =>{
  //   let milestones = [];
  //   let stonesLocal = [...stones];

  //   for(let i=0; i < stonesLocal.length; i++){
  //     if(stonesLocal[i].payment_plan_id === undefined){
  //       let ele = {
  //         course_id: courseId,
  //         payment_milestone_nbr: (i+1),
  //         amount_due: stonesLocal[i].amount_due,
  //         duration: stonesLocal[i].duration,
  //         duration_unit: stonesLocal[i].duration_unit
  //       }
  //       milestones.push(ele)
  //     }else{
  //       let ele = {
  //         payment_plan_id:stonesLocal[i].payment_plan_id,     
  //         course_id: courseId,
  //         payment_milestone_nbr: (i+1),
  //         amount_due: stonesLocal[i].amount_due,
  //         duration: stonesLocal[i].duration,
  //         duration_unit: stonesLocal[i].duration_unit
  //       }
  //       milestones.push(ele)
  //     }
  //   }

  //   axios({
  //     method: "POST",
  //     url: baseURL + "setCoursePaymentPlan",
  //     data: milestones,
  //     headers: { "access-token": localStorage.getItem("csr_token") },
  //   })
  //     .then(() => {
  //       new swal({
  //         title: "Done!",
  //         text: "Course updated successfully",
  //         icon: "success",
  //         timer: 2000,
  //         button: false
  //       }).then(() => {
  //         history.push("/service-partner/course-list")
  //       })
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       // alert('Something went wrong. Please try again.')
  //     })
  // }

  // const getMilestone = (id) => {
  //   getCourse(id)  
  //   axios({
  //     method: "get",
  //     url: baseURL + "getCoursePaymentPlan/"+id,
  //     headers: { "access-token": localStorage.getItem("csr_token") },
  //   })
  //     .then((response) => {
  //       setStones(response.data.Content)
  //     })
  //     .catch(() => {})
  // }

  const getCourse = (id) => {
    axios({
      method: "get",
      url: baseURL + "getCourseById/"+id,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })
      .then((response) => {
        setValues(response.data.Content)
        if(response.data.Content.subject_id){
          getSubjectsSkills(response.data.Content.subject_id);
        }
      })
      .catch(() => {})
  }


  const deleteRecord = () => {
    setConfirmationModal(false);
    const jwtHeader = {
      headers: { "access-token": localStorage.getItem("csr_token") },
      
    }
    axios.post(
      baseURL + "deleteCoursePaymentPlan/"+activeId,
      {
        // admin_id: activeId,
        // institution_id: props.id
      },
      jwtHeader
    )
      .then(() => {
        new swal({
          title: "Done!",
          text: "Milestone removed successfully",
          icon: "success",
          timer: 2000,
          button: false
        }).then(() => {
        })
      })
      .catch(() => {
        // alert('Something went wrong. Please try again.')
      })
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{padding:"0px 11px"}}>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347", paddingTop:"15px"}}>
                <p className="labelText">Course Name <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9} style={{paddingTop:"15px"}}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_name"
                    placeholder=""
                    value={values.course_name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Description</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_desc"
                    placeholder=""
                    value={values.course_desc}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Category <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="course_catg_id" value={values.course_catg_id} onChange={handleChange}>
                    <option value="">Select Course Category</option>  
                    {courseCategory.map((item, index)=>{
                      return(
                        <option key={index} value={item.course_catg_id}>{item.course_catg_name}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row> */}

            
            <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Course Subjects <span style={{ color: "#ff0000" }}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="subject_id" value={values.subject_id} onChange={handleChange}>
                    <option value="">Select Course Subject</option>
                    {subjectList.map((subject, index) => (
                      <option key={index} value={subject.subject_id}>
                        {subject.subject_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: "#002347" }}>
                <p className="labelText">Subject Skills* <span style={{ color: "#ff0000" }}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="skill_id" value={values.skill_id} onChange={handleChange}>
                    <option value="">Select Subject Skill*</option>
                    {subSkillList.map((subSkill, index) => (
                      <option key={index} value={subSkill.skill_id}>
                        {subSkill.skill_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Difficulty Level</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="course_difficulty_level" value={values.course_difficulty_level} onChange={handleChange}>
                    <option value="Beginner">Beginner</option>  
                    <option value="Intermediate">Intermediate</option>
                    <option value="Mixed">Mixed</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Collaboration <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_collaboration"
                    placeholder=""
                    value={values.course_collaboration}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Skills <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_skills"
                    placeholder=""
                    value={values.course_skills}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Rating</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select name="course_rating" value={values.course_rating} onChange={handleChange}>
                    <option value="1">1</option>  
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Rated By</p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={0}
                    name="course_rated_by_count"
                    placeholder=""
                    value={values.course_rated_by_count}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Minimum qualification for the Course <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="minimum_qualification"
                    placeholder=""
                    value={values.minimum_qualification}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Duration <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    min={1}
                    name="duration"
                    placeholder=""
                    value={values.duration}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group className="mb-3">
                  <Form.Select name="duration_unit" value={values.duration_unit} onChange={handleChange}>
                    <option value="Days">Days</option>  
                    <option value="Months">Months</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Fees (INR) <span style={{color:"#ff0000"}}>*</span></p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="fees"
                    placeholder=""
                    value={values.fees}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course URL <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_url"
                    placeholder=""
                    value={values.course_url}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>


            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Monthly Cost) <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_monthly_cost"
                    placeholder=""
                    value={values.course_monthly_cost}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>


            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Learning Product Type <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_learning_product_type"
                    placeholder=""
                    value={values.course_learning_product_type}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Enrolled Students <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_enrolled_students"
                    placeholder=""
                    value={values.course_enrolled_students}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>


            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Percentage of new career starts <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_percentage_career_start"
                    placeholder=""
                    value={values.course_percentage_career_start}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>


            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Percentage of pay increase or promotion <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_percentage_pay_promotion"
                    placeholder=""
                    value={values.course_percentage_pay_promotion}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Instructor <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_instructors"
                    placeholder=""
                    value={values.course_instructors}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>


            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
                <p className="labelText">Course Subjects <span style={{color:"#ff0000"}}>*</span> </p>
              </Col>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    name="course_subjects"
                    placeholder=""
                    value={values.course_subjects}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* {stones.map((item, index)=>{
            return(
              <MileStones key={index} totalMilestone={stones.length} removeRecord={() => removeMileStone(index)} index={index} item={item} handleChange={(name, value) => handleMilestones(index, name, value)}/>
            )
          })} */}
            <Row>
              <Col sm={3} style={{backgroundColor:"#002347"}}>
              </Col>
              <Col sm={9}>
                {/* <a href="/" onClick={(e)=>{e.preventDefault(); addMileStone()}}>Add Milestone</a> */}
              </Col>
            </Row>  
          </Card.Body>
        </Card>
        <Row>
          <Col>
            <Button className="btn primaryAdminButton" role="button" onClick={() => history.goBack()} style={{marginTop:"15px"}}>
            Back
            </Button>
          </Col>
          <Col>
            <div style={{display:"flex", justifyContent:"flex-end", marginTop:"15px"}}>
              <Button onClick={successAction} className="primaryAdminButton" >Update Course</Button>
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={confirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Milestone</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to remove this Milestone?</Modal.Body>
        <Modal.Footer>
          <button
            style={{marginLeft:"5px", color:"#ffffff"}} 
            className="btn btn-sm primaryAdminButton" variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            style={{marginLeft:"5px", color:"#ffffff"}} 
            className="btn btn-sm primaryAdminButton" variant="primary" onClick={() => deleteRecord()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateProgram

