import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowForwardIcon,
  Box,
  Button,
  Center,
  CheckIcon,
  Divider,
  Heading,
  Flex,
  Stack,
  VStack,
  HStack,
  Text,
  Select,
} from "native-base";

import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  NzLabel,
  NzSelect,
} from "../../components/NzComponents";

import { v4 as uuidv4 } from "uuid";
import {
  getCity,
  getIncome,
  getGender,
  getState,
  gethomeState,
  addProgram,
  getValidator,
  getSports,
  getSkill,
  searchProgram,
  resetSearchProgram,
} from "../../actions";
import { getFieldDistinctFromItems, upperCamelCase } from "../../js/util";

function ProgramCriteria(props) {
  const form1 = useRef(null);
  const pageEndRef = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const validators = useSelector((state) => state.validators);
  const combo = useSelector((state) => state.combo);
  const students = useSelector((state) => state.students);
  const [city, setCity] = useState([]);
  const [program, setProgram] = useState({});
  const [searchIsDisabled, setSearchIsDisabled] = useState(false);
  const [validator, setValidator] = useState([]);
  const [validatorSelected, setValidatorSelected] = useState([]);
  const [v5, setV5] = useState([]); //validatorState
  const [v6, setV6] = useState([]); //validatorCity
  const [v7, setV7] = useState(); //Validator
  const [v8, setV8] = useState(); //Gender
  const [v9, setV9] = useState(); //Income
  const [v10, setV10] = useState(); //HomeState
  const [v11, setV11] = useState(); //CGPA
  const [v12, setV12] = useState(); //Sports
  const [v13, setV13] = useState(); //Skills

  useEffect(() => {
    if (!props.location.program) {
      props.history.push("/dashboard");
    } else {
      dispatch(resetSearchProgram());
      //dispatch(getCity({}));
      //dispatch(getGender({}));
      dispatch(getIncome({}));
      //dispatch(getState({}));
      dispatch(gethomeState({}));
      //dispatch(getSports({}));
      //dispatch(getSkill({}));
      dispatch(getValidator());
    }
  }, []);

  useEffect(() => {
    const result = getFieldDistinctFromItems(validators.Items, "v13");
    setCity(result);
    setValidator(validators.Items);
    setValidatorSelected(getFieldDistinctFromItems(validators.Items, "id"));
  }, [validators]);

  useEffect(() => {
    setValidatorSelected(getFieldDistinctFromItems(validator, "id"));
    resetSearch();
  }, [validator]);

  useEffect(() => {
    if (students.Count !== -1)
      pageEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [students]);

  const resetSearch = () => {
    setSearchIsDisabled(false);
    dispatch(resetSearchProgram());
  };

  const onSearch = (formData) => {
    let program = { ...props.location.program, ...formData };
    setProgram(program);
    //console.log(program);
    let searchData = { v3: validatorSelected };
    if (formData.v8 && formData.v8 !== "all") {
      searchData.v7 = formData.v8;
    }
    if (formData.v9 && formData.v9 !== "all") {
      searchData.v8 = formData.v9;
    }
    if (formData.v11 && formData.v11 !== "all") {
      searchData.v11 = formData.v11;
    }
    dispatch(searchProgram(searchData));
    // props.history.push("/dashboard");
  };

  const onSubmit = () => {
    let prog = { ...program, v14: students.Count };
    if (!prog.v6) {
      prog.v6 = "all";
    }
    prog.v7 = validatorSelected;
    if (!prog.v8) {
      prog.v8 = "all";
    }
    if (!prog.v9) {
      prog.v9 = "all";
    }
    if (!prog.v11) {
      prog.v11 = "all";
    }
    setProgram(prog);
    console.log(prog);
    dispatch(addProgram(prog));
    props.history.push({
      pathname: "/detail",
      program: prog,
    });
  };

  const filterValidator = (itemValue) => {
    if (itemValue === "all") {
      setValidator(validators.Items);
    } else {
      setValidator(validators.Items.filter((el) => el.v13 === itemValue));
    }
    //reset({ v7: "" });
    setValue("v7", "");
    setV7("all");
  };

  return (
    <Nz1024 bg="#efefe8">
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>{"Selection Criteria "}</NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      {/* <NzBannerCard> */}
      <form
        ref={form1}
        onSubmit={handleSubmit(onSearch)}
        style={{ width: "100%" /*, backgroundColor: "orange"*/ }}
      >
        <NzBannerCard>
          <NzLabel>Location Criteria</NzLabel>
          <NzFlexWrap>
            <Controller
              control={control}
              name="v6"
              render={({ field }) => (
                <NzSelect
                  placeholder="City"
                  selectedValue={v6}
                  onValueChange={(itemValue) => {
                    setValue("v6", itemValue);
                    setV6(itemValue);
                    filterValidator(itemValue);
                  }}
                >
                  <Select.Item label={"All"} value={"all"} />
                  {city.map((el) => {
                    return <Select.Item label={el} value={el} />;
                  })}
                </NzSelect>
              )}
            />

            <Controller
              control={control}
              name="v7"
              render={({ field }) => (
                <NzSelect
                  placeholder="Validator name"
                  selectedValue={v7}
                  onValueChange={(itemValue) => {
                    //console.log(itemValue);
                    setValue("v7", itemValue);
                    setV7(itemValue);
                    resetSearch();
                    if (itemValue === "all") {
                      setValidatorSelected(
                        getFieldDistinctFromItems(validator, "id")
                      );
                    } else {
                      setValidatorSelected([itemValue]);
                    }
                  }}
                >
                  <Select.Item label={"All"} value={"all"} />
                  {validator.map((el) => {
                    return (
                      <Select.Item
                        label={upperCamelCase(el.v8)}
                        value={el.id}
                      />
                    );
                  })}
                </NzSelect>
              )}
            />
          </NzFlexWrap>
        </NzBannerCard>

        <NzBannerCard>
          <NzLabel>Personal Criteria</NzLabel>
          <NzFlexWrap>
            <Controller
              control={control}
              name="v8"
              render={({ field }) => (
                <NzSelect
                  placeholder="Gender"
                  selectedValue={v8}
                  onValueChange={(itemValue) => {
                    setValue("v8", itemValue);
                    setV8(itemValue);
                    resetSearch();
                  }}
                >
                  <Select.Item label="Female" value="f" />
                  <Select.Item label="Male" value="m" />
                  <Select.Item label="Transgender" value="t" />
                  <Select.Item label={"All"} value={"all"} />
                </NzSelect>
              )}
            />
            <Controller
              control={control}
              name="v9"
              render={({ field }) => (
                <NzSelect
                  placeholder="Income group (Annual)"
                  selectedValue={v9}
                  onValueChange={(itemValue) => {
                    setValue("v9", itemValue);
                    setV9(itemValue);
                    resetSearch();
                  }}
                >
                  {combo.Incomegroup.map((el) => {
                    return (
                      <Select.Item
                        label={upperCamelCase(el.v1)}
                        value={el.ky}
                      />
                    );
                  })}
                  <Select.Item label={"Any Group"} value={"all"} />
                </NzSelect>
              )}
            />
            <Controller
              control={control}
              name="v10"
              render={({ field }) => (
                <NzSelect
                  placeholder="Home State"
                  selectedValue={v10}
                  onValueChange={(itemValue) => {
                    setValue("v10", itemValue);
                    setV10(itemValue);
                    resetSearch();
                  }}
                >
                  <Select.Item label={"All State"} value={"all"} />
                  {combo.homestate.map((el) => {
                    return (
                      <Select.Item
                        label={upperCamelCase(el.v1)}
                        value={el.ky}
                      />
                    );
                  })}
                </NzSelect>
              )}
            />
          </NzFlexWrap>
        </NzBannerCard>
        <NzBannerCard>
          <NzLabel>CGPA Rating</NzLabel>
          <NzFlexWrap>
            <Controller
              control={control}
              name="v11"
              render={({ field }) => (
                <NzSelect
                  placeholder="CGPA Rating"
                  selectedValue={v11}
                  onValueChange={(itemValue) => {
                    setValue("v11", itemValue);
                    setV11(itemValue);
                    resetSearch();
                  }}
                >
                  <Select.Item label=" > 5" value="5" />
                  <Select.Item label=" > 6" value="6" />
                  <Select.Item label=" > 7" value="7" />
                  <Select.Item label=" > 8" value="8" />
                  <Select.Item label=" > 9" value="9" />
                </NzSelect>
              )}
            />
            <Controller
              control={control}
              name="v12"
              render={({ field }) => (
                <NzSelect
                  placeholder="Sports"
                  selectedValue={v12}
                  onValueChange={(itemValue) => {
                    setValue("v12", itemValue);
                    setV12(itemValue);
                  }}
                >
                  {combo.sports.map((el) => {
                    return (
                      <Select.Item
                        label={upperCamelCase(el.v1)}
                        value={el.ky}
                      />
                    );
                  })}
                </NzSelect>
              )}
            />
            <Controller
              control={control}
              name="v13"
              render={({ field }) => (
                <NzSelect
                  placeholder="Skills"
                  selectedValue={v13}
                  onValueChange={(itemValue) => {
                    setValue("v13", itemValue);
                    setV13(itemValue);
                  }}
                >
                  {combo.skills.map((el) => {
                    return (
                      <Select.Item
                        label={upperCamelCase(el.v1)}
                        value={el.ky}
                      />
                    );
                  })}
                </NzSelect>
              )}
            />
          </NzFlexWrap>
        </NzBannerCard>

        <NzBannerCard>
          <NzButton
            endIcon={<ArrowForwardIcon size="4" />}
            isDisabled={searchIsDisabled}
            onPress={() => {
              setSearchIsDisabled(true);
              form1.current.dispatchEvent(
                new Event("submit", { bubbles: true, cancelable: true })
              );
            }}
          >
            Search
          </NzButton>
        </NzBannerCard>
      </form>
      {students.Count !== -1 ? (
        <NzBannerCard>
          <Center>
            <NzLabel color="#6b21a8">
              Eligible Candidates Count : {students.Count}
            </NzLabel>
          </Center>
          {students.Count !== 0 ? (
            <NzButton
              endIcon={<ArrowForwardIcon size="4" />}
              onPress={() => onSubmit()}
            >
              Next (Program Planning)
            </NzButton>
          ) : null}
        </NzBannerCard>
      ) : null}
      <div ref={pageEndRef} />
      {/* </NzBannerCard> */}
    </Nz1024>
  );
}
export default ProgramCriteria;
