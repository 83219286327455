import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addUser, reEnter, removeLead, resetUser } from "../../actions";

import {
  Box,
  Center,
  Hidden,
  Text,
  StatusBar,
  Stack,
  VStack,
  useBreakpointValue,
} from "native-base";

import NzlogoHeader from "../../components/NzComponents/NzlogoHeader";
import { NzButton, NzInput } from "../../components/NzComponents";

function RegisterUserForm(props) {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const dispatch = useDispatch();
  const form1 = useRef(null);

  const leads = useSelector((state) => state.leads);
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (
      !leads.Items[0] ||
      !leads.Items[0].c1 ||
      !leads.Items[0].v2 ||
      !leads.Items[0].v3
    ) {
      props.history.push("/register");
    } else {
      dispatch(resetUser());
    }
  }, []);

  useEffect(() => {
    if (users.Count === 1) {
      dispatch(reEnter(users.Items[0]));
      dispatch(removeLead({ id: leads.Items[0].id }));
    }
  }, [users]);

  useEffect(() => {
    if (auth.c1 === "sp") {
      props.history.push("/registersponsor");
    }
    if (auth.c1 === "ag") {
      props.history.push("/registeragency");
    }
    if (auth.c1 === "tr") {
      props.history.push("/registervendor");
    }
    if (auth.c1 === "va") {
      props.history.push("/registervalidator");
    }
  }, [auth]);

  const onSubmit = (data) => {
    data.c1 = leads.Items[0].c1;
    data.v2 = leads.Items[0].v2;
    data.v3 = leads.Items[0].v3;
    delete data["p20"];
    // data.id = "123";
    //console.log(data);
    dispatch(addUser(data));
    //props.history.push("/detail");
  };

  return (
    <VStack
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
        shadow={4}
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="lg" fontWeight="bold">
              Create Email and Password
            </Text>
          </Hidden>
          <VStack>
            <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
              <VStack space="3">
                <VStack
                  space={{
                    base: "7",
                    md: "4",
                  }}
                >
                  <VStack space="1">
                    <Controller
                      control={control}
                      defaultValue={leads.Items[0] ? leads.Items[0].v2 : ""}
                      // defaultValue="abc@gmail.com"
                      name="lid"
                      render={({ field }) => (
                        <NzInput {...field} placeholder="Email ID" />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    {errors.lid && errors.lid.type === "required" && (
                      <Box px={2} ml={"3%"}>
                        Email is mandatory
                      </Box>
                    )}
                    {errors.lid && errors.lid.type === "pattern" && (
                      <Box px={2} ml={"3%"}>
                        Email is not valid
                      </Box>
                    )}
                  </VStack>
                  <VStack space="1">
                    <Controller
                      control={control}
                      // defaultValue="123"
                      name="p1"
                      render={({ field }) => (
                        <NzInput
                          {...field}
                          placeholder="Password"
                          type={show ? "text" : "password"}
                        />
                      )}
                      rules={{
                        required: true,
                        // pattern:
                        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{6,64}$/,
                      }}
                    />
                    {errors.p1 && errors.p1.type === "required" && (
                      <Box px={2} ml={"3%"}>
                        Password is mandatory
                      </Box>
                    )}
                  </VStack>
                  <VStack space="1">
                    <Controller
                      // defaultValue="123"
                      control={control}
                      name="p20"
                      render={({ field }) => (
                        <NzInput
                          {...field}
                          placeholder="Confirm Password"
                          type={show ? "text" : "password"}
                        />
                      )}
                      rules={{
                        required: true,
                        validate: (value) =>
                          value === getValues().p1 || "Passwords do not match",
                        // pattern:
                        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{6,64}$/,
                      }}
                    />
                    {errors.p20 && errors.p20.type === "required" && (
                      <Box px={2} ml={"3%"}>
                        Password is mandatory
                      </Box>
                    )}
                    {errors.p20 && errors.p20.type === "validate" && (
                      <Box px={2} ml={"3%"}>
                        {errors.p20.message}
                      </Box>
                    )}
                  </VStack>
                </VStack>
                <NzButton
                  my={5}
                  onPress={() =>
                    form1.current.dispatchEvent(
                      new Event("submit", { bubbles: true, cancelable: true })
                    )
                  }
                >
                  Continue
                </NzButton>
              </VStack>
            </form>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

function RegisterUser(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const bgColor = useBreakpointValue({
    base: "primary.1000",
    sm: "primary.1000", //after 480px
    md: "transparent", //after 768px
    lg: "transparent", //after 992px
    xl: "transparent", //after 1280px
  });

  console.log(width);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.1000",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center my="auto" bg={bgColor} h="100vh">
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "768px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <VStack space="5">
                <NzlogoHeader variant="inverted" />
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Create Email and Password
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.1000"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <NzlogoHeader variant="inverted" />
            </Center>
          </Hidden>
          <RegisterUserForm {...props} />
        </Stack>
      </Center>
    </>
  );
}

export default RegisterUser;
