import React, { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Center,
  Button,
  Heading,
  Hidden,
  StatusBar,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "native-base";
import { v4 as uuidv4 } from "uuid";

import { addLeads, resetLead } from "../../actions";

import { NzButton, NzPhone, NzInput } from "../../components/NzComponents";
import NzlogoHeader from "../../components/NzComponents/NzlogoHeader";

function RegisterContactForm(props) {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
  } = useForm();

  const form1 = useRef(null);
  const leads = useSelector((state) => state.leads);

  useEffect(() => {
    if (!props.location.type) {
      props.history.push("/register");
    } else {
      dispatch(resetLead());
    }
  }, []);

  useEffect(() => {
    if (leads.Count > 0) {
      props.history.push("/registeruser");
    }
  }, [leads]);

  const onSubmit = (data) => {
    data.id = uuidv4();
    data.c1 = props.location.type;
    // console.log(data);
    dispatch(addLeads(data));
    //props.history.push("/password");
  };

  // console.log();

  return (
    <VStack
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex="1"
        px="6"
        py="9"
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space="3"
        justifyContent="space-between"
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
        shadow={4}
      >
        <VStack space="7">
          <Hidden till="md">
            <Text fontSize="lg" fontWeight="bold">
              Please provide your Email and Phone Number
            </Text>
          </Hidden>
          <VStack>
            <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
              <VStack space="3">
                <VStack
                  space={{
                    base: "7",
                    md: "4",
                  }}
                >
                  <VStack space="1">
                    <Controller
                      // defaultValue="abc@gmail.com"
                      control={control}
                      name="v2"
                      render={({ field }) => (
                        <NzInput {...field} placeholder="Your Email ID *" />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    {errors.v2 && errors.v2.type === "required" && (
                      <Box px={2}>Email ID is mandatory</Box>
                    )}
                    {errors.v2 && errors.v2.type === "pattern" && (
                      <Box px={2}>Email ID is not valid</Box>
                    )}
                  </VStack>
                  <VStack space="1">
                    <Controller
                      // defaultValue="1234567892"
                      control={control}
                      name="v3"
                      render={({ field }) => (
                        <NzPhone {...field} placeholder="Your Phone Number *" />
                      )}
                      rules={{
                        required: true,
                        pattern:
                          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      }}
                    />
                    {errors.v3 && errors.v3.type === "required" && (
                      <Box px={2}>Phone Number is mandatory</Box>
                    )}
                    {errors.v3 && errors.v3.type === "pattern" && (
                      <Box px={2}>Phone Number is not valid</Box>
                    )}
                  </VStack>
                </VStack>
                <NzButton
                  my={5}
                  onPress={() =>
                    //   props.history.push("/password");
                    // }
                    form1.current.dispatchEvent(
                      new Event("submit", { bubbles: true, cancelable: true })
                    )
                  }
                >
                  Continue
                </NzButton>
              </VStack>
            </form>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

function RegisterContact(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const bgColor = useBreakpointValue({
    base: "primary.1000",
    sm: "primary.1000", //after 480px
    md: "transparent", //after 768px
    lg: "transparent", //after 992px
    xl: "transparent", //after 1280px
  });

  console.log(width);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.1000",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center my="auto" bg={bgColor} h="100vh">
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w="100%"
          maxW={{
            md: "768px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <VStack space="5">
                <NzlogoHeader variant="inverted" />
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Please provide your Email and Phone Number
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.1000"
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <NzlogoHeader variant="inverted" />
            </Center>
          </Hidden>
          <RegisterContactForm {...props} />
        </Stack>
      </Center>
    </>
  );
}

export default RegisterContact;
