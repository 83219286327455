import React from "react";
import { Box, Center, Heading, Flex, Image, Text, VStack } from "native-base";
import Icon1 from "./skilling_1.svg";
import Icon2 from "./skilling_2.svg";
import Icon3 from "./skilling_3.svg";

function Skilling() {
  return (
    <section id="skilling">
      <Center bg="primary.1000" py={{ base: "8", lg: "12" }}>
        <VStack
          w={{
            base: "90%",
            md: "80%",
          }}
          alignItems="center"
          space="6"
          my="4"
        >
          <Heading color="white" textAlign={{ base: "left", md: "center" }} fontSize={{ base: "28px" }} >
            Skilling for Improving Employability
          </Heading>
          <Text
            fontSize="md"
            textAlign={{ base: "left", md: "center" }}
            color="white"
            
            >
            OptShine programs are primarily aimed at helping the youth to
            develop key skills like communication, language and IT proficiency,
            digital and financial literacy that they essentially need to possess
            in order to be successful in the modern digital economy and
            post-pandemic world
          </Text>
          <Text
            fontSize="lg"
            textAlign={{ base: "left", md: "center" }}
            color="primary.400"
            italic
            bold
          >
            Join hands with OptShine to address these challenges
          </Text>
        </VStack>

        <Flex
          flexDirection={{
            base: "column",
            lg: "row",
          }}
          alignItems="center"
          justify="space-between"
          w={{
            base: "90%",
            lg: "80%",
          }}
          gap={{ base: "4", lg: "0" }}
          mt="2"
          mb="5"
        >
          <Box
            bg="white:alpha.5"
            borderRadius="4px"
            w={{ base: "100%", lg: "30%" }}
            p="4"
          >
            <Flex flexDir="row" justify="space-between" w="100%">
              <Heading fontSize="48px" color="white">
                50%
              </Heading>
              <Image source={Icon1} size="xs" />
            </Flex>
            <Text fontSize="md" color="white">
              Graduates in India are not employable
            </Text>
            <Text
              fontSize="sm"
              textAlign="right"
              italic
              color="primary.400"
              mt="4"
            >
              India Skills Report 2023
            </Text>
          </Box>
          <Box
            bg="white:alpha.5"
            w={{ base: "100%", lg: "30%" }}
            borderRadius="4px"
            p="4"
          >
            <Flex flexDir="row" justify="space-between" w="100%">
              <Heading fontSize="48px" color="white">
                69%
              </Heading>
              <Image source={Icon2} size="xs" />
            </Flex>
            <Text fontSize="md" color="white">
              Indian jobs are under threat from automation. Investing in STEM
              education, technology workforce training will become of utmost
              importance.
            </Text>
            <Text
              fontSize="sm"
              textAlign="right"
              italic
              color="primary.400"
              mt="4"
            >
              Future Of Jobs Forecast, 2020 To 2040 by&nbsp;Forrester
            </Text>
          </Box>
          <Box
            bg="white:alpha.5"
            w={{ base: "100%", lg: "30%" }}
            borderRadius="4px"
            p="4"
          >
            <Flex flexDir="row" justify="space-between" w="100%">
              <Heading fontSize="48px" color="white">
                83%
              </Heading>
              <Image source={Icon3} size="xs" />
            </Flex>
            <Text fontSize="md" color="white">
              Teenagers are financially illiterate
            </Text>
            <Text
              fontSize="sm"
              textAlign="right"
              italic
              color="primary.400"
              mt="4"
            >
              Teenage Financial Literacy Report 2021 by&nbsp;Streak
            </Text>
          </Box>
        </Flex>
      </Center>
    </section>
  );
}
export default Skilling;
