import { Switch, Route } from "react-router-dom"
import React, { Suspense } from "react"
import Header from "./common/Header"
import Dashboard from "./Dashboard"
import Program from "./pages/manage-program/index"
import ProgramList from "./pages/manage-program/List"
import ProgramDetails from "./pages/manage-program/ProgramDetails"
import Users from "./pages/manage-users/index"
import Signup from "./SignUp"
import "./sponsorcss.css"
import ListNew from "./pages/manage-beneficiary/list-new"
import FinanceDetails from "./pages/manage-program/FinanceDetails"
import BeneficiaryDocuments from "./pages/manage-beneficiary/BeneficiaryDocuments"

function Admin() {
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/sponsor/register"
          render={(props) => (
            <Suspense fallback={<p> Loading...</p>}>
              <Signup {...props} />
            </Suspense>
          )}
        />
        <SponsorLayoutRoute exact path="/sponsor/beneficiary-list" component={ListNew} />
        <SponsorLayoutRoute exact path="/sponsor/dashboard" component={Dashboard} />
        <SponsorLayoutRoute exact path="/sponsor/program-add" component={Program} />
        <SponsorLayoutRoute exact path="/sponsor/program-list" component={ProgramList} />
        <SponsorLayoutRoute exact path="/sponsor/program/:id" component={ProgramDetails} />
        <SponsorLayoutRoute exact path="/sponsor/users" component={Users} />
        <SponsorLayoutRoute exact path="/sponsor/finance-detail" component={FinanceDetails} />
        <SponsorLayoutRoute exact path="/sponsor/beneficiary-documents/:beneficiary_id" component={BeneficiaryDocuments}/>
      </Switch>
    </div>
  )
}

export default Admin

const SponsorLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          style={{
            backgroundColor: "#ffffff",
            backgroundSize: "cover",
            minHeight: "100vh",
          }}
        >
          <Header />
          <div className="contentArea">
            <Suspense fallback={<p> Loading...</p>}>
              <div className="fixContent">
                <Component {...props} />
              </div>
            </Suspense>
          </div>
        </div>
      )}
    />
  )
}
