import React from "react";
import "./style.css";
import Navbar from "../../components/HomeComponents/NavBar/navbar";
import Banner from "../../components/HomeComponents/Banner/banner";
import Csr from "../../components/HomeComponents/Csr/csr";
import Skilling from "../../components/HomeComponents/Skilling/skilling";
import Features from "../../components/HomeComponents/Features/features";
import Dashboard from "../../components/HomeComponents/Dashboard/dashboard";
import Contact from "../../components/HomeComponents/Contact/contact";
import Footer from "../../components/HomeComponents/Footer/footer";
import AboutUs from "../../components/HomeComponents/About/aboutus";
import OurApproach from "../../components/HomeComponents/OurApproach/OurApproach";
import Faq from "../../components/HomeComponents/Faq/Faq";
import { useHistory } from "react-router-dom";

import Login from '../Login';
function Home(props) {
  const history = useHistory()
  return (
    <>
      {/* <Navbar {...props} /> */}
      <main>

        <Login history={history} />
        {/* <Banner />
        <AboutUs />
        <Csr />
        <Skilling />
        <OurApproach/>
        <Features />
        <Faq/>
        <Contact />
        <Footer /> */}
      </main>
    </>
  );
}

export default Home;
