import React, {useState} from "react";

import UserType from "./UserType";
import Login from "./login";

const LoginComponent = (props) =>{

  const [activeComponent, setActiveComponent] = useState("usertype");
  const [activeUserType, setActiveUserType] = useState("");
  return(
    <>
      {/* <a 
        href="/"
        style={{
          position: "fixed",
          right: "20px",
          top: "20px",
          fontSize: "16px",
          color: "rgb(126, 34, 206)",
          zIndex:9999
        }}
      >Back to Home</a> */}
      {activeComponent === "usertype" ? <UserType onChange={(val)=> {setActiveUserType(val); setActiveComponent("login")}} /> : <Login history={props.history} userType={activeUserType}/>}
    </>
  )
}



export default LoginComponent;



