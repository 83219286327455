//let url = 'http://nodzon.com';
//let url = 'http://dofo.io';
//let url = 'http://localhost:7171';

// let url = process.env.REACT_APP_URL_DEV;
// if(process.env.NODE_ENV === 'production')
// let url = process.env.REACT_APP_OPT_SOCIETY_URL;
//let url = "https://api.optsociety.com/api";
// let url = "https://api.optshine.com/api";
// let url ="http://localhost:8098/api";
import { baseURL as url} from "../../config/baseURLPath";
let nz_url = process.env.REACT_APP_NZ_URL;
let nodzon_url = process.env.REACT_APP_NODZON_URL;
// console.log(nodzon_url);
// let saiurl = process.env.REACT_APP_SAI_URL_DEV;
// if(process.env.NODE_ENV === 'production')
let saiurl = process.env.REACT_APP_SAI_URL;

class Api {
  static addLeads(om) {
    return fetch(url + "addContactUs", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static addUser(om) {
    return fetch(url + "/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static updateUser(om) {
    return fetch(url + "/user", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static addProgram(om) {
    return fetch(url + "/prog", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static login(om) {
    let sub_url = "";
    // let reqBody = {
    //   admin_email: "",
    //   password:""
    // }
    if(om.userType === "sp"){
      sub_url = "auth/sponsorSignin";
    }
    if(om.userType === "tr"){
      sub_url = "auth/vendorSignin";
    }
    if(om.userType === "va"){
      sub_url = "auth/validatorSignin";
    }

    let reqBody = {
      admin_email: om.lid,
      password:om.p1
    }

    return fetch(url + sub_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  static resetPassword(email) {
    let sub_url = "auth/forgotPassword";
    
    let reqBody = {
      admin_email: email
    }

    return fetch(url + sub_url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  static removeLead(om) {
    return fetch(url + "/lead", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static getLeads(key, id, limit, lastSortKey) {
    let fetchUrl;
    // if (lastSortKey) {
    //   fetchUrl =
    //     saiurl + "/gpr?id=" + key + "&li=" + limit + "&so=" + lastSortKey;
    // } else {
    fetchUrl = url + "lead";
    // }

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static getUsers(om) {
    let fetchUrl;
    fetchUrl = url + "user?type=" + om;

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static getProgram(om) {
    let fetchUrl;
    fetchUrl = url + "prog?id=" + om.id;

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static searchProgram(om) {
    return fetch(url + "srh", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        throw err;
      });
  }

  static removeProgram(om) {
    return fetch(url + "prog", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static getCombo(payload) {
    let fetchUrl;
    fetchUrl = nz_url + "getCombo?type=" + payload.type;

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return new Date(a.n1) - new Date(b.n1);
        });
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static getMaster(om) {
    let fetchUrl;
    fetchUrl = nz_url + "master?ky=" + om.ky;

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static getStudent(om) {
    let fetchUrl;
    fetchUrl = url + "student?id=" + om.id + "&li=" + om.li;
    if (om.la) {
      fetchUrl = fetchUrl + "&la=" + JSON.stringify(om.la);
    }
    if (om.desc) {
      fetchUrl = fetchUrl + "&desc=true";
    }
    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static removeStudent(om) {
    return fetch(url + "student", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  static getCourse(om) {
    let fetchUrl;
    fetchUrl = url + "course?id=" + om.id + "&li=" + om.li;
    if (om.la) {
      fetchUrl = fetchUrl + "&la=" + JSON.stringify(om.la);
    }
    if (om.desc) {
      fetchUrl = fetchUrl + "&desc=true";
    }
    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static removeCourse(om) {
    return fetch(url + "course", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static getBuild(om) {
    let fetchUrl;
    fetchUrl = url + "getBuild?id=" + om.id + "&v2=" + om.v2;

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.Items.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }

  static removeBuild(om) {
    return fetch(url + "build", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(om),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  static runNotification(om) {
    let fetchUrl;
    fetchUrl = url + "runNotification";

    return fetch(encodeURI(fetchUrl))
      .then((response) => response.json())
      .then((responseJson) => {
        return responseJson;
      })
      .catch((err) => {
        //return err;
        console.log(err);
        throw err;
      });
  }
}

export default Api;
