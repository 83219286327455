// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.card {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

`, "",{"version":3,"sources":["webpack://./src/sponsor/pages/manage-beneficiary/list.css"],"names":[],"mappings":";;;AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":["\r\n\r\n\r\n.card {\r\n  background: #ffffff;\r\n  padding: 2rem;\r\n  border-radius: 10px;\r\n  margin-bottom: 1rem;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
