import React, { useEffect, useState } from 'react'
// import {useNavigate, useLocation} from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap'
import { baseURL,walletBaseURL } from '../config/baseURLPath'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import './sponsorcss.css'
import GenderGraph from './charts/Gender'
import CGPA from './charts/CGPA'
import TopPrograms from './charts/TopPrograms'
import LevelOfAchievement from './charts/LevelOfAchievement'
import IndiaMap from './charts/IndiaMap'

const Dashboard = () => {
  const history = useHistory()
  const [dashboardData, setDashboardData] = useState('')

  const addNewProgram = () => {
    history.push('/sponsor/program-add')
  }

  const allocateBeneficiary = () => {
    history.push('/sponsor/beneficiary-list')
  }


  useEffect(() => {
    try {

      if(localStorage.getItem('userType') !== "sponsor"){
      history.push(`/${localStorage.getItem('userType')}/dashboard`)
    }
   
      const userData = JSON.parse(localStorage.getItem('user'))
      getDashboard(userData.Sponsor.sponsor_id)
      getChartData(userData.Sponsor.sponsor_id)

    } catch (e) {
      console.log(e)
    }
  }, [])


  const getChartData =(id)=>{
      const url = baseURL + 'sponsor-chart-data/' + id

      axios({
        method:'get',
        url,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
      .then((response)=>{
        console.log(response,'Chart Response')
      })
  }

  const getDashboard = (id) => {
    const url = baseURL + 'getSponsorDashboard/' + id // Construct the URL

    console.log('URL:', url) // Log the URL to the console
    axios({
      method: 'get',
      url: baseURL + 'getSponsorDashboard/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response, 'Response')
        setDashboardData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="contentBox">
        <Row className="numbersRow">
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="red colored-box">
              <div className="number-box">
                <p className="numbersText">{(dashboardData && dashboardData?.ProgramCount) || 0}</p>
                <p className="sublabelText">Nos. of Programs</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="yellow colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData && dashboardData?.Beneficiaries) || 0}
                </p>
                <p className="sublabelText">Nos. of Beneficiaries</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="blue colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData && dashboardData?.CommittedFunds) || 0}
                </p>
                <p className="sublabelText">Funds Committed</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={3} className="numberBoxDiv">
            <div className="green colored-box">
              <div className="number-box">
                <p className="numbersText">
                  {(dashboardData?.length > 0 &&
                    dashboardData?.DisbursedFunds[0]?.disbursedFunds) ||
                    0}
                </p>
                <p className="sublabelText">Funds Disbursed</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
        <Col>
             <TopPrograms />       
          </Col>
          <Col>
             <GenderGraph />       
          </Col>
          <Col>
             <CGPA />       
          </Col>
        </Row>
        <Row>
          <Col>
            <IndiaMap  />        
          </Col>
          <Col md={8}>
             <LevelOfAchievement />       
          </Col>
        </Row>            
        <Row>
          <Col md={3} xs={12} style={{ marginTop: '15px' }}>
            <p className="dashboard-section-title">CURRENT RUNNING PROGRAMS</p>
          </Col>
          <Col
            className="xs-only"
            md={3}
            xs={12}
            style={{
              marginBottom: '15px',
            }}
          >
            <Button
              onClick={addNewProgram}
              style={{ width: '100%' }}
              className="primaryAdminButton"
            >
              Add New Program
            </Button>
          </Col>
          <Col
            className="hide-xs"
            md={3}
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginBottom: '15px',
            }}
          >
            
          </Col>
          {/* <Col
            className="xs-only"
            style={{
              display: 'flex',
              marginBottom: '15px',
            }}
          > */}
            {/* <Button
              style={{ marginRight: '15px' }}
              onClick={() => history.push('/sponsor/program-list')}
              className="primaryAdminButton"
            >
              View Programs
            </Button> */}
            {/* <Button onClick={allocateBeneficiary} className="primaryAdminButton">
              View Beneficiaries
            </Button> */}
          {/* </Col> */}
          <Col
            className="hide-xs"
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              marginBottom: '15px',
            }}
          >
            {/* <Button
              style={{ marginRight: '15px' }}
              onClick={() => history.push('/sponsor/program-list')}
              className="primaryAdminButton"
            >
              View Programs
            </Button> */}

              <Button onClick={addNewProgram} className="primaryAdminButton" style={{marginRight:"10px"}}>
              Add New Program
            </Button>
            <Button onClick={allocateBeneficiary} className="primaryAdminButton">
              View Beneficiaries
            </Button>
          </Col>
        </Row>
        <Row>
          {dashboardData && dashboardData?.RunningProgram.length > 0 ? (
            dashboardData?.RunningProgram.map((item, index) => (
              <Col sm={3} xs={12} key={index}>
                <MyPrograms item={item} />
              </Col>
            ))
          ) : (
            <Col>
              <div className="programBox">
                <p className="programName">
                  Currently no program is running. Please select a curated program from the list
                  below or add a new program by clicking Add New Program.
                </p>
              </div>
            </Col>
          )}

          {dashboardData && dashboardData?.RunningProgram.length > 4 && (
            <Col sm={12}>
              <button
                onClick={() => history.push('/sponsor/program-list')}
                className="btn btn-primary"
                style={{ backgroundColor: '#4b0082', borderColor: '#4b0082' }}
              >
                View All Programs
              </button>
            </Col>
          )}
        </Row>

        <Row>
          <Col style={{ marginTop: '15px' }}>
            <p className="dashboard-section-title">CURATED PROGRAMS</p>
          </Col>
        </Row>
        <Row className="numbersRowNoShadow">
          {dashboardData && dashboardData?.PredefinedPrograms?.length > 0 ? (
            dashboardData?.PredefinedPrograms.map((item, index) => {
              return (
                <Col sm={3} key={index}>
                  <PredefinedPrograms item={item} />
                </Col>
              )
            })
          ) : (
            <Col>
              <div className="programBox">
                <p className="programName">No Data available</p>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  )
}

export default Dashboard

const MyPrograms = (props) => {
  const history = useHistory()
  return (
    <div className="my-program-card">
      <Card style={{ fontWeight: 'bolder', width: '100%', height: '100%' }}>
        <Card.Body>
          <Card.Title>{props.item.program_name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted h6">
            {/* {props.item.program_desc.substring(0, 300)} */}

             {props.item.program_desc.length > 30
          ? `${props.item.program_desc.substring(0, 30)}...`
          : props.item.program_desc}
          </Card.Subtitle>
          <Card.Text className="mb-2 text-muted">
            <p style={{ color: '#7f00ff' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#2a0036"
                className="bi bi-clock bi-type-bold"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
                  stroke="#2a0036"
                  strokeWidth="1"
                />
                <path
                  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"
                  stroke="#2a0036"
                  strokeWidth="1"
                />
              </svg>
              &nbsp; 3 months left
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <span className="h3" style={{ color: '#4b0082', fontWeight: 'bold' }}>
                {/* {((props.item.total_amount / props.item.planned_budget) * 100).toFixed(2)}% */}
                {props.item.total_disbursed_amount}
              </span>
              <span className="mb-2 text-muted h6">funds deployed</span>
            </div>
            <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
              <Button
                target="_blank"
                className="btn btn-sm"
                role="button"
                style={{
                  backgroundColor: '#ccc',
                  color: '#000000',
                  marginLeft: '5px',
                }}
                onClick={(event) => {
                  event.preventDefault()
                  history.push('/sponsor/program/' + props.item.program_id)
                }}
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </Button>

              {props.item.fund_disbursed_yn === 'N' && (
                <Button
                  className="btn btn-sm"
                  role="button"
                  style={{
                    fontWeight: 'bold',
                    color: 'white',
                    background: '#4b0082',
                    marginLeft: '5px', // Adjust the spacing as needed
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    history.push(
                      `/sponsor/finance-detail?sponsor_id=${props.item.sponsor_id}&program_id=${props.item.program_id}`,
                    )
                  }}
                >
                  Confirm Payment
                </Button>
              )}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

const PredefinedPrograms = (props) => {
  const history = useHistory()
  return (
    <div className="my-program-card">
      <Card style={{ fontWeight: 'bolder', width: '100%', height: '100%' }}>
        <Card.Body className="in-second-card">
          <Card.Title>{props.item.program_name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted h6">
            {props.item.program_desc.substring(0, 100)}
          </Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted h6">
            {props.item.target_beneficiaries === '1' ? 'Students' : 'Ex-Servicemen'}
          </Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted h6">
            {props.item.program_type === '1' ? 'skilling' : 'Scholorship'}
          </Card.Subtitle>
          <Card.Text className="mb-2 text-muted">
            <Button
              className="btn btn-sm"
              role="button"
              style={{
                marginTop: '-20px',
                fontWeight: 'bold',
                color: 'white',
                float: 'right',
                clear: 'both',
                background: '#4b0082',
              }}
              onClick={() =>
                history.push('/sponsor/program-add?import_program=' + props.item.program_id)
              }
            >
              + Add
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

