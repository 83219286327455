import React from "react";
import { Center } from "native-base";

const Nz1024 = (props) => {
  return (
    <Center shadow={3} py={2} {...props}>
      <Center
        w={{
          base: "90%",
        }}
        maxW={{
          base: "1024px",
        }}
      >
        {props.children}
      </Center>
    </Center>
  );
};

export default Nz1024;
