import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import '../admin.css'
import Swal from 'sweetalert2'

const ProgramSetup = (props) => {
  
  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }
  const [courseFee, setCourseFee] = useState(0)
  const [associatedCost, setAssociatedCost] = useState(props.values.additional_expense)
  const [budgetEmarked, setBugetEmarkted] = useState(props.values.total_amount)
  const [beingSponsored, setBeingSponsored] = useState(0)
  // const [amountPerStudent, setAmountPerStudent] = useState("")
  const [courseList, setCourseList] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [skillList, setSkillList] = useState([])
  const [values, setValues] = useState(props.values)
  const [courseSelected, setCourseSelected] = useState("");
  // const [duration, setDuration] = useState(0)
  // const [month, setMonth] = useState(0)
  // const [courseCategory, setCourseCategory] = useState([])
   useEffect(() => {
    getSubjects()
  }, [])


   useEffect(() => {
    setValues(props.values)
    if (props.subject_id !== '') {
      // fetchCourses(props.subject_id)
      getSkills(props.subject_id)
    }
     if (props.skill_id !== '') {
      fetchCourses(props.skill_id)
      // getSkills(props.subject_id)
    }
     if (props.course_id !== '') {
      fetchCourse(props.course_id)
      applyFilter(props.course_id)
    }
    
  }, [props.subject_id])

  
  useEffect(() => {
    // Calculate Budget emarked
    let budget_emarked = (parseInt(courseFee) + parseInt(associatedCost)) * parseInt(beingSponsored)
    setBugetEmarkted(budget_emarked)

    if (props?.programType==="1" && props?.plannedBudget && props?.program?.eligible_candidates_count) {
      props.setAmountPerStudent(
        Number(props?.plannedBudget / props?.program?.eligible_candidates_count),
      )
      
    }
    if (props?.plannedBudget && props?.programType==='0' && courseSelected &&  courseFee) {  
      let res = Math.floor(props.plannedBudget / courseFee)
      // if (res > props?.program?.eligible_candidates_count) {
      //   setBeingSponsored(props?.program?.eligible_candidates_count)
      //    props.setAmountToBePaidToStartProgram(props?.program?.eligible_candidates_count * courseFee )
      // } 

      console.log(res,'No of beneficiaries',values?.eligible_candidates_count, props)

      if(res>values?.eligible_candidates_count){
          setBeingSponsored(values?.eligible_candidates_count)
          props.setAmountToBePaidToStartProgram(values?.eligible_candidates_count*courseFee)
      }else {
        setBeingSponsored(res)
         props.setAmountToBePaidToStartProgram(res*courseFee)
      }
    }
  }, [
    courseFee,
    beingSponsored,
    props?.plannedBudget,
    courseList,
    props.subject_id,
    props.skill_id,
    props?.programType
  ])

  useEffect(() => {
    props.handleValue('sponsored_candidate_count', beingSponsored)
  }, [beingSponsored])

  useEffect(() => {
    props.handleValue('total_amount', budgetEmarked)
  }, [budgetEmarked])

  const getSubjects = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjects',
      jwtHeader,
    })
      .then((response) => {
        setSubjectList(response.data.Content)
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const getSkills = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjectSkills/' + id,
      jwtHeader,
    })
      .then((response) => {
        setSkillList(response.data.data)
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const fetchCourses =async (id) => {
   await axios({
      method: 'get',
      url: baseURL + 'getCoursesBySkills/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // console.log(response)
        setCourseList(response.data.Content)
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  const fetchCourse =async (id) => { 
    if(id){
         await  axios
      .get(baseURL + 'getCourseById/' + id, jwtHeader)
      .then((response) => {
        setCourseSelected(response.data.Content)
        setCourseFee(response.data?.Content?.fees)
        props.setVendor_id(response.data?.Content?.vendor_id)
      })
      .catch((error) => {
        // console.log(error)
      })
    }
  }

  useEffect(() => {
    // Update amount to be paid to start the program when plannedBudget changes
    if (props.programType==="1" &&  props.paymentOption === 'monthly' && props.programDuration > 0) {
      props.setAmountToBePaidToStartProgram(props?.plannedBudget / Number(props.programDuration))
    } else if (props.paymentOption === 'upfront') {
      props.setAmountToBePaidToStartProgram(props?.plannedBudget)
    }
  }, [props?.plannedBudget, props.paymentOption, props.programDuration,props.subject_id])

  const handleChange = (e) => {
    const { name, value } = e.target
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))

    if (name === 'payment_option') {
      props.setPaymentOption(value)
      if (value === 'upfront') {
        props.setAmountToBePaidToStartProgram(props?.plannedBudget)
        props.setProgramDuration(null)
        return
      }
      if (value === 'monthly') {
        if (props.programDuration > 0) {
          props.setAmountToBePaidToStartProgram(
            props?.plannedBudget / Number(props.programDuration),
          )
          
          return
        } else {
          Swal.fire({
            title: 'Select Program Duration!',
            timer: 2000,
          })
          return
        }
      }
    }

    if (name === 'payment_name') {
      if (props.programType === '1') {
        if (props?.plannedBudget) {
          props.setStartAmount(props?.plannedBudget)
        } else {
          Swal.fire({
            title: 'Enter planned budget!',
          })
          return
        }
      }
    }

    if (name === 'program_type') {
      if (!props?.plannedBudget || !(props?.plannedBudget > 0)) {
        Swal.fire({
          title: 'Please add "Planned Budget"',
        })
        return
      }
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))

      props.setAmountToBePaidToStartProgram('');
      props.setPaymentOption('')
      props.setProgramType(value)

    }

    if (name === 'subject_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['subject_id']: '',
      }))
      // fetchCourses(value)
      props.setCourse_id('')
      props.setSkill_id('')
      props.setSubject_id(value)
      setCourseFee(0);
      // fetchCourses(value)
      getSkills(value)
      setBeingSponsored(0)
    }else if(name === 'skill_id'){
        setValues((prev) =>({
          ...prev,
          [name]:value
        }))
        fetchCourses(value)
    } else if (name === 'course_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['vendor_id']: '',
      }))
      props.setCourse_id(value)
      fetchCourse(value)
      // fetchVendor(value)
      // applyFilter(value)
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
    props.handleValue(name, value)
  }

  const handleSkill =(id) =>{
    props.setSkill_id(id);
          applyFilter(id)
    fetchCourses(id);
    setValues((prev) => ({
        ...prev,
        ['skill_id']: id,
      }))
      
  }

  const [filter, setFilter] = useState({})
  useEffect(() => {
    setFilter({
      skill_id: props.program?.skill_id,
      ig_id: props.program?.ig_id,
      sport_id: props.program?.sport_id,
      pc_id: props.program?.pc_id,
      current_state_id: props.program?.current_state_id,
      city_id: props.program?.city_id,
      college_id: props.program?.college_id,
      gender: props.program?.gender,
      target_beneficiaries:props.program?.target_beneficiaries
    })
  }, [props.program])

  // eslint-disable-next-line no-debugger
  const applyFilter = (val) => {
    let filterValues = { ...filter, skill_id: val }
    axios({
      method: 'POST',
      url: baseURL + 'getEligibleCandidates',
      data: filterValues,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        localStorage.setItem(
          'eligibleCandidates',
          JSON.stringify(response?.data?.EligibleCandidates),
        )
        setValues((prev) =>({
          ['eligible_candidates_count']:response?.data?.EligibleCandidates.length
        }))
      })
      .catch((error) => {
        // alert('Something went wrong. Please try again.')
      })
  }

  const checkSelectedOption = (name, value) => {
    let selectedOptions = []

    switch (name) {
      case 'cgpa_input':
        selectedOptions = [props.selectedCGPA]
        break
      case 'mobile_wallet_setup':
        selectedOptions = [props.selectedMobileWallet]
        break
      case 'financial_wellness':
        selectedOptions = [props.selectedFW]
        break
      case 'twelth_marks':
        selectedOptions = [props.selected12th]
        break
      case 'family_income':
        selectedOptions = [props.selectedFI]
        break
      default:
        break
    }

    // Check if the selected value is already chosen by others
    if (selectedOptions.includes(value)) {
      // Do something, maybe show an alert or handle it as per your requirement
      // console.log(`${value} is already selected by others for ${name}`)
    } else {
      // Update the selected value in the state
      switch (name) {
        case 'cgpa_input':
          props.setSelectedCGPA(value)
          break
        case 'mobile_wallet_setup':
          props.setSelectedMobileWallet(value)
          break
        case 'financial_wellness':
          props.setSelectedFW(value)
          break
        case 'twelth_marks':
          props.setSelected12th(value)
          break
        case 'family_income':
          props.setSelectedFI(value)
          break
        default:
          break
      }
    }
  }

  return (
    <>
      <Row>
        {/* <Col sm={3} style={{ backgroundColor: "#002347", borderTop: "1px solid #ffffff" }}>
            <p className="labelText">Program Type</p>
          </Col> */}
        <Col sm={3} style={{ backgroundColor: '#002347' }}>
          <p className="labelText">Program Type*</p>
        </Col>
        {/* <Col sm={9} style={{ borderTop: "1px solid #000000", paddingTop: "5px" }}> */}
        <Col sm={9}>
          <Form.Group className="mb-3">
            <Form.Select name="program_type" value={props.programType} onChange={handleChange}>
              <option value="0">Skilling</option>
              <option value="1">Scholarship</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      {props.programType === '0' && (
        <>
          {/* <Row>
            <Col sm={3} style={{ backgroundColor: "#002347", borderTop: "1px solid #ffffff" }}>
              <p className="labelText">Category</p>
            </Col>
            <Col sm={9} style={{ borderTop: "1px solid #000000", paddingTop: "5px" }}>
              <Form.Group className="mb-3">
                <Form.Select
                  name="course_category"
                  value={values.course_category}
                  onChange={handleChange}
                >
                  <option value="">Select Category</option>
                  {courseCategory.map((item, index) => {
                    return (
                      <option key={index} value={item.course_catg_id}>
                        {item.course_catg_name}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
              <p className="labelText">Subject* </p>
            </Col>
            <Col sm={9} style={{ borderTop: '1px solid #000000', paddingTop: '5px' }}>
              <Form.Group className="mb-3">
                <Form.Select name="subject_id" value={props.subject_id} onChange={handleChange}>
                  <option value="">Select </option>
                  {subjectList.map((item, index) => {
                    return (
                      <option key={index} value={item.subject_id}>
                        {item.subject_name}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
              <p className="labelText">Skill</p>
            </Col>
            <Col sm={9} style={{ borderTop: '1px solid #000000', paddingTop: '5px' }}>
              <Form.Group className="mb-3">
                <Form.Select
                  name="skill_id"
                  value={props.skill_id}
                  onChange={(e) => handleSkill(e.target.value)}
                >
                  <option value="" disabled>
                    Select Skill
                  </option>
                  {skillList.map((item) => {
                    return (
                      <option key={item.skill_id} value={item.skill_id}>
                        {item.skill_name}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course*</p>
            </Col>
            <Col sm={9}>
              <Form.Group className="mb-3">
                <Form.Select name="course_id" value={props.course_id} onChange={handleChange}>
                  <option value="">Select</option>
                  {courseList?.map((item) => (
                    <option value={item.course_id} key={item.course_id}>
                      {item.course_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Course Fee</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{courseFee}</p>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">No of eligible beneficiaries</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{values?.eligible_candidates_count}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">No of beneficiaries to be sponsored</p>
            </Col>
            <Col sm={9}>
              <p className="valueText">{beingSponsored}</p>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText"></p>
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <p className="valueText">Selection Criteria (Multiple items can be selected)</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">CGPA</p>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={props.selectedCGPA}
                      onChange={(e) => checkSelectedOption('cgpa_input', e.target.value)}
                    >
                      <option value="" disabled={!props.selectedCGPA && 'Select Priority'}>
                        {!props.selectedCGPA ? 'Select Priority' : 'Clear Priority'}
                      </option>
                      {[1, 2, 3].map((priority) => (
                        <option
                          key={priority}
                          value={priority}
                          disabled={
                            props.selectedMobileWallet === String(priority) ||
                            props.selected12th === String(priority) ||
                            props.selectedFI === String(priority) ||
                            props.selectedFW === String(priority)
                          }
                        >
                          {priority}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          
          {/* <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Mobile Wallet Setup</p>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={props.selectedMobileWallet}
                      onChange={(e) => checkSelectedOption('mobile_wallet_setup', e.target.value)}
                    >
                      <option value="" disabled={!props.selectedMobileWallet && 'Select Priority'}>
                        {!props.selectedMobileWallet ? 'Select Priority' : 'Clear Priority'}
                      </option>
                      {[1, 2, 3, 4, 5].map((priority) => (
                        <option
                          key={priority}
                          value={priority}
                          disabled={
                            props.selectedCGPA === String(priority) ||
                            props.selected12th === String(priority) ||
                            props.selectedFI === String(priority) ||
                            props.selectedFW === String(priority)
                          }
                        >
                          {priority}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row> */}

          {/* <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Financial Wellness</p>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={props.selectedFW}
                      onChange={(e) => checkSelectedOption('financial_wellness', e.target.value)}
                    >
                      <option value="" disabled={!props.selectedFW && 'Select Priority'}>
                        {!props.selectedFW ? 'Select Priority' : 'Clear Priority'}
                      </option>
                      {[1, 2, 3, 4, 5].map((priority) => (
                        <option
                          key={priority}
                          value={priority}
                          disabled={
                            props.selectedCGPA === String(priority) ||
                            props.selected12th === String(priority) ||
                            props.selectedFI === String(priority) ||
                            props.selectedMobileWallet === String(priority)
                          }
                        >
                          {priority}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row> */}

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">12th Marks</p>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={props.selected12th}
                      onChange={(e) => checkSelectedOption('twelth_marks', e.target.value)}
                    >
                      <option value="" disabled={!props.selected12th && 'Select Priority'}>
                        {!props.selected12th ? 'Select Priority' : 'Clear Priority'}
                      </option>
                      {[1, 2, 3].map((priority) => (
                        <option
                          key={priority}
                          value={priority}
                          disabled={
                            props.selectedCGPA === String(priority) ||
                            props.selectedFW === String(priority) ||
                            props.selectedFI === String(priority) ||
                            props.selectedMobileWallet === String(priority)
                          }
                        >
                          {priority}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347' }}>
              <p className="labelText">Family Income</p>
            </Col>
            <Col sm={9}>
              <Row>
                <Col sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={props.selectedFI}
                      onChange={(e) => checkSelectedOption('family_income', e.target.value)}
                    >
                      <option value="" disabled={!props.selectedFI && 'Select Priority'}>
                        {!props.selectedFI ? 'Select Priority' : 'Clear Priority'}
                      </option>
                      {[1, 2, 3].map((priority) => (
                        <option
                          key={priority}
                          value={priority}
                          disabled={
                            props.selectedCGPA === String(priority) ||
                            props.selectedFW === String(priority) ||
                            props.selected12th === String(priority) ||
                            props.selectedMobileWallet === String(priority)
                          }
                        >
                          {priority}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      {props.programType === '1' && (
        <>
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
              <p className="labelText">Amount Per Student </p>
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Control
                    disabled
                    type="text"
                    name="amount_per_student"
                    placeholder="0"
                    value={props.amountPerStudent!==''?props.amountPerStudent?.toFixed(2):props.amountPerStudent}
                    onChange={(e) => props.setAmountPerStudent(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Col>
          </Row>{' '}
          <Row>
            <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
              <p className="labelText" style={{ marginTop: '10px' }}>
                Payment Options*
              </p>
            </Col>
            <Col style={{ marginTop: '10px' }}>
              <Col sm={9}>
                <Form.Group className="mb-3">
                  <Form.Select
                    name="payment_option"
                    value={props.paymentOption}
                    onChange={handleChange}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    <option value="upfront">Upfront</option>
                    <option value="monthly">Monthly</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Col>
          </Row>
          {props.paymentOption === 'monthly' && (
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347', borderTop: '1px solid #ffffff' }}>
                <p className="labelText">Program Duration (In months)* </p>
              </Col>
              <Col style={{ marginTop: '10px' }}>
                <Col sm={9}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      name="program_duration"
                      value={props?.programDuration}
                      onChange={(e) => props?.setProgramDuration(e.target.value)}
                    >
                      <option disabled selected>
                        Select Program Duration
                      </option>
                      {[...Array(12).keys()].map((value) => (
                        <option key={value + 1} value={value + 1}>
                          {value + 1}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Col>
            </Row>
          )}
        </>
      )}
      {props.programType === '1' && props.paymentOption === 'monthly' && props.programDuration > 0 && (
        <Row>
          <Col sm={3} style={{ backgroundColor: '#002347' }}>
            <p className="labelText">Amount to be paid to start the program</p>
          </Col>

          <Col sm={9}>
            <Form.Group className="mb-3">
              <span>{props.amountToBePaidToStartProgram}</span>
            </Form.Group>
          </Col>
        </Row>
      )}

      {props.programType === '1' && props.paymentOption === 'upfront' && (
        <Row>
          <Col sm={3} style={{ backgroundColor: '#002347' }}>
            <p className="labelText">Amount to be paid to start the program</p>
          </Col>
          <Col sm={9}>
            <Form.Group className="mb-3">
              <span>{props.amountToBePaidToStartProgram}</span>
            </Form.Group>
          </Col>
        </Row>
      )}

      {props.programType === '0' && beingSponsored > 0 && ( <>
        <Row>
          <Col sm={3} style={{ backgroundColor: '#002347' }}>
            <p className="labelText">Amount to be paid to start the program</p>
          </Col>
          <Col sm={9}>
            <Form.Group className="mb-3">
              <span>{props.amountToBePaidToStartProgram}</span>
            </Form.Group>
          </Col>
        </Row>

        {(props.plannedBudget-props.amountToBePaidToStartProgram)>0 && <Row>
          <Col sm={3} style={{ backgroundColor: '#002347' }}>
            <p className="labelText">Balance Budget</p>
          </Col>
          <Col sm={9}>
            <Form.Group className="mb-3">
              <span>{props.plannedBudget-props.amountToBePaidToStartProgram}</span>
              <span className='d-flex'>(OPTShine will continue working for deployment of the balance budget.)</span>
            </Form.Group>
          </Col>
        </Row>}
        </>
      )}
    </>
  )
}

export default ProgramSetup
