// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.02);
  z-index: 1;
  font-family:"Montserrat";
}

.logo img {
  height: 40px;
  width: auto;
}

.menu {
  display: flex;
  justify-content: space-evenly;
}

.menu li a {
  height: 40px;
  margin: 3px;
  padding: 5px 22px;
  border-radius: 5px;
  color: grey;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.9em;
  word-wrap: nowrap !important;
}

.menu li a:hover {
  background-color: var(--purple);
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeComponents/NavBar/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,MAAM;EACN,OAAO;EACP,sBAAsB;EACtB,6CAA6C;EAC7C,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,+BAA+B;EAC/B,uBAAuB;AACzB","sourcesContent":["nav {\n  min-height: 60px;\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  top: 0;\n  left: 0;\n  background-color: #fff;\n  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.02);\n  z-index: 1;\n  font-family:\"Montserrat\";\n}\n\n.logo img {\n  height: 40px;\n  width: auto;\n}\n\n.menu {\n  display: flex;\n  justify-content: space-evenly;\n}\n\n.menu li a {\n  height: 40px;\n  margin: 3px;\n  padding: 5px 22px;\n  border-radius: 5px;\n  color: grey;\n  text-transform: uppercase;\n  font-weight: 500;\n  letter-spacing: 1px;\n  font-size: 0.9em;\n  word-wrap: nowrap !important;\n}\n\n.menu li a:hover {\n  background-color: var(--purple);\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
