import React, { useEffect, useRef } from "react";
import { Flex, HStack, VStack } from "native-base";
import { useDispatch, useSelector } from "react-redux";

import { getProgram, removeProgram } from "../../actions";

import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  NzLabel,
} from "../../components/NzComponents";

const DashBoard = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const programs = useSelector((state) => state.programs);
  // const [Items] = useState(props.data);

  useEffect(() => {
    dispatch(getProgram({ id: auth.id }));
  }, []);

  useEffect(() => {
    // if(programs.refreshPage){
    //   alert("are you sure you want to delete");
    // }
    if (programs.refreshPage) {
      dispatch(getProgram({ id: auth.id }));
    }
  }, [programs]);

  return (
    <Nz1024 bg="#efefe8">
      <NzBannerCard>
        <NzHeading>
          {auth.c1 === "sp" ? "Sponsor Dashboard" : "Agency Dashboard"}
        </NzHeading>
      </NzBannerCard>
      <NzBannerCard>
        <Flex direction="row-reverse" justifyContent={"space-between"}>
          <NzButton
            variant="outline"
            onPress={(e) => {
              props.history.push({ pathname: "/program", new: true });
            }}
          >
            Add New Program
          </NzButton>
        </Flex>

        <NzFlexWrap>
          {programs.Items.map((el) => {
            return (
              <VStack
                bg="#efefe8"
                rounded="lg"
                p="4"
                m="2"
                w={{ base: "100%" /*, lg: "auto" */ }}
              >
                {/* <NzLabel size="md">{JSON.stringify(el)}</NzLabel> */}
                <NzLabel size="md">{el.v1}</NzLabel>
                <HStack direction="row-reverse">
                  <NzButton
                    bg="primary.600"
                    onPress={(e) => {
                      props.history.push({
                        pathname: "/program",
                        program: el,
                      });
                    }}
                  >
                    Edit Program
                  </NzButton>
                  <NzButton
                    mx="4"
                    variant="ghost"
                    onPress={(e) => {
                      const confirmBox = window.confirm(
                        "Sure to delete Program (" + el.v1 + ")?"
                      );
                      if (confirmBox === true) {
                        dispatch(removeProgram({ id: el.id, ky: el.ky }));
                      }
                    }}
                  >
                    Remove
                  </NzButton>
                </HStack>
              </VStack>
            );
          })}
        </NzFlexWrap>
      </NzBannerCard>
    </Nz1024>
  );
};

export default DashBoard;
