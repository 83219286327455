import React from "react";
import { CheckIcon, Select } from "native-base";

function NzSelect(props) {
  return (
    <Select bg="primary.50" fontSize="md" m="1" {...props}>
      {props.children}
    </Select>
  );
}

export default NzSelect;
