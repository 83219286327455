/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import '../admin.css'
import swal from 'sweetalert2'
const AddBeneficiary = (props) => {
  const [loading, setLoading] = useState(false);
  const [maxDate, setMaxDate] = useState('')
  const history = useHistory()
  const [values, setValues] = useState({
    beneficiary_first_name: '',
    beneficiary_last_name: '',
    beneficiary_email: '',
    beneficiary_phone: '',
    current_street_number: '',
    current_city_id: '',
    current_pin_code: '',
    current_country_id: 1,
    current_state_id: '',
    same_address: false,
    permanent_street_number: '',
    permanent_city_id: '',
    permanent_pin_code: '',
    permanent_country_id: 1,
    permanent_state_id: '',
    beneficiary_dob: '',
    cgpa: '',
    institution_id: '',
    subject_id: '',
    college_id: '',
    qualification_id: '',
    enrollment_number: '',
    family_income: '',
    home_state: '',
    gender: '',
    ig_id: '',
    pc_id: '',
    skill_id: '',
    sport_id: '',
    wallet_status: '',
    skill_level: '',
    institute_name: '',
    subject_required_id: '',
    skill_required_id: '',
    aadhar_no: '',
    beneficiary_type:''
  })

  const handleChange2 = (e) => {
    const { name, options, selectedIndex } = e.target
    const selectedOptions = []

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedOptions.push(options[i].value)
      }
    }

    setValues((prev) => ({
      ...prev,
      [name]: selectedOptions,
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'current_state_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['current_city_id']: '',
      }))
      fetchCities(value)
      fetchCollege(value)
    } else if (name === 'permanent_state_id') {
      setValues((prev) => ({
        ...prev,
        [name]: value,
        ['permanent_city_id']: '',
      }))
      fetchPermanentCities(value)
      fetchCollege(value)
    } else if (name === 'subject_required_id') {
      setValues((prev) => ({
        ...prev,
        ['skill_required_id']: value,
      }))
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
      fetchSkills2(value)
    } else if (name === 'subject_id') {
      setValues((prev) => ({
        ...prev,
        ['skill_id']: value,
      }))
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
      fetchSkills(value)
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const onCheckBoxChange = (value) => {
    setValues((prev) => ({
      ...prev,
      ['same_address']: value,
      ['permanent_street_number']: values.current_street_number,
      ['permanent_city_id']: values.current_city_id,
      ['permanent_pin_code']: values.current_pin_code,
      ['permanent_country_id']: values.current_country_id,
      ['permanent_state_id']: values.current_state_id,
    }))
    fetchPermanentCities(values.current_state_id)
  }

  useEffect(() => {
    try {
      if (localStorage.getItem('userType') !== 'validator') {
        history.push('/login')
      }

      const today = new Date().toISOString().split('T')[0]
      setMaxDate(today)
      const userData = JSON.parse(localStorage.getItem('user'))
      setValues((prev) => ({
        ...prev,
        ['institution_id']: userData?.AdminUser?.institution_id,
        ['institute_name']: userData?.AdminUser?.admin_name,
      }))
    } catch (e) {
      console.log(e)
    }

    fetchState()
    fetchCriteria()
    fetchIncomeGroup()
    fetchSports()
    fetchSubject()
    // fetchSkills();
    // fetchInstitution();
    // fetchCollage();
    fetchCourses()
    fetchQualification()
    if (props.id !== 0) {
      // fetchProgram(props.id);
    }
  }, [])

  const [activeStep, setActiveStep] = useState(2)
  const [stateList, setStateList] = useState([])
  const fetchState = () => {
    axios({
      method: 'get',
      url: baseURL + 'getStateList',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response)
        setStateList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Vendor
  const [collegeList, setCollegeList] = useState([])
  const fetchCollege = (state_id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCollegeMaster/' + state_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // console.log(response)
        setCollegeList(response.data.Content)
      })
      .catch((error) => {})
  }

  const [qualificationList, setQualificationList] = useState([])
  const fetchQualification = () => {
    axios({
      method: 'get',
      url: baseURL + 'getQualification',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // console.log(response)
        setQualificationList(response.data.Content)
      })
      .catch((error) => {})
  }

  const [courseList, setCourseList] = useState([])
  const fetchCourses = () => {
    axios({
      method: 'get',
      url: baseURL + 'getCourses',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCourseList(response.data.Content)
      })
      .catch((error) => {})
  }

  // Institution
  const [institutionList, setInstitutionList] = useState([])
  const fetchInstitution = () => {
    axios({
      method: 'get',
      url: baseURL + 'getInstitution',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        // setInstitutionList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Performace Criteria
  const [criteriaList, setCriteriaList] = useState([])
  const fetchCriteria = () => {
    axios({
      method: 'get',
      url: baseURL + 'getPerformanceCriteria',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCriteriaList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Income Group
  const [incomeGroupList, setIncomeGroupList] = useState([])
  const fetchIncomeGroup = () => {
    axios({
      method: 'get',
      url: baseURL + 'getIncomeGroup',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setIncomeGroupList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Sports List
  const [sportsList, setSportsList] = useState([])
  const fetchSports = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSports',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setSportsList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [subjectList, setSubjectList] = useState([])
  const [subjectList2, setSubjectList2] = useState([])

  const fetchSubject = () => {
    axios({
      method: 'get',
      url: baseURL + 'getSubjects/',
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setSubjectList(response.data.Content)
        setSubjectList2(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // Skills List
  const [skillList, setSkillList] = useState([])
  const [skillList2, setSkillList2] = useState([])

  const fetchSkills = (subject_id) => {
    axios({
      method: 'get',
      url: baseURL + `getSubjectSkills/${subject_id}`,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setSkillList(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchSkills2 = (subject_id) => {
    axios({
      method: 'get',
      url: baseURL + `getSubjectSkills/${subject_id}`,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setSkillList2(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // City List
  const [cityList, setCityList] = useState([])
  const fetchCities = (state_id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCity/' + state_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setCityList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // City List
  const [permanentCityList, setPermanentCityList] = useState([])
  const fetchPermanentCities = (state_id) => {
    axios({
      method: 'get',
      url: baseURL + 'getCity/' + state_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setPermanentCityList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const successAction = () => {
    
    if (
      values.beneficiary_first_name.trim() === '' ||
      values.beneficiary_last_name.trim() === '' ||
      values.beneficiary_email.trim() === '' ||
      values.beneficiary_phone.trim() === '' ||
      values.current_street_number.trim() === '' ||
      values.current_city_id.trim() === '' ||
      values.current_pin_code.trim() === '' ||
      values.current_state_id.trim() === '' ||
      values.permanent_street_number.trim() === '' ||
      values.permanent_city_id.trim() === '' ||
      values.permanent_pin_code.trim() === '' ||
      values.permanent_state_id.trim() === '' ||
      values.beneficiary_dob.trim() === '' ||
      // values.cgpa.trim() === '' ||
      values.qualification_id === '' ||
      values.college_id === '' ||
      values.enrollment_number.trim() === '' ||
      values.family_income.trim() === '' ||
      // values.home_state.trim() === '' ||
      values.gender.trim() === '' ||
      // values.ig_id.trim() === "" ||
      values.pc_id.trim() === ''
    ) {
      alert('All fields are required.')
    } else {
      setLoading(true)
      axios({
        method: 'POST',
        url: baseURL + 'addBeneficiary',
        data: values,
        headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then((response) => {
          setLoading(false)
          new swal({
            title: 'Done!',
            text: 'Details added successfully!',
            icon: 'success',
            timer: 2000,
            button: false,
          }).then((result) => {
            history.push('/validator/beneficiaries-list')
          })
        })
        .catch((error) => {
          setLoading(false)
          // alert(error)
          if(error?.response?.data.Status === 401){
            new swal({
              title:error?.response?.data?.message,
              icon:'error',
              button:true
            })
          }else{
            new swal({
              title:"Error",
              text:error,
              icon:"error",
              button:true
            })
          }
        })
    }
  }

  const fetchProgram = (id) => {
    axios({
      method: 'get',
      url: baseURL + 'getProgramById/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        setValues(response.data.Content[0])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <div className="container">
        <Card>
          <Card.Body style={{ padding: '0px 11px' }}>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Personal Details <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9} style={{ marginTop: '5px' }}>
                <Row>
                  <Col>
                    <span className="formLabelText">First name</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="beneficiary_first_name"
                        placeholder=""
                        value={values.beneficiary_first_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <span className="formLabelText">Last name</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="beneficiary_last_name"
                        placeholder=""
                        value={values.beneficiary_last_name}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="formLabelText">Email</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="beneficiary_email"
                        placeholder=""
                        value={values.beneficiary_email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <span className="formLabelText">Phone</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        maxLength={10}
                        name="beneficiary_phone"
                        placeholder=""
                        value={values.beneficiary_phone}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="formLabelText">DOB</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="date"
                        name="beneficiary_dob"
                        placeholder="DOB"
                        max={maxDate}
                        value={values.beneficiary_dob}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <span className="formLabelText">Aadhar Number</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        name="aadhar_no"
                        placeholder="aadhar no"
                        max={maxDate}
                        value={values.aadhar_no}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Current Address <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9}>
                <Row style={{ borderTop: '1px solid #ccc', paddingTop: '5px' }}>
                  <Col md={6}>
                    <span className="formLabelText">Street Number</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="current_street_number"
                        placeholder=""
                        value={values.current_street_number}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">State</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="current_state_id"
                        value={values.current_state_id}
                        onChange={handleChange}
                      >
                        <option value="">State</option>
                        {stateList?.map((stateids) => (
                          <option value={stateids.state_id} key={stateids.state_id}>
                            {stateids.state_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">City</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="current_city_id"
                        value={values.current_city_id}
                        onChange={handleChange}
                      >
                        <option value="">City</option>
                        {cityList?.map((item) => (
                          <option value={item.city_id} key={item.city_id}>
                            {item.city_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">Pin code</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="current_pin_code"
                        maxLength={6}
                        placeholder=""
                        value={values.current_pin_code}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Permanent Address <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9}>
                <Row style={{ borderTop: '1px solid #ccc', paddingTop: '5px' }}>
                  <Col md={6} style={{ display: 'flex' }}>
                    <Form.Check
                      type={'checkbox'}
                      checked={values.same_address}
                      onChange={() => onCheckBoxChange(!values.same_address)}
                      // label=""
                    />
                    <p className="formLabelText" style={{ marginLeft: '15px' }}>
                      Same as current address
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <span className="formLabelText">Street Number</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        readOnly={values.same_address}
                        name="permanent_street_number"
                        placeholder=""
                        value={values.permanent_street_number}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">State</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="permanent_state_id"
                        disabled={values.same_address}
                        value={values.permanent_state_id}
                        onChange={handleChange}
                      >
                        <option value="">State</option>
                        {stateList?.map((stateids) => (
                          <option value={stateids.state_id} key={stateids.state_id}>
                            {stateids.state_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">City</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="permanent_city_id"
                        disabled={values.same_address}
                        value={values.permanent_city_id}
                        onChange={handleChange}
                      >
                        <option value="">City</option>
                        {permanentCityList?.map((item) => (
                          <option value={item.city_id} key={item.city_id}>
                            {item.city_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">Pin code</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        maxLength={6}
                        readOnly={values.same_address}
                        name="permanent_pin_code"
                        placeholder=""
                        value={values.permanent_pin_code}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Academic Details<span style={{ color: '#ff0000' }}>{values.beneficiary_type === "0" ? "" : "*"}</span>
                </p>
              </Col>
              <Col sm={9}>
                <Row style={{ borderTop: '1px solid #ccc', paddingTop: '5px' }}>
                  <Col md={6}>
                    <span className="formLabelText">Enrollment Number</span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="enrollment_number"
                        placeholder=""
                        value={values.enrollment_number}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">College</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="college_id"
                        value={values.college_id}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {collegeList?.map((item) => (
                          <option value={item.college_id} key={item.college_id}>
                            {item.college_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span className="formLabelText">Qualification</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="qualification_id"
                        value={values.qualification_id}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {qualificationList?.map((item) => {
                          return (
                            <option key={item.qualification_id} value={item.qualification_id}>
                              {item.qualification_name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
                  <span className="formLabelText">CGPA</span>  
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      name="cgpa"
                      placeholder=""
                      value={values.cgpa}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col> */}
                </Row>
                {/* <Row>
                <Col md={6}>
                  <span className="formLabelText">Family Income</span>  
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      name="family_income"
                      placeholder=""
                      value={values.family_income}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              </Col>
            </Row>
            <Row>
              <Col sm={3} style={{ backgroundColor: '#002347' }}>
                <p className="labelText">
                  Other Details <span style={{ color: '#ff0000' }}>*</span>
                </p>
              </Col>
              <Col sm={9}>
                <Row style={{ borderTop: '1px solid #ccc', paddingTop: '5px' }}>
                  {/* <Col md={6}>
                  <span className="formLabelText">University Name</span>
                  <Form.Group className="mb-3">
                    <Form.Select name="institution_id" value={values.institution_id} onChange={handleChange}>
                      <option value="">University Name</option>
                      {institutionList?.map((item) => (
                        <option value={item.institution_id} key={item.institution_id}>
                          {item.institute_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col> */}
                  <Col md={6}>
                    <span className="formLabelText">Annual Income (In Lacs)</span>
                    <Form.Group className="mb-3">
                      {/* <Form.Select name="ig_id" value={values.ig_id} onChange={handleChange}>
                        <option value="">Income Group (Annual)</option>
                        {incomeGroupList?.map((item)=>{
                          return(
                            <option key={item.ig_id} value={item.ig_id}>Less than {item.ig_name} lacs</option>    
                          )
                        })}
                      </Form.Select> */}
                      <Form.Control
                        type="number"
                        name="family_income"
                        placeholder=""
                        value={values.family_income}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
                  <span className="formLabelText">Home State</span>
                  <Form.Group className="mb-3">
                    <Form.Select name="home_state" value={values.home_state} onChange={handleChange}>
                      <option value="">Home State</option>
                      {stateList?.map((stateids) => (
                        <option value={stateids.state_id} key={stateids.state_id}>
                          {stateids.state_name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col> */}
                  <Col md={6}>
                    <span className="formLabelText">Performance Criteria</span>
                    <Form.Group className="mb-3">
                      <Form.Select name="pc_id" value={values.pc_id} onChange={handleChange}>
                        <option value="">CGPA</option>
                        {criteriaList?.map((item) => {
                          return (
                            <option key={item.pc_id} value={item.pc_id}>
                              {item.pc_name}
                            </option>
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
                    <span className="formLabelText">Sports</span>    
                    <Form.Group className="mb-3">
                      <Form.Select name="sport_id" value={values.sport_id} onChange={handleChange}>
                        <option value="">Sports</option>
                        {sportsList?.map((item)=>{
                          return(
                            <option key={item.sport_id} value={item.sport_id}>{item.sport_name}</option>    
                          )
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col> */}

                  <Row>
                    <h6>Skills Acquired</h6>
                    <Col md={6}>
                      <span className="formLabelText">Subject</span>
                      <Form.Group className="mb-3">
                        <Form.Select
                          name="subject_id"
                          value={values.subject_id}
                          onChange={handleChange}
                        >
                          <option value="">Subjects</option>
                          {subjectList?.map((item) => {
                            return (
                              <option key={item.subject_id} value={item.subject_id}>
                                {item.subject_name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <span className="formLabelText">Skill</span>
                      <Form.Group className="mb-3">
                        <Form.Select
                          name="skill_id"
                          value={values.skill_id}
                          onChange={handleChange2}
                          multiple
                        >
                          <option value="">Select Skill</option>
                          {skillList?.map((item) => (
                            <option key={item.skill_id} value={item.skill_id}>
                              {item.skill_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <h6>Skills Required</h6>
                    <Col md={6}>
                      <span className="formLabelText">Subject</span>
                      <Form.Group className="mb-3">
                        <Form.Select
                          name="subject_required_id"
                          value={values.subject_required_id}
                          onChange={handleChange}
                        >
                          <option value="">Subjects</option>
                          {subjectList2?.map((item) => {
                            return (
                              <option key={item.subject_id} value={item.subject_id}>
                                {item.subject_name}
                              </option>
                            )
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <span className="formLabelText">Skill</span>
                      <Form.Group className="mb-3">
                        <Form.Select
                          name="skill_required_id"
                          value={values.skill_required_id}
                          onChange={handleChange2}
                          multiple
                        >
                          <option value="">Select Skill</option>
                          {skillList2?.map((item) => (
                            <option key={item.skill_id} value={item.skill_id}>
                              {item.skill_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* <Col md={6}>
                    <span className="formLabelText">Skill Level</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="skill_level"
                        value={values.skill_level}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value={'Basic'}>Basic</option>
                        <option value={'Intermediate'}>Intermediate</option>
                        <option value={'Advance'}>Advance</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}

                  {/* <Col md={6}>
                    <span className="formLabelText">Wallet Status</span>
                    <Form.Group className="mb-3">
                      <Form.Select
                        name="wallet_status"
                        value={values.wallet_status}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="1">Not applied</option>
                        <option value="2">Applied</option>
                        <option value="3">Activated</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  
                  <Col md={6}>
                    <span className="formLabelText">Gender</span>
                    <Form.Group className="mb-3">
                      <Form.Select name="gender" value={values.gender} onChange={handleChange}>
                        <option value="">Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                  <span className='formLabelText'>Beneficiary Type</span>
                  <Form.Select name='beneficiary_type' value={values.beneficiary_type} onChange={handleChange} >
                    <option value="">Select</option>
                    <option value={1}>Student</option>
                    <option value={0}>Ex-Servicemen</option>
                  </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row>
          <Col>
            <Button
              className="btn primaryAdminButton"
              role="button"
              onClick={() => history.goBack()}
              style={{ marginTop: '15px' }}
            >
              Back
            </Button>
          </Col>
          <Col>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
              <Button disabled={loading} onClick={successAction} className="primaryAdminButton">
                {loading ? <img alt="spinner" src="/loaders/spinner_red.gif" style={{height:"20px"}} /> : "Add" }
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AddBeneficiary
