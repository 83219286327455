import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import {
  ArrowForwardIcon,
  Box,
  Button,
  Center,
  CheckIcon,
  Flex,
  Heading,
  HStack,
  Select,
  Stack,
  Text,
  VStack,
} from "native-base";
import { v4 as uuidv4 } from "uuid";

import { getVendor } from "../../actions";
import {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzHeading,
  //NzInput,
  NzLabel,
  NzSelect,
} from "../../components/NzComponents";
import ProgramType from "../../components/ProgramType";
import { upperCamelCase, isPositiveNumber } from "../../js/util";

import { addProgram } from "../../actions";
const { ToWords } = require("to-words");
const toWords = new ToWords();

//var converter = require("number-to-words");

function ProgramDetail(props) {
  const form1 = useRef(null);
  const pageStartRef = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const combo = useSelector((state) => state.combo);
  const [v1, setV1] = useState();
  const [v2, setV2] = useState();
  const [v14, setV14] = useState(0);
  const [v16, setV16] = useState();
  const [v17, setV17] = useState();
  const [v18, setV18] = useState("r"); //Currency
  const [v19, setV19] = useState("d"); //vendor
  const [v20, setV20] = useState("d"); //Course
  //const [v21, setV21] = useState(0); //Planned Amount
  const [prog, setProg] = useState([
    {
      id: uuidv4(),
      t: "", //type
      v: "", //vendor
      f: 0, //fees
      d: 0, //duration
    },
  ]);
  const [selectedFees, setSelectedFees] = useState();
  const [selectedDuration, setSelectedDuration] = useState();

  // This method executes only ones on pageload
  useEffect(() => {
    pageStartRef.current.scrollIntoView({ behavior: "smooth" });
    dispatch(getVendor());
  }, []);

  useEffect(() => {
    if (!props.location.program && !props.location.new) {
      props.history.push("/dashboard");
    } else {
      if (props.location.program) {
        let program = props.location.program;
        console.log(program);
        //setValue("v3", program.v3);
        setValue("v17", program.v17);
        setV1(program.v1);
        setV2(program.v2);
        //setV3(program.v3);
        setV14(parseInt(program.v14));
        setV16(program.v16);
        setV17(program.v17);
      }
    }
  }, []);

  const onSubmit = (data) => {
    data.id = auth.id;
    if (!props.location.program) {
      data.ky = uuidv4();
    } else {
      data.ky = props.location.program.ky;
    }

    //console.log(data);
    dispatch(addProgram(data));
    props.history.push({
      pathname: "/criteria",
      program: data,
    });
  };

  function setProgramType(value, i) {
    let progUpdate = update(prog, { [i]: { $set: value } });
    //console.log(progUpdate);
    setProg(progUpdate);
    let fees = 0;
    let duration = 0;
    progUpdate.map((el) => {
      fees = fees + el.f;
      duration = duration + el.d;
    });
    setSelectedFees(fees);
    setSelectedDuration(duration);
  }

  return (
    <Nz1024 bg="#efefe8">
      <div ref={pageStartRef} />
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>{v1} - Program Detailing</NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      <form
        ref={form1}
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
      >
        <NzBannerCard>
          <NzLabel>Target Beneficiaries : </NzLabel>
          <NzLabel color="#6b21a8">{upperCamelCase(v16)}</NzLabel>
        </NzBannerCard>

        <NzBannerCard>
          <NzLabel>Eligible Beneficiaries : </NzLabel>
          <NzLabel color="#6b21a8">
            {v14} ({toWords.convert(v14, { currency: true })})
          </NzLabel>
        </NzBannerCard>

        {/*<NzBannerCard>
          <NzLabel>Planned Amount (Budget)</NzLabel>
          <NzLabel color="#6b21a8">
            {isPositiveNumber(v21)
              ? upperCamelCase(converter.toWords(v21))
              : null}
          </NzLabel>
          <HStack>
            <Controller
              control={control}
              name="v18"
              render={({ field }) => (
                <NzSelect
                  minW="60"
                  w="60"
                  placeholder="Currency"
                  selectedValue={v18}
                  onValueChange={(itemValue) => {
                    setValue("v18", itemValue);
                    setV18(itemValue);
                  }}
                >
                  <Select.Item label="INR" value="r" />
                  <Select.Item label="USD" value="d" />
                  <Select.Item label="EURO" value="e" />
                </NzSelect>
              )}
            />
            <Controller
              control={control}
              name="v21"
              render={({ field }) => (
                <NzInput
                  maxLength="8"
                  {...field}
                  placeholder="Planned Amount *"
                  onChange={(event) => setV21(event.target.value)}
                />
              )}
              rules={{
                required: true,
              }}
            />
          </HStack>
          {errors.v1 && errors.v1.type === "required" && (
            <Box px={2} ml={"3%"}>
              Planned Amount is mandatory
            </Box>
          )}
        </NzBannerCard>*/}
        {/*<Text>{JSON.stringify(prog)}</Text>*/}
        {prog.map((el, i) => {
          return (
            <ProgramType
              key={el.id}
              counter={i}
              setProgramType={(result) => {
                setProgramType(result, i);
              }}
              removeProgramType={() => {
                let progNew = update(prog, { $splice: [[i, 1]] });
                setProg(progNew);
              }}
            />
          );
        })}
        <NzBannerCard>
          <HStack>
            <NzButton
              variant="outline"
              onPress={(e) => {
                let progNew = update(prog, {
                  $push: [
                    {
                      id: uuidv4(),
                      t: "", //type
                      v: "", //vendor
                      f: 0, //fees
                      d: 0, //duration
                    },
                  ],
                });
                setProg(progNew);
              }}
            >
              Add More Programs
            </NzButton>
          </HStack>
          {/*<NzBannerCard>
            <NzLabel>Budget Earmarked for Program : </NzLabel>
            <NzLabel color="#6b21a8">
              Rs.{" "}
              {isPositiveNumber(22) && isPositiveNumber(selectedFees)
                ? 22 * selectedFees
                : 0}
            </NzLabel>
          </NzBannerCard>*/}

          <NzBannerCard>
            <NzLabel>Prioritize Selection By : </NzLabel>
            <Controller
              control={control}
              name="v8"
              render={({ field }) => (
                <NzSelect
                  placeholder="Prioritize Selection By"
                  //selectedValue={v8}
                  onValueChange={(itemValue) => {}}
                >
                  <Select.Item label="Income" value="i" />
                  <Select.Item label="CGPA" value="c" />
                </NzSelect>
              )}
            />
          </NzBannerCard>
        </NzBannerCard>

        <VStack>
          <Button
            endIcon={<ArrowForwardIcon size="4" />}
            mt={12}
            size={"md"}
            _text={{
              fontSize: "sm",
              fontWeight: "bold",
            }}
            // onPress={() =>
            //   form1.current.dispatchEvent(
            //     new Event("submit", { bubbles: true, cancelable: true })
            //   )
            // }
          >
            Next (Payments)
          </Button>
        </VStack>
      </form>
    </Nz1024>
  );
}
export default ProgramDetail;
