import React, { useEffect, useState } from 'react'
// import {useNavigate, useLocation} from 'react-router-dom';
import { Row, Col, Table } from 'react-bootstrap'
import { baseURL } from '../config/baseURLPath'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import './sponsorcss.css'

const Dashboard = () => {
  const history = useHistory()
  // const [dashboardData, setDashboardData] = useState([]);

  const [dashboardData, setDashboardData] = useState({
    programCount: [{ distinct_programs: 0, total_committed_amount: 0, total_utilised_amount: 0 }],
    numberOfBeneficiaries: [{ total_beneficiaries: 0 }],
  })

  const addNewProgram = () => {
    history.push('/vendor/course-list')
  }

  useEffect(() => {
    if (localStorage.getItem('userType') !== 'validator') {
      history.push('/login')
    }

    try {
      const userData = JSON.parse(localStorage.getItem('user'))
      getDashboard(userData.AdminUser.institution_id)
    } catch (e) {
      console.log(e)
    }

    fetchSponsors()
    fetchPrograms()
  }, [])

  const [sponsors, setSponsors] = useState([])
  const fetchSponsors = () => {
    axios({
      method: 'post',
      url: baseURL + 'getListofSponsors',
    })
      .then((response) => {
        setSponsors(response.data.Data)
      })
      .catch((error) => {})
  }

  const [programs, setPrograms] = useState([])
  const fetchPrograms = () => {
    axios({
      method: 'post',
      url: baseURL + 'getListofPrograms',
    })
      .then((response) => {
        setPrograms(response.data.Data)
      })
      .catch((error) => {})
  }

  const addNewBeneficiaries = () => {
    history.push('/validator/beneficiaries-list')
  }

  const uploadBeneficiaries = () => {
    history.push('/validator/beneficiaries-upload')
  }

  const existingBeneficiaries = () => {
    history.push('/validator/beneficiaries-existing')
  }

  const getDashboard = async (id) => {
    await axios({
      method: 'get',
      url: baseURL + 'getValidatorDashboard/' + id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    })
      .then((response) => {
        console.log(response.data.Result)
        setDashboardData(response.data.Result)
      })
      .catch((error) => {})
  }

  return (
    <>
      <div className="contentBox">
        {/* <Row>
          <Col style={{display:"flex", alignItems:"flex-end", justifyContent:"flex-end", marginBottom:"15px"}}>
            <Button onClick={addNewBeneficiaries} className="primaryAdminButton" style={{marginLeft:"5px"}}>Beneficiaries List</Button>
          </Col>
        </Row> */}
        <Row className="numbersRow">
          <Col className="numberBoxDiv">
            <div className="dashboarddata-box green">
              <p className="numbersText">
                {' '}
                {(dashboardData && dashboardData.programCount && dashboardData.programCount[0]
                  && dashboardData.programCount[0].distinct_programs)
                  || 0}
              </p>
              <p className="sublabelText">Nos. of Programs</p>
            </div>
          </Col>
          <Col className="numberBoxDiv">
            <div className="dashboarddata-box yellow">
              <p className="numbersText">
                <p className="numbersText">
                  {(dashboardData &&
                  dashboardData.numberOfBeneficiaries &&
                  dashboardData.numberOfBeneficiaries[0]
                    && dashboardData.numberOfBeneficiaries[0].total_beneficiaries)
                    || 0}
                </p>
              </p>
              <p className="sublabelText">Nos. of Beneficiaries</p>
            </div>
          </Col>
          <Col className="numberBoxDiv">
            <div className="dashboarddata-box blue">
              <p className="numbersText">
                <p className="numbersText">
                  {(dashboardData && dashboardData.programCount && dashboardData.programCount[0]
                    && dashboardData.programCount[0].total_committed_amount) || 0}
                </p>
              </p>
              <p className="sublabelText">Scholarship funds committed</p>
            </div>
          </Col>
          <Col className="numberBoxDiv">
            <div className="dashboarddata-box red">
              <p className="numbersText">
                <p className="numbersText">
                  {(dashboardData &&
                    dashboardData.programCount &&
                    dashboardData.programCount[0] &&
                    dashboardData.programCount[0].total_utilised_amount) ||
                    0}
                </p>
              </p>
              <p className="sublabelText">Scholarship funds disbursed</p>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '15px' }}>
          <Col>
            <h6>Data Section</h6>
          </Col>
        </Row>
        <Row style={{ margin: '15px' }}>
          <Col>
            <div className="btn-box">
              <p className="btn-tooltip-text">Add new data for beneficiaries.</p>
              <button className="dashboard-call-btns" onClick={uploadBeneficiaries}>
                Enter/Upload
              </button>
            </div>
          </Col>
          <Col>
            <div className="btn-box">
              <p className="btn-tooltip-text">View list of beneficiaries.</p>
              <button className="dashboard-call-btns" onClick={addNewBeneficiaries}>
                View
              </button>
            </div>
          </Col>
          <Col>
            <div className="btn-box">
              <p className="btn-tooltip-text">Update existing beneficiary data.</p>
              <button className="dashboard-call-btns" onClick={existingBeneficiaries}>Update</button>
            </div>
          </Col>
        </Row>
        <Row style={{ margin: '15px' }}>
          <Col>
            <h6>Report Section</h6>
          </Col>
        </Row>
        <Row style={{ margin: '15px' }}>
          <Col md={12}>
            <div
              className="table-header-div"
              style={{ backgroundColor: 'rgba(0,0,0,0.6)', color: '#ffffff' }}
            >
              List of Sponsors
              {/* (Total {sponsors.length} Nos.) */}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/validator/sponsors-list')
                }}
                style={{ position: 'absolute', right: '10px', color: '#ffffff' }}
              >
                View All
              </a>
            </div>
            <SponsorTable data={sponsors} />
          </Col>
          <Col md={12}>
            <div
              className="table-header-div"
              style={{ backgroundColor: 'rgba(0,0,0,0.6)', color: '#ffffff' }}
            >
              List of Programs
              {/* (Total {programs.length} Nos.) */}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/validator/programs-list')
                }}
                style={{ position: 'absolute', right: '10px', color: '#ffffff' }}
              >
                View All
              </a>
            </div>
            <ProgramTable data={programs} />
          </Col>
          {/* <Col md={6}>
            <div className="btn-box">
              <p className="btn-tooltip-text">Report data will come here...</p>
            </div>
          </Col>
          <Col md={12}>
            <div className="btn-box">
              <p className="btn-tooltip-text">Report data will come here...</p>
            </div>
          </Col> */}
        </Row>
        {/* Running Projects */}
        {/* <Row>
          <Col className="dataDividerRow">
            <p>CURRENT RUNNING PROGRAMS</p>
          </Col>
        </Row>
        <Row className="numbersRowNoShadow">
          <Col>
                <div className="programBox">
                  <p className="programName">Currently No Program is running. Please add a new Program by clicking Add New Program.</p>
                </div>
          </Col>
        </Row> */}
        {/* Predefined Programs */}
        {/* <Row>
          <Col className="dataDividerRow">
            <p>PREDEFINED PROGRAMS</p>
          </Col>
        </Row>
        <Row className="numbersRowNoShadow">
          {preDefinedPrograms.map((item, index)=>{
            return(
              <Col sm={3} key={index}>
                <div className="programBox">
                  <p className="programName">{item.name}</p>
                </div>
              </Col>
            )
          })}
        </Row> */}
      </div>
    </>
  )
}

export default Dashboard

const SponsorTable = (props) => {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Category</th>
          <th>City</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((item, index) => {
          if (index < 5) {
            return (
              <tr key={index}>
                <td>{item.sponsor_name}</td>
                <td>{item.sponsor_catg_type}</td>
                <td>{item.city_name}</td>
              </tr>
            )
          }
        })}
      </tbody>
    </Table>
  )
}

const ProgramTable = (props) => {
  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Name</th>
          <th>Sponsor Name</th>
          <th>Target Beneficiaries</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((item, index) => {
          if (index < 5) {
            return (
              <tr key={index}>
                <td>{item.program_name}</td>
                <td>{item.sponsor_name}</td>
                <td>
                  {item?.target_beneficiaries === '1' ? 'College Students' : 'School Students'}
                </td>
              </tr>
            )
          }
        })}
      </tbody>
    </Table>
  )
}
