import React from "react";



const NotFound  =()=>{



    return (<>
            <h1>PAGE NOT FOUND!</h1>
    </>)

}


export default NotFound;