import React from "react";
import { Input } from "native-base";

function NzInput(props) {
  if (props.variant !== "contact") {
    return (
      <Input
        bg={"primary.50"}
        m="1"
        w={"100%"}
        borderRadius="4"
        _dark={{
          borderColor: "coolGray.700",
        }}
        _light={{
          borderColor: "coolGray.300",
        }}
        size="xl"
        {...props}
      />
    );
  } else {
    return (
      <Input
        bg={"#ffffff1a"}
        border={"none"}
        borderRadius={"4px"}
        p={"10px"}
        color={"white"}
        _focus={{ bg: "#ffffff2c" }}
        placeholderTextColor={"#ffffff80"}
        {...props}
      />
    );
  }
}

export default NzInput;
