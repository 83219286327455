import React from "react";
import Img from "./example dash.png";
import Img1 from "./example dash 2.png";
import { Box, Center, Flex, Heading, VStack } from "native-base";

function Dashboard() {
  return (
    <Center bg="#00000005" py={{ base: "8", lg: "12" }}>
      <VStack
        w={{
          base: "90%",
          md: "80%",
        }}
        alignItems="center"
        space="8"
        my="4"
      >
        <Heading color="primary.1000">Dashboard</Heading>
        <Flex
          direction="row"
          wrap="wrap"
          justify="space-between"
          align="center"
          w="100%"
        >
          <Box
            w={{
              base: "80%",
              lg: "49%",
            }}
            h="auto"
            my="2"
            mx="auto"
          >
            <img src={Img} />
          </Box>
          <Box
            w={{
              base: "80%",
              lg: "45%",
            }}
            h="auto"
            my="2"
            mx="auto"
          >
            <img src={Img1} />
          </Box>
        </Flex>
      </VStack>
    </Center>
  );
}
export default Dashboard;
