import React, { useEffect, useState } from "react";
import produce from "immer";

import {
  Box,
  Button,
  Center,
  Checkbox,
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  Heading,
  Hidden,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "native-base";
import {
  Nz1024,
  NzBannerCard,
  NzFlexWrap,
  NzHeading,
  NzLabel,
} from "../../components/NzComponents";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../actions";
import { DownArrow2, BadgeVerified } from "../../components/Icons/NzIcons";

function Courses(props) {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses);
  const auth = useSelector((state) => state.auth);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [direction, setDirection] = useState(false);
  const [data, setData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(
      getCourse({
        id: auth.id,
        li: auth.pageLimit,
      })
    );
  }, []);

  useEffect(() => {
    if (selectAll) {
      setSelected(data);
    } else {
      setSelected([]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (courses.lastKey) {
      setNextPage(true);
    } else {
      setNextPage(false);
    }
  }, [courses]);

  useEffect(() => {
    let _courses;
    if (direction === "down") {
      _courses = courses.Items.reverse();
    } else {
      _courses = courses.Items;
    }
    setData(_courses);
  }, [courses]);

  let total = 0;
  return (
    <Nz1024 bg="#efefe8">
      {/*<Text>{JSON.stringify(selected)}</Text>*/}
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>Courses</NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      <Hidden from="md">
        <Pagination />
        <VStack
          w={"100%"}
          mt="4"
          justifyContent="space-around"
          alignItems="flex-start"
          borderBottomColor={"primary.500"}
          borderBottomWidth="12"
          borderTopColor="coolGray.200"
          borderTopWidth="1"
        >
          {data &&
            data.map((el, i) => {
              return (
                <Flex
                  direction="row"
                  justify="space-between"
                  align="center"
                  w="100%"
                  px="8"
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                  bg={i % 2 === 0 ? "white" : ""}
                >
                  <Box flexShrink="1" py="4" pr="2">
                    <Text>{el.v5}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v3}
                    </Text>
                    <Text>{el.v2}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v1}
                    </Text>
                    <Text>{el.v6}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v4}
                    </Text>
                    <Text>{el.n1}</Text>
                  </Box>
                  <Flex direction="row" align="center">
                    <BadgeVerified size="xs" />

                    <DownArrow2 ml="2.5" size="xs" />
                  </Flex>
                </Flex>
              );
            })}
        </VStack>
        <Pagination />
      </Hidden>
      <Hidden till="md">
        <NzBannerCard>
          <Pagination />
          <HStack
            bg="primary.600"
            py="3"
            w={{
              base: "90%",
              lg: "999px",
            }}
            justifyContent="space-around"
            alignItems="flex-start"
            shadow={4}
          >
            <Box pl="5">
              <Checkbox
                isChecked={selectAll}
                onChange={(state) => {
                  setSelectAll(!selectAll);
                }}
              />
            </Box>
            <Box w={"18%"}>
              <Text pl="4" pr="1" bold color="white">
                Course
              </Text>
            </Box>
            <Box w={"10%"}>
              <Text pl="4" pr="1" bold color="white">
                Institute
              </Text>
            </Box>
            <Box w={"15%"}>
              <Text pl="4" pr="1" bold color="white">
                Url
              </Text>
            </Box>

            <Box w={"20%"}>
              <Text pl="4" pr="1" bold color="white">
                Course Detail
              </Text>
            </Box>

            <Box w={"10%"}>
              <Text pl="4" pr="1" bold color="white">
                Duration
              </Text>
            </Box>
            <Box w={"15%"}>
              <Text pl="4" pr="1" bold color="white">
                experience
              </Text>
            </Box>

            <Box w={"10%"}>
              <Text pl="4" pr="1" bold color="white">
                Price
              </Text>
            </Box>
          </HStack>
          <VStack
            w={{
              base: "90%",
              lg: "999px",
            }}
            justifyContent="space-around"
            alignItems="flex-start"
            shadow={8}
          >
            {data &&
              data.map((item, i) => {
                return (
                  <Item
                    el={item}
                    i={i}
                    onCheck={(checked) => {
                      select(checked, item);
                    }}
                    toggle={selectAll}
                  />
                );
              })}
          </VStack>
          <Pagination />
        </NzBannerCard>
      </Hidden>
    </Nz1024>
  );

  function select(checked, item) {
    if (checked) {
      //console.log('checked');
      setSelected(
        produce(selected, (draft) => {
          draft.push(item);
        })
      );
    } else {
      //console.log('not checked');
      setSelected(
        produce(selected, (draft) => {
          draft.splice(
            draft.findIndex((el) => el.i === item.i),
            1
          );
        })
      );
    }
  }

  function Pagination() {
    return (
      <HStack
        px="1"
        my="2"
        w={{
          base: "90%",
          lg: "999px",
        }}
        direction="row-reverse"
      >
        <HStack alignItems="center" space="4">
          <Button
            endIcon={<ChevronLeftIcon />}
            isDisabled={pageNumber === 1}
            variant="subtle"
            onPress={() => {
              dispatch(
                getCourse({
                  id: auth.id,
                  li: auth.pageLimit,
                  la: { id: auth.id, ky: data[0].ky },
                  desc: true,
                })
              );
              setDirection("down");
              setPageNumber(pageNumber - 1);
              setSelectAll(false);
            }}
          ></Button>
          <Box>{pageNumber}</Box>
          <Button
            endIcon={<ChevronRightIcon />}
            isDisabled={!nextPage}
            variant="subtle"
            onPress={() => {
              dispatch(
                getCourse({
                  id: auth.id,
                  li: auth.pageLimit,
                  la:
                    direction === "down"
                      ? { id: auth.id, ky: data[auth.pageLimit - 1].ky }
                      : courses.lastKey,
                })
              );
              setDirection("up");
              setPageNumber(pageNumber + 1);
              setSelectAll(false);
            }}
          ></Button>
        </HStack>
      </HStack>
    );
  }
}

function Item({ el, i, onCheck, toggle }) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(toggle);
  }, [toggle]);
  return (
    <HStack width={"100%"} bg={i % 2 === 0 ? "white" : ""}>
      <Box borderBottomColor="coolGray.200" borderBottomWidth="1" p="4">
        <Checkbox
          isChecked={selected}
          onChange={() => {
            selected ? setSelected(false) : setSelected(true);
            selected ? onCheck(false) : onCheck(true);
          }}
        />
      </Box>
      <Box
        w={"17%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v5}
        </Text>
      </Box>
      <Box
        w={"10%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v3}
        </Text>
      </Box>
      <Box
        w={"15%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v2}
        </Text>
      </Box>
      <Box
        w={"20%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v1}
        </Text>
      </Box>

      <Box
        w={"10%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v6}
        </Text>
      </Box>
      <Box
        w={"15%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v4}
        </Text>
      </Box>
      <Box
        w={"10%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.n1}
        </Text>
      </Box>
      {/* <Box
      w={"20%"}
      borderBottomColor="coolGray.200"
      borderBottomWidth="1"
      pl="4"
    >
      <Text px="1" my="4">
        {el.createdAt}
      </Text>
    </Box> */}
    </HStack>
  );
}

export default Courses;
