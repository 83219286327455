//THIS WILL BE DIFFERENT FOR EVERY NODZON APP
const appId = 10; //optsociety
//######## CHANGE THIS TO 0 - WHEN DEVICE ID IMPLEMENTED
//const deviceId = 1;
const deviceId = "web";

const defaultState = {
  pageLimit: 10,
  isLoggedIn: false,
  appId: appId,
  deviceId: deviceId,
  lid: "",
  otp_sent:false
};

export default function (state = defaultState, action) {
  switch (action.type) {
  case "CONNECTED":
    return Object.assign({}, state, {
      connected: action.connected,
    });
  case "OTP":
    return Object.assign({}, state, {
      appId: appId,
      deviceId: action.deviceId,
      isLoggedIn: false,
      loginId: action.mobile,
      otp: "",
    });
  case "REENTER":
    return Object.assign({}, state, {
      isLoggedIn: true,
      id: action.payload.id,
      c1: action.payload.c1,
      lid: action.payload.lid,
      token: action.payload.token,
      status: "",
    });
  case "LOGIN_FAIL":
    return Object.assign({}, state, {
      isLoggedIn: false,
      status: action.status,
    });
  case "OTP_SENT":
    return Object.assign({}, state, {
      isLoggedIn: false,
      otp_sent: true,
    });
  case "LOGOUT":
    return Object.assign({}, state, {
      isLoggedIn: false,
      id: "",
      c1: "",
      lid: "",
      token: "",
      status: "",
    });
  case "UPDATE_MOMENTID":
    return Object.assign({}, state, {
      momentId: action.payload,
    });
  default:
    return state;
  }
}
