import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import { baseURL } from "../../../config/baseURLPath"
import DataTable from "react-data-table-component"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import CustomInput from "../../utils/CustomInput"
import "../admin.css"
import swal from "sweetalert2";
import {read, utils} from "xlsx";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const make_cols = refstr => {
  let o = [],
    C = utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i };
  return o;
};

const List = (props) => {
  
  const imageUploader = useRef(null);
  const history = useHistory();
  const [beneficaries, setBeneficaries] = useState([])
  
  const [institution, setInstitution] = useState(0);  
  const [downloadBeneficiaryList, setDownloadBeneficiaryList] = useState([]);
  

  const jwtHeader = {
    headers: { "access-token": localStorage.getItem("csr_token") },
  }
  useEffect(() => {
    try{
      const userData = JSON.parse(localStorage.getItem("user"))
      getRecords(userData.AdminUser.institution_id)
      setInstitution(userData.AdminUser.institution_id)
    }catch(e){
      console.log(e)
    }
  }, [])

  const getRecords = (id) => {
    axios({
      method: "get",
      url: baseURL + "getBeneficiaryByValidator/"+id,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })
      .then((response) => {
        setBeneficaries(response.data.Content)
        let filtered_data = [];
        response.data.Content.map((item, index)=>{
          let ele = {
            beneficiary_id: item.beneficiary_id,
            enrollment_number:item.enrollment_number,
            first_name:item.beneficiary_first_name,
            last_name:item.beneficiary_last_name,
            semester: item.semester,
            cgpa:item.cgpa
          }
          filtered_data.push(ele);
        })
        setDownloadBeneficiaryList(filtered_data);
      })
      .catch((error) => {})
  }

  let tableHeader = [
    {
      name: "#",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: "First Name",
      selector: (row) => row?.beneficiary_first_name,
    },
    {
      name: "Last Name",
      selector: (row) => row?.beneficiary_last_name,
    },
    {
      name: "Email",
      selector: (row) => row?.beneficiary_email,
    },
    {
      name: "Qualification",
      selector: (row) => row?.qualification_name,
    },
    {
      name: "Current State",
      selector: (row) => row?.current_state,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="btn btn-sm primaryAdminButton" onClick={() => editBeneficiary(row.beneficiary_id)}>
            <i className="fa fa-edit"></i>
          </button>
          <button
            target="_blank"
            className="btn btn-sm"
            role="button"
            style={{backgroundColor:"#ff1a1a",color:"#ffffff", marginLeft:"5px"}}
            onClick={()=> deleteBeneficiary(row.beneficiary_id)}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const editBeneficiary = (beneficiary_id) =>{
    history.push("/validator/beneficiaries-edit", {beneficiary_id: beneficiary_id})
  }

  const deleteBeneficiary = (id) =>{
    // setActiveId(id)
    // setConfirmationModal(true);
  }

  const handleImageUpload = (e /*:File*/) => {
    const file = e.target.files[0];
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      console.log(data);
      // setData(data);
      // setCols(make_cols(ws["!ref"]))
      uploadBeneficiaries(data);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  const validCGPA = (input_str) => {
    var re = /^(10|\d)(\.\d{1,2})?$/;

    return re.test(input_str);
  }

  const uploadBeneficiaries = (data) =>{

    let records = [];
    if(data.length < 1){
      alert("No data found in excel");
      return;
    }else if(data[1].length !== 6 ){
      alert("Please check your sheet format");
      return;
    }
    for(let i = 1; i < data.length; i++){
      if(data[i].length === 6){
        
        if(data[i][4] === null || data[i][4] === ""){
        }else if(!validCGPA(data[i][5])){
        }else{
          let ele = {
            beneficiary_id: data[i][0],
            semester: data[i][4],
            cgpa: data[i][5]
          }
          records.push(ele)
        }
      }
    }

    axios({
      method: "POST",
      url: baseURL + "setBeneficiaryData",
      data: records,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })
      .then((response) => {
        new swal({
          title: "Done!",
          text: "Records successfully updated.",
          icon: "success",
          timer: 2000,
          button: false
        }).then((result) => {
          getRecords(institution)
        })
      })
      .catch((error) => {
        console.log(error)
        // alert('Something went wrong. Please try again.')
      })
  }

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    let fileName = "beneficiary_list";
    let csvData = downloadBeneficiaryList;
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { "data": ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <div className="container" style={{ marginTop: "10px" }}>
      <Card>
        <Card.Header as="h4" style={{ color: "#000", backgroundColor:"#ffffff" }}>
          <div style={{ float: "right", marginBottom: "10px" }}>
            <Button className="btn primaryAdminButton" role="button" onClick={()=> exportToCSV()} style={{marginLeft:"5px"}}>
              Download List
            </Button>
            <Button className="btn primaryAdminButton" role="button" onClick={()=> imageUploader.current.click()} style={{marginLeft:"5px"}}>
              Bulk Update
            </Button>
            <input
              className="edit_btn_photo"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
          </div>
        </Card.Header>
        
      </Card>
      <div style={{overflowX:"hidden"}}>
        <Row style={{backgroundColor:"#ffffff"}}>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{marginBottom:"10px", marginTop:"10px", fontWeight:"bold", fontSize:"14px"}}>Enrollment Number</p></Col>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{marginBottom:"10px", marginTop:"10px", fontWeight:"bold", fontSize:"14px"}}>First Name</p></Col>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{marginBottom:"10px", marginTop:"10px", fontWeight:"bold", fontSize:"14px"}}>Last Name</p></Col>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{marginBottom:"10px", marginTop:"10px", fontWeight:"bold", fontSize:"14px"}}>Name of year/semester</p></Col>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{marginBottom:"10px", marginTop:"10px", fontWeight:"bold", fontSize:"14px"}}>Updated CGPA</p></Col>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}><p style={{marginBottom:"10px", marginTop:"10px", fontWeight:"bold", fontSize:"14px"}}>Action</p></Col>
        </Row>
        {beneficaries.map((r, i) => (
          <ExistingRecord item={r} key={i} refreshRecords={() => getRecords(institution)} /> 
        ))}
      </div>
      <Button className="btn primaryAdminButton" role="button" onClick={() => history.goBack()} style={{marginTop:"15px"}}>
        Back
      </Button>
    </div>
  )
}

export default List


const ExistingRecord = (props) =>{

  const [item, setItems] = useState(null);

  useEffect(()=>{
    setItems(props.item)
  }, [props.item])

  const handleChange = (e) =>{
    let {name, value} = e.target;
    setItems({...item, [name]:value})
  }

  const validCGPA = (input_str) => {
    var re = /^(10|\d)(\.\d{1,2})?$/;

    return re.test(input_str);
  }

  const updateSingleRecord = () =>{
    
    if(item.semester === null || item.semester.trim() === ""){
      alert("Please enter valid semester.");
      return;
    }else if(!validCGPA(item.cgpa)){
      alert("Please enter a valid CGPA");
      return;
    }

    let records = [{
      beneficiary_id: item.beneficiary_id,
      semester:item.semester,
      cgpa:item.cgpa
    }]

    axios({
      method: "POST",
      url: baseURL + "setBeneficiaryData",
      data: records,
      headers: { "access-token": localStorage.getItem("csr_token") },
    })
      .then((response) => {
        new swal({
          title: "Done!",
          text: "Record successfully updated.",
          icon: "success",
          timer: 2000,
          button: false
        }).then((result) => {
          props.refreshRecords()
        })
      })
      .catch((error) => {
        console.log(error)
        // alert('Something went wrong. Please try again.')
      })
  }
  

  // 

  return(
    <>
      {item !== null ? 
        <Row style={{backgroundColor:"#ffffff", padding:"3px 5px", borderBottom:"1px solid #ccc"}}>
          <Col>
            <input className="form-control" type={"text"} value={item.enrollment_number} readOnly style={{backgroundColor:"#ccc"}}/>
          </Col>
          <Col>
            <input className="form-control" type={"text"} value={item.beneficiary_first_name} readOnly style={{backgroundColor:"#ccc"}} />
          </Col>
          <Col>
            <input className="form-control" type={"text"} value={item.beneficiary_last_name} readOnly style={{backgroundColor:"#ccc"}}/>
          </Col>
          <Col>
            <input className="form-control" type={"text"} name="semester" value={item.semester} onChange={handleChange} />
          </Col>
          <Col>
            <input className="form-control" type={"number"} name="cgpa" value={item.cgpa} min={0} onChange={handleChange} />
          </Col>
          <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <>
              <button className="btn btn-sm" onClick={() => updateSingleRecord()} style={{backgroundColor:"#4caf50", color:"#ffffff"}}>
                <i className="fa fa-plus" style={{marginRight:"5px"}}></i>
              Update
              </button>
            </> 
          </Col>
        </Row> : null }
    </>
  )
}