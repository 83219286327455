import { Switch, Route } from "react-router-dom"
import React, { Suspense } from "react"
import Header from "./common/Header"
import Dashboard from "./Dashboard"
import Course from "./pages/manage-course/index";
import List from "./pages/manage-course/List";
import EditCourse from "./pages/manage-course/UpdateProgram";
import Listbeneficiaries from "./pages/manage-beneficiareis/List";
import UpdateProgress from "./pages/manage-beneficiareis/UpdateProgress";

// import Addbeneficiaries from "./pages/manage-beneficiareis/Add";

// import ListUsers from "./pages/manage-users/List";
// import AddUsers from "./pages/manage-users/Add";

import Signup from "./SignUp";
import "./sponsorcss.css"

function Admin() {
  
  return (
    <div className="App">
      <Switch>
        <Route
          exact 
          path="/service-partner/register"
          render={(props) => (
            <Suspense fallback={<p> Loading...</p>}>
              <Signup {...props} />
            </Suspense>
          )}
        />
        <VendorLayoutRoute exact path="/service-partner/dashboard" component={Dashboard} />
        <VendorLayoutRoute exact path="/service-partner/course-list" component={List} />
        <VendorLayoutRoute exact path="/service-partner/course-add" component={Course} />
        <VendorLayoutRoute exact path="/service-partner/course-edit" component={EditCourse} />
        <VendorLayoutRoute exact path="/service-partner/beneficiaries-list" component={Listbeneficiaries} />
        <VendorLayoutRoute exact path="/service-partner/update-beneficiary-progress/:id" component={UpdateProgress} />
        {/* <VendorLayoutRoute exact path="/service-partner/beneficiaries-add" component={Addbeneficiaries} /> */}
        {/* <VendorLayoutRoute exact path="/service-partner/users-list" component={ListUsers} />
        <VendorLayoutRoute exact path="/service-partner/users-add" component={AddUsers} /> */}
      </Switch>
    </div>
  )
}

export default Admin

const VendorLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          style={{
            backgroundColor: "#ffffff",
            backgroundSize: "cover",
            minHeight: "100vh",
          }}
        >
          <Header />
          <div className="contentArea">
             
            <Suspense fallback={<p> Loading...</p>}>
              <div className="fixContent">
                <Component {...props} />
              </div>
            </Suspense>
          </div>
        </div>
      )}
    />
  );
};