import React, { useState, useEffect } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Card, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
//import "primeflex/primeflex.css";
import '../manage-beneficiary/list.css'
const List = () => {
  const [programList, setProgramList] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // beneficiary_first_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // beneficiary_email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    // beneficiary_dob: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // program_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // institute_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // beneficiaries: { value: null, matchMode: FilterMatchMode.IN },
  })
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    fetchPrograms()
  }, [])

  const fetchPrograms = async () => {
    const jwtHeader = {
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }
    let sponsorData = JSON.parse(localStorage.getItem('parent_data'))

    try {
      const response = await axios.get(
        baseURL + 'getPrograms/' + sponsorData?.sponsor_id,
        jwtHeader,
      )
      console.log(response, 'Response')
      setProgramList(response.data.Content)
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    )
  }

  const header = renderHeader()

  return (
    <div className="container" style={{ marginTop: '10px' }}>
      <Card>
        <Card.Header as="h4" style={{ color: '#000', backgroundColor: '#ffffff' }}>
          <div style={{ float: 'right', marginBottom: '10px' }}>Programs</div>
          {/* Additional Back button for navigation */}
          <Button
            className="btn primaryAdminButton"
            role="button"
            style={{ marginLeft: '5px' }}
            onClick={(e) => {
              e.preventDefault()
              history.push('/sponsor/dashboard')
            }}
          >
            Back
          </Button>
        </Card.Header>
        <Card.Body>
          <DataTable
            scrollHeight="400px"
            value={programList}
            paginator
            rows={10}
            dataKey="id"
            showGridlines
            stripedRows
            loading={loading}
            filterDisplay="row"
            globalFilterFields={['beneficiary_first_name', 'beneficiary_email']}
            header={header}
            emptyMessage="No beneficiaries found."
            style={{ fontSize: '14px' }}
          >
            <Column field="program_name" header="Program Name" />
            <Column field="course_name" header="Course Name" />
            <Column field="sponsored_candidate_count" header="Beneficiaries Count" />
            <Column field="committed_amount" header="Amount Committed" />
            <Column
              body={(rowData) => (
                <Button
                  className="m-1"
                  onClick={() => history.push('/sponsor/program/' + rowData.program_id)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
              )}
            />
          </DataTable>
        </Card.Body>
      </Card>
    </div>
  )
}

export default List
