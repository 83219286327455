import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "native-base";
import { logout } from "../../actions";

const Logout = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    props.history.push("/login");
  }, [auth]);

  // This method executes only ones on pageload
  useEffect(() => {
    localStorage.removeItem("user");
    dispatch(logout({}));
  }, []);

  return (
    <>
      <Text>Please wait while we are loging you out</Text>
    </>
  );
};

export default Logout;
