// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-box{
    background: #fff;
    border: 1px solid #e9e7e6;
    border-radius: 4px;
    box-shadow: 4px 4px 25px rgb(0 0 0 / 15%);
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 15px 50px;
    width: -moz-fit-content;
    width: fit-content;
}

.primaryButton, .primaryButton:active, .primaryButton:hover{
    all: unset;
    background-color: #002347;
    /* width: 100%; */
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    height: 40px;
    color: #ffffff;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.actionButtonDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.fixContent{
    height: calc(100vh - 70px);
    overflow-y: auto;
    width: calc(100vw - 220px);
    background-color: #f5f5f5;
    padding: 15px;
}

.boxSize{
    width: -moz-fit-content !important;
    width: fit-content !important;
}`, "",{"version":3,"sources":["webpack://./src/admin/admincss.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;IACzC,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,0BAA0B;IAC1B,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,kCAA6B;IAA7B,6BAA6B;AACjC","sourcesContent":[".content-box{\n    background: #fff;\n    border: 1px solid #e9e7e6;\n    border-radius: 4px;\n    box-shadow: 4px 4px 25px rgb(0 0 0 / 15%);\n    box-sizing: border-box;\n    margin-bottom: 20px;\n    padding: 15px 50px;\n    width: fit-content;\n}\n\n.primaryButton, .primaryButton:active, .primaryButton:hover{\n    all: unset;\n    background-color: #002347;\n    /* width: 100%; */\n    padding-left: 20px;\n    padding-right: 20px;\n    border-radius: 20px;\n    height: 40px;\n    color: #ffffff;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.actionButtonDiv{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 50px;\n}\n\n.fixContent{\n    height: calc(100vh - 70px);\n    overflow-y: auto;\n    width: calc(100vw - 220px);\n    background-color: #f5f5f5;\n    padding: 15px;\n}\n\n.boxSize{\n    width: fit-content !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
