import React from 'react'

const Privacy = () => {
  return (
    <>
      <div className="container mb-2 p-2">
        <h2>Privacy Policy</h2>
        <h2>Last Updated: April 1,2024</h2>
        <p>
          st updated: April 1, 2024 This privacy policy ("Policy") explains the accepted privacy
          practices of OPTSHINE DIGITECH SOLUTIONS PVT LTD. ("OptShine", "we", "us" or "our") for
          (i) our website www.OptShine.com (the "Website"); (ii) any OptShine mobile software
          applications that we license (each an "App"); and (iii) the Corporate Social
          Responsibility, volunteering & philanthropy, education & skilling and placement related
          services which we make available to our users via the Website and/or App (the "Service"),
          and describes the ways we collect and use your Personal Information (defined below) and
          the rights and options available to you with respect this information. This Policy is
          incorporated into, as applicable, OptShine's Terms of Use (the "ToU"). Unless the Website,
          App or Service is separately referred to in this Policy, all references herein to the
          "Services" shall include the Website, the App and the Service. We reserve the right, at
          our discretion, to change this Privacy Policy at any time. Such change will be effective
          immediately following the posting of the revised Privacy Policy on the Website and/or App.
          You agree to be bound by this policy and any of the changes made in the terms of this
          Policy by using or continuing to use the Services immediately after any changes to this
          Policy are posted will indicate your acceptance. If you do not agree with any of the
          amended terms, you must avoid any further use of the Services.
        </p>
        <h4>Personal Information that you provide.</h4>
        <p>
          While some areas of the Services may not require registration or any other form of
          identification, other parts may. Consequently, when you register with OptShine, we may ask
          you to provide certain personal details such as your name, e-mail address, date of birth,
          location, and further optional information. "Personal Information" means any information
          that may be used, either alone or in combination with other information, to personally
          identify an individual, including, but not limited to:
        </p>
        <ol style={{ 'list-style-type': 'circle' }}>
          <li>
            First and last name, a personal profile, an email address, or other contact information.
          </li>
          <li>
            Your full name, address, e-mail address, phone number, date of birth and any proof of
            Your identity and/or address that we may request;
          </li>
          <li>
            Details of your transactions that are recorded on our Website or our mobile application
            or application using Your OptShine account;
          </li>
          <li>Any correspondence sent by you to OptShine;</li>
          <li>Survey that you complete through the Website or based on our request;</li>
          <li>Your participation in any promotion sponsored by OptShine;</li>
          <li>Calls that we make to you or you make to us;</li>
          <li>Information collected through Cookies;</li>
          <li>Your IP address, log-in times, operating system and browser type;</li>
          <li>
            Details of Your visits to our Website/ mobile application including, but not limited to,
            traffic data, location data, weblogs and other communication data, and the resources
            that You access whilst visiting Our Website or mobile application.
          </li>
        </ol>
        <p>
          We keep your Personal Information in strict confidentiality and we will only such use
          information to provide you with the requested Services.
        </p>
        <p>
          We may collect your financial Personal Information through the services of a third party
          that will assist us in providing you with our Services, in which case you acknowledge and
          agree to review and be bound by the terms of use and privacy policy of said third-party
          service provider.
        </p>
        <h4>Additional Personal Information that we collect.</h4>
        <p>
          In addition to the Personal Information you provide, we may further collect information
          when you use the Services. For example, this information may include your family
          background, your performance in academics and other fields, your phone-book, the frequency
          and scope of your use of the Services, transactions that you made, the Internet protocol
          (IP) address, or mobile device ID, and the name of the domain that serves you to access
          the Services, your browser type and the geographic location of the computer system or
          mobile device (as described in further detail below) that you are using to log-in to the
          Services.
        </p>
        <h4>What do we do with your personally identifiable information?</h4>
        <p>
          Your privacy is our priority. We will not sell, trade or lease your personally
          identifiable information. We may use your personally identifiable information for the
          following purposes only:
        </p>
        <ol style={{ 'list-style-type': 'circle' }}>
          <li>to enable you to use the Services;</li>

          <li>to improve and customize your experience on the Website and/or App;</li>
          <li>
            to provide you with support, handle complaints and collect fees and to conduct
            administrative activities necessary to maintain and provide our Services;
          </li>

          <li>to send you Services-related updates, notices and announcements;</li>

          <li>to provide you with information related to the Services;</li>

          <li>to conduct surveys and questionnaires;</li>

          <li>
            {' '}
            to contact you regarding service and administration related issues as and when OptShine
            believes it to be necessary;
          </li>

          <li>
            to comply with any applicable law and assist law enforcement agencies under any
            applicable law, when OptShine has good faith to believe that OptShine's cooperation with
            the law enforcement agencies is legally mandated or meets the applicable legal
            standards;
          </li>

          <li>
            to prevent fraud, misappropriation, infringements, identity theft and other illegal
            activities and misuse of the Services;
          </li>

          <li>
            to take any action in any case of dispute, or legal proceeding of any kind between you
            and OptShine, or between you and any other third parties or users with respect to, or in
            relation with the Services;
          </li>
        </ol>

        <h4>How do we use aggregated information?</h4>
        <p>
          We may use anonymous, statistical or aggregated information collected on or through the
          use of the Services. We may also use this information to properly operate the Services, to
          improve the quality of the Services, to enhance your experience, to create new services
          and features, including customized services, to change or cancel existing content or
          service, for further internal, commercial and statistical purposes.
        </p>

        <h4>Sharing information with others.</h4>
        <p>
          We do not sell, rent or lease your personally identifiable information to third parties
          for any of their marketing purposes.
        </p>
        <p>
          We may share Personal Information with others in any of the following instances, or
          subject to your explicit consent:
        </p>

        <ol style={{ 'list-style-type': 'circle' }}>
          <li>
            To operate the Website, App and Service, and their respective services, and to enable
            you to use them; we will share your Personal Information with third parties to the
            extent required to operate our Services and to facilitate your use of them.
          </li>
          <li>
            We may share your information with third parties for analysis purposes in the hopes of
            better improving our Services;
          </li>

          <li>
            If we reasonably believe that you have breached OptShine's Terms of Use, or abused your
            rights to use any of the Services, or performed any act or omission that we reasonably
            believe to be violating any applicable laws, rules, or regulations. We may share your
            information in these cases, with law enforcement and other competent authorities and
            with any third party, as may be required to handle any result of your wrongdoing;
          </li>

          <li>
            If we are required, or reasonably believe to be required by law to share or disclose
            your information;
          </li>

          <li>
            In any case of dispute, or legal proceeding of any kind between you and us or between
            you and any other third parties and/or users with respect to, or in relation with any of
            the Services;
          </li>

          <li>
            In any case where we reasonably believe that sharing information is necessary to prevent
            imminent harm to an individual or physical damage or damage to property;
          </li>

          <li>
            If we organize the operation of any of our Services within a different framework, or
            through another legal structure or entity, or if we are acquired by, or merged into or
            with another entity, provided however, that those entities agree to be bound by the
            provisions of this Policy, with respective changes taken into consideration;
          </li>

          <li>
            We may also share Personal Information with companies or organizations connected, or
            affiliated with us and/or our Services, such as subsidiaries, affiliates, parent
            companies, service providers and other partners, with the express provision that their
            use of such information must comply with this Policy, in order to improve and provide
            the Services.
          </li>

          <li>
            If OptShine becomes involved in a merger, acquisition, or any form of sale of some or
            all of its assets, we will provide notice before personal information is transferred and
            becomes subject to a different privacy policy.
          </li>

          <li>
            We may also store and/or back up your comments (as defined on our Services) through
            third party storage providers.
          </li>
        </ol>

        <h4>Choice/Opt-out Option.</h4>
        <p>
          We provide you with an opportunity to opt-out of having your Personal Information used for
          certain purposes, when we ask for this information. For example, if you no longer wish to
          receive our promotional communications, service related announcements and our account
          billing alerts, you may opt-out of receiving them by sending us a request specifying your
          new choice. Simply send your request to info@optshine.com.
        </p>

        <h4>Accessing your Personal Information.</h4>
        <p>
          At any time you may access your account on the App and edit, delete or amend your Personal
          Information. If you find that the Personal Information in your account is not accurate,
          complete or updated, than you should make all necessary changes to correct it. Please keep
          in mind that false, incorrect, or outdated information may prevent you from registering
          with the Services and impair our ability to provide you with the Service through the
          Website and/or App. If you are unable to edit, delete or amend any Personal Information
          via your account, you may submit an access request to us by sending an email to
          info@optshine.com. Your email should include adequate details of your request.
        </p>

        <h4>Data retention.</h4>
        <p>
          At all times we may review, retain and disclose any Personal Information as we deem
          necessary to satisfy any applicable law, regulation, legal process or governmental
          request. However, by doing so we do not either expressly or impliedly endorse, affirm or
          in any manner assume any responsibility for such communications.
        </p>

        <p>
          When your account is terminated, we will delete your Personal Information. Naturally, once
          disconnected, you will no longer have access to your OptShine account. However, due to
          periodical backups and safety mechanisms meant for your information protection and for our
          administrative purposes, certain aggregated data and/or Personal Information from your
          account may remain in our systems for an indefinite time. We will not knowingly keep this
          in a way that could identify you. If you have a query about any such Personal Information
          that we may be storing, please email us at info@optshine.com. Your email should include
          adequate details of your query.
        </p>

        <h4>Cookies and other tracking technologies.</h4>
        <p>
          We may use cookies with the Services. Cookies are packets of information sent by the
          Services' servers to your web browser and then sent back by your browser each time you
          access the Services' servers.
        </p>

        <p>
          The cookies may contain a variety of information, such as the content of search queries,
          the Services' pages you have accessed, session durations and IP addresses or mobile device
          ID.
        </p>

        <p>
          The Services may use cookies for various purposes, such as to save you the need to
          re-enter your user name and password each time you log-in, to facilitate the use of the
          Services, to collect statistical information with respect to the use of the Services, to
          verify information, to customize the Services to your personal preferences and for
          information security purposes.
        </p>

        <p>
          Some of the cookies may expire when your browsing session ends and you exit your browser,
          however other cookies are saved on your computer's or mobile device's hard drive. If you
          wish to block the Services' cookies, then please use the help button in your browser, or
          use the "settings" function in your mobile device, and follow the necessary instructions.
          However, bear in mind that disabling cookies may complicate or even prevent your use of
          the Services' or certain features and functions thereof.
        </p>

        <p>
          In addition, we may make use of web beacons on the Services for user security purposes and
          in order to improve product service and evaluation. A web beacon is an object that is
          inserted in an e-mail or web page and is usually invisible to the user. The beacon allows
          checking that a user has viewed the page or e-mail, allowing us to track user services and
          website traffic more effectively.
        </p>

        <p>
          In addition to the above, we may collect limited information from your mobile device in
          order to provide the App. Such information may include your mobile device type, mobile
          device id, and date and time stamps of App use. We may deploy tracking technologies within
          the App to help us gather aggregate statistics, but we do not intend to use Personal
          Information for such purposes.
        </p>

        <h4>Links to Other Sites.</h4>
        <p>
          The Services may contain links to other sites that are not owned or controlled by us. We
          are not responsible for the privacy practices of such other sites. Therefore, we encourage
          you to be aware when you leave our Website or App and to read the privacy statements of
          each and every website that collects personally identifiable information.
        </p>

        <h4>Information Security.</h4>
        <p>
          We take great effort in securing your Personal Information and we will do our best to
          safeguard your information from any wrongdoings, malfunctions, unlawful interceptions or
          access, or other kinds of abuse and misuse.
        </p>

        <p>
          We implement a variety of systems, applications and procedures to secure your Personal
          Information, to minimize the risks of theft, damage, loss of information, or unauthorized
          access or use of information. Among such security methods is the storing of Personal
          Information on cloud services, firewalls, encryption codes, authentication procedures and
          multi layer passwords. Further, our employees are bound by Code of Conduct and
          Confidentiality Policies which obligate them to protect the confidentiality of personal
          information.
        </p>

        <p>
          <b>Please keep your OptShine password safe.</b>We strongly advise that you do not share
          this information with any third parties or leave it exposed. Furthermore, we strongly
          advise you to change your password from time to time. Please also be aware that certain
          Personal Information and other information provided by you in connection with your use of
          the App may be stored on your mobile device (even if we do not collect that information).
          You are solely responsible for maintaining the security of your mobile device from
          unauthorized access.
        </p>

        <p>
          If you have any questions, or concerns about this Policy or any complaints or grievances
          about the manner in which we handle your personal information or the use of your personal
          information (in doing so acknowledging that we may be unable to provide you all or some of
          the services), please feel free to contact the Grievance Officer any time at
          info@optshine.com.
        </p>

        <h4>Disclaimer</h4>
        <p>OptShine aggregates the information related to:</p>
        <ul>
          <li>
            Corporate Social Responsibility, volunteering & philanthropy benefits offered by its
            partner sponsors
          </li>
          <li>
            Beneficiaries seeking these benefits as provided by institutions and organizations these
            beneficiaries are associated with
          </li>
          <li>education & skilling courses offered by its service partners</li>
          <li>placement opportunities available with its partner sponsors</li>
        </ul>

        <p>
          OptShine doesn’t represent its partners. OptShine is committed to providing a transparent
          and reliable platform for all stakeholders. We take utmost care towards ensuring that all
          information available on our Website and/or App is accurate.
        </p>

        <p>
          We encourage our users to report any doubt related to information and data available on
          our Website and/or App; and we take such reports seriously and investigate them promptly.
        </p>

        <p>
          If we find evidence of inaccurate/misleading information or claims, we take immediate
          action to rectify the situation.
        </p>
      </div>
    </>
  )
}

export default Privacy
