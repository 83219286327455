import React from "react";
import "./style.css";
import Logo from "./Logo-new.png";
// import Logo from './Optsociety-logo.svg'
import {
  Center,
  HamburgerIcon,
  Hidden,
  HStack,
  Image,
  Menu,
  Pressable,
  VStack,
} from "native-base";

function Navbar(props) {
  const logmeOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    localStorage.removeItem("userType");
    localStorage.removeItem("csr_token");
    window.sessionStorage.clear();
    window.location.reload(); 
  };

  const openDashboard = () => {
    let userType = localStorage.getItem("userType");
    if (userType === "sponsor") {
      props.history.push("/sponsor/dashboard");
    } else if (userType === "vendor") {
      props.history.push("/service-partner/dashboard");
    } else if (userType === "validator") {
      props.history.push("/validator/dashboard");
    }
  };

  return (
    <Center
      w={"100vw"}
      bgColor="white"
      zIndex={1}
      shadow={2}
      py={3}
      position={"fixed"}
      top={0}
    >
      <Center
        w={{
          base: "90%",
        }}
      >
        <HStack w={"100%"} justifyContent="space-between" alignItems="center">
          <img src={Logo} height="36" alt="" />
          <Hidden till="lg">
            <ul
              className="menu"
              style={{ marginTop: "0px", marginBottom: "0px" }}
            >
              <li>
                <a  href="#" style={{ color: "#002347", fontSize: "18px" }} >Home</a>
              </li>
              <li>
                <a href="#aboutus" style={{ color: "#002347", fontSize: "18px" }}>About&nbsp;us</a>
              </li>
              <li>
                <a href="#csr" style={{ color: "#002347", fontSize: "18px" }}>CSR&nbsp;</a>
              </li>
              <li>
                <a href="#skilling" style={{ color: "#002347", fontSize: "18px" }}>Skilling</a>
              </li>

              <li>
                <a href="#ourapproach" style={{ color: "#002347", fontSize: "18px" }}>Our&nbsp;Approach</a>
              </li>
              <li>
                <a href="#FAQ" style={{ color: "#002347", fontSize: "18px" }} >FAQs</a>
              </li>
              {/* <li>
                <a href="#features">Features</a>
              </li> */}
              {/* <li>
                  <a href="#dashboard">Dashboard</a>
                </li> */}
              <li>
                <a href="#contact" style={{ color: "#002347", fontSize: "18px" }}>Contact</a>
              </li>
            </ul>
          </Hidden>
          <div>
            <Hidden till="lg">
              <ul
                className="menu"
                style={{ marginTop: "0px", marginBottom: "0px" }}
              >
                {localStorage.getItem("csr_token") === null ? (
                  <>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push("/login");
                        }} 
                        style={{ color: "#002347", fontSize: "18px" }}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push("/register");
                        }}
                        style={{ color: "#002347", fontSize: "18px" }}
                      >
                        Register
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          openDashboard();
                        }}
                        style={{ color: "#002347", fontSize: "18px" }}
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          logmeOut();
                        }}
                        style={{ color: "#002347", fontSize: "18px" }}
                      >
                        Logout
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </Hidden>
            <Hidden from="lg">
              <Menu
                w="250"
                mt={5}
                mr={1}
                trigger={(triggerProps) => {
                  return (
                    <Pressable
                      accessibilityLabel="More options menu"
                      {...triggerProps}
                      alignSelf="center"
                    >
                      <HamburgerIcon size="32px" />
                    </Pressable>
                  );
                }}
              >
                {localStorage.getItem("csr_token") === null ? (
                  <>
                    <Menu.Item onPress={() => props.history.push("/login")}>
                      Login
                    </Menu.Item>
                    <Menu.Item onPress={() => props.history.push("/register")}>
                      Register
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item onPress={() => openDashboard()}>
                      Dashboard
                    </Menu.Item>
                    <Menu.Item onPress={() => logmeOut()}>Logout</Menu.Item>
                  </>
                )}
                <Menu.Item onPress={() => window.open("/#contact")}>
                  Contact Us
                </Menu.Item>
              </Menu>
            </Hidden>
          </div>
        </HStack>
      </Center>
    </Center>
  );
}

export default Navbar;
