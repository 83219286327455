import React, { useState, useEffect } from "react"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import { baseURL } from "../../../config/baseURLPath"
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom"
import "../admin.css"

import Edit from "./Edit";
import List from "./List";
import Add from "./Add";

const UserIndex = (props) => {
  const history = useHistory();
  const location = useLocation()
  
  const [activeTab, setActiveTab] = useState(1) 
  const [activeId, setActiveId] = useState(0);
  const [institutionId, setInstitutionId] = useState(0);  


  const jwtHeader = {
    headers: { "access-token": localStorage.getItem("csr_token") },
  }
  useEffect(() => {
    try{
      const userData = JSON.parse(localStorage.getItem("user"))
      setInstitutionId(userData.Content.sponsor_id)
    }catch(e){
      console.log(e)
    }
  }, [])

  console.log(activeId)

  return (
    <div className="container">
      {institutionId !== 0 ?  
        <Row>
          {activeTab === 1 ? <List id={institutionId} changeActiveTab={(val, id) => {setActiveTab(val); setActiveId(id)}} /> : null }
          {activeTab === 2 ? <Add id={institutionId} changeActiveTab={(val) => {setActiveTab(val); setActiveId(0)}}/> : null}
          {activeTab === 3 && activeId !== 0 ? <Edit id={institutionId} changeActiveTab={(val) => {setActiveTab(val); setActiveId(0)}} activeId={activeId}/> : null }
        </Row> : null }
    </div>
  )
}

export default UserIndex
