import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { baseURL } from '../../../config/baseURLPath'
import axios from 'axios'
import '../admin.css'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import NewProgram from './NewProgram'
import CreateProgram from './CreateProgram'
import CommitFunds from './CommitFunds'

const InstituionPage = () => {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(1)
  const [programId, setProgramId] = useState(
    location.state?.id === undefined ? 0 : location.state.id,
  )
  const [program, setProgram] = useState('')
  const [plannedBudget, setPlannedBudget] = useState(0)
  const [programType, setProgramType] = useState('')
  const [program_id , setProgram_id] = useState('');
  const [skill_id, setSkill_id] = useState('')
  const [subject_id, setSubject_id] = useState('')
  const [course_id, setCourse_id] = useState('')
  const [programDuration, setProgramDuration] = useState('')
  const [amountPerStudent, setAmountPerStudent] = useState('')
  const [paymentOption, setPaymentOption] = useState('')
  const [amountToBePaidToStartProgram, setAmountToBePaidToStartProgram] = useState('')
  const [selectedCGPA, setSelectedCGPA] = useState('')
  const [selectedMobileWallet, setSelectedMobileWallet] = useState('')
  const [selectedFW, setSelectedFW] = useState('')
  const [selected12th, setSelected12th] = useState('')
  const [selectedFI, setSelectedFI] = useState('')
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSetUpComplete, setIsSetUpComplete] = useState(false);
  const [vendor_id, setVendor_id] = useState('');
  const [isCarryForwardFunds, setIsCarryForwardFunds] = useState(false);

  useEffect(() => {
    try {
      setUp()
    } catch (e) {
      // do nothing
    }
  }, [])

  const jwtHeader = {
    headers: { 'access-token': localStorage.getItem('csr_token') },
  }

  const setUp = async () => {
    try {
      let routes = location.search.split('=')
      if (routes.length === 2) {
        await fetchProgram(routes[1])
        setIsUpdate(true);
        setProgram_id(routes[1])
        setIsSetUpComplete(true);
      }else{
        setIsSetUpComplete(true);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  const fetchProgram = async (id) => { 
    try {
      const response = await axios.get(baseURL + 'getProgramById/' + id, jwtHeader);
      setProgram(response.data.Content[0]);
    } catch (error) {
      // console.log(error);
    }
  }

  const handleTabClick = (tab) => {
    if (activeTab === 1 && tab===2) {
      return;
    }
    setActiveTab(tab);
  }

  return (
    <div className="container">
      <Row>
        <Col>
          <Button
            onClick={() => setActiveTab(1)}
            className={activeTab === 1 ? 'instituionTabSection active' : 'instituionTabSection'}
          >
            New Program
          </Button>

          {programId !== 0 || program !== null ? (
            <Button
              onClick={() => handleTabClick(2)}
              className={activeTab === 2 ? 'instituionTabSection active' : 'instituionTabSection'}
            >
              Deploy Programs
            </Button>
          ) : null}

          {programId !== 0 || program !== null ? (
            <Button
              // onClick={() => setActiveTab(3)}
              className={activeTab === 3 ? 'instituionTabSection active' : 'instituionTabSection'}
            >
              Commit Funds
            </Button>
          ) : null}
        </Col>
        {/* Edit Institution */}
        {isSetUpComplete && activeTab === 1 ? ( 
          <NewProgram
            id={programId}
            isUpdate={isUpdate}
            program_id={program_id}
            program={program}
            setProgram={(val) => {
              setProgram(val)
              setActiveTab(2)
            }}
            setActiveTab={setActiveTab}
          />
        ) : null}
        {activeTab === 2 ? (
          <CreateProgram
            id={programId}
            program={program}
            setProgram={(val) => {
              setProgram(val)
              setActiveTab(3)
            }}
            plannedBudget={plannedBudget}
            setPlannedBudget={setPlannedBudget}
            programType={programType}
            setProgramType={setProgramType}
            programDuration={programDuration}
            setProgramDuration={setProgramDuration}
            amountPerStudent={amountPerStudent}
            setAmountPerStudent={setAmountPerStudent}
            paymentOption={paymentOption}
            setPaymentOption={setPaymentOption}
            amountToBePaidToStartProgram={amountToBePaidToStartProgram}
            setAmountToBePaidToStartProgram={setAmountToBePaidToStartProgram}
            selectedCGPA={selectedCGPA}
            setSelectedCGPA={setSelectedCGPA}
            selectedMobileWallet={selectedMobileWallet}
            setSelectedMobileWallet={setSelectedMobileWallet}
            selectedFW={selectedFW}
            setSelectedFW={setSelectedFW}
            selected12th={selected12th}
            setSelected12th={setSelected12th}
            selectedFI={selectedFI}
            setSelectedFI={setSelectedFI}
            skill_id={skill_id}
            subject_id={subject_id}
            course_id={course_id}
            setSkill_id={setSkill_id}
            setCourse_id={setCourse_id}
            setSubject_id={setSubject_id}
            setActiveTab={setActiveTab}

            setVendor_id={setVendor_id}
            vendor_id={vendor_id}

            isCarryForwardFunds={isCarryForwardFunds}
            setIsCarryForwardFunds={setIsCarryForwardFunds}
          />
        ) : null}

        {activeTab === 3 ? (
          <CommitFunds
            id={programId}
            program={program}
            plannedBudget={plannedBudget}
            setPlannedBudget={setPlannedBudget}
            programType={programType}
            setProgramType={setProgramType}
            programDuration={programDuration}
            setProgramDuration={setProgramDuration}
            amountPerStudent={amountPerStudent}
            setAmountPerStudent={setAmountPerStudent}
            paymentOption={paymentOption}
            setPaymentOption={setPaymentOption}
            amountToBePaidToStartProgram={amountToBePaidToStartProgram}
            setAmountToBePaidToStartProgram={setAmountToBePaidToStartProgram}
            skill_id={skill_id}
            subject_id={subject_id}
            course_id={course_id}
            setSkill_id={setSkill_id}
            setCourse_id={setCourse_id}
            setSubject_id={setSubject_id}
            setActiveTab={setActiveTab}
            selectedCGPA={selectedCGPA}
            setSelectedCGPA={setSelectedCGPA}
            selectedMobileWallet={selectedMobileWallet}
            setSelectedMobileWallet={setSelectedMobileWallet}
            selectedFW={selectedFW}
            setSelectedFW={setSelectedFW}
            selected12th={selected12th}
            setSelected12th={setSelected12th}
            selectedFI={selectedFI}
            setSelectedFI={setSelectedFI}
            setVendor_id={setVendor_id}
            vendor_id={vendor_id}
            isCarryForwardFunds={isCarryForwardFunds}
            setIsCarryForwardFunds={setIsCarryForwardFunds}
          />
        ) : null}
      </Row>
    </div>
  )
}

export default InstituionPage
