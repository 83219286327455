export function capitalizeFirstLetter(string) {
  return string.replace(/^./, string[0].toUpperCase());
}

export function isPositiveNumber(s) {
  if (!isNaN(s) && s !== "" && s > 0) return true;
  else return false;
}

export function upperCamelCase(s) {
  if (s) {
    return s.replace(/\w+/g, function (w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    });
  } else return s;
}

export function getFieldDistinctFromItems(items, fieldName) {
  var lookup = {};
  var result = [];

  for (var item, i = 0; (item = items[i++]); ) {
    var name = item[fieldName];

    if (!(name in lookup)) {
      lookup[name] = 1;
      result.push(name);
    }
  }
  return result;
}
