import React from "react";
import { Heading } from "native-base";

function NzHeading(props) {
  return (
    <Heading size={"lg"} {...props}>
      {props.children}
    </Heading>
  );
}

export default NzHeading;
