import React from "react";
import { Box, Center, Flex, Heading, Text, VStack, Image } from "native-base";
import Img from "./checked.png";
import Img2 from "./equalizer.png";
import Img3 from "./wallet.png";
import Img4 from "./piggybank.png";

function Features() {
  return (
    <section id="features">
      <Center bg="primary.1000" py={{ base: "8", lg: "12" }}>
        <VStack alignItems="center" space="6" my="4">
          <Heading color="white" textAlign="center">
            Features
          </Heading>
          <Flex
            flexDirection={{
              base: "column",
              md: "row",
            }}
            wrap="wrap"
            justifyContent="space-around"
          >
            <Flex
              flexDirection={{
                base: "column",
                md: "row",
              }}
              justifyContent="space-around"
            >
              <Box bg="white" w="300px" minH="320" m="4">
                <VStack my="4" space="5" alignItems="center">
                  <Box px="7" pt="4">
                    <Image source={Img} size="md" />
                  </Box>
                  <Text bold fontSize="xl" color="primary.1000" px="4">
                    Validation Agencies
                  </Text>
                  <Text px="7" pb="4" fontSize="md" textAlign="center">
                    We approach eligible beneficiaries through educational
                    institutions to ensure that details of the beneficiaries are
                    duly validated
                  </Text>
                </VStack>
              </Box>
              <Box bg="white" w="300px" minH="320" m="4">
                <VStack my="4" space="5" alignItems="center">
                  <Box px="7" pt="4">
                    <Image source={Img2} size="md" />
                  </Box>
                  <Text bold fontSize="xl" color="primary.1000" px="4">
                    Ready to use Programs
                  </Text>
                  <Text px="7" pb="4" fontSize="md" textAlign="center">
                    OptSociety provides ready to use customizable CSR programs
                    so that your CSR charter can be implemented with ease.
                    Sponsorships can be mapped to beneficiaries performance and
                    dynamic decisions can be made based on pre-defined
                    performance criteria
                  </Text>
                </VStack>
              </Box>
            </Flex>
            <Flex
              flexDirection={{
                base: "column",
                md: "row",
              }}
              justifyContent="space-around"
            >
              <Box bg="white" w="300px" minH="320" m="4">
                <VStack my="4" space="5" alignItems="center">
                  <Box px="7" pt="4">
                    <Image source={Img3} size="md" />
                  </Box>
                  <Text bold fontSize="xl" color="primary.1000" px="4">
                    Purpose Specific Payments
                  </Text>
                  <Text px="7" pb="4" fontSize="md" textAlign="center">
                    Our partners provide state of the art payment solutions for
                    better control on how CSR funds are to be used.
                  </Text>
                </VStack>
              </Box>
              <Box bg="white" w="300px" minH="320" m="4">
                <VStack my="4" space="5" alignItems="center">
                  <Box px="7" pt="4">
                    <Image source={Img4} size="md" />
                  </Box>
                  <Text bold fontSize="xl" color="primary.1000" px="4">
                    Financial Literacy Solution
                  </Text>
                  <Text px="7" pb="4" fontSize="md" textAlign="center">
                    OptSociety has an in-built financial literacy solution to
                    help beneficiaries understand and imbibe financial wellness
                    practices in their behaviour
                  </Text>
                </VStack>
              </Box>
            </Flex>
          </Flex>
        </VStack>
      </Center>
    </section>
  );
}
export default Features;
