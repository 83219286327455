import React, { useEffect } from "react";
import {
  Box,
  Center,
  Flex,
  HamburgerIcon,
  Hidden,
  HStack,
  Text,
  VStack,
} from "native-base";

const Flexed = (props) => {
  return (
    <Flex direction="row" wrap>
      <Center bg="primary.100">
        <Text>Flex Aligned</Text>
      </Center>
      <Center bg="primary.300">
        <Text>Flex Aligned</Text>
      </Center>
      <Center bg="primary.100">
        <Text>Flex Aligned</Text>
      </Center>
      <Center bg="primary.100">
        <Text>Flex Aligned</Text>
      </Center>
      <Center bg="primary.300">
        <Text>Flex Aligned</Text>
      </Center>
      <Center bg="primary.100">
        <Text>Flex Aligned</Text>
      </Center>
    </Flex>
  );
};

const HStacked = (props) => {
  return (
    <HStack>
      <HStack bg="primary.200" w={"100%"}>
        <Center bg="primary.100" w="20%">
          <HStack>
            <Text>HStack Center Aligned</Text>
            <Text> - 20% Width</Text>
          </HStack>
        </Center>
        <Center bg="primary.300" w="60%">
          <HStack>
            <Text>HStack Center Aligned</Text>
            <Text> - 60% Width</Text>
          </HStack>
        </Center>
        <Center bg="primary.100" w="20%">
          <HStack>
            <Text>HStack Center Aligned</Text>
            <Text> - 20% Width</Text>
          </HStack>
        </Center>
      </HStack>
    </HStack>
  );
};

const Centered = (props) => {
  return (
    <Center bg="primary.100">
      <Center bg="primary.300" w={1024}>
        <HStack>
          <Text>width Hardcoded :</Text>
          <Text>1024 px (Center Aligned)</Text>
        </HStack>
      </Center>
    </Center>
  );
};

const Test = (props) => {
  return (
    <Center bg="primary.100">
      <Center
        w={{
          base: "90%",
        }}
        maxW={{
          base: "1024px",
        }}
      >
        <VStack bg="primary.200" w={"100%"}>
          <VStack bg="primary.500" w={"100%"}>
            <Center>
              <HStack>
                <Text bold>HEADER - width MAX Hardcoded :</Text>
                <Text bold>1024 px (Center Aligned)</Text>
              </HStack>
            </Center>
          </VStack>
          <Flex direction="row" justifyContent={"space-between"} px={5}>
            <Text bold>OptSociety</Text>
            <Text bold>My Menu on Right</Text>
          </Flex>

          <Flex direction="row" px={5} wrap="wrap">
            <Hidden till="lg">
              <VStack bg={"primary.300"} h={"100vh"} w={"250px"} p={3}>
                <Text>Menu 1</Text>
                <Text>Menu 1</Text>
                <Text>Menu 1</Text>
              </VStack>
            </Hidden>
            <Flex align="flex-start" direction="row" px={5} wrap="wrap">
              <Box
                bg="primary.500"
                p="4"
                m="2"
                shadow={2}
                _text={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                This is a Box
              </Box>
              <Box
                bg="primary.500"
                p="4"
                m="2"
                shadow={2}
                _text={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                This is a Box
              </Box>
              <Box
                bg="primary.500"
                p="4"
                m="2"
                shadow={2}
                _text={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                This is a Box
              </Box>
            </Flex>
          </Flex>
        </VStack>
      </Center>
    </Center>
  );
};

const BlankRow = (props) => {
  return (
    <HStack>
      <Text>Blank Row</Text>
    </HStack>
  );
};

const AAlignment = (props) => {
  return (
    <>
      <Centered />
      <BlankRow />
      <BlankRow />
      <HStacked />
      <BlankRow />
      <BlankRow />
      <Flexed />
      <BlankRow />
      <BlankRow />
      <Test />
    </>
  );
};

export default AAlignment;
