import React from "react";
import { Center, Flex, HStack, Text } from "native-base";
import Logo from "./Logo.png";
import LogoInv from "../../HomeComponents/NavBar/Logo-inverted-new.svg";

// import Logo from "./Optsociety-logo.svg";
// import LogoInv from "./Optsociety-ogo-nverted.svg";

const NzLogo = (props) => {
  if (props.variant === "inverted") {
    return (
      <Center w={"200px"} h={"80px"}>
        <img src={LogoInv} />
      </Center>
    );
  } else {
    return (
      <Center h={"80px"}>
        <img src={Logo} />
      </Center>
    );
  }
};

export default NzLogo;
