import React from "react";
import { Flex } from "native-base";

const NzFlexWrap = (props) => {
  return (
    <Flex
      direction="row"
      justifyContent={"space-between"}
      px="1"
      space="1"
      wrap="wrap"
    >
      {props.children}
    </Flex>
  );
};

export default NzFlexWrap;
