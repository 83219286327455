import React, { useEffect, useState } from "react";
import produce from "immer";
import {
  Button,
  Box,
  Center,
  Checkbox,
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  Heading,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { getStudent } from "../../actions";
import { DownArrow2, BadgeVerified } from "../../components/Icons/NzIcons";
import {
  Nz1024,
  NzBannerCard,
  NzFlexWrap,
  NzHeading,
  NzInput,
  NzLabel,
  NzSelect,
} from "../../components/NzComponents";
import { removeStudent } from "../../actions";

const StudentsList = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  // const [Items] = useState(props.data);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const [direction, setDirection] = useState(false);
  const [data, setData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    dispatch(getStudent({ id: auth.id, li: auth.pageLimit }));
  }, []);

  useEffect(() => {
    if (selectAll) {
      setSelected(data);
    } else {
      setSelected([]);
    }
  }, [selectAll]);
  useEffect(() => {
    if (students.lastKey) {
      setNextPage(true);
    } else {
      setNextPage(false);
    }
  }, [students]);

  useEffect(() => {
    let _students;
    if (direction === "down") {
      _students = students.Items.reverse();
    } else {
      _students = students.Items;
    }
    setData(_students);
  }, [students]);

  let total = 0;

  return (
    <Nz1024 bg="#efefe8">
      <NzBannerCard>
        <NzFlexWrap>
          <NzHeading>Students detail</NzHeading>
        </NzFlexWrap>
      </NzBannerCard>
      <Hidden from="md">
        <Pagination />
        <VStack
          w={"100%"}
          mt="4"
          justifyContent="space-around"
          alignItems="flex-start"
          borderBottomColor={"primary.500"}
          borderBottomWidth="12"
          borderTopColor="coolGray.200"
          borderTopWidth="1"
        >
          {data &&
            data.map((el, i) => {
              return (
                <Flex
                  direction="row"
                  justify="space-between"
                  align="center"
                  w="100%"
                  px="8"
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                  bg={i % 2 === 0 ? "white" : ""}
                >
                  <Box flexShrink="1" py="4" pr="2">
                    <Text>{el.v1}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v2}
                    </Text>
                    <Text>{el.v9}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v13}
                    </Text>{" "}
                    <Text>{el.v4}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v5}
                    </Text>
                    <Text>{el.v3}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v6}
                    </Text>
                    <Text>{el.v9}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v8}
                    </Text>
                    <Text>{el.v7}</Text>
                    <Text fontSize="xs" color="grey">
                      {el.v10}
                    </Text>
                  </Box>
                  <Flex direction="row" align="center">
                    <BadgeVerified size="xs" />
                  </Flex>
                </Flex>
              );
            })}
        </VStack>
        <Pagination />
      </Hidden>

      <Hidden till="md">
        <NzBannerCard>
          <Pagination />
          <HStack
            bg="primary.600"
            py="3"
            w={{
              base: "90%",
              lg: "999px",
            }}
            justifyContent="space-around"
            alignItems="flex-start"
            shadow={4}
          >
            <Box pl="5">
              <Checkbox
                isChecked={selectAll}
                onChange={(state) => {
                  setSelectAll(!selectAll);
                }}
              />
            </Box>
            <Box w={"7%"}>
              <Text pl="4" pr="1" bold color="white">
                First Name
              </Text>
            </Box>
            <Box w={"8%"}>
              <Text pl="4" pr="1" bold color="white">
                Last Name
              </Text>
            </Box>
            <Box w={"8%"}>
              <Text pl="4" pr="1" bold color="white">
                Email
              </Text>
            </Box>
            <Box w={"11%"}>
              <Text pl="4" pr="1" bold color="white">
                Phone Number
              </Text>
            </Box>
            <Box w={"7%"}>
              <Text pl="4" pr="1" bold color="white">
                Gender
              </Text>
            </Box>
            <Box w={"8%"}>
              <Text pl="4" pr="1" bold color="white">
                Aadhar Number
              </Text>
            </Box>
            <Box w={"8%"}>
              <Text pl="4" pr="1" bold color="white">
                Address
              </Text>
            </Box>
            <Box w={"7%"}>
              <Text pl="4" pr="1" bold color="white">
                Family Income
              </Text>
            </Box>
            <Box w={"7%"}>
              <Text pl="4" pr="1" bold color="white">
                Stream
              </Text>
            </Box>
            <Box w={"6%"}>
              <Text pl="4" pr="1" bold color="white">
                CGPA
              </Text>
            </Box>
            <Box w={"8%"}>
              <Text pl="4" pr="1" bold color="white">
                Mail Sent?
              </Text>
            </Box>
            <Box w={"9%"}>
              <Text pl="4" pr="1" bold color="white">
                Agreed?
              </Text>
            </Box>
          </HStack>

          <VStack
            w={{
              base: "90%",
              lg: "999px",
            }}
            justifyContent="space-around"
            alignItems="flex-start"
            shadow={8}
          >
            {data &&
              data.map((item, i) => {
                return (
                  <Item
                    el={item}
                    i={i}
                    onCheck={(checked) => {
                      select(checked, item);
                    }}
                    toggle={selectAll}
                  />
                );
              })}
          </VStack>
          <Pagination />
        </NzBannerCard>
      </Hidden>
    </Nz1024>
  );

  function select(checked, item) {
    if (checked) {
      //console.log('checked');
      setSelected(
        produce(selected, (draft) => {
          draft.push(item);
        })
      );
    } else {
      //console.log('not checked');
      setSelected(
        produce(selected, (draft) => {
          draft.splice(
            draft.findIndex((el) => el.i === item.i),
            1
          );
        })
      );
    }
  }

  function Pagination() {
    return (
      <HStack
        px="1"
        my="2"
        w={{
          base: "90%",
          lg: "999px",
        }}
        direction="row-reverse"
      >
        <HStack alignItems="center" space="4">
          <Button
            endIcon={<ChevronLeftIcon />}
            isDisabled={pageNumber === 1}
            variant="subtle"
            onPress={() => {
              dispatch(
                getStudent({
                  id: auth.id,
                  li: auth.pageLimit,
                  la: { id: auth.id, ky: data[0].ky },
                  desc: true,
                })
              );
              setDirection("down");
              setPageNumber(pageNumber - 1);
              setSelectAll(false);
            }}
          ></Button>
          <Box>{pageNumber}</Box>
          <Button
            endIcon={<ChevronRightIcon />}
            isDisabled={!nextPage}
            variant="subtle"
            onPress={() => {
              dispatch(
                getStudent({
                  id: auth.id,
                  li: auth.pageLimit,
                  la:
                    direction === "down"
                      ? { id: auth.id, ky: data[auth.pageLimit - 1].ky }
                      : students.lastKey,
                })
              );
              setDirection("up");
              setPageNumber(pageNumber + 1);
              setSelectAll(false);
            }}
          ></Button>
        </HStack>
      </HStack>
    );
  }
};

function Item({ el, i, onCheck, toggle }) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(toggle);
  }, [toggle]);
  return (
    <HStack width={"100%"} bg={i % 2 === 0 ? "white" : ""}>
      <Box borderBottomColor="coolGray.200" borderBottomWidth="1" p="4">
        <Checkbox
          isChecked={selected}
          onChange={() => {
            selected ? setSelected(false) : setSelected(true);
            selected ? onCheck(false) : onCheck(true);
          }}
        />
      </Box>
      <Box
        w={"7%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v1}
        </Text>
      </Box>
      <Box
        w={"8%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v2}
        </Text>
      </Box>
      <Box
        w={"8%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v4}
        </Text>
      </Box>
      <Box
        w={"11%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v5}
        </Text>
      </Box>
      <Box
        w={"7%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v3}
        </Text>
      </Box>
      <Box
        w={"8%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v6}
        </Text>
      </Box>
      <Box
        w={"8%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v7}
        </Text>
      </Box>
      <Box
        w={"7%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v8}
        </Text>
      </Box>
      <Box
        w={"7%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v9}
        </Text>
      </Box>
      <Box
        w={"6%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.v10}
        </Text>
      </Box>
      <Box
        w={"8%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.b1 ? "yes" : "no"}
        </Text>
      </Box>
      <Box
        w={"9%"}
        borderBottomColor="coolGray.200"
        borderBottomWidth="1"
        pl="4"
      >
        <Text px="1" my="4">
          {el.b2 ? "yes" : "no"}
        </Text>
      </Box>

      {/* <Box
  w={"10%"}
  borderBottomColor="coolGray.200"
  borderBottomWidth="1"
  pl="4"
>
  <Text px="1" my="4">
    {el.v5}
  </Text>
</Box> */}
    </HStack>
  );
}

export default StudentsList;
