import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'

const LevelOfAchievement = () => {
  const chartOptions = {
    title: {
      text: 'Progress',
    },
    subtitle: {
      text:'(Beneficiaries count)'
    },
    xAxis: {
      categories: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'], // Years
      title: {
        text: 'Year',
      },
    },
    yAxis: {
      title: {
        text: 'Number of Students',
      },
    },
    series: [
      {
        name: 'Program A',
        data: [120, 150, 180, 170, 200, 220, 240, 260, 280, 300], // Enrollment data for Program A
      },
      {
        name: 'Program B',
        data: [80, 95, 110, 105, 130, 145, 160, 175, 190, 210], // Enrollment data for Program B
      },
      {
        name: 'Program C',
        data: [100, 115, 130, 120, 140, 155, 170, 185, 200, 220], // Enrollment data for Program C
      },
    ],
    tooltip: {
      shared: true, // Show tooltip for all series when hovering over a point
      valueSuffix: ' students',
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          format: '{y}', // Label format
        },
        enableMouseTracking: true, // Enable tracking the mouse for tooltips
      },
    },
  };

    return (
      <div className='chart-container'>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    )
};

export default LevelOfAchievement
