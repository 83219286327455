import React, { useEffect } from "react";
import {
  Box,
  Center,
  Flex,
  Heading,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";

import { getUsers } from "../../actions";
import { DownArrow2 } from "../../components/Icons/NzIcons";

function ValidatorList(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  // const [Items] = useState(props.data);
  useEffect(() => {
    dispatch(getUsers("va"));
  }, []);

  return (
    <Center>
      <Heading
        mt={{
          base: "8",
          md: "16",
        }}
        mx="4"
        textAlign="center"
      >
        Validator List
      </Heading>
      <Hidden from="md">
        <VStack
          w={"100%"}
          mt="4"
          justifyContent="space-around"
          alignItems="flex-start"
          borderBottomColor={"primary.500"}
          borderBottomWidth="12"
          borderTopColor="coolGray.200"
          borderTopWidth="1"
        >
          {users.Items.map((el, i) => {
            return (
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                w="100%"
                px="8"
                borderBottomColor="coolGray.200"
                borderBottomWidth="1"
                bg={i % 2 === 0 ? "white" : ""}
              >
                <Box flexShrink="1" py="4" pr="2">
                  <Text>{el.v8}</Text>
                  <Text fontSize="xs" color="grey">
                    {el.lid}
                  </Text>
                </Box>
                <Pressable
                  onPress={() => {
                    return (
                      <HStack>
                        <Text>Hello</Text>
                      </HStack>
                    );
                  }}
                >
                  <DownArrow2 ml="2.5" size="xs" />
                </Pressable>
              </Flex>
            );
          })}
        </VStack>
      </Hidden>
      <Hidden till="md">
        <HStack
          bg="primary.600"
          px="1"
          py="3"
          mt="6"
          w={{
            base: "90%",
            lg: "1024px",
          }}
          justifyContent="space-around"
          alignItems="flex-start"
          shadow={4}
        >
          <Box w={"24%"}>
            <Text pl="4" pr="1" bold color="white">
              User ID
            </Text>
          </Box>
          <Box w={"20%"}>
            <Text px="1" bold color="white">
              Validator Name
            </Text>
          </Box>
          <Box w={"18%"}>
            <Text px="1" bold color="white">
              Contact Person Name
            </Text>
          </Box>
          <Box w={"15%"}>
            <Text px="1" bold color="white">
              Phone Number
            </Text>
          </Box>
          <Box w={"15%"}>
            <Text px="1" bold color="white">
              Location
            </Text>
          </Box>
          <Box w={"8%"}>
            <Text pl="1" pr="4" bold color="white">
              Pincode
            </Text>
          </Box>
        </HStack>
        <VStack
          w={{
            base: "90%",
            lg: "1024px",
          }}
          justifyContent="space-around"
          alignItems="flex-start"
          shadow={8}
          mb="24"
        >
          {users.Items.map((el, i) => {
            return (
              <HStack width={"100%"} bg={i % 2 === 0 ? "white" : ""}>
                <Box
                  w={"24%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                  pl="4"
                >
                  <Text px="1" my="4">
                    {el.lid}
                  </Text>
                </Box>
                <Box
                  w={"20%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v8}
                  </Text>
                </Box>
                <Box
                  w={"18%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v10}
                  </Text>
                </Box>
                <Box
                  w={"15%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v11}
                  </Text>
                </Box>
                <Box
                  w={"15%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text px="1" my="4">
                    {el.v13}
                  </Text>
                </Box>
                <Box
                  w={"8%"}
                  borderBottomColor="coolGray.200"
                  borderBottomWidth="1"
                >
                  <Text pl="1" pr="4" my="4">
                    {el.v9}
                  </Text>
                </Box>
              </HStack>
            );
          })}
        </VStack>
      </Hidden>
    </Center>
  );
}

export default ValidatorList;
