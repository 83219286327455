import React from 'react'

const TermsConditions = () => {
  return (
    <>
      <div className="container mb-2 p-2">
        <h1>Terms of Use (ToU)</h1>

        <p>
          This document is an electronic record in terms of Information Technology Act, 2000 (“IT
          Act, 2000”), the applicable rules thereunder and the provisions pertaining to electronic
          records in various statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not require any physical or
          digital signatures.
        </p>

        <p>
          This document is published in accordance with the provisions of Rule 3 (1) of the
          Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the
          rules and regulations, privacy policy and Terms of Use for access to or usage of{' '}
          <a href="https://www.OptShine.com">www.OptShine.com</a> website.
        </p>

        <p>
          The domain name <a href="https://www.OptShine.com">www.OptShine.com</a>, including the
          related website and mobile application (hereinafter referred to as “Platform”) is owned
          and operated by OptShine Digitech Solutions Private Limited (hereinafter referred to as
          'OptShine'), a company incorporated under the Companies Act, 1956, with its registered
          office at J-2/10, Khirki Extension, Malviya Nagar, Delhi, India.
        </p>

        <p>
          For the purpose of the Terms of Use (hereinafter referred to as “ToU”), wherever the
          context so requires, ‘you’ and ‘your’ shall relate to any natural or legal person who has
          agreed to become a sponsor on the Platform by providing registration data while
          registering on the Platform using computer systems. The word ‘user’ shall collectively
          imply a sponsor, an implementation agency, a partner or vendor, a beneficiary data
          provider or validator, and a beneficiary on the Platform and the terms ‘we’, ‘us’ and
          ‘our’ shall mean OptShine.
        </p>

        <p>
          Your use of the Platform and the features therein is governed by the following terms and
          conditions (ToU) including applicable policies available on the Platform, notifications
          and communications sent to you on the Platform which are incorporated herein by way of
          reference. If you are registered on the Platform, you shall be subject to the policies
          that are applicable to the Platform for such a transaction. By mere use of the Platform
          you shall be contracting with OptShine, and these terms and conditions including the
          policies constitute your binding obligations to OptShine.
        </p>

        <p>
          When you use any current or future services provided by us through the Platform you will
          be subject to the rules, guidelines, policies, terms and conditions applicable to such
          services and they shall be deemed incorporated into the ToU and considered a part and
          parcel of the ToU. We reserve the right, at our sole discretion, to change, modify, add,
          or remove portions of the ToU at any time. We will notify you through any of the
          communication modes as mentioned in this ToU in case of any changes or updates to the ToU
          that materially impact your use of the Platform. Your continued use of the Platform
          following the changes or updates will mean that you accept and agree to the revisions. As
          long as you comply with the ToU, we grant you a personal, non-exclusive, non-transferable,
          and limited privilege to enter and use the Platform.
        </p>

        <p>
          ACCESSING, BROWSING, OR OTHERWISE USING THE PLATFORM INDICATES YOUR AGREEMENT WITH ALL THE
          TERMS AND CONDITIONS UNDER THE TOU. PLEASE READ THE TOU CAREFULLY BEFORE PROCEEDING. By
          impliedly or expressly accepting the ToU, you also accept and agree to be bound by all of
          OptShine’s policies applicable to you, as amended, from time to time.
        </p>

        <h4>Sponsor Eligibility</h4>
        <p>
          The use of the Platform is available only to persons who can form legally binding
          contracts under the Indian Contract Act, 1872. Persons who are "incompetent to contract"
          within the meaning of the Indian Contract Act, 1872 including minors, un-discharged
          insolvents etc. are not eligible to use the Platform. If you register as a business
          entity, you represent that you are duly authorized by the business entity to accept the
          ToU and you have the authority to bind the business entity to the ToU.
        </p>
        <h4>Platform for Philanthropy/ Corporate Social Responsibility (CSR) Programs</h4>
        <p>
          The Platform is a platform that sponsors and implementation agencies utilize to create
          philanthropy/ CSR programs (hereinafter referred to as Programs) based on information
          provided or validated by partners or vendors and beneficiary data providers or validators
          independently. OptShine is not and cannot be a party to any transaction or dispute between
          users on the Platform.
        </p>

        <h4>Consequently:</h4>

        <ol>
          <li>
            All commercial/contractual terms are offered by you and agreed upon between you and
            users alone. The commercial/contractual terms include (without limitation) philanthropy
            amount, payment milestones, price, shipping costs, payment methods and terms, date,
            period, and mode of delivery, and warranties and after-sales services related to
            products and services. OptShine does not determine, advise, have any control, or in any
            way involve itself in the offering or acceptance of such commercial/contractual terms
            between you and users.
          </li>

          <li>
            OptShine does not make any representations or warranties regarding specifics (such as
            quality, value, etc.) of the products or services proposed to be offered to the
            beneficiaries of the programs. OptShine does not implicitly or explicitly support or
            endorse the sale or purchase of any products and services on the Platform. OptShine
            accepts no liability for any errors or omissions of third parties in relation to the
            products and services.
          </li>

          <li>
            OptShine is not responsible for any non-performance or breach of any contract between
            you and users or beneficiaries. OptShine cannot and does not guarantee that you and
            buyers concerned will perform transaction(s) concluded on the Platform.
          </li>

          <li>
            OptShine follows a basic due diligence mechanism before onboarding users on the platform
            but does not make any representations or warranties regarding item-specifics of any of
            its users.
          </li>

          <li>
            OptShine does not at any point in time during a transaction between you and a user on
            the Platform come into or take possession of any of the products or services offered by
            vendors, gain title to or have any rights or claims over the products or services
            offered by vendor to the beneficiaries.
          </li>

          <li>
            At no time shall OptShine hold any right/title to or interest in the items nor have any
            obligations or liabilities with respect to such a contract. OptShine is not responsible
            for unsatisfactory or delayed performance of services, damages, or delays.
          </li>

          <li>
            The Platform is only a platform that can be utilized by you to create your programs by
            selecting products and services offered by vendors registered on the platform for the
            benefit of identified beneficiaries. OptShine only provides a platform for facilitating
            programs and it is agreed that the contract for sale of any products or services shall
            be a strictly bipartite contract between you and the vendor.
          </li>

          <li>
            You release and indemnify OptShine and/or any of its officers and representatives from
            any cost, damage, liability or other consequence of any of your actions of on the
            Platform and specifically waive any claims that you may have in this behalf under any
            applicable law. Notwithstanding its reasonable efforts on that behalf, OptShine cannot
            control the information provided by other users which is made available on the Platform.
            You may find other user's information to be offensive, harmful, inaccurate or deceptive.
            Please use caution and practice safety when using the Platform.
          </li>
        </ol>

        <h4>Use of the Platform</h4>
        <p>
          You agree and understand that OptShine and the Platform merely facilitates creation of
          programs by sponsors and implementation agencies.{' '}
        </p>

        <p>
          All items listed and the contents therein are listed by registered users and are third
          party user generated contents. OptShine shall bear no responsibility or liability in
          relation to or arising out of third party user generated content. OptShine neither
          originates nor initiates the transmission nor selects the sender and receiver of the
          transmission nor selects nor modifies the information contained in the transmission.
          OptShine is merely an intermediary and does not interfere in the transaction between
          users.
        </p>

        <p>
          You agree, undertake and confirm that your use of the Platform shall be strictly governed
          by the following binding principles:
        </p>

        <ol>
          <li>
            You shall not use any ‘deep-link’, ‘page-scrape’, ‘robot’, ‘spider’, automatic device,
            software program, algorithm, methodology, or any similar or equivalent manual process to
            access, acquire, copy, monitor any portion of the Platform or content or in any way
            reproduce, or circumvent the navigational structure, presentation of the Platform, or
            any content to obtain or attempt to obtain any material, documents, or information
            through any means not purposely made available through the Platform. We reserve our
            right to bar any such activities.
          </li>

          <li>
            You shall not attempt to gain unauthorized access to any portion or feature of the
            Platform, other systems, networks connected to the Platform, server, computer, network,
            or the services offered on or through the Platform by hacking, password ‘mining’, or any
            other illegitimate means.
          </li>

          <li>
            You shall not probe, scan or test the vulnerability of the Platform or any network
            connected to the Platform or breach the security, authentication measures on the
            Platform or any network connected to the Platform. You may not reverse look-up, trace or
            seek to trace information on any other user of or visitor to Platform (including any
            account on the Platform that is not owned by you) or to its source or exploit the
            Platform, any service, information made available, or offered by or through the Platform
            in any way where the purpose is to reveal any information (including but not limited to
            personal identification or information other than your own information) provided by the
            Platform.
          </li>

          <li>
            You shall not make any negative, denigrating, or defamatory statement(s)/ comment(s)
            about us, the brand name or domain name used by us, including the terms OptShine,
            OptShine or otherwise engage in any conduct or action that might tarnish the image or
            reputation of OptShine or users on the platform or otherwise tarnish or dilute any
            OptShine trademark, service marks, trade name and/or goodwill associated with such
            trade, service marks or trade name as may be owned or used by us. You agree that you
            will not take any action that imposes an unreasonable or disproportionately large load
            on the infrastructure of the Platform or OptShine’s systems, networks, or any systems or
            networks connected to OptShine.
          </li>

          <li>
            You agree not to use any device, software or routine to interfere or attempt to
            interfere with the proper working of the Platform, any transaction being conducted on
            the Platform or any other person’s use of the Platform.
          </li>

          <li>
            You shall not forge headers or otherwise manipulate identifiers in order to disguise the
            origin of any message, transmittal you send to us on or through the Platform, or any
            service offered on or through the Platform. You may not pretend that you are or
            represent someone else or impersonate any other individual or entity.
          </li>

          <li>
            You may not use the Platform or any content for any purpose that is unlawful or
            prohibited by the ToU or to solicit the performance of any illegal activity or other
            activity which infringes the rights of OptShine and/or others.
          </li>

          <li>
            You shall at all times ensure full compliance with the applicable provisions of the
            Information Technology Act, 2000, and the other rules thereunder as applicable and
            amended from time to time and also all applicable domestic laws, rules and regulations
            (including the provisions of any applicable Exchange Control Laws or Regulations in
            force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and
            Regulations and obtain the necessary licences and permits regarding your use of our
            Platform. You shall not engage in any transaction, which is prohibited by the provisions
            of any applicable law including but not limited to exchange control or money laundering
            laws or regulations for the time being in force.
          </li>

          <li>
            Solely to enable us to use the information you provide us with and so that we do not
            violate any rights you might have in your information, you agree to grant us a
            non-exclusive, worldwide, perpetual, irrevocable, royalty-free, and sub-licensable
            (through multiple-tiers) right to exercise the copyright, publicity or database rights
            or any other rights you have in your information for any current or future promotional
            or advertising related activities and any business purposes of OptShine.
          </li>

          <li>
            It is possible that other users (including unauthorized persons or ‘hackers’) may post
            or transmit offensive or obscene material on the Platform and that you may be
            involuntarily exposed to such material. It is also possible for others to obtain
            personal information about you due to your use of the Platform and use such information
            to harass or injure you. We do not approve of such unauthorized uses but by using the
            Platform, you acknowledge and agree that we are not responsible for the use of any
            personal information that you publicly disclose or share with others on the Platform.
            Please carefully select the type of information that you publicly disclose or share with
            others on the Platform.
          </li>

          <li>
            OptShine shall have all the rights to take necessary action and claim damages that may
            occur due to your involvement/participation in any way on your own or through group(s)
            of people, intentionally or unintentionally, in DoS (denial of service) / DDoS
            (Distributed Denial of Services).
          </li>

          <h4>Your Account and Registration Obligations</h4>
          <p>
            In the course of your use of the Platform, you agree to furnish your details and
            information as requested by us from time to time and honor your commitments under your
            programs. You shall remain responsible for maintaining confidentiality of this
            information, as well as your display name, login and password details. You agree that if
            you provide any information which is untrue, inaccurate, not current, or incomplete or
            we have reasonable grounds to suspect that such information is untrue, inaccurate, not
            current, incomplete, or not in accordance with the ToU, we shall have the right to
            suspend or terminate your account on the Platform or indefinitely block you from
            accessing the Platform.
          </p>

          <h4>Sponsor Account Deactivation</h4>
          <p>
            As per our policy, if a sponsor requests for a closure of the account, the account will
            be put on hold for a period of 90 days to ensure smooth closure of transactions which
            have been made prior to his closure request and this will be available to the said
            sponsor for downloading details of programs created on the platform, if required. After
            90 days, the sponsor’s account will be deactivated provided there are no open dispute
            regarding sponsorship amount committed by the sponsor, with certain information being
            retained by OptShine at all times, such as registered mobile number, registered email ID
            and other transaction related information. Such information is being retained for audit
            purposes and to prevent fraudulent acts on the platform. If a sponsor decides to
            associate with OptShine again, he/she will not be able to create a new account but the
            older account can be restored if it is required.
          </p>

          <h4>Communications</h4>
          <p>
            When you use the Platform or send emails, other data, information, or communication to
            us, you agree and understand that you are communicating with us through electronic
            records and you consent to receive communications via electronic records from us
            periodically or as and when required. We may communicate with you by email or any other
            mode of communication, electronic or otherwise.
          </p>

          <h4>Charges</h4>
          <p>
            Registration on the Platform is free. OptShine does not charge any fee for registering
            on the Platform. However, before you create a program on the Platform, we request you to
            review our fee policy, which is hereby incorporated by reference into this ToU. OptShine
            reserves the right to change its fee policy from time to time. In particular, OptShine
            may, at its sole discretion, introduce new services and modify some or all of the
            existing services offered on the Platform. In such an event, OptShine reserves the right
            to introduce fees for the new services offered or amend/introduce fees for existing
            services, as the case may be. Changes to the fee policy shall be posted on the OptShine
            and such changes shall automatically become effective immediately after they are posted
            on the Platform. Unless otherwise stated, all fees shall be quoted in Indian Rupees
            (INR) and payable to OptShine. You shall be solely responsible for compliance with all
            applicable laws for making payments to OptShine. You hereby agree that OptShine shall
            have the right to set off any amounts due and payable by You to OptShine against any
            payments due from OptShine to You.
          </p>

          <p>
            GST /Taxes: You are responsible for paying all fees associated with the use of the
            Platform and charges collected in respect of such usage. You agree to bear any and all
            applicable taxes, charges, cesses levied thereon (including CGST+SGST/IGST/CGST+UGST and
            GST cess as may be applicable to the transaction).
          </p>

          <ol>
            <li>
              Sponsors acknowledge that OptShine will not be liable for any damages, interests,
              claims etc. resulting from not processing a transaction or any delay in processing a
              transaction that is beyond the control of OptShine.
            </li>
          </ol>
          <span>Compliance with Laws:</span>
          <ol>
            <li>
              Sponsor confirms that they have obtained and will continue to maintain in force all
              the necessary licenses, permissions, authorizations, and permits needed to run
              programs on the platform under applicable laws, from time to time.
            </li>

            <li>
              Sponsor shall comply with all laws and regulations applicable, including but not
              limited to anti-money laundering (including ‘know your customer’ and ‘customer due
              diligence’), and economic sanctions laws and regulations. Neither party will engage in
              a transaction pursuant to this ToU that will cause the other party to violate such
              regulations.
            </li>

            <li>
              If and to the extent that you collect, access, use, store, record, or otherwise
              process (collectively “Process”) any personally identified or identifiable information
              such as name, age, gender, email address, physical address, phone number, in any form
              that can be linked to a specific individual (“Personal Information”) received by you
              from or on behalf of OptShine (or any OptShine group company) employees, contractors,
              users, partners, or other third parties or otherwise obtained in connection with
              performing your obligations under this ToU, you agree to:
            </li>
            <ol style={{ 'list-style-type': 'lower-alpha' }}>
              <li>
                comply with applicable data protection laws, rules and regulations governing the
                collection, use, protection, breach notification, retention, disclosure of Personal
                Information including but not limited to Information Technology Act, 2000 and the
                Information Technology (Reasonable Security practices and procedures and sensitive
                personal data or information) Rules, 2011 (“Applicable Data Protection Law”),
                including any requirements applicable to storage or cross-border transfer of
                Personal Information outside India;
              </li>
              <li>
                keep and maintain all OptShine’s Personal Information in strict confidence and the
                obligation to protect Personal Information shall survive in perpetuity; and
              </li>
              <li>
                process OptShine’s Personal Information solely to fulfil Your obligations under this
                ToU and not sell, rent, trade, lease, use for its own advertisement or marketing
                purposes, or otherwise make an unauthorized disclosure of OptShine’s Personal
                Information to any third party.
              </li>

              <li>
                If and to the extent that you provide yours or others’ Personal Information to
                OptShine for processing, you warrant that such Personal Information was collected
                lawfully, and there is no restriction on you under the Applicable Data Protection
                Law, from providing such Personal Information to OptShine or any processing by
                OptShine under this ToU. We will only use information provided by you in accordance
                with this ToU and our Privacy Policy available at www.OptShine.com.
              </li>
            </ol>
          </ol>
        </ol>
        <h4>Product Description</h4>
        <p>
          OptShine does not warrant that product description or other content on the Platform is
          accurate, complete, reliable, current, or error-free and assumes no liability in this
          regard.
        </p>

        <h4>Refund of amount paid under programs</h4>
        <p>
          Credit of the benefit amount under the program is linked to achievement of payment
          milestones defined under the program. In case, the payment milestone is not achieved
          within 30 working days of release of benefit payment by the sponsor, OptShine shall make
          payments towards refund of benefit money not released to the beneficiaries into the bank
          account provided by a sponsor during the sponsor registration process. Once OptShine has
          made payments into such a bank account number, OptShine shall be discharged of any/all
          liabilities towards the sponsor and the sponsor shall not be eligible for any claims
          whatsoever.
        </p>

        <h4>Breach</h4>
        <p>
          Without limiting other remedies, we may limit your activity, immediately remove your
          information, warn other users of your actions immediately, temporarily/indefinitely
          suspend/terminate/block your account and/or refuse you access to the Platform, or put your
          account on hold, in the event of, including but not limited to, the following:
        </p>
        <ol>
          <li>if you breach the ToU, privacy policy or other policies (if any);</li>
          <li>if we are unable to verify or authenticate any information you provide; </li>
          <li>
            if it is believed that your actions may cause legal liability for you, other users, or
            us; or
          </li>
          <li> if you do not produce the legal requirement documents.</li>
        </ol>
        <p>
          We may at any time, at our sole discretion, reinstate suspended sponsors. A sponsor that
          has been suspended or blocked may not register or attempt to register with us or use the
          Platform (through itself or any other entity or legal form) in any manner whatsoever until
          such time that such a sponsor is reinstated by us. Notwithstanding the foregoing, if you
          breach the ToU or other rules and policies, we reserve the right to recover any amounts
          due and owed by you to us and take strict legal action, including but not limited to a
          referral to the appropriate police or other authorities for initiating criminal or other
          proceedings against you.
        </p>

        <h4>Indemnity</h4>
        <p>
          You shall indemnify and hold harmless OptShine its owner, licensee, affiliates,
          subsidiaries, group companies (as applicable) and their respective officers, directors,
          partners, agents, and employees from any claim, demand, or actions including reasonable
          attorneys' fees made by any third party or penalty imposed due to or arising out of your
          breach of the ToU, privacy policy and other policies or your violation of any law, rules,
          regulations or the rights (including infringement of intellectual property rights) of a
          third party.
        </p>

        <h4>Limitation of Liability</h4>
        <p>
          IN NO EVENT SHALL OPTSHINE BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR
          CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THE TOU, EVEN IF OPTSHINE HAS BEEN
          INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h4>Applicable Law</h4>
        <p>
          The ToU shall be governed, interpreted, and construed in accordance with the laws of
          India. The place of jurisdiction shall exclusively be Delhi.
        </p>

        <h4>Contact Us</h4>
        <p>
          Please send any comments or questions, including all enquiries not related to trademark
          and copyright infringement, by sending an email on info@OptShine.com.
        </p>

        <h4>Grievance Officer</h4>
        <p>
          In accordance with the IT Act, 2000, and the rules thereunder, the name and contact
          details of the grievance officer are provided below:
        </p>


      </div>
    </>
  )
}

export default TermsConditions
