import React, { useState, useEffect } from "react"
import { Card, Row, Col, Form, Button, Modal } from "react-bootstrap"
import { baseURL } from "../config/baseURLPath"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"
import "./sponsorcss.css"
import swal from "sweetalert2";
const Add = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    sponsor_name: "",
    sponsor_catg_type: "",
    sponsor_email: "",
    sponsor_phone: "",
    city_id: "",
    pin_code: "",
    state_id: "",
    country_id: "1",
    address: "",
    site_logo: "",
    display_status: "",
    program_type: "",
    admin_name:"",
    gender:"Male",
    password:"",
    confirm_password:"",
    role_id: "1",
    finance_email:"",
    terms:false
  })

  const handleChange = (e) => {
    const { name, value } = e.target

    if(name === "terms"){
      console.log(e.target.checked);
      setValues((prev) => ({
        ...prev,
        [name]: e.target.checked,
      
      }))
      
    } else{

    
      if(name === "state_id"){
        setValues((prev) => ({
          ...prev,
          [name]: value,
          ["city_id"]:""
        }))
        fetchCities(value)
      }else{
        setValues((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    }
  
    
  }

  useEffect(()=>{
    fetchState();
    fetchRoles();
  }, [])

  const [roles, setRoles] = useState([])
  const fetchRoles = () => {
    axios
      .get(baseURL + "getUserRoles")
      .then((response) => {
        // console.log(response);
        setRoles(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [stateList, setStateList] = useState([])
  const fetchState = () => {
    axios
      .get(baseURL + "getStateList")
      .then((response) => {
        console.log(response,"response")
        setStateList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  // City List
  const [cityList, setCityList] = useState([])
  const fetchCities = (state_id) => {
    axios
      .get(baseURL + "getCity/" + state_id)
      .then((response) => {
        setCityList(response.data.Content)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const validatePhoneNumber = (input_str) => {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(input_str);
  }

  const validatePincode = (input_str) => {
    if(input_str === ""){
      return true;
    }
    var re = /^[1-9][0-9]{5}$/;

    return re.test(input_str);
  }

  const successAction = (e) => {
    e.preventDefault()
    if (
     
      values.sponsor_name.trim() === "" ||
      values.sponsor_catg_type.trim() === "" ||
      values.site_admin_email.trim() === "" ||
      values.site_admin_phone.trim() === "" ||
      values.address.trim() === "" ||
      // values.city_id.trim() === '' ||
      values.state_id.trim() === "" ||
      values.password.trim() === "" ||
      values.finance_email.trim() === ""
    ) {
      alert("Please fill all required fields.")
    } else if(!validateEmail(values.site_admin_email.trim())){
      alert("Please add valid email address.");
    } else if(!validatePhoneNumber(values.site_admin_phone.trim())){
      alert("Please add valid mobile number.");
    } else if(values.password.trim().length < 8 ){
      alert("Minimum 8 character password required")  
    } else if(values.password.trim() !== values.confirm_password.trim()){
      alert("Password not matching")  
    } else if(!validateEmail(values.finance_email.trim())){
      alert("Please add valid email address.");
    } else if(!validatePincode(values.pin_code.trim())){
      alert("Please add a valid pincode.");
    } else if(!values.terms){
      alert("Please accept the terms and condiotions .");
    }
    else {
      let req = {
        sponsor_name: values.sponsor_name.trim(),
        sponsor_catg_type: values.sponsor_catg_type.trim(),
        sponsor_email: values.site_admin_email.trim(),
        sponsor_phone: values.site_admin_phone.trim(),
        city_id: values.city_id.trim(),
        pin_code: values.pin_code.trim(),
        state_id: values.state_id.trim(),
        country_id: 1,
        address: values.address.trim(),
        site_logo: "",
        display_status: "",
        program_type: "",
        role_id: 1,
        admin_name: values.admin_name.trim(),
        admin_email: values.site_admin_email.trim(),
        password: values.password.trim(),
        mobile_number: values.site_admin_phone.trim(),
        sponsor_id: "",
        gender: values.gender,
        finance_email:values.finance_email.trim()
      }

      axios({
        method: "POST",
        url: baseURL + "auth/sponsorSignup",
        data: req,
        // headers: { 'access-token': localStorage.getItem('csr_token') },
      })
        .then((response) => {
          // console.log(response.data)
          if(response.data.Status === 400){
            alert("Email/Mobile number is already registered. Please try with some other.")
          }else{
            new swal({
              title: "Done!",
              text: "Registered successfully",
              icon: "success",
              timer: 2000,
              button: false
            }).then((result) => {
              history.push("/login")
            })
          }
          
        })
        .catch(function (error) {
          if (error.response) {
            alert(error.response.data.Message)
          }
        })
    }
  }
 
  return (
    <div className="container" style={{paddingTop:"30px"}}>
      <a 
        href="/"
        style={{
          position: "fixed",
          right: "20px",
          top: "20px",
          fontSize: "16px",
          color: "rgb(126, 34, 206)",
          zIndex:9999
        }}
      >Back to Home</a>
      <Row>  
        <Col md={3}></Col>
        <Col md={6}>     
          <form onSubmit={successAction}>
            <Card>
              <div>
                <div style={{backgroundColor:"#4c2190", padding:"10px 20px", color:"#ffffff", fontWeight:"bold", fontSize:"20px"}}>
                Register as Sponsor
                </div>
              </div>
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <span style={{fontSize:"13px"}}>Sponsor Name <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="sponsor_name"
                        placeholder=""
                        value={values.sponsor_name}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
            
                  {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="site_admin_email"
                  placeholder="Site Admin Email *"
                  value={values.site_admin_email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="site_admin_phone"
                  placeholder="Site Admin Phone *"
                  value={values.site_admin_phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col> */}
                  <Col md={12}>
                    <span style={{fontSize:"13px"}}>Address <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        // type="text"
                        as="textarea" rows={3}
                        name="address"
                        placeholder=""
                        value={values.address}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Select State <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Select name="state_id" value={values.state_id} onChange={handleChange}>
                        <option value="">Select</option>
                        {stateList?.map((stateids) => (
                          <option value={stateids.state_id} key={stateids.state_id}>
                            {stateids.state_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>City</span>    
                    <Form.Group className="mb-3">
                      <Form.Select name="city_id" value={values.city_id} onChange={handleChange}>
                        <option value="">Select</option>
                        {cityList?.map((item) => (
                          <option value={item.city_id} key={item.city_id}>
                            {item.city_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Select Sponsor Category Type <span style={{color:"#ff0000"}}>*</span></span>        
                    <Form.Group className="mb-3">
                      <Form.Select name="sponsor_catg_type" value={values.sponsor_catg_type} onChange={handleChange}>
                        <option value="">Select</option>
                        <option value="NGO">NGO</option>
                        <option value="Corporate">Corporate</option> 
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Pin Code</span>
                    <Form.Group className="mb-3">
                      <Form.Control 
                        type="text"
                        name="pin_code"
                        maxLength={6}
                        placeholder=""
                        value={values.pin_code}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Select name="display_status" value={values.display_status} onChange={handleChange}>
                  <option value="">Display Status</option>
                  <option value="1">Active</option>
                  <option value="0">In-active</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
                </Row>
                <Row style={{borderTop:"1px solid #ccc", paddingTop:"15px"}}>
                  {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Select name="role_id" value={values.role_id} onChange={handleChange}>
                  <option value="">Select Role</option>
                  {roles?.map((role) => (
                    <option value={role.role_id} key={role.role_id}>
                      {role.role_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col> */}
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Name of contact person <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        name="admin_name"
                        placeholder=""
                        value={values.admin_name}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Email of the contact person <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        name="site_admin_email"
                        placeholder=""
                        value={values.site_admin_email}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Mobile of the contact person <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="number"
                        maxLength={10}
                        name="site_admin_phone"
                        placeholder=""
                        value={values.site_admin_phone}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Select name="gender" value={values.gender} onChange={handleChange}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Email of the finance department <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        name="finance_email"
                        placeholder=""
                        value={values.finance_email}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Password <span style={{color:"#ff0000"}}>*</span></span>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder=""
                        value={values.password}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Confirm Password <span style={{color:"#ff0000"}}>*</span></span>  
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        placeholder=""
                        value={values.confirm_password}
                        onChange={handleChange}
                        autoComplete={"off"}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <span style={{fontSize:"13px"}}>Nature of CSR Program</span>
                    <Form.Group className="mb-3">
                      <Form.Select name="display_status" value={values.display_status} onChange={handleChange}>
                        <option value="" disabled>Nature of CSR Program</option>
                        <option selected value="0">Skilling</option>
                        <option value="1">Scholorship</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
              
            
                    {/* <Form.Group  >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <input className="form-check-input" id="checktems" type="checkbox" name="terms" onChange={handleChange} />
                        <label className="form-check-label" htmlFor="checktems" style={{ width: "140%", marginLeft: "4px" }}>I agree to terms and conditions</label>
                      </div>
                    </Form.Group> */}

                      <Form.Group>
                      <div
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <input
                          className="form-check-input"
                          id="checkTerms"
                          type="checkbox"
                          name="terms"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="checkTerms"
                          style={{ width: '100%', marginLeft:'3px' }}
                        >
                          I have read and agree to&nbsp;
                          <a
                            href="/terms-condition"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of use
                          </a>
                          &nbsp;and&nbsp;
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
      
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", paddingTop:"15px"}}>
              <Button type="submit" className="primaryAdminButton" >REGISTER</Button>
            </div>
          </form>
        </Col>
        <Col md={3}></Col>
      </Row>

    </div>
  )
}

export default Add
