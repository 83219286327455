// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --heading:  Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --body: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --purple: #002347;
}

*,
::before,
::after {
  box-sizing: bounding-box;
}

img,
a {
  text-decoration: none;
  outline-style: none;
}

ul {
  list-style: none;
}

body,
main {
  background-color: rgba(0, 0, 0, 0.02);
  margin: 0px;
  font-family: var(--body);
}

h1 {
  font-family: var(--heading);
  font-size: 24px;
}

p {
  font-family: var(--body);
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Home/style.css"],"names":[],"mappings":"AAAA;EACE;wEACsE;EACtE;wDACsD;EACtD,iBAAiB;AACnB;;AAEA;;;EAGE,wBAAwB;AAC1B;;AAEA;;EAEE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,qCAAqC;EACrC,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[":root {\n  --heading:  Montserrat, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto,\n    Oxygen, Ubuntu, Cantarell, \"Open Sans\", \"Helvetica Neue\", sans-serif;\n  --body: Avenir, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen, Ubuntu,\n    Cantarell, \"Open Sans\", \"Helvetica Neue\", sans-serif;\n  --purple: #002347;\n}\n\n*,\n::before,\n::after {\n  box-sizing: bounding-box;\n}\n\nimg,\na {\n  text-decoration: none;\n  outline-style: none;\n}\n\nul {\n  list-style: none;\n}\n\nbody,\nmain {\n  background-color: rgba(0, 0, 0, 0.02);\n  margin: 0px;\n  font-family: var(--body);\n}\n\nh1 {\n  font-family: var(--heading);\n  font-size: 24px;\n}\n\np {\n  font-family: var(--body);\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
