import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import NzHeaderAdmin from "../components/NzComponents/NzHeaderAdmin";
import AAlignment from "../screens/AAlignment";
// import Admin from "../screens/Admin";
import AgencyList from "../screens/AgencyList";
import Courses from "../screens/Courses";
import ProgramCriteria from "../screens/ProgramCriteria";
import DashBoard from "../screens/DashBoard";
import Home from "../screens/Home";
import LeadList from "../screens/LeadList";
import Login from "../screens/Login";
import Logout from "../screens/Logout";
import Fpassword from "../screens/Fpassword";
import Notification from "../screens/Notification/notification";
import ProgramAdd from "../screens/ProgramAdd";
import ProgramDetail from "../screens/ProgramDetail";
import ProgramSummary from "../screens/ProgramSummary";
import RegisterAgency from "../screens/RegisterAgency";
import RegisterContact from "../screens/RegisterContact";
import RegisterSponsor from "../screens/RegisterSponsor";
import Register from "../screens/Register";
import RegisterUser from "../screens/RegisterUser";
import RegisterVendor from "../screens/RegisterVendor";
import RegisterValidator from "../screens/RegisterValidator";
import SponsorList from "../screens/SponsorList";
import StudentList from "../screens/StudentList";
import UserLayout from "../screens/UserLayout";
import UserList from "../screens/UserList";
import ValidatorList from "../screens/ValidatorList";
import VendorList from "../screens/VendorList";
import VDashBoard from "../screens/VDashBoard";

// New Admin URLs
import Admin from "../admin";

// Sponsor
import Sponsor from "../sponsor";
// Vendor
import Vendor from "../vendor";
// Validator
import Validator from "../validator";
import TermsConditions from "../admin/pages/manage-users/terms-sponsor";
import Privacy from "../admin/pages/manage-users/privacy-sponsor";
import BeneficiaryTermsCondition from "../beneficiary-terms-condition";

const AdminLayout = (props) => (
  <>
    <NzHeaderAdmin {...props} />
    {props.children}
  </>
);

const AdminLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminLayout {...props}>
          <Component {...props} />
        </AdminLayout>
      )}
    />
  );
};

const UserLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <UserLayout {...props}>
          <Component {...props} />
        </UserLayout>
      )}
    />
  );
};

export default  () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Home {...props} />} />
      <Route
        path="/aalign"
        exact
        render={(props) => <AAlignment {...props} />}
      />
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/logout" exact render={(props) => <Logout {...props} />} />
      <Route
        path="/fpassword"
        exact
        render={(props) => <Fpassword {...props} />}
      />
      <Route
        path="/registercontact"
        exact
        render={(props) => <RegisterContact {...props} />}
      />
      <Route
        path="/register"
        exact
        render={(props) => <Register {...props} />}
      />
      <Route
        path="/registeruser"
        exact
        render={(props) => <RegisterUser {...props} />}
      />
      <Route
        path="/admin"
        render={(props) => <Admin {...props} />}
      />
      <Route
        path="/sponsor"
        render={(props) => <Sponsor {...props} />}
      />
      {/* <Route
        path="/vendor"
        render={(props) => <Vendor {...props} />}
      /> */}

      <Route
        path="/service-partner"
        render={(props) => <Vendor {...props} />}
      />
      <Route
        path="/validator"
        render={(props) => <Validator {...props} />}
      />

      <Route
        path="/terms-condition"
        render={(props) => <TermsConditions {...props} />}
      />

       <Route
        path="/privacy-policy"
        render={(props) => <Privacy {...props} />}
      />

      <Route 
        path="/beneficiary-terms-condition"
        render={(props) => <BeneficiaryTermsCondition {...props} />}
      />
      
      {/* <AdminLayoutRoute path="/admin" component={Admin} /> */}
      <AdminLayoutRoute path="/leadlist" component={LeadList} />
      <AdminLayoutRoute path="/agencyList" component={AgencyList} />
      <AdminLayoutRoute path="/sponsorList" component={SponsorList} />
      <AdminLayoutRoute path="/userlist" component={UserList} />
      <AdminLayoutRoute path="/validatorList" component={ValidatorList} />
      <AdminLayoutRoute path="/vendorList" component={VendorList} />
      <AdminLayoutRoute path="/notification" component={Notification} />
      <UserLayoutRoute path="/courses" component={Courses} />
      <UserLayoutRoute path="/dashboard" component={DashBoard} />
      {/* <UserLayoutRoute path="/program" component={ProgramAdd} /> */}
      <UserLayoutRoute path="/criteria" component={ProgramCriteria} />
      <UserLayoutRoute path="/detail" component={ProgramDetail} />
      <UserLayoutRoute path="/summary" component={ProgramSummary} />
      <UserLayoutRoute path="/registeragency" component={RegisterAgency} />
      <UserLayoutRoute path="/registersponsor" component={RegisterSponsor} />
      <UserLayoutRoute path="/registervendor" component={RegisterVendor} />
      <UserLayoutRoute path="/vdashboard" component={VDashBoard} />
      <UserLayoutRoute
        path="/registervalidator"
        component={RegisterValidator}
      />
      <UserLayoutRoute path="/studentList" component={StudentList} /> 
      
      
    </Switch>
  </BrowserRouter>
);
