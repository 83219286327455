import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactLink } from "react-router-dom";
import { login, reEnter } from "../../actions";
import { baseURL, walletBaseURL } from "../../config/baseURLPath";
import { loginSuccess } from "../../actions";

import {
  Avatar,
  Box,
  Center,
  CircleIcon,
  Divider,
  Heading,
  Hidden,
  Image,
  Icon,
  Stack,
  StatusBar,
  Text,
  VStack,
  useBreakpointValue,
  HStack,
  Button,
  IconButton,
} from "native-base";

import { Eye, EyeSlash } from "../../components/Icons/NzIcons";
import { NzButton, NzInput } from "../../components/NzComponents";
import NzlogoHeader from "../../components/NzComponents/NzlogoHeader";
import Swal from "sweetalert2";

function LoginForm(props) {
  const otpRef2 = useRef(null);
  const otpRef3 = useRef(null);
  const otpRef4 = useRef(null);

  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const dispatch = useDispatch();
  const form1 = useRef(null);

  const auth = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [registeredEmail, setRegisteredEmail] = useState("");
  const [otp1, setOTP1] = useState("");
  const [otp2, setOTP2] = useState("");
  const [otp3, setOTP3] = useState("");
  const [otp4, setOTP4] = useState("");
  const [otp5, setOTP5] = useState("");
  const [otp6, setOTP6] = useState("");

  const [useremail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetUserId, setResetUserId] = useState(0);
  // console.log(auth)

  useEffect(() => {
    if (otp1.length === 1) {
      console.log(otpRef2);
    }
  }, [otp1]);

  useEffect(() => {}, [otp2]);

  useEffect(() => {}, [otp3]);

  useEffect(() => {
    if (auth.isLoggedIn === true) {
      if (props.userType === "sp") {
        localStorage.setItem("userType", "sponsor");
        props.history.push("/sponsor/dashboard");
      } else if (props.userType === "tr") {
        localStorage.setItem("userType", "vendor");
        props.history.push("/service-partner/dashboard");
      } else if (props.userType === "va") {
        localStorage.setItem("userType", "validator");
        props.history.push("/validator/dashboard");
      } else {
        props.history.push("/vdashboard");
      }
    }
  }, [auth]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    let data = {
      lid: useremail,
      p1: password,
      userType: props.userType,
    };

    let sponsorData = {
      sponsor_email: useremail,
      password: password,
    };

    if (props.userType === "sp") {
      axios
        .post(baseURL + "auth/sponsorSignin", sponsorData)
        .then((response) => {
          if (response.data.Status === 200) {
            localStorage.setItem("user_id", response.data.Sponsor.sponsor_id);
            localStorage.setItem(
              "user_name",
              response.data.Sponsor.sponsor_name
            );
            localStorage.setItem(
              "parent_data",
              JSON.stringify(response.data.Sponsor)
            );
            localStorage.setItem("user", JSON.stringify(response.data.Sponsor));
            localStorage.setItem("csr_token", response.data.AccessToken);
            console.log(response.data.TA_TOKEN?.Response_Code,'Response Code');
            if(response.data.TA_TOKEN?.Response_Code==="1000"){
                localStorage.setItem("TA_TOKEN",response?.data.TA_TOKEN?.New_Token);
            }
            // createToken();
            dispatch(loginSuccess());
            dispatch(reEnter(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire("Error", "Invalid Credentials", "error");
        });
    } else {
      dispatch(login(data));
    }
  };

  const validateEmail = (mail) => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  };

  const sendNewPassword = () => {
    if (registeredEmail.trim() == "") {
      alert("Please enter email address.");
    } else {
      if (!validateEmail(registeredEmail)) {
        alert("Please enter a valid email address.");
      } else {
        if (props.userType === "sp") {
          let values = {
            sponsor_email: registeredEmail,
          };

          axios({
            method: "POST",
            url: baseURL + "auth/forgotPasswordSponsor",
            data: values,
          })
            .then((response) => {
              if (response.data.Status === "200") {
                setActiveSection(2);
              }
            })
            .catch(function (error) {
              if (error.response) {
                // console.log(error.response.data.Message);
                // alert(error.response.data.Message);

                alert(
                  "Check email you have entered, it does not exist in database"
                );
              }
            });
        } else {
          let values = {
            admin_email: registeredEmail,
          };

          axios({
            method: "POST",
            url: baseURL + "auth/forgotPassword",
            data: values,
          })
            .then((response) => {
              if (response.data.Status === "200") {
                setActiveSection(2);
              }
            })
            .catch(function (error) {
              if (error.response) {
                // console.log(error.response.data.Message);
                // alert(error.response.data.Message);

                alert(
                  "Check email you have entered, it does not exist in database"
                );
              }
            });
        }
      }
    }
  };

  const verifyOTP = () => {
    if (!validateEmail(registeredEmail)) {
      alert("Please enter a valid email address.");
    } else {
      if (props.userType === "sp") {
        let values = {
          sponsor_email: registeredEmail,
          email_otp: otp1 + otp2 + otp3 + otp4 + otp5 + otp6,
        };

        axios({
          method: "POST",
          url: baseURL + "auth/verifyEmailOTPSponsor",
          data: values,
        })
          .then((response) => {
            debugger;

            if (response.data.Message === 400) {
              alert(response.data.Content);
            } else {
              setActiveSection(3);
              setResetUserId(response.data.Content[0].sponsor_id);
            }
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response);
              alert(error.response.data.Message);
            }
          });
      } else {
        let values = {
          admin_email: registeredEmail,
          email_otp: otp1 + otp2 + otp3 + otp4 + otp5 + otp6,
        };
        axios({
          method: "POST",
          url: baseURL + "auth/verifyEmailOTP",
          data: values,
        })
          .then((response) => {
            if (response.data.Message === 400) {
              alert(response.data.Content);
            } else {
              setActiveSection(3);
              setResetUserId(response.data.Content[0].admin_id);
            }
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response);
              alert(error.response.data.Message);
            }
          });
      }
    }
  };

  const resetPassword = () => {
    if (newPassword.trim().length < 8) {
      alert("Minimum 8 character password required.");
    } else if (newPassword.trim() !== confirmPassword.trim()) {
      alert("Password not matching.");
    } else {
      if (props.userType === "sp") {
        debugger;

        let values = {
          sponsor_id: resetUserId,
          password: newPassword,
        };
        axios({
          method: "POST",
          url: baseURL + "auth/resetPasswordSponsor",
          data: values,
        })
          .then((response) => {
            setActiveSection(0);
            alert("Your password reset successfully.");
          })
          .catch(function (error) {
            if (error.response) {
              alert(error.response.data.Message);
            }
          });
      } else {
        let values = {
          admin_id: resetUserId,
          password: newPassword,
        };
        axios({
          method: "POST",
          url: baseURL + "auth/resetPassword",
          data: values,
        })
          .then((response) => {
            setActiveSection(0);
            alert("Your password reset successfully.");
          })
          .catch(function (error) {
            if (error.response) {
              alert(error.response.data.Message);
            }
          });
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <VStack
      contentContainerStyle={{
        flexGrow: 1,
      }}
      style={{
        flex: 1,
      }}
    >
      <VStack
        flex='1'
        px='6'
        py='9'
        _light={{
          bg: "white",
        }}
        _dark={{
          bg: "coolGray.800",
        }}
        space='3'
        justifyContent='space-between'
        borderTopRightRadius={{
          base: "2xl",
          md: "xl",
        }}
        borderBottomRightRadius={{
          base: "0",
          md: "xl",
        }}
        borderTopLeftRadius={{
          base: "2xl",
          md: "0",
        }}
        shadow={4}
      >
        {activeSection === 0 ? (
          <VStack space='7'>
            <Hidden till='md'>
              <Text fontSize='lg' fontWeight='normal'>
                Sign in to continue as{" "}
                {props.userType === "sp"
                  ? "Sponsor"
                  : props.userType === "tr"
                  ? "Service Partner"
                  : "Validator"}
              </Text>
            </Hidden>
            <VStack>
              <form ref={form1} onSubmit={() => console.log("submit")}>
                <VStack space='3'>
                  <VStack
                    space={{
                      base: "7",
                      md: "4",
                    }}
                  >
                    <VStack space='1'>
                      <Controller
                        control={control}
                        defaultValue='' //admin@optsociety.com
                        name='lid'
                        render={({ field }) => (
                          <NzInput
                            {...field}
                            placeholder='Email'
                            value={useremail}
                            autoComplete='off'
                            onChange={(e) => setUserEmail(e.target.value)}
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        }}
                      />
                      {errors.lid && errors.lid.type === "required" && (
                        <Box px={2} ml={"3%"}>
                          Email is mandatory
                        </Box>
                      )}
                      {errors.lid && errors.lid.type === "pattern" && (
                        <Box px={2} ml={"3%"}>
                          Email is not valid
                        </Box>
                      )}
                    </VStack>
                    <VStack space='1'>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='p1'
                        render={({ field }) => (
                          <NzInput
                            {...field}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyPress={handleKeyPress}
                            autoComplete='off'
                            InputRightElement={
                              show ? (
                                <Eye
                                  mr='2'
                                  size='4'
                                  onClick={() => setShow(!show)}
                                />
                              ) : (
                                <EyeSlash
                                  mr='2'
                                  size='4'
                                  onClick={() => setShow(!show)}
                                />
                              )
                            }
                            placeholder='Password'
                            type={show ? "text" : "password"}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                      {errors.p1 && errors.p1.type === "required" && (
                        <Box px={2} ml={"3%"}>
                          Password is mandatory
                        </Box>
                      )}
                      {auth.status === "InvalidUser" && (
                        <Box px={2} ml={"3%"}>
                          Couldn't find your Optshine Account
                        </Box>
                      )}
                      {auth.status === "WrongPWD" && (
                        <Box px={2} ml={"3%"}>
                          Wrong Password. Try again or click "Forgot Password"
                          to reset it.
                        </Box>
                      )}
                      {auth.status === "Invalid Credential" ? (
                        <Box px={2} ml={"3%"}>
                          <Text color='pink.600'>
                            The email id or password you entered is incorrect.
                          </Text>
                        </Box>
                      ) : null}
                      {auth.status === "networkerror" && (
                        <Box px={2} ml={"3%"}>
                          <Text color='pink.600'>
                            Something went wrong. Please try again.
                          </Text>
                        </Box>
                      )}
                    </VStack>
                  </VStack>
                  <a
                    href='/'
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveSection(1);
                    }}
                  >
                    <Text color='#7e22ce' textAlign={"end"}>
                      Forgot password?
                    </Text>
                  </a>
                  <NzButton
                    my={5}
                    // onPress={(e) => {
                    //   props.history.push("/dashBoard");
                    // }}
                    onPress={
                      () => onSubmit()
                      // form1.current.dispatchEvent(
                      //   new Event("submit", { bubbles: true, cancelable: true })
                      // )
                    }
                  >
                    LOGIN
                  </NzButton>
                  <HStack space='2' alignItems='center' justifyContent='center'>
                    <Divider
                      w='30%'
                      _light={{
                        bg: "coolGray.200",
                      }}
                      _dark={{
                        bg: "coolGray.700",
                      }}
                    ></Divider>
                    <Text
                      fontWeight='medium'
                      _light={{
                        color: "coolGray.300",
                      }}
                      _dark={{
                        color: "coolGray.500",
                      }}
                    >
                      or
                    </Text>
                    <Divider
                      w='30%'
                      _light={{
                        bg: "coolGray.200",
                      }}
                      _dark={{
                        bg: "coolGray.700",
                      }}
                    ></Divider>
                  </HStack>
                  <NzButton
                    mt={5}
                    // onPress={(e) => {
                    //   props.history.push("/dashBoard");
                    // }}
                    variant='secondary'
                    onPress={(e) => {
                      props.history.push(
                        props.userType === "sp"
                          ? "sponsor/register"
                          : props.userType === "tr"
                          ? "service-partner/register"
                          : "validator/register"
                      );
                    }}
                  >
                    Register
                  </NzButton>
                </VStack>
              </form>
            </VStack>
          </VStack>
        ) : null}
        {activeSection === 1 ? (
          <VStack space='7'>
            <Hidden till='md'>
              <Text fontSize='lg' fontWeight='normal'>
                Forgot Password?
              </Text>
            </Hidden>
            <VStack>
              <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
                <VStack space='3'>
                  <VStack
                    space={{
                      base: "7",
                      md: "4",
                    }}
                  >
                    <VStack space='1'>
                      <Controller
                        control={control}
                        defaultValue='' //admin@optsociety.com
                        name='lid'
                        render={({ field }) => (
                          <NzInput
                            {...field}
                            placeholder='Registered Email'
                            value={registeredEmail}
                            onChange={(e) => setRegisteredEmail(e.target.value)}
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        }}
                      />
                      {errors.lid && errors.lid.type === "required" && (
                        <Box px={2} ml={"3%"}>
                          Email is mandatory
                        </Box>
                      )}
                      {errors.lid && errors.lid.type === "pattern" && (
                        <Box px={2} ml={"3%"}>
                          Email is not valid
                        </Box>
                      )}
                    </VStack>
                  </VStack>
                  <NzButton
                    my={5}
                    // onPress={(e) => {
                    //   props.history.push("/dashBoard");
                    // }}
                    onPress={() => sendNewPassword()}
                  >
                    SEND EMAIL
                  </NzButton>
                  <HStack space='2' alignItems='center' justifyContent='center'>
                    <Divider
                      w='30%'
                      _light={{
                        bg: "coolGray.200",
                      }}
                      _dark={{
                        bg: "coolGray.700",
                      }}
                    ></Divider>
                    <Text
                      fontWeight='medium'
                      _light={{
                        color: "coolGray.300",
                      }}
                      _dark={{
                        color: "coolGray.500",
                      }}
                    >
                      or
                    </Text>
                    <Divider
                      w='30%'
                      _light={{
                        bg: "coolGray.200",
                      }}
                      _dark={{
                        bg: "coolGray.700",
                      }}
                    ></Divider>
                  </HStack>
                  <NzButton
                    mt={5}
                    variant='secondary'
                    onPress={(e) => {
                      setActiveSection(0);
                    }}
                  >
                    Back to Login
                  </NzButton>
                </VStack>
              </form>
            </VStack>
          </VStack>
        ) : null}
        {activeSection === 2 ? (
          <VStack space='7'>
            <Hidden till='md'>
              <Text fontSize='lg' fontWeight='normal'>
                Enter OTP received in email!
              </Text>
            </Hidden>
            <VStack>
              <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
                <VStack space='3'>
                  <VStack
                    space={{
                      base: "7",
                      md: "4",
                    }}
                  >
                    <VStack space='1'>
                      <Row>
                        <Col>
                          <Controller
                            control={control}
                            defaultValue='' //admin@optsociety.com
                            name='lid'
                            render={({ field }) => (
                              <NzInput
                                placeholder={"Enter your 6 digit OTP"}
                                maxLength={6}
                                {...field}
                                type={"number"}
                                value={otp1}
                                onChange={(e) => setOTP1(e.target.value)}
                                style={{ textAlign: "center" }}
                              />
                            )}
                            rules={{
                              required: true,
                              pattern:
                                /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            }}
                          />
                        </Col>
                        {/* <Col>
                    <Controller
                      control={control}
                      defaultValue="" //admin@optsociety.com
                      name="lid"
                      render={({ field }) => (
                        <NzInput maxLength={1} {...field} type={'number'} value={otp2} onChange={(e)=> setOTP2(e.target.value)} style={{textAlign:'center'}} />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    </Col>
                    <Col>
                    <Controller
                      control={control}
                      defaultValue="" //admin@optsociety.com
                      name="lid"
                      render={({ field }) => (
                        <NzInput maxLength={1} {...field} type={'number'} value={otp3} onChange={(e)=> setOTP3(e.target.value)} style={{textAlign:'center'}} />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    </Col>
                    <Col>
                    <Controller
                      control={control}
                      defaultValue="" //admin@optsociety.com
                      name="lid"
                      render={({ field }) => (
                        <NzInput maxLength={1} {...field} type={'number'} value={otp4} onChange={(e)=> setOTP4(e.target.value)} style={{textAlign:'center'}} />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    </Col>
                    <Col>
                    <Controller
                      control={control}
                      defaultValue="" //admin@optsociety.com
                      name="lid"
                      render={({ field }) => (
                        <NzInput maxLength={1} {...field} type={'number'} value={otp5} onChange={(e)=> setOTP5(e.target.value)} style={{textAlign:'center'}} />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    </Col>
                    <Col>
                    <Controller
                      control={control}
                      defaultValue="" //admin@optsociety.com
                      name="lid"
                      render={({ field }) => (
                        <NzInput maxLength={1} {...field} type={'number'} value={otp6} onChange={(e)=> setOTP6(e.target.value)} style={{textAlign:'center'}} />
                      )}
                      rules={{
                        required: true,
                        pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      }}
                    />
                    </Col> */}
                      </Row>
                    </VStack>
                  </VStack>
                  <NzButton
                    my={5}
                    // onPress={(e) => {
                    //   props.history.push("/dashBoard");
                    // }}
                    onPress={() => verifyOTP()}
                  >
                    VERIFY OTP
                  </NzButton>

                  <NzButton
                    my={5}
                    // onPress={(e) => {
                    //   props.history.push("/dashBoard");
                    // }}
                    onPress={() => sendNewPassword()}
                  >
                    RESEND OTP
                  </NzButton>
                </VStack>
              </form>
            </VStack>
          </VStack>
        ) : null}
        {activeSection === 3 ? (
          <VStack space='7'>
            <Hidden till='md'>
              <Text fontSize='lg' fontWeight='normal'>
                Reset Password!
              </Text>
            </Hidden>
            <VStack>
              <form ref={form1} onSubmit={handleSubmit(onSubmit)}>
                <VStack space='3'>
                  <VStack
                    space={{
                      base: "7",
                      md: "4",
                    }}
                  >
                    <VStack space='1'>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='p1'
                        render={({ field }) => (
                          <NzInput
                            {...field}
                            InputRightElement={
                              newPasswordVisible ? (
                                <Eye
                                  mr='2'
                                  size='4'
                                  onClick={() =>
                                    setNewPasswordVisible(!newPasswordVisible)
                                  }
                                />
                              ) : (
                                <EyeSlash
                                  mr='2'
                                  size='4'
                                  onClick={() =>
                                    setNewPasswordVisible(!newPasswordVisible)
                                  }
                                />
                              )
                            }
                            placeholder='Password'
                            type={newPasswordVisible ? "text" : "password"}
                            autoComplete={false}
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </VStack>
                    <VStack space='1'>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='p1'
                        render={({ field }) => (
                          <NzInput
                            {...field}
                            InputRightElement={
                              confirmPasswordVisible ? (
                                <Eye
                                  mr='2'
                                  size='4'
                                  onClick={() =>
                                    setConfirmPasswordVisible(
                                      !confirmPasswordVisible
                                    )
                                  }
                                />
                              ) : (
                                <EyeSlash
                                  mr='2'
                                  size='4'
                                  onClick={() =>
                                    setConfirmPasswordVisible(
                                      !confirmPasswordVisible
                                    )
                                  }
                                />
                              )
                            }
                            placeholder='Confirm Password'
                            type={confirmPasswordVisible ? "text" : "password"}
                            autoComplete={false}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </VStack>
                  </VStack>
                  <NzButton
                    my={5}
                    // onPress={(e) => {
                    //   props.history.push("/dashBoard");
                    // }}
                    onPress={() => resetPassword()}
                  >
                    RESET PASSWORD
                  </NzButton>
                </VStack>
              </form>
            </VStack>
          </VStack>
        ) : null}
      </VStack>
    </VStack>
  );
}

function Login(props) {
  const width = useBreakpointValue({
    sm: "90%", //after 480px
    md: "80%", //after 768px
    lg: "40%", //after 992px
    xl: "30%", //after 1280px
  });

  const bgColor = useBreakpointValue({
    base: "primary.1000",
    sm: "primary.1000", //after 480px
    md: "transparent", //after 768px
    lg: "transparent", //after 992px
    xl: "transparent", //after 1280px
  });

  console.log(width);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor='transparent'
        barStyle='light-content'
      />
      <Box
        safeAreaTop
        _light={{
          bg: "primary.1000",
        }}
        _dark={{
          bg: "coolGray.900",
        }}
      />
      <Center my='auto' bg={bgColor} h='100vh'>
        <Stack
          flexDirection={{
            base: "column",
            md: "row",
          }}
          w='100%'
          maxW={{
            md: "768px",
          }}
          flex={{
            base: "1",
            md: "none",
          }}
        >
          <Hidden from='md'>
            <VStack px='4' mt='4' mb='5' space='9'>
              <VStack space='5'>
                <NzlogoHeader variant='inverted' />
                <Text fontSize='3xl' fontWeight='bold' color='coolGray.50'>
                  Welcome back!
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till='md'>
            <Center
              flex='1'
              bg='primary.1000'
              borderTopLeftRadius={{
                base: "0",
                md: "xl",
              }}
              borderBottomLeftRadius={{
                base: "0",
                md: "xl",
              }}
            >
              <NzlogoHeader variant='inverted' />
            </Center>
          </Hidden>
          <LoginForm {...props} />
        </Stack>
      </Center>
    </>
  );
}

export default Login;
