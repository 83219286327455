import Nz1024 from "./nz1024";
import NzBannerCard from "./nzBannerCard";
import NzButton from "./nzButton";
import NzFlexWrap from "./nzFlexWrap";
import NzLabel from "./nzLabel";
import NzPhone from "./nzPhone";
import NzHeading from "./nzHeading";
import NzInput from "./nzInput";
import NzSelect from "./nzSelect";
import NzTextarea from "./nztextarea";

export {
  Nz1024,
  NzBannerCard,
  NzButton,
  NzFlexWrap,
  NzInput,
  NzHeading,
  NzLabel,
  NzPhone,
  NzSelect,
  NzTextarea,
};
