import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { baseURL } from '../../config/baseURLPath';

const GenderGraph = () => {
  const [genderData, setGenderData] = useState([]);

  useEffect(() => {

    const userData = JSON.parse(localStorage.getItem('user'))

    // Fetch the gender distribution data from the API
    axios({
      method:'get',
      url:baseURL+'sponsor-gender-wise/'+userData.Sponsor.sponsor_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }) 
      .then(data => {
        console.log(data?.data?.data?.gender,'DATA GENDER')
        setGenderData(data?.data?.data?.gender);
      });
  }, []);

  const options = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Gender'
    },
    tooltip: {
      valueSuffix: '%'
    },
    subtitle: {
      text: '(Counts in %)'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%',
          distance: -50, // Pull the labels inside the pie slices
          style: {
            color: 'white',
            textOutline: 'none', // Remove text outline for better visibility
            fontWeight: 'bold',
          },
        },
        showInLegend: true, // Optional: Show labels in a legend
      },
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: genderData // Use the fetched gender data
      }
    ]
  };

  return (
    <div className='chart-container'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default GenderGraph;

