import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Highcharts3D from 'highcharts/highcharts-3d';
import Cylinder from 'highcharts/modules/cylinder';
import axios from 'axios';
import { baseURL } from '../../config/baseURLPath';

// Initialize 3D and Cylinder modules
Highcharts3D(Highcharts);
Cylinder(Highcharts);

const TopPrograms = () => {
  const [programsData, setProgramsData] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'))

    axios({
      method:'get',
      url:baseURL+'sponsor-chart-data/'+userData.Sponsor.sponsor_id,
      headers: { 'access-token': localStorage.getItem('csr_token') },
    }).then(data => {
      console.log(data?.data?.data?.topPrograms,'Top Program')
        const chartData = data?.data?.data?.topPrograms.map(program => program.count);
        const categories = data?.data?.data?.topPrograms.map(program => program.name);
        
        setProgramsData({ chartData, categories });
      });
  }, []);

  const chartOptions = {
    chart: {
      type: 'column', // Specify as 'column' and then configure 3D settings
      options3d: {
        enabled: true,
        alpha: 15,
        beta: 15,
        depth: 50,
        viewDistance: 25,
      },
    },
    title: {
      text: 'Top Programs',
    },
    subtitle: {
      text:'(Included Beneficiaries)'
    },
    plotOptions: {
      series: {
        depth: 25, // Depth of the cylinders
        dataLabels: {
          enabled: true,
          format: '{point.y}', // Display the number of students on each cylinder
        },
      },
      column: {
        colorByPoint: true,
        edgeWidth: 1, // Edge width of the cylinders
        edgeColor: 'black', // Edge color of the cylinders
      },
    },
    xAxis: {
      categories: programsData.categories || [],  // Use the fetched program names
      title: {
        text: 'Programs',
      },
    },
    yAxis: {
      title: {
        text: 'Number of Students',
      },
    },
    series: [
      {
        type: 'cylinder', // Set series type to 'cylinder'
        data: programsData.chartData || [], // Use the fetched data
        name: 'Students',
        showInLegend: false, // Hide legend for simplicity
      },
    ],
  };

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default TopPrograms;
