import { Switch, Route } from 'react-router-dom'
import React, { lazy, Suspense } from 'react'
import Login from './adminLogin'
import Header from './common/Header'
import SideBar from './common/SideBar'
import Dashboard from './Dashboard'
import './admincss.css'
import NotFound from './pages/NotFound'

// Admins
const ListAdmin = lazy(() => import('./pages/manage-admin/List'))
const EditAdmin = lazy(() => import('./pages/manage-admin/Edit'))

// Institution
// const ListInstitution = lazy(() => import("./pages/manage-institution/List"))
// const AddInstitution = lazy(() => import("./pages/manage-institution/Add"))
// const EditInstitution = lazy(() => import("./pages/manage-institution/institution"))

// Students
const ListStudent = lazy(() => import('./pages/manage-student/List'))
const AddStudent = lazy(() => import('./pages/manage-student/Add'))
const EditStudent = lazy(() => import('./pages/manage-student/Edit'))

// Sponsors
const ListSponsors = lazy(() => import('./pages/manage-sponsors/List'))
const AddSponsors = lazy(() => import('./pages/manage-sponsors/Add'))
const EditSponsors = lazy(() => import('./pages/manage-sponsors/sponsors'))

// Leads
const ListLeads = lazy(() => import('./pages/manage-leads/List'))
const EmailLogs = lazy(() => import('./pages/manage-leads/EmailLog'))
// Vendor
// const ListVendor = lazy(() => import("./pages/manage-vendor/List"))
// const AddVendor = lazy(() => import("./pages/manage-vendor/Add"))
// const EditVendor = lazy(() => import("./pages/manage-vendor/vendors"))

// Validator
// const ListValidator = lazy(() => import("./pages/manage-validator/List"))
// const AddValidator = lazy(() => import("./pages/manage-validator/Add"))
// const EditValidator = lazy(() => import("./pages/manage-validator/vendors"))

// Programs
const ProgramList = lazy(() => import('./pages/manage-program/List'))
const Program = lazy(() => import('./pages/manage-program/index'))
const ProgramDetail = lazy(() => import('./pages/manage-program/ProgramDetails'))

//Add beneficiary
const BeneficiariesAdd = lazy(() => import('./pages/manage-beneficiary/Beneficiaries-add'))

// Beneficiaries Questions
const BeneficiariesQuestions = lazy(() =>
  import('./pages/manage-beneficiary/Beneficiaries-questions'),
)

const MappingBeneficiariesInstitution = lazy(()=> import("./pages/MappingBeneficiariesInstitution"))


const BulkUploadBeneficiaries = lazy(()=>import ('./pages/BulkUploadBeneficiaries'));

const tags = lazy(() => import('./pages/manage-tags/AddList'))
const courses = lazy(() => import('./pages/manage-institution-courses/List'))
const addCoursesAdmin = lazy(() => import('./pages/manage-institution-courses/Add'))
const AddAdminUsers = lazy(() => import('./pages/manage-users/manage-users'))
const AddSponsor = lazy(() => import('./pages/manage-users/add-sponsor'))
const AddAdminValidator = lazy(() => import('./pages/manage-users/add-validator-partner'))
const AddServicePartner = lazy(() => import('./pages/manage-users/add-service-partner'))

//Master routes
const Skills = lazy(() => import('./pages/Master/manage-skills/List'))
const Subjects = lazy(() => import('./pages/Master/manage-subjects/List'))
// const SubjectCategory = lazy(()=>import("./pages/Master/manage-subjects/SubjectCategoryList"));
const Skills_category = lazy(() => import('./pages/Master/skill-category/List'))
const College_master = lazy(() => import('./pages/Master/collage-master/college-master'))
const Sports = lazy(() => import('./pages/Master/Sports/List'))
const IncomeGroup = lazy(() => import('./pages/Master/Income-group/List'))
const PerformaceCriteria = lazy(() => import('./pages/Master/performance-criteria/List'))
const Qualifications = lazy(() => import('./pages/Master/Qualifications/List'))
const ExpenseCategory = lazy(() => import('./pages/Master/Expense-category/List'))
const Beneficiaries = lazy(() => import('./pages/manage-beneficiary/Beneficiaries'))
const SubjectMapping = lazy(() => import('./pages/Master/manage-subjects/SubjectMapping'))
const SubjectSkillMapping = lazy(() => import('./pages/Master/manage-subjects/SubjectSkillMapping'))

//Reports
const BeneficiaryReport = lazy(() => import('./pages/reports/BeneficiaryReports'))
const CSRFundsUtilisation = lazy(() => import('./pages/reports/FundsUtilisation'))
const BeneficiariesDis = lazy(() => import('./pages/reports/beneficiaryDistribution'))
const BeneficiariesPerformace = lazy(() => import('./pages/reports/beneficiariesPerformance'));



function Admin() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/admin" render={(props) => <Login {...props} />} />
        <AdminLayoutRoute exact path="/admin/dashboard" component={Dashboard} />
        {/* Admin */}
        <AdminLayoutRoute exact path="/admin/admin-list" component={ListAdmin} />
        <AdminLayoutRoute exact path="/admin/admin-add" component={AddAdminUsers} />
        <AdminLayoutRoute exact path="/admin/admin-edit" component={EditAdmin} />

        <AdminLayoutRoute exact path="/admin/admin-add-sponsor" component={AddSponsor} />
        <AdminLayoutRoute
          exact
          path="/admin/admin-add-service-partner"
          component={AddServicePartner}
        />
        <AdminLayoutRoute exact path="/admin/admin-add-validator" component={AddAdminValidator} />

        {/* Institution */}
        {/* <AdminLayoutRoute exact path="/admin/vendor-list" component={ListInstitution} />
        <AdminLayoutRoute exact path="/admin/vendor-add" component={AddInstitution} />
        <AdminLayoutRoute exact path="/admin/vendor-edit" component={EditInstitution} /> */}

        {/* Students */}
        <AdminLayoutRoute exact path="/admin/student-list" component={ListStudent} />
        <AdminLayoutRoute exact path="/admin/student-add" component={AddStudent} />
        <AdminLayoutRoute exact path="/admin/student-edit" component={EditStudent} />

        {/* Sponsors */}
        <AdminLayoutRoute exact path="/admin/sponsors-list" component={ListSponsors} />
        <AdminLayoutRoute exact path="/admin/sponsor-add" component={AddSponsors} />
        <AdminLayoutRoute exact path="/admin/sponsor-edit" component={EditSponsors} />

        {/* Leads */}
        <AdminLayoutRoute exact path="/admin/leads-list" component={ListLeads} />
        <AdminLayoutRoute exact path="/admin/email-logs" component={EmailLogs} />

        {/* Program */}
        <AdminLayoutRoute exact path="/admin/program/:id" component={ProgramDetail} />
        <AdminLayoutRoute exact path="/admin/program-list" component={ProgramList} />
        <AdminLayoutRoute exact path="/admin/program-add" component={Program} />

        {/* Admin Courses */}
        <AdminLayoutRoute exact path="/admin/courses" component={courses} />
        <AdminLayoutRoute exact path="/admin/edit-course/:course_id" component={addCoursesAdmin} />
        <AdminLayoutRoute exact path="/admin/add-course" component={addCoursesAdmin} />

        {/*Beneficiaries Questions */}
        <AdminLayoutRoute exact path="/admin/beneficiary-add" component={BeneficiariesAdd} />
        <AdminLayoutRoute  exact path="/admin/upload-beneficiaries" component={BulkUploadBeneficiaries}/>
        <AdminLayoutRoute
          exact
          path="/admin/beneficiary-questions"
          component={BeneficiariesQuestions}
        />
        <AdminLayoutRoute exact path="/admin/beneficiaries-mapping" component={MappingBeneficiariesInstitution} />
       
       
       
        <AdminLayoutRoute exact path="/admin/beneficiary" component={Beneficiaries} />
        <AdminLayoutRoute exact path="/admin/tags" component={tags} />
        <AdminLayoutRoute exact path="/admin/skills" component={Skills} />
        <AdminLayoutRoute exact path="/admin/skills-category" component={Skills_category} />
        <AdminLayoutRoute exact path="/admin/sports" component={Sports} />
        <AdminLayoutRoute exact path="/admin/income-group" component={IncomeGroup} />
        <AdminLayoutRoute exact path="/admin/performance-criteria" component={PerformaceCriteria} />
        <AdminLayoutRoute exact path="/admin/qualifications" component={Qualifications} />
        <AdminLayoutRoute exact path="/admin/expense-category" component={ExpenseCategory} />
        <AdminLayoutRoute exact path="/admin/college-master" component={College_master} />

        {/**Manage Subjects */}
        <AdminLayoutRoute exact path="/admin/subjects" component={Subjects} />
        {/* <AdminLayoutRoute exact path="/admin/subjects-category" component={SubjectCategory} /> */}
        <AdminLayoutRoute exact path="/admin/subjects-mapping" component={SubjectMapping} />
        <AdminLayoutRoute
          exact
          path="/admin/subjects-skill-mapping"
          component={SubjectSkillMapping}
        />
        {/**Reports */}
        <AdminLayoutRoute exact path="/admin/reports/beneficiary" component={BeneficiaryReport} />
        <AdminLayoutRoute
          exact
          path="/admin/reports/CSRFundsUtilisation"
          component={CSRFundsUtilisation}
        />

        <AdminLayoutRoute
          exact
          path="/admin/reports/getBeneficiaryDistribution"
          component={BeneficiariesDis}
        />
        <AdminLayoutRoute 
          exact
          path="/admin/reports/beneficiariesPerformance"
          component={BeneficiariesPerformace}
        />

      </Switch>
    </div>
  )
}

export default Admin

const AdminLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <div
          style={{
            backgroundColor: '#ffffff',
            // color: 'white',
            backgroundSize: 'cover',
            minHeight: '100vh',
          }}
        >
          <Header />
          <div className="contentArea">
            <div>
              <SideBar />
            </div>
            <Suspense fallback={<p> Loading...</p>}>
              <div className="fixContent">
                <Component {...props} />
              </div>
            </Suspense>
          </div>
        </div>
      )}
    />
  )
}
