import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Row, Col, Button } from "react-bootstrap"
import { baseURL } from "../config/baseURLPath"
import NzlogoHeader from "../components/NzComponents/NzlogoHeader"

import axios from "axios"
import "./admincss.css"
const AdminLogin = () => {
  const history = useHistory()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")

  const handleLogin = (e) => {
    e.preventDefault()
  
    if (!username || !password) setMessage("Please enter credentials")
    else {
      axios({
        method: "post",
        url: baseURL + "auth/adminSignin",
        data: { admin_email: username, password },
      })
        .then((response) => {
          console.log(response, "REsponse")
          if (response.status === 200) {
            localStorage.setItem("user_id", response?.data?.AdminUser.admin_id)
            localStorage.setItem("user", JSON.stringify(response?.data?.AdminUser))
            localStorage.setItem("csr_token", response?.data?.AccessToken)
            history.push("/admin/dashboard")
          } else {
            setMessage("Invalid Credential")
          }
        })
        .catch((error) => {
          setMessage("Invalid credentials")
        })
    }
  }

  return (
    <>
      <div>
        <Row style={{ margin: 0 }}>
          <Col md={4}></Col>
          <Col
            md={4}
            className="content-box"
            style={{
              marginTop: "25vh",
              height: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center", 
              backgroundColor:"#002347"
            }}
          >
            <div style={{padding:"10px"}}>
              <NzlogoHeader variant="inverted"  />

            </div>
              
            <Row style={{ margin: 0  }}>
              <Col md={12} style={{ padding: "0px", }}>
                <Col xs={12}>
                  <Row>
                    <Col
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                  
                    </Col>
                  </Row>
                </Col>
                <p className="headingText" style={{ textAlign: "center" , color:"white"}}>
                  ADMIN LOGIN
                </p>
                <Row>
                  <Col md={12}>
                    <div style={{ height: "60px" }}>
                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                    </div>
                    <input
                      autoComplete="none"
                      className="form-control"
                      type="email"
                      placeholder="Email *"
                      value={username}
                      onChange={(e) => {
                        setMessage("")
                        setUsername(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Col md={12}>
                    <input
                      autoComplete="none"
                      className="form-control"
                      type="password"
                      placeholder="Password *"
                      value={password}
                      onChange={(e) => {
                        setMessage("")
                        setPassword(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row className="actionButtonDiv">
                  <Button
                    className="primaryButton"
                    style={{ backgroundColor: "#7E4C24" }}
                    onClick={handleLogin}
                  >
                    SUBMIT
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row>
      </div>
    </>
  )
}

export default AdminLogin
